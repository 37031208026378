import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { IMedia } from "./InvestmentsAndFundsEditController.web";



export interface ITattoo{
     body_part: string, 
     files: (File | IMedia)[],
}

export interface IPhysical {
    height_in_feet: string,
    height_in_inch: string,
    eye_colour: string,
    hair_colour: string,
    visible_mark: string,
    have_any_tattoo: boolean,
    tattoo_details_attributes: ITattoo[]
};

interface IPhysicalInformation {
        id: string;
        type: "physical_informations";
        attributes: IPhysicalAttributes;
}

interface IPhysicalAttributes {
    id: number;
    height_in_feet: string;
    height_in_inch: string;
    eye_colour: string;
    hair_colour: string;
    visible_mark: string;
    have_any_tattoo: boolean;
    account_id: number;
    tattoo_details: ITattooDetails;
}

interface ITattooDetails {
    data: ITattooDetail[];
}

interface ITattooDetail {
    id: string;
    type: "tattoo_details";
    attributes: TattooAttributes;
}

interface TattooAttributes {
    id: number;
    body_part: string;
    files: IMedia[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  physical: IPhysical,
  isEdit: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutPhysicalEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize : number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        physical: {
            height_in_feet: '',
            height_in_inch: '',
            eye_colour: '',
            hair_colour: '',
            visible_mark: '',
            have_any_tattoo: false,
            tattoo_details_attributes: [{ body_part: '', files: [] }],
          },
          isLoading: false,
          isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callGetPhysicalDataApiId: string = "";
  callEditPhysicalDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getPhysicalData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetPhysicalDataApiId]: this.handleGetPhysicalDataApiResponse,
        [this.callEditPhysicalDataApiId]: this.handleEditPhysicalDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getPhysicalData = async() => {
    const token = await getStorageData("token");

    this.callGetPhysicalDataApiId = sendAPIRequest(
        configJSON.getAboutMePhysicalDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetPhysicalDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
        meta?: { message: string };
        data?: IPhysicalInformation;
    };

    if (response.data) {
        const attributes = response.data.attributes;
  
        let physical = {
            height_in_feet: attributes.height_in_feet,
            height_in_inch: attributes.height_in_inch,
            eye_colour: attributes.eye_colour,
            hair_colour: attributes.hair_colour,
            visible_mark: attributes.visible_mark,
            have_any_tattoo: attributes.have_any_tattoo,
            tattoo_details_attributes: attributes.tattoo_details.data.length > 0 ? attributes.tattoo_details.data.map(
                (tattoo) => {
                  return(
                    {
                        body_part: tattoo.attributes.body_part, 
                        files: tattoo.attributes.files?.map((mediaFile) => {
                            return({
                              file_id: mediaFile.file_id,
                              file_name: mediaFile.file_name,
                              content_type: mediaFile.content_type,
                              file_size: mediaFile.file_size,
                              url: mediaFile.url,
                            })
                          }) || [],
                    }
                  )
                }
              ) : [{ body_part: '', files: [] }],
        } as IPhysical;
        this.setState({ physical });
      }
  };

  handleEditPhysicalDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }
    this.setState({isEdit: false});
    if (responseJSON.data) {
    this.props.handleSuccess()
    }
  };

  // Toggle edit mode
  toggleEditMode = () => {
    this.setState({ isEdit: true });
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: IMedia | File): Promise<Blob> => {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
      return file as Blob;
    }
  };

  handleAboutPhysicalDataFormSubmit = async (values: IPhysical) => {
    if(!this.state.isEdit){
      this.toggleEditMode();
      return;
    }
    const token = await getStorageData("token");
    const formData = new FormData();
    this.setState({isLoading: true})
    Object.entries(values).forEach(([keyName, value]) => {
        if(keyName !== "tattoo_details_attributes"){
          formData.append(`physcial[${keyName}]`, value);
        }
      });


    if (values.have_any_tattoo) {
      await this.appendTattooDetails(formData, values.tattoo_details_attributes);
    }

    this.callEditPhysicalDataApiId = sendAPIRequest(
      configJSON.getAboutMePhysicalDataApiEndPoint,
      {
        method: configJSON.postApiRequest,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  // Helper to append tattoo details to formData
  appendTattooDetails = async (formData: FormData, tattooDetails: ITattoo[]) => {
    for (const tattoo of tattooDetails) {
      for (const [keyName, value] of Object.entries(tattoo)) {
        if (value) {
          if (keyName === "files" && Array.isArray(tattoo.files) && tattoo.files.length > 0) {
            await this.appendTattooFiles(formData, tattoo.files);
          } else {
            formData.append(`physcial[tattoo_details_attributes][][${keyName}]`, value as string);
          }
        }
      }
    }
  };

  // Helper to append tattoo files to formData
  appendTattooFiles = async (formData: FormData, files: (File | IMedia)[]) => {
    for (const file of files) {
      const blobFile = await this.convertToBlob(file);
      formData.append(`physcial[tattoo_details_attributes][][files][]`, blobFile);
    }
  };

  validationSchema = () => {
    if (this.state.isEdit) {
      return (
        Yup.object().shape({
          height_in_feet: Yup.string().required('Feet is required'),
          height_in_inch: Yup.string().required('Inch is required').min(0).max(11, 'Inches must be between 0 and 11'),
          eye_colour: Yup.string().required('Eye color is required'),
          hair_colour: Yup.string().required('Hair color is required'),
          tattoo_details_attributes: Yup.mixed().when('have_any_tattoo', {
            is: true,
            then: Yup.array().of(
              Yup.object().shape({
                body_part: Yup.string().nullable().required('Body part is required'),
              })
            ),
            otherwise: Yup.array().nullable(),
          }),
        })
      )
    }
    else {
      return Yup.object().shape({});
    }
  }

  handleSwitchChange = async (
    value: boolean,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
     setFieldValue(`have_any_tattoo`, value);
  }

    handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: (File | IMedia)[]) => {
          if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
            prevFiles = [];
          }
          if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            const oversizedFiles = newFiles.filter(file => file.size > this.maxFileSize);
            if (oversizedFiles.length > 0) {
              this.props.handleError(configJSON.maxFileSizeError)
              return
            }
            let files = [...prevFiles, ...newFiles]
            setFieldValue(`tattoo_details_attributes.${index}.files`, files)
          }
    };

  // Customizable Area End
}
