import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
  Container,
  createTheme
} from "@material-ui/core";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { 
  Field, 
  FieldArray, 
  Form, 
  Formik, 
  FormikErrors, 
  FormikTouched, 
  FormikValues 
} from "formik";
import { 
  DialogWrapper,
  StyledRadio 
} from "../../../blocks/customform/src/LegalInformation.web";
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import ErrorMessage from "../../../components/src/ErrorMessage";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import AfterWishesEditController, {
  configJSON,
  IAfterWishesInformation,
  IWish,
  Props,
} from "./AfterWishesEditController.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
export const theme = createTheme({
    overrides: {
      MuiTypography: {
        h1: {
          fontFamily: "Quattrocento",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
        },
        h2: {
          fontFamily: "Lato",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "28px",
          color: "#9176C4",
        },
        h3: {
          fontFamily: "Quattrocento",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "22px",
        },
        subtitle1: {
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "26px",
          color: "#94A3B8",
        },
        body2: {
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#64748B",
        },
      },
      MuiFormLabel: {
          root: {
            color: "#334155", 
            fontFamily: "Lato", 
            fontSize: "14px", 
            lineHeight: "22px",
            fontWeight: 400,
          }
      },
  
      MuiButton: {
        outlined: {
          width: "248px",
          height: "48px",
          color: "#9176C4",
          fontFamily: "Lato",
          textTransform: "none",
          fontSize: '16px',
          fontWeight: 600,
          borderRadius: "100px",
        },
        contained: {
          width: "248px",
          height: "48px",
          backgroundColor: "#A190C2",
          color: "#FFFFFF",
          textTransform: "none",
          fontFamily: "Lato",
          fontSize: '16px',
          fontWeight: 600,
          borderRadius: "100px",
        },
      },
      MuiRadio: {
          colorSecondary: {
            '&$checked': {
              color: "#9176C4",
          },
          }
      },
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: "#A190C2",
          },
        }
      },
      MuiSwitch: {
        root: {
          borderRadius: "40px",
          padding: 0,
          width: "48px",
          height: "28px",
        },
        switchBase: {
          color: "#fff",
          padding: "2px",
          '&$checked': {
            transform: 'translateX(20px)',
            color: "#fff",
            '& + $track': {
              opacity: 1,
              borderColor: "#A190C2 !important",
              backgroundColor: "#A190C2 !important",
            },
      },
        },
        thumb: {
          width: "24px",
          boxShadow: 'none',
          height: "24px",
        },
        track: {
          color: "fff",
          backgroundColor: "gray",
          borderRadius: "40px",
          opacity: 1,
        },
        colorPrimary:{
          '&$checked':{
            padding: "2px",
            color: "#fff",
          }
        }
      },
      MuiInputBase: {
        root: {
          minHeight: '48px',
        },
      },
    },
  });
// Customizable Area End

export default class AfterWishesEdit extends AfterWishesEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAfterWishesForm = (
    values: FormikValues,
    errors: FormikErrors<IAfterWishesInformation>, 
    touched: FormikTouched<IAfterWishesInformation>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        const { 
            isEditWishes } = this.state;
    return(
        <Grid item xs={12}>
            <Grid container spacing={2} className={`${isEditWishes ? "formSectionBackground" : ""}`}>
                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Typography data-test-id="afterWishesFormTitle" variant={isEditWishes ? "h3" : "h2"}>{configJSON.afterWishesFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.delegateInChargeFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="delegateId"
                        className="selectField"
                        name="delegateId"
                        as={CustomSelect}
                        disabled={!isEditWishes}
                        setValue={true}
                        placeholder="Select Delegate"
                        options={this.state.delegateInChargeWishes}
                        value={values.delegate_id !== null ? values.delegate_id : "1"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("delegate_id", e.target.value)
                        }}
                        error={touched.delegate_id && errors.delegate_id}
                        errorMsg={errors.delegate_id}
                    />
                </Grid>
                <FieldArray name="my_wishes_attributes" data-test-id="fieldArray">
                    {({ remove, push }) => (
                        values.my_wishes_attributes.map((myWish: IWish, index: number) => {
                            return (
                                <>
                                    <Grid item xs={12}>
                                        <Box className="myWishLabelWrapper">
                                            <FormLabel component="label">{configJSON.myWishFieldLabel}</FormLabel>
                                            {index !== 0 && isEditWishes && (
                                                <Button
                                                    data-test-id="addMoreWishes"
                                                    className="addMoreWishesBtn"
                                                    style={{ padding: "0px" }}
                                                    onClick={() => remove(index)}>
                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                    {configJSON.removeBtnText}
                                                </Button>)}
                                        </Box>
                                        <Field
                                            data-test-id="myWish"
                                            name="myWish"
                                            size="medium"
                                            disabled={!isEditWishes}
                                            multiline
                                            style={{ minHeight: "84px !important" }}
                                            as={CustomInput}
                                            placeholder="Eg, Please donate to a dog centre in memory of me"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`my_wishes_attributes.${index}.wish`, e.target.value)
                                            }
                                            value={myWish.wish}
                                        />
                                        <ErrorMessage name={`my_wishes_attributes.${index}.wish`} />
                                        <Box>
                                            {index === 0 && isEditWishes &&(
                                                <Button
                                                    className="addMoreWishesBtn"
                                                    data-test-id="addMoreWishesBtn"
                                                    onClick={() => push({
                                                        id: 0,
                                                        wish: "",
                                                    })}
                                                >
                                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                    {configJSON.addMoreWishesBtnText}
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </>
                            );
                        })
                    )}
                </FieldArray>
            </Grid>
        </Grid>
  )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
        wishes_information,
        showAfterWishesForm,
        openDialog,
        stepsWishes,
        isEditWishes
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} />
            <Box className="container">
             <Container>
             <Box>
                        <BreadcrumbStepper data-test-id ="breadcrumbStepperids" steps={stepsWishes} onStepClick={(path) => this.handleNavigationWishes(path)} />
                    </Box>
                    <Box className="afterWishesWrapper">
                <Box className="wishesPageTitle">
                    <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                        {configJSON.afterWishesPageTitle}
                    </Typography>
                </Box>
                <Box className="wishesFormWrapper wishesWrapperContainer">
                  <Formik
                    initialValues={wishes_information}
                    validationSchema={this.validationSchema(isEditWishes)}
                    onSubmit={(values) => {
                      this.handleWishesInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="afterWishesForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form className="wishesWrapperContainer">
                        <Box minHeight="calc(100% - 100px)" className="wishesFormWrapper">
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                              { isEditWishes && (
                               <Box>
                                <FormLabel component="label" className="formRadioLabel">
                                {configJSON.afterWishesQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="havePassed"
                                  aria-label="havePassed"
                                  name="havePassed"
                                  value={values.have_passed}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("have_passed", e.target.value);
                                    this.setState({ showAfterWishesForm: !showAfterWishesForm });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                               </Box>
                              )}
                                { !isEditWishes && values.have_passed === "0" && (
                                <Box>
                                  <Typography variant="h2">
                                    {configJSON.wishesTitle}
                                  </Typography>
                                  <Typography variant="subtitle1" className="dataNotAvailable">
                                    {configJSON.dataNotAvailable}
                                  </Typography>
                                </Box>
                              )}
                              </Grid>
                              {  values.have_passed === "1" &&(
                                    this.renderAfterWishesForm(values, errors, touched, setFieldValue)
                              )}
                            </Grid>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                                variant="outlined"
                                className="buttonSize"
                                onClick={() => this.handleNavigationWishes("CustomForm")}
                                data-test-id="skipForNowBtn"
                            >
                              {configJSON.backBtnText}
                            </Button>
                            { isEditWishes ? (
                            <Button
                              type="submit"
                              variant="contained"
                              className="buttonSize"
                              data-test-id="saveAndNextBtn"
                            >
                              {configJSON.saveChangesBtnText}
                            </Button>):
                            (
                                  <Button
                                    className="buttonSize"
                                    data-test-id="editDetailsBtn"
                                    variant="contained"
                                    type="button"
                                    onClick={this.handleEditWishesClick}
                                  >
                                    {configJSON.editDetailsBtnText}
                                  </Button>    
                            )
                            }
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
             </Container>
             
            </Box>
            <WebFooter handleNavigation={this.handleNavigationWishes} />
          </Wrapper>
        </ThemeProvider>
        <GenericModal open={openDialog} data-test-id="data-edited" onClose={this.handleCloseDialogWishes}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <img alt="success" 
                  src={rightTickImg} 
                  style={{ 
                    width: "56px", 
                    height: "56px", 
                    margin: "0px auto" 
                  }} />
                  <Typography variant="body2" align="center">
                    {configJSON.dialogMessageText}
                  </Typography>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .afterWishesWrapper": {
    width: "100%",
  },
  "& .container": {
    display: "flex",
    padding: "50px 72px",
    gap: "72px",
  },
  "& .wishesPageTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
  },
  "& .wishesFormWrapper": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "40px",
  },
  "& .wishesWrapperContainer":{
    height: "100%"
  },
  "& .selectField": {
    width: "275px",
    height: "48px",
    display: "block",
    borderRadius: "8px",
    margin: "5px 0px",
  },
  "& .radioLabel": {
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: '#1E293B',
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .radioGroup": {
    marginLeft: "16px",
    display: 'flex',
    gap: "35px",
    flexDirection: 'row',
  },
  "& .formRadioLabel": {
    fontSize: "16px",
  },
  "& .formSectionBackground": {
      padding: "24px",
      backgroundColor: "rgba(206, 195, 229, 0.1)",
      borderRadius: "8px",
      marginBottom: "24px", 
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .buttonSize": {
      width: "158px",
      height: "48px",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .addMoreWishesBtn": {
      textTransform: "none",
      fontSize: "14px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#9176C4",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
    '&:hover': {
        textDecoration: "underline",
        backgroundColor: "transparent",
    },
  },
  "& .myWishLabelWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  "& .errorMsg": {
    display: "flex",
    fontSize: "12px",
    color: "#DC2626",
    textAlign: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End
