// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
  createTheme,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Field, Form, Formik } from "formik";
import ErrorMessage from "../../../components/src/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import CustomInput from "../../../components/src/CustomInput";
import { ThemeProvider, styled } from "@material-ui/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Quattrocento",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "44px",
      },
      h2: {
        color: "#9176C4",
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "44px",
      },
      h3: {
        fontFamily: "Quattrocento",
        fontSize: "24px",
        color: "#0F172A",
        fontWeight: 700,
        lineHeight: "42px",
      },
      subtitle1: {
        color: "#1E293B",
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "40px",
      },
      subtitle2: {
        color: "#1E293B",
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
      },
      body1: {
        color: "#9176C4",
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "40px",
        fontStyle: "italic"
      },
    },
    MuiFormLabel: {
      root: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      }
    },

    MuiButton: {
      contained: {
        width: "248px",
        height: "48px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
    },
    MuiInputBase: {
      root: {
        minHeight: '48px',
      },
    },
  },
});

import ReportDeathController, {
  Props,
  configJSON,
} from "./ReportDeathController.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";

const Wrapper = styled("div")(() => ({
  "& .container": {
    justifyContent: "space-between",
    padding: "60px 72px",
  },
  "& .reportContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "40px",
    marginTop: '30px'
  },
  "& .reportFormContainer": {
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: 12,
    padding: '44px 34px',
    flexDirection: "column"
  },
  "& .reportInputField": {
    height: "48px",
    width: "100%",
    borderRadius: "8px",
  },
  "& .leftContainer": {
    padding: '15px 40px 10px 0px',
    gap: "17px"
  }
}));
// Customizable Area End

export default class ReportDeath extends ReportDeathController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} />
            <Grid container className="container">
              <Grid item xs={12} md={6}>
                <Grid container direction="column" className="leftContainer">
                  <Typography variant="h2">
                    {configJSON.reportADeathHeading}
                  </Typography>
                  <Typography variant="h1">
                    {configJSON.howDoesItWorkHeading}
                  </Typography>
                  <Typography variant="subtitle1">
                    {configJSON.reportSteps}
                  </Typography>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {configJSON.howDoesItWorkStep1}
                    </Typography>
                    <Typography variant="subtitle1">
                      {configJSON.howDoesItWorkStep2}
                    </Typography>
                    <Typography variant="subtitle1">
                      {configJSON.howDoesItWorkStep3}
                    </Typography>
                    <Typography variant="subtitle1">
                      {configJSON.howDoesItWorkStep4}
                    </Typography>
                  </Grid>
                  <Typography variant="subtitle2">
                    {configJSON.howDoesItWorkNote}
                  </Typography>
                  <Typography variant="h3">
                    {configJSON.whatIsSecurityPhraseHeading}
                  </Typography>
                  <Typography variant="subtitle1">
                    {configJSON.whatIsSecurityPhraseDescription}
                  </Typography>
                  <Typography variant="body1">
                    "{configJSON.contactUsNote}"
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container className="reportFormContainer">
                  <Typography variant="h3">
                    {configJSON.reportFormHeading}
                  </Typography>
                  <Typography variant="subtitle2">
                    {configJSON.reportFormInstruction}
                  </Typography>
                  <Box className="reportContainer">
                    <Formik
                      initialValues={this.state.reports}
                      validationSchema={this.reportValidationSchema}
                      onSubmit={(values, { resetForm }) => {
                        this.handleReportDeathForm(values, resetForm)
                      }}
                      enableReinitialize={true}
                      data-test-id="reportFormikForm"
                    >
                      {({ values, setFieldValue }) => (
                        <Form translate="no">
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportNameLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <Field
                                  data-test-id="reportName"
                                  className="reportInputField"
                                  name={configJSON.reportNameLabel}
                                  as={CustomInput}
                                  size="small"
                                  placeholder={configJSON.reportNamePlaceholder}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`name`, e.target.value)
                                  }}
                                  value={values.name}
                                />
                                <ErrorMessage name={`name`} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportEmailLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <Field
                                  data-test-id="reportEmail"
                                  className="reportInputField"
                                  name={configJSON.reportEmailLabel}
                                  as={CustomInput}
                                  size="small"
                                  placeholder={configJSON.reportEmailPlaceholder}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`email`, e.target.value)
                                  }}
                                  value={values.email}
                                />
                                <ErrorMessage name={`email`} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportPhoneNumberLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <PhoneInput
                                  data-test-id="phone_number"
                                  placeholder={configJSON.reportPhoneNumberPlaceholder}
                                  country={'gb'}
                                  value={values.full_phone_number}
                                  onChange={(value) =>
                                    setFieldValue("full_phone_number", value)
                                  }
                                  inputStyle={{
                                    padding: '10px 45px',
                                    width: '100%',
                                    height: '48px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                  }}
                                  buttonStyle={{
                                    padding: '2px',
                                    border: '1px solid #e0e0e0',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                  }}
                                  containerStyle={{
                                    margin: '5px 0',
                                    width: '100%',
                                    height: '48px',
                                  }}
                                  inputProps={{
                                    required: true,
                                    autoFocus: true,
                                    name: 'phone',
                                  }}
                                />
                                <ErrorMessage name={`full_phone_number`} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportDeceasedNameLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <Field
                                  data-test-id="deceasedName"
                                  className="reportInputField"
                                  name={configJSON.reportDeceasedNameLabel}
                                  as={CustomInput}
                                  size="small"
                                  placeholder={configJSON.reportDeceasedNamePlaceholder}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`deceased_name`, e.target.value)
                                  }}
                                  value={values.deceased_name}
                                />
                                <ErrorMessage name={`deceased_name`} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportDeceasedEmailLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <Field
                                  data-test-id="reportDecEmail"
                                  className="reportInputField"
                                  name={configJSON.reportDeceasedEmailLabel}
                                  as={CustomInput}
                                  size="small"
                                  placeholder={configJSON.reportDeceasedEmailPlaceholder}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`deceased_email`, e.target.value)
                                  }}
                                  value={values.deceased_email}
                                />
                                <ErrorMessage name={`deceased_email`} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label">
                                {configJSON.reportSecurityPhraseLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Grid item xs={12} >
                                <Field
                                  data-test-id="securityPhrase"
                                  className="reportInputField"
                                  name={configJSON.reportSecurityPhraseLabel}
                                  as={CustomInput}
                                  size="small"
                                  placeholder={configJSON.reportSecurityPhrasePlaceholder}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`security_phrase`, e.target.value)
                                  }}
                                  value={values.security_phrase}
                                  endIcon={infoIcon}
                                />
                                <ErrorMessage name={`security_phrase`} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Box justifyContent="center" display="flex" mt="25px">
                            <Button
                              variant="contained"
                              data-test-id="saveAndNextBtn"
                              type="submit"
                            >
                              {configJSON.reportDeathButton}
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

const infoIcon = styled(InfoOutlinedIcon)({
  fill: "#7b818a",
});

// Customizable Area End
