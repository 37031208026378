import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { sendAPIRequest } from "../../../components/src/Utils";
import { toast } from "react-toastify";

interface ContactUsData {
    email: string;
    full_phone_number: string;
    full_address: string}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface contactUSForm {
    name: string;
    email: string;
    country_code: {dialCode: string};
    phone_number: string;
    query_type: string;
    subject: string;
    additional_detail: string;
    description: string;
}

interface S {
    // Customizable Area Start
    ContactUsData: ContactUsData
    contactUSForm: contactUSForm
    queryOptions: { name: string, value: string }[]
    selectedQuery: string
    contactForm: boolean
    successMessage: string;
    successDialog: boolean
    // Customizable Area End
}

interface SS {
    id: string;
}

export default class ContactUSFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            ContactUsData: {
                email: "",
                full_phone_number: "",
                full_address: ""
            },
            contactUSForm: {
                name: "",
                email: "",
                country_code: {dialCode: "44"},
                phone_number: "",
                query_type: "",
                subject: "",
                additional_detail: "",
                description: "",
            },
            selectedQuery: "",
            contactForm: false,
            queryOptions: [
                {
                    value: "ask_a_question",
                    name: "Ask a question"
                },
                {
                    value: "press_and_media",
                    name: "Press and Media"
                },
                {
                    value: "online_purchase",
                    name: "Online Purchase"
                },
                {
                    value: "user_card_enquiry",
                    name: "User card enquiry"
                },
                {
                    value: "user_login_enquiry",
                    name: "User login/enquires"
                }
            ],
            successMessage: "",
            successDialog: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.ContactUsAPICallID) {
            if (responseJSON?.data) {
                let { email,
                    full_phone_number,
                    full_address } = responseJSON?.data?.attributes
                let customData = { ...this.state.ContactUsData }
                customData.email = email;
                customData.full_address = full_address;
                customData.full_phone_number = full_phone_number
                this.setState({ ContactUsData: customData })
            }
        }
        if(apiRequestCallId === this.contactUsFormAPICallID){
            if(responseJSON.data){
                this.setState({successMessage: responseJSON?.meta?.message , successDialog: true})
                 !this.state.contactForm && this.reset()
            }else{
                let cvError = responseJSON?.errors[0]
        if (cvError) {
          let key = Object.keys(cvError)[0]
           toast.error(cvError[key])
        }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    ContactUsAPICallID: string = "";
    contactUsFormAPICallID: string = "";
    reset: any

    async componentDidMount() {
        super.componentDidMount();
        this.getContactUsDataList();
    }


    getContactUsDataList = () => {
        this.ContactUsAPICallID = sendAPIRequest(
            configJSON.getcontactUsAddEndPoint,
            {
                method: configJSON.validationApiMethodType,
            }
        );
    };

    openContactPopUp = (type: string) =>{
        this.setState({selectedQuery: type , contactForm: true})
    }
    ContactUSFormValidationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters')
            .max(50, 'Name can be at most 50 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(/^\d{7,15}$/, 'Phone number must be between 7 and 15 digits')
            .required('Phone number is required'),
        query_type: Yup.string()
            .required('Query type is required'),
        subject: Yup.string()
            .required('Subject is required'),
        description: Yup.string()
            .required('Description is required')
    });

    handleCloseContactPopUp = () =>{
        this.setState({contactForm: false, selectedQuery: ""})
    }
    removeDialCode(phone : string, dialCode : string) {
        if (phone.startsWith(dialCode)) {
            return phone.slice(dialCode.length);
        }
        return phone;
    }
    handleContactUSFormSubmit = (values: contactUSForm , resetForm : any) => {
       let {name,
        email,
        country_code,
        phone_number,
        query_type,
        subject,
        additional_detail,
        description} = values

        const transformedData = {
            data: {
                attributes: {
                name: name,
                email: email,
                country_code: country_code.dialCode,
                phone_number: this.removeDialCode(phone_number, country_code.dialCode),
                query_type: query_type,
                subject: subject,
                additional_detail: additional_detail,
                description: description
              }
            }
          };
          this.reset = resetForm
          this.contactUsFormAPICallID = sendAPIRequest(
            configJSON.getcontactUsFormEndPoint,
            {
                method: configJSON.httpPostMethod,
                headers: {
                    "Content-Type": configJSON.contactUsApiContentType,
                  },
                body: transformedData
            }
        );
    }
    getFormName = (value: string) =>{
        const selectedOption = this.state.queryOptions.find(option => option.value === value);
        return selectedOption?.name
    }
    closeSuccessDialog = () =>{
        this.setState({successDialog: false, successMessage: ""})
        this.handleCloseContactPopUp()
    }
    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    // Customizable Area End
}
