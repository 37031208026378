import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IStep } from "./FriendsDetailsEditController.web";

// Customizable Area Start
interface ITabList {
  key: string, 
  value: string,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTabIndex: number
  selectedTab: string
  errorMessage: string;
  isError: boolean;
  successDialog: boolean;
  tabList: ITabList[];
  steps: IStep[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutMeEditController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        isError: false,
        selectedTab:"physical",
        selectedTabIndex: 1,
        errorMessage: "",
        tabList:[
          {
              key: "physical",
              value: "Physical",
          },
          {
              key: "favourite",
              value: "Favourite",
          },
          {
              key: "hobbies",
              value: "Hobbies",
          } ,
          {
              key: "dreams",
              value: "Dreams",
          },
          {
              key: "proudest_moment",
              value: "Proudest Moment",
          } ,
          {
              key: "achievements",
              value: "Achievements",
          },
          {
              key: "cv",
              value: "CV",
          }],
        successDialog: false,
        steps: [
          { label: 'Home', path: 'HomePage' },
          { label: 'My Data', path: 'HomePage' },
          { label: 'About Me Data', path: 'AboutMeEdit' },
        ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  onTabClick = (key: string, index: number) =>{
    this.setState({selectedTab: key, selectedTabIndex: index})
  }

  onErrorHit = (errorMessage: string) =>{
    if(errorMessage){
      this.setState({isError: true, errorMessage})
    }
  }

  onSuccessClick = () =>{
    this.setState({successDialog: true})
  }

  onNextClick = (path: string) =>{
    if(path === "CustomForm"){
      this.handleNavigation(path)
    }
    let {selectedTabIndex, tabList} = this.state
    let nextTabIndex = selectedTabIndex + 1;
    
    if (nextTabIndex < tabList.length) {
      let updatedTabList = [...this.state.tabList]
      updatedTabList[selectedTabIndex] = {
        ...updatedTabList[selectedTabIndex],
      };  
      this.setState({successDialog: false, tabList: updatedTabList})
     this.onTabClick(tabList[nextTabIndex].key , nextTabIndex)
    }else{
      this.handleNavigation(path)
    }
  }

  handleCloseSnackbar = ()=>{
    this.setState({errorMessage: "", isError: false});
  };

  handleCloseDialog = () => {
    this.setState({ successDialog: false });
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
