import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IMedia } from "../../../blocks/customform/src/LegalInformationController.web";
import { IAttributes } from "../../../blocks/customform/src/AdditionalInfoSidebarController.web";


interface IGetFriendsDetailsAPIResponse {
    id: string;
    type: string;
    attributes: {
        id: number;
        friend_name: string;
        friend_summary: string;
        location: string;
        description: string;
        media: {
            image_location: string;
            image_date: string;
            files: IMedia[];
        };
        have_favourite_memory: string;
        favourite_memories: {
            id: number;
            title: string;
            loction: string;
            time_stamp: string;
            files: IMedia[];
        }[];
        have_closest_friend: string;
        close_friends: {
            id: number;
            full_name: string;
            about: string;
            loction: string;
            description: string | null;
            add_memory_with_close_friend: boolean;
            media: {
                image_location: string;
                image_date: string;
                files: IMedia[];
            };
            memories: [
              {
                  id: number,
                  title: string,
                  loction: string,
                  time_stamp: string,
                  files: IMedia[];
              }
          ]
        }[];
        have_group_memory: string;
        group_memories: {
            id: number;
            friend_name: string[];
            files: IMedia[];
        }[];
        account_id: number;
    };
    meta: {
        message: string;
    };
};

export interface IFriendsDetails {
    friend_name: string;
    friend_summary: string;
    location: string;
    description: string;
    have_favourite_memory: string;
    have_closest_friend: string;
    have_group_memory: string;
    friend_media_attributes: IFriendMediaAttributes;
    friend_memories_attributes: IFriendMemoriesAttributes[];
    close_friends_attributes: ICloseFriendsAttributes[];
    group_memories_attributes: IGroupMemoriesAttributes[];
};

export interface IFriendMediaAttributes {
    image_location: string;
    image_date: string;
    files: (File | IMedia)[];
};

export interface IFriendMemoriesAttributes {
    title: string;
    loction: string;
    time_stamp: string;
    files: (File | IMedia)[];
};

export interface ICloseFriendsAttributes {
    full_name: string;
    about: string;
    loction: string;
    description: string;
    add_memory_with_close_friend: boolean;
    friend_media_attributes: IFriendMediaAttributes;
    friend_memories_attributes: IFriendMemoriesAttributes;
};

export interface IGroupMemoriesAttributes {
    friend_name: string[];
    files: (File | IMedia)[];
};
export interface IStep {
    label: string;
    path: string;
  };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  friendsDetails: IFriendsDetails;
  filesForFriendMedia: (IMedia | File)[];
  filesForFriendMemories: (IMedia | File)[][];
  filesForCloseFriends: (IMedia | File)[][];
  filesForCloseFriendsMemories: (IMedia | File)[][];
  filesForGroupMemories: (IMedia | File)[][];
  showFriendsMemory: boolean;
  showClosestFriend: boolean;
  showGroupMemory: boolean;
  showCloseFriendMemories: boolean;
  openDialog: boolean;
  subscriptionData: IAttributes | null;
  isEdit: boolean;
  friendSteps: IStep[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FriendsDetailsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        friendsDetails: {
            friend_name: "",
            friend_summary: "",
            location: "",
            description: "",
            have_favourite_memory: "0",
            have_closest_friend: "0",
            have_group_memory: "0",
            friend_media_attributes: {
                image_location: "",
                image_date: "",
                files: [],
            },
            friend_memories_attributes: [{
                title: "",
                loction: "",
                time_stamp: "",
                files: [],
            }],
            close_friends_attributes: [{
                full_name: "",
                about: "",
                loction: "",
                description: "",
                add_memory_with_close_friend: false,
                friend_media_attributes: {
                    image_location: "",
                    image_date: "",
                    files: [],
                },
                friend_memories_attributes: {
                    title: "",
                    loction: "",
                    time_stamp: "",
                    files: [],
                }
            }],
            group_memories_attributes: [{
                friend_name: [" ", " ", " ", " ", " "],
                files: [],
            }],
        },
        filesForFriendMedia: [],
        filesForFriendMemories: [],
        filesForCloseFriends: [],
        filesForCloseFriendsMemories: [],
        filesForGroupMemories: [],
        showFriendsMemory: false,
        showClosestFriend: false,
        showGroupMemory: false,
        showCloseFriendMemories: false,
        openDialog: false,
        subscriptionData: null,
        isEdit: false,
        friendSteps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Friends Details', path: 'FriendsDetailsEdit' },
          ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetFriendsDetailsApiId: string = "";
  callUpsertFriendsDetailsApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getFriendsDetails();

    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetFriendsDetailsApiId]: this.handleGetFriendsDetailsApiResponse,
      [this.callUpsertFriendsDetailsApiId]: this.handleUpsertFriendsDetailsApiResponse,

    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getFriendsDetails = async() => {
    const token = await getStorageData("token");

    this.callGetFriendsDetailsApiId = sendAPIRequest(
        configJSON.getFriendsDetailsApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleFriendsDetailsFormSubmit = async (values: IFriendsDetails) => {
    if(!this.state.isEdit){
        this.toggleEditMode();
      } else
      {
        const token = await getStorageData("token");
        const formData = new FormData();
        Object.entries(values).forEach(([keyName, value]) => {
            if(keyName !== "friend_media_attributes" && keyName !== "friend_memories_attributes" && keyName !== "close_friends_attributes" && keyName !== "group_memories_attributes"){
              formData.append(`friend[${keyName}]`, value);
            }
          });
      
          Object.entries(values.friend_media_attributes).forEach(([keyName, value]) => {
            if (value) {
              if(keyName === 'files'){
                this.processFilesForFriendMedia(value, keyName, formData);
              }
              else{
                formData.append(`friend[friend_media_attributes][][${keyName}]`, value);
              }
            }
          });
      
          values.friend_memories_attributes.forEach((friendMemory, index) => {
            Object.entries(friendMemory).forEach(([keyName, value]) => {
              if (value) {
                if(keyName === 'files'){
                  this.processFilesForFriendMemory(friendMemory, keyName, formData, index);
                }
                else{
                  formData.append(`friend[friend_memories_attributes][][${keyName}]`, value as string);
                }
              }
            })
          });
      
          values.close_friends_attributes.forEach((closeFriend, index) => {
            Object.entries(closeFriend).forEach(([keyName, value]) => {
              if (value) {
                if(keyName === 'friend_media_attributes'){
                  this.processNestedAttributes(
                    value,
                    index,
                    formData,
                    this.processFilesForCloseFriend,
                    `friend[close_friends_attributes][][friend_media_attributes][]`
                  );
                }
                else if(keyName === 'friend_memories_attributes'){
                  this.processNestedAttributes(
                    value,
                    index,
                    formData,
                    this.processFilesForCloseFriendMemories,
                    `friend[close_friends_attributes][][friend_memories_attributes][]`
                  );
                }
                else{
                  formData.append(`friend[close_friends_attributes][][${keyName}]`, value as string);
                }
              }
            })
          });
      
          values.group_memories_attributes.forEach((groupMemory, index) => {
            Object.entries(groupMemory).forEach(([keyName, value]) => {
              if (value) {
                if(keyName === 'files'){
                  this.processFilesForGroupMemories(groupMemory, keyName, formData, index);
                }
                else{
                  formData.append(`friend[group_memories_attributes][][${keyName}][]`, value as string);
                }
              }
            })
          });
      
          this.callUpsertFriendsDetailsApiId = sendAPIRequest(
              configJSON.getFriendsDetailsApiEndPoint,
              {
                method: configJSON.formAPiMethod,
                headers: {
                  token,
                },
                body: formData,
              }
          );
      }
 
  };
  // Toggle edit mode and update steps
  toggleEditMode = () => {
    this.setState((prevState) => ({
      isEdit: true,
      friendSteps: [...prevState.friendSteps, { label: 'Edit Details', path: 'FriendsDetailsEdit' }],
    }));
  };
  handleEditBtnClickFrd = (event: any) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isEdit: true,
      friendSteps: [...prevState.friendSteps, { label: 'Edit Details', path: 'FriendsDetailsEdit' }],
    }));
  }
   // Helper function to process nested attributes
    processNestedAttributes = (
      attributes: any,
      index: number,
      formData: FormData,
      processFilesFunction: Function,
      pathPrefix: string
    ) => {
      Object.entries(attributes).forEach(([key, val]: any) => {
        if (val) {
          if (key === 'files') {
            processFilesFunction(attributes, key, formData, index);
          } else {
            formData.append(`${pathPrefix}[${key}]`, val as string);
          }
        }
      });
    };

  // Helper function to handle file processing for Friend media
  processFilesForFriendMedia = (
    friendMedia:(File | IMedia)[],
    keyName: string,
    formData: FormData
  ) => {
    if(friendMedia.length > 0 && this.state.filesForFriendMedia.length > 0) {
      friendMedia.forEach((file) => {
        if(this.state.filesForFriendMedia){
          formData.append(`friend[friend_media_attributes][][${keyName}][]`, file as Blob);
        }
      });
    }
  };

  // Helper function to handle file processing for Friend memory
  processFilesForFriendMemory = (
    friendMemory: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (friendMemory.files && friendMemory.files.length > 0 && this.state.filesForFriendMemories.length > 0) {
      friendMemory.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForFriendMemory(file, keyName, formData, index, fileIndex);
      });
    }
  };

  // Helper function to check if a file exists and append it to formData for friend memory
  appendFileToFormDataForFriendMemory = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForFriendMemories[index] && this.state.filesForFriendMemories[index][fileIndex]) {
      formData.append(`friend[friend_memories_attributes][][${keyName}][]`, file as Blob);
    }
  };

  // Helper function to handle file processing for close friend
  processFilesForCloseFriend = (
    closeFriendFiles: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (closeFriendFiles.files && closeFriendFiles.files.length > 0 && this.state.filesForCloseFriends.length > 0) {
      closeFriendFiles.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForCloseFriend(file, keyName, formData, index, fileIndex);
      });
    }
  };

  // Helper function to check if a file exists and append it to formData for close friend media
  appendFileToFormDataForCloseFriend = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForCloseFriends[index] && this.state.filesForCloseFriends[index][fileIndex]) {
      formData.append(`friend[close_friends_attributes][][friend_media_attributes][][${keyName}][]`, file as Blob);
    }
  };

  // Helper function to handle file processing for close friend memories
  processFilesForCloseFriendMemories = (
    closeFriendMemoryFiles: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (closeFriendMemoryFiles.files && closeFriendMemoryFiles.files.length > 0 && this.state.filesForCloseFriendsMemories.length > 0) {
      closeFriendMemoryFiles.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForCloseFriendMemories(file, keyName, formData, index, fileIndex);
      });
    }
  };

  // Helper function to check if a file exists and append it to formData for close friend memories
  appendFileToFormDataForCloseFriendMemories = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForCloseFriendsMemories[index] && this.state.filesForCloseFriendsMemories[index][fileIndex]) {
      formData.append(`friend[close_friends_attributes][][friend_memories_attributes][][${keyName}][]`, file as Blob);
    }
  };

  // Helper function to handle file processing for group memories
  processFilesForGroupMemories = (
    groupMemoryFiles: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (groupMemoryFiles.files && groupMemoryFiles.files.length > 0 && this.state.filesForGroupMemories.length > 0) {
      groupMemoryFiles.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForGroupMemories(file, keyName, formData, index, fileIndex);
      });
    }
  };

  // Helper function to check if a file exists and append it to formData for group memories
  appendFileToFormDataForGroupMemories = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForGroupMemories[index] && this.state.filesForGroupMemories[index][fileIndex]) {
      formData.append(`friend[group_memories_attributes][][${keyName}][]`, file as Blob);
    }
  };


  updateStateFlags = (attributes: any) => {
    this.setState({
      showFriendsMemory: attributes.have_favourite_memory === "Yes",
      showClosestFriend: attributes.have_closest_friend === "Yes",
      showGroupMemory: attributes.have_group_memory === "Yes",
    });
  };

  getYesNoFlag = (value: string) => {
    return value === "Yes" ? "1" : "0";
  };

  handleGetFriendsDetailsApiResponse = (
  
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) { return; }
    
    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFriendsDetailsAPIResponse;
      };
  
      if (response.data) {
        const attributes = response.data.attributes;

        this.updateStateFlags(attributes);

        let friendsDetails = {
            friend_name: attributes.friend_name,
            friend_summary: attributes.friend_summary,
            location: attributes.location,
            description: attributes.description,
            have_favourite_memory: this.getYesNoFlag(attributes.have_favourite_memory),
            have_closest_friend: this.getYesNoFlag(attributes.have_closest_friend),
            have_group_memory: this.getYesNoFlag(attributes.have_group_memory),
            friend_media_attributes: {
                image_location: attributes.media.image_location, 
                image_date: attributes.media.image_date,
                files: attributes.media.files?.map((mediaFile: IMedia) => {
                    return ({
                        file_id: mediaFile.file_id,
                        file_name: mediaFile.file_name,
                        content_type: mediaFile.content_type,
                        file_size: mediaFile.file_size,
                        url: mediaFile.url,
                    });
                }) || [],
            },
            friend_memories_attributes: attributes.favourite_memories.length > 0 ? attributes.favourite_memories.map(
                (memory) => {
                    return (
                        {
                            title: memory.title,
                            loction: memory.loction,
                            time_stamp: memory.time_stamp,
                            files: memory.files?.map((mediaFile: IMedia) => {
                              return ({
                                  file_id: mediaFile.file_id,
                                  file_name: mediaFile.file_name,
                                  content_type: mediaFile.content_type,
                                  file_size: mediaFile.file_size,
                                  url: mediaFile.url,
                              });
                          }) || [],
                        }
                    );
                }
            ) : [{
              title: "",
              loction: "",
              time_stamp: "",
              files: [],
          }],
            close_friends_attributes: attributes.close_friends.length > 0 ? attributes.close_friends.map(
                (memory) => {
                    return (
                        {
                            full_name: memory.full_name,
                            about: memory.about,
                            loction: memory.loction,
                            description: memory.description,
                            add_memory_with_close_friend: memory.add_memory_with_close_friend,
                            friend_media_attributes: {
                                image_location: memory.media.image_location,
                                image_date: memory.media.image_date,
                                files: memory.media.files?.map((mediaFile: IMedia) => {
                                    return ({
                                        file_id: mediaFile.file_id,
                                        file_name: mediaFile.file_name,
                                        content_type: mediaFile.content_type,
                                        file_size: mediaFile.file_size,
                                        url: mediaFile.url,
                                    });
                                }) || [],
                            },
                            friend_memories_attributes: {
                              title: memory.memories[0].title,
                              loction: memory.memories[0].loction,
                              time_stamp: memory.memories[0].time_stamp,
                              files: memory.memories[0].files?.map((mediaFile: IMedia) => {
                                return ({
                                    file_id: mediaFile.file_id,
                                    file_name: mediaFile.file_name,
                                    content_type: mediaFile.content_type,
                                    file_size: mediaFile.file_size,
                                    url: mediaFile.url,
                                });
                            }) || [],
                            }
                        }  
                    );
                }
            ) : [{
              full_name: "",
              about: "",
              loction: "",
              description: "",
              add_memory_with_close_friend: false,
              friend_media_attributes: {
                  image_location: "",
                  image_date: "",
                  files: [],
              },
              friend_memories_attributes: {
                  title: "",
                  loction: "",
                  time_stamp: "",
                  files: [],
              }
          }],
            group_memories_attributes: attributes.group_memories.length > 0 ? attributes.group_memories.map((memory) => {
              return(
                {
                  friend_name: memory.friend_name,
                  files: memory.files?.map((mediaFile: IMedia) => {
                    return ({
                        file_id: mediaFile.file_id,
                        file_name: mediaFile.file_name,
                        content_type: mediaFile.content_type,
                        file_size: mediaFile.file_size,
                        url: mediaFile.url,
                    });
                }) || [],
                }
              );
            }) : [{
              friend_name: [" ", " ", " ", " ", " "],
              files: [],
          }],
        } as IFriendsDetails;

        this.setState({ friendsDetails });
      }
  };

  handleUpsertFriendsDetailsApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetFriendsDetailsAPIResponse;
    };

    if (response.data) {
      this.setState((prevState) => ({
        openDialog: true,
        isEdit: false,
        friendSteps: prevState.friendSteps.filter(
          (step) => !(step.label === 'Edit Details' && step.path === 'FriendsDetailsEdit')
        ),
      }));
    }
  };

  validationSchema = (isEdit: boolean) => {
    if(isEdit) {
        return Yup.object().shape({
            location: Yup.string().nullable().required("Please enter location"),
            friend_media_attributes: Yup.object().shape({
              image_location: Yup.string().when('files', {
                is: (files) => files.length > 0,
                then: Yup.string().nullable().required('Please enter a location of image'),
                otherwise: Yup.string().nullable(),
              }),
              image_date: Yup.string().when('files', {
                is: (files) => files.length > 0,
                then: Yup.string().nullable().required('Please enter a date of image'),
                otherwise: Yup.string().nullable(),
              }),
            }),
            friend_memories_attributes: Yup.array().when(['have_favourite_memory'], {
              is: (have_favourite_memory) => have_favourite_memory === "1",
              then: Yup.array().of(Yup.object().shape({
                title: Yup.string().nullable().required("Please enter memory title"),
                loction: Yup.string().nullable().required("Please enter location"),
                time_stamp: Yup.string().nullable().required("Please enter time stamp"),
              })),
              otherwise: Yup.array().of(Yup.object().nullable()),
            }),
            close_friends_attributes: Yup.array().when(['have_closest_friend'], {
              is: (have_festive_memory) => have_festive_memory === "1",
              then: Yup.array().of(Yup.object().shape({
                loction: Yup.string().nullable().required("Please enter location"),
                friend_media_attributes: Yup.object().shape({ image_location: Yup.string().when('files', {
                    is: (fileList) => fileList.length > 0,
                    then: Yup.string().nullable().required('Please enter a location of image'),
                    otherwise: Yup.string().nullable(),
                  }),
                  image_date: Yup.string().when('files', { is: (fileList) => fileList.length > 0,
                    then: Yup.string().nullable().required('Please enter a date of image'),
                    otherwise: Yup.string().nullable(),
                  }),
                }),
                friend_memories_attributes: Yup.object().when(['close_friends_attributes.have_close_friend_memory'], {
                  is: (have_close_friend_memory) => have_close_friend_memory,
                  then: Yup.object().shape({
                    title: Yup.string().nullable().required("Please enter memory title"),
                    location: Yup.string().nullable().required("Please enter location"),
                    time_stamp: Yup.string().nullable().required("Please enter time stamp"),
                  }),
                  otherwise: Yup.object().nullable(),
                }),
              })),
              otherwise: Yup.array().of(Yup.object().nullable()),
            }),
          });
    }else {
        return Yup.object().nullable();
    }
  }
  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    attributeName: string,
    index: number = 0) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      if(attributeName === "friend_media_attributes") {
        this.setState((prevState) => ({
          filesForFriendMedia: [...prevState.filesForFriendMedia, ...newFiles],
        }), () => {
          // Set the field value after the state has been updated
          setFieldValue('friend_media_attributes.files', [...this.state.filesForFriendMedia]);
        });
      }
      else if(attributeName === "friend_memories_attributes") {
        this.setState((prevState) => ({
          filesForFriendMemories: this.updateFilesState(prevState, "filesForFriendMemories", index, newFiles),
        }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForFriendMemories[index] || [];
            setFieldValue(`friend_memories_attributes.${index}.files`, currentFiles);
        });
      }
      else if(attributeName === "close_friends_attributes") {
        this.setState((prevState) => ({
          filesForCloseFriends: this.updateFilesState(prevState, "filesForCloseFriends", index, newFiles),
        }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForCloseFriends[index] || [];
            setFieldValue(`close_friends_attributes.${index}.friend_media_attributes.files`, currentFiles);
        });
      }
      else if(attributeName === "close_friend_memories_attributes") {
        this.setState((prevState) => ({
          filesForCloseFriendsMemories: this.updateFilesState(prevState, "filesForCloseFriendsMemories", index, newFiles),
        }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForCloseFriendsMemories[index] || [];
            setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.files`, currentFiles);
        });
      }
      else if(attributeName === "group_memories_attributes") {
        this.setState((prevState) => ({
          filesForGroupMemories: this.updateFilesState(prevState, "filesForGroupMemories", index, newFiles),
        }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForGroupMemories[index] || [];
            setFieldValue(`group_memories_attributes.${index}.files`, currentFiles);
        });
      }
    }
  };

   // Helper function to update state
   updateFilesState = (prevState: any, stateKey: string, index: number, newFiles: (File | IMedia)[]): 
   (File | IMedia)[][] => {
    const updatedFiles = [...(prevState[stateKey] || [])];
    if (!updatedFiles[index]) {
      updatedFiles[index] = [];
    }
    updatedFiles[index] = [...updatedFiles[index], ...newFiles];
    return updatedFiles;
  };

  handleSwitchChange = async (
    index: number,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    await new Promise<void>((resolve) => this.setState({ showCloseFriendMemories: !this.state.showCloseFriendMemories }, () => resolve()));
    setFieldValue(`close_friends_attributes.${index}.add_memory_with_close_friend`, this.state.showCloseFriendMemories);
  }

  calenderIcon = () => {
    return (
      <div className="calenderIconStyle">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#A190C2" d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke="#A190C2"  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M11.9955 13.6992H12.0045" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 13.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 16.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}