import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { IMedia, ISelectOptions } from "./LegalInformationController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IAttributes } from "./AdditionalInfoSidebarController.web";
import { toast } from "react-toastify";

export interface IMedicalInformationInitial {
    delegate_id: string;
    include_in_final_pdf: boolean
    have_medical_condition: string;
    have_registered_organ_donation: string;
    have_you_give_blood: string;
    have_you_know_blood_type: string;
}

export interface IMedicalConditions {
    condition_type: string;
    condition_name: string;
    level: string;
    description: string;
}

export interface IOrganDonation {
  id_number: string;
  organs: string[];
  organ_name: string[];
}

export interface IBloodType {
  blood_group: string;
  files: string[] | ArrayBuffer[] | File[] | IMedia[] | null | undefined;
}

export interface IMedicalInformations {
    medical_information: IMedicalInformationInitial;
    medical_condition: IMedicalConditions[];
    organ_donation: IOrganDonation;
    blood_type: IBloodType;
}

export interface IGetMedicalInfoAPIResponse {
    id: string;
    type: string;
    attributes: {
      id: number;
      delegate_id: number;
      delegate_charge: {
        name: string;
      };
      include_in_final_pdf: boolean;
      have_medical_condition: string;
      medical_conditions: {
        id: number;
        condition_type: string;
        condition_name: string;
        level: string;
        description: string;
      }[];
      have_registered_organ_donation: string;
      organ_donation: {
        id: number;
        id_number: string;
        organs: string[];
        organ_name: string[];
      };
      have_you_give_blood: string;
      have_you_know_blood_type: string;
      blood_type: {
        id: number;
        blood_group: string;
      };
      account_id: number;
      media: IMedia[];
    };
  };

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  medicalInformations: IMedicalInformations;
  files: (IMedia | File)[];
  delegateInCharge: ISelectOptions[];
  showMedicalConditionForm: boolean;
  showOrganDonationDetails: boolean;
  showBloodGroupForm: boolean;
  openDialog: boolean;
  subscriptionData: IAttributes | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MedicalInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        medicalInformations: {
            medical_information: {
                delegate_id: "",
                include_in_final_pdf: false,
                have_medical_condition: "0",
                have_registered_organ_donation: "0",
                have_you_give_blood: "0",
                have_you_know_blood_type: "0",
            },
            medical_condition: [{
                condition_type: "",
                condition_name: "",
                level: "",
                description: "",
            }],
            organ_donation: {
              id_number: "",
              organs: [],
              organ_name: [""],
            },
            blood_type: {
              blood_group: "",
              files: [],
            },
        },
        files: [],
        delegateInCharge: [],
        showMedicalConditionForm: false,
        showBloodGroupForm: false,
        showOrganDonationDetails: false,
        openDialog: false,
        subscriptionData: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callUpsertMedicalInfoApiId: string = "";
  callGetMedicalInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getMedicalInformation();

    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callGetMedicalInfoApiId]: this.handleGetMedicalInfoApiResponse,
      [this.callUpsertMedicalInfoApiId]: this.handleUpsertMedicalInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  getMedicalInformation = async() => {
    const token = await getStorageData("token");

    this.callGetMedicalInfoApiId = sendAPIRequest(
        configJSON.getMedicalInformationApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
   if (this.handleErrorResponse(responseJSON)) { 
    return; 
  }

    const response = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      }[];
    };

    if (response.data) {
      let delegateInCharge: ISelectOptions[] = [];

      response.data.forEach((member) => {
        delegateInCharge.push({
          value: member.attributes.id.toString(),
          name: member.attributes.first_name + " " + member.attributes.last_name,
        });
      });

      this.setState({ delegateInCharge });
    }
  };

  updateStateBasedOnAttributes = (data : IGetMedicalInfoAPIResponse) => {
    if (data.attributes.have_medical_condition === "Yes") {
      this.setState({ showMedicalConditionForm: true });
    }
    if (data.attributes.have_registered_organ_donation === "No") {
      this.setState({ showOrganDonationDetails: true });
    }
    if (data.attributes.have_you_know_blood_type === "Yes") {
      this.setState({ showBloodGroupForm: true });
    }
  };

  handleGetMedicalInfoApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetMedicalInfoAPIResponse;
    };

    if (response.data) {
      this.updateStateBasedOnAttributes(response.data);

      const attributes = response.data.attributes;
      if(attributes.media){
        this.setState({ files: attributes.media.map((mediaFile: IMedia) => {
          return({
            file_id: mediaFile.file_id,
            file_name: mediaFile.file_name,
            content_type: mediaFile.content_type,
            file_size: mediaFile.file_size,
            url: mediaFile.url,
          })
        }),})
      }
      let medicalInformations = {
      medical_information: {
          delegate_id: attributes.delegate_id.toString(),
          include_in_final_pdf: attributes.include_in_final_pdf,
          have_medical_condition: attributes.have_medical_condition === "Yes" ? "1" : "0",
          have_registered_organ_donation: attributes.have_registered_organ_donation === "No" ? "1" : "0",
          have_you_give_blood: attributes.have_you_give_blood === "Yes" ? "1" : "0",
          have_you_know_blood_type: attributes.have_you_know_blood_type === "Yes" ? "1" : "0",
        },
        organ_donation: {
          id_number: attributes.organ_donation?.id_number,
          organs: attributes.organ_donation.organs?.flatMap(organ => organ.split(',').map(name => name.trim())) || [],
          organ_name: attributes.organ_donation.organ_name ? [ ...attributes.organ_donation.organ_name] : [],
        },
        blood_type: {
          blood_group: attributes.blood_type.blood_group,
          files: attributes.media?.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }),
        },
        medical_condition: attributes.medical_conditions.length > 0 ? attributes.medical_conditions?.map(
          (medicalCondition) => {
            return(
              {
                condition_type: medicalCondition.condition_type,
                condition_name: medicalCondition.condition_name,
                level: medicalCondition.level,
                description: medicalCondition.description,
                }
            )
          }
        ) : [{
          condition_type: "",
          condition_name: "",
          level: "",
          description: "",
      }],
      } as IMedicalInformations;
      this.setState({ medicalInformations });
    }
  };

  handleUpsertMedicalInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetMedicalInfoAPIResponse;
    };

    if (response.data) {
      this.setState({ openDialog: true });
    }
  };

  handleMedicalInformationsFormSubmit = async (values: IMedicalInformations) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values.medical_information).forEach(([keyName, value]) => {
      formData.append(`medical_information[${keyName}]`, value);
    });

    values.medical_condition.forEach((medicalCondition, index) => {
      Object.entries(medicalCondition).forEach(([keyName, value]) => {
        if (value) {
          formData.append(`medical_condition[][${keyName}]`, value as string);
        }
      });
    });

    Object.entries(values.organ_donation).forEach(([keyName, value]) => {
      if (value && keyName === "id_number") {
          formData.append(`organ_donation[${keyName}]`, value);
      }
      else{
        formData.append(`organ_donation[${keyName}][]`, value);
      }
    });

    Object.entries(values.blood_type).forEach(([keyName, value]) => {
      if (keyName !== 'files') {
          formData.append(`blood_type[${keyName}]`, value);
      }
    });

    Object.entries(values.blood_type).forEach(([keyName, value]) => {
      if (value) {
        if(keyName === 'files' && values.blood_type.files && values.blood_type.files.length > 0){
          if(this.state.files.length > 0 && !('content_type' in this.state.files[0])){
            values.blood_type.files.forEach((file: string | IMedia | File | ArrayBuffer) => (
              formData.append(`blood_type[${keyName}][]`, file as Blob)
            ))
        }
        }
      }
    });


    this.callUpsertMedicalInfoApiId = sendAPIRequest(
        configJSON.getMedicalInformationApiEndPoint,
        {
          method: configJSON.formAPiMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
  }

  validationSchema = Yup.object().shape({
    medical_information: Yup.object().shape({
      delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
    }),
    medical_condition:  Yup.array().when(['medical_information.have_medical_condition'], {
      is: (have_medical_condition) => have_medical_condition === "1",
      then: Yup.array().of(Yup.object().shape({
        condition_type: Yup.string().nullable().required("Please select condition type"),
        condition_name: Yup.string().nullable().required("Please enter condition name"),
        level: Yup.string().nullable().required("Please select level"),
      })),
      otherwise: Yup.array().of(Yup.object().nullable()),
    }),
    organ_donation: Yup.object().when(['medical_information.have_registered_organ_donation'], {
      is: (have_registered_organ_donation) => have_registered_organ_donation === "1",
      then: Yup.object().shape({
        id_number: Yup.string().nullable().required("Please enter id number"),
        organs: Yup.array().nullable(),
        organ_name: Yup.array()
          .of(Yup.string().nullable()).when('organs', {
            is: (organs: string[]) => organs.includes('Other'),
            then: Yup.array().of(Yup.string().required('Organ name is required')),
            otherwise: Yup.array().of(Yup.string().nullable()),
          }),
      }),
      otherwise: Yup.object().nullable(),
    }),
    blood_type: Yup.object().when(['medical_information.have_you_know_blood_type'], {
      is: (have_you_know_blood_type) => have_you_know_blood_type === "1",
      then: Yup.object().shape({
        blood_group: Yup.string().nullable().required("Please select blood type"),
      }),
      otherwise: Yup.object().nullable(),
    }),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFiles],
      }), () => {
        // Set the field value after the state has been updated
        setFieldValue('blood_type.files', [...this.state.files]);
      });
    }
  };

  handleFileRemove = (index: number, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.files];
      updatedFiles.splice(index, 1);
      return { files: updatedFiles };
    });
    setFieldValue('will.files', [ ...this.state.files ]);
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
