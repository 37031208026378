import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { sendAPIRequest } from "../../../components/src/Utils";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

type PasswordCriteriaKey = "uppercase" | "numerical" | "lowercase" | "length";
interface PasswordCriteria {
  uppercase?: boolean;
  numerical?: boolean;
  lowercase?: boolean;
  length?: boolean;
}

export interface IPasswordDetails {
  current_password: string,
  password: string,
  password_confirmation: string,
};
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordCriteria: PasswordCriteria;
  openDialog: boolean;
  passwordDetails: IPasswordDetails;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callChangePasswordApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      passwordCriteria: {},
      openDialog: false,
      passwordDetails: {
        current_password: "",
        password: "",
        password_confirmation: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callChangePasswordApiId]: this.handleChangePasswordApiResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleChangePasswordApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      message: string
    };
    if (response) {
      this.setState({ 
        openDialog: true,
      });
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      const errors = possibleErrors;
      if (Array.isArray(errors) && errors[0]) {
        const errorMsg = Object.values(errors[0])[0] as string;
        toast.error(errorMsg);
        return true; // Indicates that there was an error
      }
      return false; // Indicates that there was no error
    }
  }

  handleChangePasswordFormSubmit = async (values: IPasswordDetails, { resetForm }: any) => {
    const token = await getStorageData("token");
    resetForm();

    this.callChangePasswordApiId = sendAPIRequest(
      `/account_block/accounts/change_password`,
      {
        method: 'PUT',
        headers: {
          token,
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            attributes: values,
          },
        },
      }
    )
  }

  validationSchema = Yup.object().shape({
    current_password: Yup.string().nullable().required("Please enter current password")
      .matches(/[A-Z]/, 'Password should have atleast one upperCase character')
      .matches(/\d/, 'Password should have atleast one numerical')
      .min(8, 'Password should be 8 character long')
      .matches(/[a-z]/, 'Password should have atleast one lowerCase character'),
    password: Yup.string()
      .notOneOf([Yup.ref('current_password'), null], 'Password must not match with current password').required("Please enter new password")
      .matches(/[A-Z]/, 'Password should have atleast one upperCase character')
      .matches(/\d/, 'Password should have atleast one numerical')
      .min(8, 'Password should be 8 character long')
      .matches(/[a-z]/, 'Password should have atleast one lowerCase character'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Please enter confirm password"),
  });

  validatePassword = (password: string) => {
    const passwordCriteria = {
      uppercase: /[A-Z]/.test(password),
      numerical: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      length: password.length >= 8,
    };

    this.setState({ passwordCriteria });
  }

  handlePasswordChange = (password: string, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void;
      (arg0: string, arg1: string): void;
    }) => {
    setFieldValue("password", password);
    this.validatePassword(password);
  };

  handleClose = () => {
    this.setState({ 
      openDialog: false,
    });
  }

  renderPasswordCriteria = () => {
    const criteriaText: Record<PasswordCriteriaKey, string> = {
      uppercase: "At least one Uppercase character (A-Z)",
      numerical: "At least one numerical (0-9)",
      lowercase: "At least one Lowercase character (a-z)",
      length: "Minimum 8 characters long",
    };

    return (["uppercase", "numerical", "lowercase", "length"] as PasswordCriteriaKey[]).map(
      (criterion) => {
        const criterionState = this.state.passwordCriteria[criterion];
        let criterionClass;

        if (criterionState === undefined) {
          criterionClass = "criteriaInitialText";
        } else if (criterionState) {
          criterionClass = "criteriaTextValid";
        } else {
          criterionClass = "criteriaText";
        }

        return (
          <Typography className={criterionClass} key={criterion}>
            <CheckCircleIcon fontSize="small" style={{ marginRight: "8px" }} />
            {criteriaText[criterion]}
          </Typography>
        );
      }
    );
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
