Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SaveAsPdf";
exports.labelBodyText = "SaveAsPdf Body";

exports.btnExampleTitle = "CLICK ME";

exports.getFinalPdfDataApiEndPoint = "bx_block_saveaspdf2/final_pdf";
exports.finalPDFText = "Final PDF";
exports.finalPDFHeading = "Your Little Book of Memories";
exports.finalPDFDescription = "From heartfelt messages to cherished moments, your Little Book of Memories is a treasured expression of your love and life, ensuring your story endures and brings comfort to those you hold dear.";
exports.createMemoriesBtnText = "Create Memories";

exports.previewPdfDescription = "This is the preview of the final pdf of your memories and data that will be received by delegated members upon your passing";
exports.littleBookOfMemories = "Little Book of Memories";
exports.aboutMeText = `"It's all about me"`;
exports.personalInformationTitle = "Personal Information";
exports.nationalityLabel = "Nationality:";
exports.userIdLabel = "User ID:";
exports.genderLabel = "Gender:";
exports.dobLabel = "DOB:";
exports.reasonForProfileCreationTitle = "Reason for profile creation";
exports.reasonForProfileCreation = `"Leave memories behind for loved ones to look back to and remember good times"`;
exports.myDelegatesTitle = "My Delegates";
exports.legalInformationTitle = "Legal Information";
exports.attorneyLabel = "Attorney:";
exports.haveWillLabel = "Have a Will:";
exports.lifeInsuranceLabel = "Life Insurance:";
// Customizable Area End