import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Container,
    FormLabel,
    Grid,
    ThemeProvider,
    Typography,
    createTheme,
    styled
} from "@material-ui/core";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import ChangePasswordController, {
    Props,
    configJSON,
} from "./ChangePasswordController.web";
import CustomInput from "../../../components/src/CustomInput";
import { Field, Form, Formik } from "formik";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import ErrorMessage from "../../../components/src/ErrorMessage";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
            },
            h6: {
                marginBottom: "20px",
                color: "#64748B",
                fontFamily: "Lato",
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: "26px",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#334155",
                fontFamily: "Lato",
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
            }
        },
        MuiButton: {
            contained: {
                backgroundColor: "#A190C2",
                color: "#FFFFFF",
                textTransform: "none",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: "100px",
                lineHeight: "24px",
            },
        },
        MuiInputBase: {
            root: {
                minHeight: '48px',
            },
        },
    },
});

const steps = [
    { label: 'Account', path: 'HomePage' },
    { label: 'My Profile', path: 'MyProfile' },
    { label: 'Change Password', path: 'LegalData' },
];
// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { passwordDetails } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Container>
                                <Box mb={1}>
                                    <BreadcrumbStepper steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="formWrapper">
                                    <Box className="resetPasswordContent">
                                        <Typography variant="h1"
                                            data-test-id="changePasswordTitle"
                                        >
                                            {configJSON.changePasswordTitle}
                                        </Typography>
                                    </Box>
                                    <Formik
                                        initialValues={passwordDetails}
                                        validationSchema={this.validationSchema}
                                        onSubmit={(values, resetForm) => {
                                            this.handleChangePasswordFormSubmit(values, resetForm)
                                        }}
                                        enableReinitialize={true}
                                        data-test-id="changePasswordForm"
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>
                                                <Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} className="gridItem">
                                                            <FormLabel component="label">
                                                                {configJSON.currentPasswordFieldLabel}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Field
                                                                data-test-id="currentPassword"
                                                                name="currentPassword"
                                                                className="inputField"
                                                                size="small"
                                                                as={CustomInput}
                                                                isVisibilityIcon={true}
                                                                placeholder="Current Password"
                                                                value={values.current_password}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    setFieldValue("current_password", e.target.value)
                                                                }
                                                            />
                                                            <ErrorMessage name={`current_password`} />
                                                        </Grid>
                                                        <Grid item xs={12} className="gridItem">
                                                            <FormLabel component="label">
                                                                {configJSON.newPasswordFieldLabel}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Field
                                                                data-test-id="newPassword"
                                                                name="newPassword"
                                                                className="inputField"
                                                                size="small"
                                                                as={CustomInput}
                                                                isVisibilityIcon={true}
                                                                placeholder="New Password"
                                                                value={values.password}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    this.handlePasswordChange(e.target.value, setFieldValue)
                                                                }
                                                            />
                                                            <ErrorMessage name={`password`} />
                                                        </Grid>
                                                        <Grid item xs={12} className="gridItem">
                                                            <FormLabel component="label">
                                                                {configJSON.confirmPasswordFieldLabel}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Field
                                                                data-test-id="confirmPassword"
                                                                name="confirmPassword"
                                                                className="inputField"
                                                                size="small"
                                                                as={CustomInput}
                                                                isVisibilityIcon={true}
                                                                placeholder="Confirm Password"
                                                                value={values.password_confirmation}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    setFieldValue("password_confirmation", e.target.value)
                                                                }
                                                            />
                                                            <ErrorMessage name={`password_confirmation`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Box className="criteriaBox">
                                                        {this.renderPasswordCriteria()}
                                                    </Box>
                                                    <Box className="btnWrapper">
                                                        <Button
                                                            data-test-id="changePasswordBtn"
                                                            variant="contained"
                                                            className="btnSize"
                                                            type="submit"
                                                        >
                                                            {configJSON.changePasswordBtnText}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter data-test-id="footer" handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                <GenericModal open={this.state.openDialog} data-test-id="passwordChanged" onClose={this.handleClose}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="alignCenter">
                                <img alt="success" src={rightTickImg} className="rightTickImg" />
                                <Typography align="center" variant="h6">
                                    Your profile password has been changed successfully.
                                </Typography>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .container": {
        alignItems: "center",
        padding: "56px 72px",
    },
    "& .resetPasswordContent": {
        display: "flex",
        justifyContent: "center",
    },
    "& .formWrapper": {
        margin: '16px auto',
        width: '43%',
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        padding: "44px",
        borderRadius: "12px",
    },
    "& .span": {
        color: "red",
        marginTop: "5px",
    },
    "& .label": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        color: "#334155",
        marginBottom: "10px",
    },
    "& .button": {
        width: "372px",
        height: "48px",
        fontSize: "16px",
        fontFamily: "Lato",
        marginTop: "72px",
        marginBottom: 0,
        borderRadius: "46px",
        color: "#fff",
        backgroundColor: "#A190C2",
        textTransform: "none",
    },
    "& .criteriaBox": {
        marginTop: theme.spacing(2),
    },
    "& .criteriaInitialText": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'gray',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    "& .criteriaText": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'red',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    "& .criteriaTextValid": {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'green',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    "& .inputField": {
        height: "48px",
        borderRadius: "8px",
        width: "100%",
    },
    "& .btnSize": {
        height: "48px",
        width: "385px",
        marginTop: "44px",
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "center",
    },
    [theme.breakpoints.down(1050)]: {
        "& .formWrapper": {
            width: '50%',
        },
        "& .container": {
            padding: "28px 16px",
        },
    },
    [theme.breakpoints.down('xs')]: {
        "& .formWrapper": {
            width: '65%',
        },
    },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .alignCenter": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
        width: "369px",
    },
    "& .rightTickImg": {
        width: "56px",
        height: "56px",
    },
}));
// Customizable Area End
