import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";

export interface IHaveHobbies {
            name: string,
            location: string,
            duration: string,
            files: File[]
};

export interface IHobbies {
    have_hobbies: string;
    hobby_details_attributes: IHaveHobbies[]
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
hobbies: IHobbies
maxHobbies: number
isloading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutHobbiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize : number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        hobbies: {
            have_hobbies: '0',
            hobby_details_attributes: [{
                name: "",
                location: "",
                duration: "",
                files: []
            }]
          },
          maxHobbies: 0,
          isloading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.callAboutHobbiesInfoApiId){
        this.setState({isloading: false})
    if (responseJSON.data) {
    this.props.handleSuccess()
    }else{
        let hobbiesErr = responseJSON?.errors[0]
        if(hobbiesErr){
            let key = Object.keys(hobbiesErr)[0]
            let message = key +  " " + hobbiesErr[key]
            this.props.handleError(message.replace("_", " "))
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  callAboutHobbiesInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getMaxHobbies()
}

getMaxHobbies = async () =>{
  let subscriptionData = await getStorageData("active_subscription");
  let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_hobbies
  if(parsedSubscriptionData){
    this.setState({maxHobbies: parseInt(parsedSubscriptionData)})
  }
} 

getHobbiesList = (index: number , length: number) => {
    return index === 0 && length < this.state.maxHobbies
  }

  handleAboutHobbiesInformationsFormSubmit = async (values: IHobbies) => {
    const token = await getStorageData("token");
    const formData = new FormData();
    this.setState({isloading: true})
    Object.entries(values).forEach(([keyName, value]) => {
        if(keyName !== "hobby_details_attributes"){
          formData.append(`hobby[${keyName}]`, value);
        }
      });
  
      values.hobby_details_attributes.forEach((tattoo) => {
        Object.entries(tattoo).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'files' && tattoo.files.length > 0){
                tattoo.files.forEach((file: File) => {
                    formData.append(`hobby[hobby_details_attributes][][${keyName}][]`, file as Blob);
                  });
              }
            else{
              formData.append(`hobby[hobby_details_attributes][][${keyName}]`, value as string);
            }
          }
        })
      });

    this.callAboutHobbiesInfoApiId = sendAPIRequest(
      configJSON.hobbiesEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  validationSchema = Yup.object().shape({
    hobby_details_attributes: Yup.array().when(['have_hobbies'], {
        is: (have_hobbies) => have_hobbies === "1",
        then: Yup.array().of(Yup.object().shape({
            name: Yup.string().nullable().required("Please enter name"),
            location: Yup.string().nullable().required("Please enter location")
        })),
        otherwise: Yup.array().of(Yup.object().nullable()),
    })
  });


  handleHoddieFile = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number, 
prevFiles: File[]) => {
    if (event.target.files) {
      const hobbiesFile = Array.from(event.target.files);
      const oversizedFiles = hobbiesFile.filter(file => file.size > this.maxFileSize);    
    if (oversizedFiles.length > 0) {
      this.props.handleError(configJSON.maxFileSizeError)
      return 
    }
    let files= [...prevFiles, ...hobbiesFile]
    setFieldValue(`hobby_details_attributes.${index}.files`,files)
    }
  };

  // Customizable Area End
}
