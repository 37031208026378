import React from "react";
// Customizable Area Start
import AboutMomentController, {
    configJSON,
    Props,
    IMoment
} from "./AboutMomentController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
import Spinner from "./components/Spinner.web";

// Customizable Area End

export default class AboutMoment extends AboutMomentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            aboutMoment,
            isLoading
        } = this.state;
        return (
            <Box className="formWrapper">
                {isLoading && <Spinner spinnerModal={isLoading} />}
                <Formik
                    initialValues={aboutMoment}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutMomentInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutMomentForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                             <Box className="formSectionBackground">
                            <FieldArray name="proudMoment" data-test-id="fieldArray">
                                {({ remove, push }) => (
                                    values.proudMoment.map((_proudMoment: IMoment, index: number) => {
                                        return (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                                    <Typography data-test-id="fieldArrayTitle" variant="h6" style={{ fontWeight: 500, color: "#1E293B", fontFamily: "Lato" }}>{configJSON.momentHeading}</Typography>
                                                    {index === 0 && (
                                                        <Button
                                                            className="addLettersBtn"
                                                            data-test-id="addLettersBtn"
                                                            onClick={() => push({
                                                                moment: '',
                                                                description: '',
                                                                files: [],
                                                            })}
                                                        >
                                                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                            {configJSON.addMoreMoment}
                                                        </Button>
                                                    )}
                                                    {index !== 0 && (
                                                        <Button
                                                            data-test-id="removeBtn"
                                                            className="addLettersBtn"
                                                            onClick={() => remove(index)}>
                                                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                            {configJSON.removeBtnText}
                                                        </Button>)}
                                                </Grid>
                                                <Grid item lg={4} xs={12} sm={6}>
                                                    <FormLabel component="label">
                                                        {configJSON.moment}<span style={{ color: "red" }}>*</span>
                                                    </FormLabel>
                                                    <Grid item xs={12} >
                                                        <Field
                                                            data-test-id="aboutMoment"
                                                            className="inputField"
                                                            name={configJSON.moment}
                                                            as={CustomInput}
                                                            size="small"
                                                            placeholder={configJSON.momentPlaceholder}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                setFieldValue(`proudMoment.${index}.moment`, e.target.value)
                                                            }}
                                                            value={_proudMoment.moment}
                                                        />
                                                        <ErrorMessage name={`proudMoment.${index}.moment`} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormLabel component="label">
                                                        {configJSON.momentDescription}
                                                    </FormLabel>
                                                    <Grid item xs={12} >
                                                        <Field
                                                            data-test-id="aboutDesc"
                                                            className="multilineInput"
                                                            name={configJSON.momentDescription}
                                                            as={CustomInput}
                                                            size="small"
                                                            multiline
                                                            placeholder={configJSON.momentDescriptionPlaceholder}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                setFieldValue(`proudMoment.${index}.description`, e.target.value)
                                                            }}
                                                            value={_proudMoment.description}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        data-test-id="mediaUploadForMoment"
                                                        files={_proudMoment.files}
                                                        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleProudFileUpload(event, setFieldValue, index, _proudMoment.files)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                )}
                            </FieldArray>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    className="btnSize"
                                    variant="outlined"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="skipMoment"
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    className="btnSize"
                                    data-test-id="saveMoment"
                                    type="submit"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End