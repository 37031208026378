import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import {sendAPIRequest} from "../../../components/src/Utils";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IStep } from "./LegalDataEditController.web";

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any
}

export interface IMedia {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
}

export interface S {
    letters: ILetters
    delegateInCharge: ISelectOptions[];
    isEditNotes: boolean
    VoiceNotesSteps: IStep[]
    successFamilyDialog: boolean
    maxVoiceNotes: number
    openDialog: boolean;
    promptsList: { id: number, value: string, name: string }[]
    uploadDialog: boolean
    selectedIndex: number | undefined
    selectedFieldValue: any
    isRecording: boolean;
    audioBlob: Blob | null;
    prevFiles: File[],
    isEditMedia: boolean
}

export interface ILetters {
    voiceNotes: IVoiceNotes[];
};

export interface IVoiceNotes {
    delegate_id: string;
    recipient: string;
    have_special_day: boolean;
    special_day: string;
    prompt: string
    files: (File | IMedia)[];
};
export interface SS {

}

export default class VoiceNotessEditController extends BlockComponent<Props,
    S,
    SS
> {
    mediaRecorder: any = null;
    audioChunks: any = [];
    maxFileSize: number = 15 * 1024 * 1024;
    callGetVoiceNotessCallID: string = ""
    callSetVoiceNotessCallID: string = ""
    getMembersApiId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),

        ];
        this.state = {
            letters: {
                voiceNotes: [{
                    delegate_id: "",
                    recipient: "",
                    have_special_day: false,
                    prompt: "",
                    special_day: "",
                    files: [],
                }],
            },
            delegateInCharge: [],
            promptsList: [{ id: 1, value: "Good Morning", name: "Good Morning" },
            { id: 2, value: "Happy Birthday", name: "Happy Birthday" },
            { id: 3, value: "Twinkle Twinkle little star", name: "Twinkle Twinkle little star" },
            { id: 4, value: "I love you", name: "I love you" },
            { id: 5, value: "Good Night", name: "Good Night" }
            ],
            uploadDialog: false,
            selectedIndex: undefined,
            selectedFieldValue: {},
            isRecording: false,
            audioBlob: null,
            isEditNotes: false,
            openDialog: false,
            VoiceNotesSteps: [
                { label: 'Home', path: 'HomePage' },
                { label: 'My Data', path: 'HomePage' },
                { label: 'Voice Notes', path: 'VoiceNotesEdit' },
            ],
            successFamilyDialog: false,
            maxVoiceNotes: 3,
            prevFiles: [],
            isEditMedia: false
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.callGetVoiceNotessCallID) {
            if (responseJSON.data) {
                let letters = {
                    voiceNotes: responseJSON.data.length > 0 ?
                        responseJSON.data.map((_data: { attributes: IVoiceNotes }) => {
                            let { delegate_id,
                                recipient,
                                have_special_day,
                                prompt,
                                special_day,
                                files, } = _data.attributes
                            return ({
                                delegate_id,
                                recipient,
                                prompt,
                                have_special_day,
                                special_day,
                                files
                            }

                            )
                        }) : [{
                            delegate_id: "",
                            recipient: "",
                            letter: "",
                            have_special_day: false,
                            special_day: "",
                            files: [],
                        }]
                }
                this.setState({ letters })
            }
        }
        if (apiRequestCallId === this.callSetVoiceNotessCallID) {
            if (responseJSON.data) {
                this.setState({ successFamilyDialog: true })
            } else {
                this.handleErrorMessage(responseJSON.errors)
            }
        }
        if (apiRequestCallId === this.getMembersApiId) {
            if (responseJSON.data) {
                let delegateInCharge: ISelectOptions[] = [];
                responseJSON.data.forEach((delegate: { attributes: { id: string, first_name: string, last_name: string } }) => {
                    delegateInCharge.push({
                        value: delegate.attributes.id.toString(),
                        name: delegate.attributes.first_name + " " + delegate.attributes.last_name,
                    });
                });
                this.setState({ delegateInCharge });
            }
        }
        // Customizable Area End
    }

    getDelegateMembers = async () => {
        const token = await getStorageData("token");

        this.getMembersApiId = sendAPIRequest(
            configJSON.getDelegateMembersApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    getMaxLetters = (index: number, length: number) => {
        return index === 0 && length < this.state.maxVoiceNotes
    }

    closeSuccessFamilyDialog = () => {
        this.getFamilyDetailsData()
        this.setState({ successFamilyDialog: false, isEditNotes: false, VoiceNotesSteps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Voice Notes', path: 'VoiceNotesEdit' },
        ], });
    }
    handleErrorMessage = (delegateErrors: { [key: string]: string }[]) => {
        if (delegateErrors.length > 0) {
            delegateErrors.forEach(errorObj => {
                Object.entries(errorObj).forEach(([key, message]) => {
                    let errorMessage = key === "token" ? message : key.replace("_", " ") + " " + message
                    toast.error(errorMessage)
                });
            });
        }
    }

    setEditForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        this.setState((prevState) => ({
            isEditNotes: true,
            VoiceNotesSteps: [...prevState.VoiceNotesSteps, { label: 'Edit Details', path: 'VoiceNotesEdit' }],
        }))
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getFamilyDetailsData()
        this.getDelegateMembers()
        this.setMaxDocumentUpload()
    }

    setMaxDocumentUpload = async () => {
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features.max_voice_notes
        if (parsedSubscriptionData) {
            this.setState({ maxVoiceNotes: parseInt(parsedSubscriptionData) })
        }
    }

    isDisableField = (index: number) => {
        return this.state.isEditNotes ? index !== 0 : !this.state.isEditNotes

    }

    getFamilyDetailsData = async () => {
        const token = await getStorageData("token");

        this.callGetVoiceNotessCallID = sendAPIRequest(
            configJSON.getVoiceNotesEndPoint,
            {
                method: configJSON.validationApiMethodType,
                headers: {
                    token,
                },
            }
        );
    }

    getClassName = () => {
        return this.state.isEditNotes ? "formSectionBackground" : ""
    }

    getVaraintName = () => {
        return this.state.isEditNotes ? "h3" : "h2"
    }

    getTitle = () => {
        return !this.state.isEditNotes ? "Media" : ""
    }

    getFileLength = (length: number) => {
        return !this.state.isEditNotes && length === 0
    }

    validateFamilyDetails = () => {
        if (this.state.isEditNotes) {
            return (
                Yup.object().shape({
                    voiceNotes: Yup.array().of(Yup.object().shape({
                        delegate_id: Yup.string()
                            .nullable()
                            .when('$isFirst', {
                                is: true,
                                then: Yup.string().required("Please selecct Delegate ID"),
                                otherwise: Yup.string().nullable(),
                            }),
                            special_day: Yup.string().when(['have_special_day'], {
                                is: (have_special_day) => have_special_day,
                                then: Yup.string().nullable().required("Please enter special day"),
                                otherwise: Yup.string().nullable(),
                            }),
                        recipient: Yup.string().nullable().required("Please enter recipient"),
                    })),
                })
            )
        } else {
            return Yup.object().shape({});
        }
    }

    handleSwitchChange = async (
        switchIndex: number,
        setFieldValue:
            {
                (field: string,
                    value: any,
                    shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
            },
        checked: boolean) => {
        setFieldValue(`voiceNotes.${switchIndex}.have_special_day`, checked);
    }

    checkFileKey = (key:string , length: number)=>{
        return key === 'files' && this.state.isEditMedia && length > 0
    }

    handleFamilDetailsForm = async (values: ILetters) => {
        if (this.state.isEditNotes) {
            const token = await getStorageData("token");
            const formData = new FormData();
            console.log(values, "voice notes value")
            values.voiceNotes.forEach((VoiceNotes) => {
                if (VoiceNotes) {
                    Object.entries(VoiceNotes).forEach(([keyName, value]) => {
                        if (value) {
                            if (keyName === 'delegate_id') {
                                formData.append(`voice_note[${keyName}]`, value);
                            }
                            else if (this.checkFileKey(keyName, VoiceNotes.files.length)) {
                                VoiceNotes.files.forEach((file: File | IMedia) => {
                                    formData.append(`notes[][${keyName}][]`, file as Blob)
                                }
                                )
                            }
                            else if (keyName !== 'files') {
                                formData.append(`notes[][${keyName}]`, value as string);
                            }
                        }
                    });
                }
            })

            this.callSetVoiceNotessCallID = sendAPIRequest(
                configJSON.getVoiceNotesEndPoint,
                {
                    method: configJSON.exampleAPiMethod,
                    headers: {
                        token,
                    },
                    body: formData,
                }
            );
        }
    }


    handleFileDrops = async (files: File[], event: React.DragEvent<HTMLDivElement>, index: number, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        prevFiles: any
    ) => {
        event.preventDefault()
        if (files) {
            const fileDrop = Array.from(files);
            if (await this.checkForFileType(fileDrop)) {
                let allFiles = this.state.isEditMedia ? [...prevFiles, ...fileDrop] : [...fileDrop]
                if (this.checkFileSize(allFiles)) {
                    this.setState({isEditMedia: true})
                    setFieldValue(`voiceNotes.${index}.files`, allFiles);
                }
            }
        }
    }
    retryRecording = () => {
        this.mediaRecorder = null;
        this.audioChunks = [];
        this.setState({ audioBlob: null })
    }
    handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number = 0) => {
        this.setState({ isRecording: false })
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            if (await this.checkForFileType(newFiles)) {
                let allFiles = this.state.isEditMedia ?  [...this.state.prevFiles, ...newFiles] : [...newFiles]
                if (this.checkFileSize(allFiles)) {
                    this.setState(({isEditMedia: true}))
                    setFieldValue(`voiceNotes.${index}.files`, allFiles);
                    this.handleCloseDialog()
                }
            }
        }
    };
    checkFileSize = (file: any[]) => {
        const oversizedFiles = file.filter(file => (file.size | file.file_size) > this.maxFileSize);

    if (oversizedFiles.length > 0) {
      toast.error(configJSON.maxFileSizeError)
      return false
    }
        return true
    }
    checkForFileType = async (file: File[]) => {
        let isInvalid = file.some(file => !file.type.startsWith('audio/'))
        if (isInvalid) {
            toast.error(configJSON.invalidFileError)
            return false
        }
        let isValidFile: boolean = true
        const checks = file.map(_file => {
            return new Promise((resolve) => {
                const audio = new Audio();
                audio.src = URL.createObjectURL(_file);

                audio.onloadedmetadata = () => {
                    if (audio.duration > 10) {
                        isValidFile = false;
                        toast.error("Audio file exceeds 10 seconds.");
                        resolve(false)
                    }
                    resolve(true);
                };

                audio.onerror = () => {
                    resolve(false);
                };
            });
        });

        // Wait for all promises to complete
        await Promise.all(checks);
        return isValidFile
    }

    handleRecordingButton = () => {
        this.state.isRecording ? this.stopRecording() : this.startRecording()
    }

    handleRecordingButtonText = () => {
        return this.state.isRecording ? "Stop Recording" : "Start Recording"
    }
    saveRecording = async () => {
        let index = this.state.selectedIndex || 0
        if (this.state.audioBlob) {
            const audioFile = new File([this.state.audioBlob], 'recording.wav', {
                type: 'audio/wav',
            });
            if (this.checkFileSize([audioFile])) {
                let allFiles = this.state.isEditMedia ? [...this.state.prevFiles, ...[audioFile]] : [...[audioFile]]
                this.state.selectedFieldValue(`voiceNotes.${index}.files`, allFiles);
                this.setState({isEditMedia:true})
                this.handleCloseDialog()
            }

        }
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false, uploadDialog: false, selectedIndex: undefined, audioBlob: null, isRecording: false, prevFiles: [] });
    };

    openUploadDialog = (index: number | null, setFieldValue: any, prevFiles: any) => {
        if (index !== null) {
            this.setState({ selectedIndex: index, uploadDialog: true, selectedFieldValue: setFieldValue, prevFiles })
        }
    }
    startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new (window as any).MediaRecorder(stream);

            this.audioChunks = [];
            this.mediaRecorder.ondataavailable = (event: any) => {
                this.audioChunks.push(event.data);
            };

            this.mediaRecorder.onstop = () => {
                const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                this.setState({ audioBlob });
            };

            this.mediaRecorder.start();
            this.setState({ isRecording: true });
        } catch (error) {
            this.setState({ isRecording: false });
            if (error instanceof DOMException && error.name === "NotAllowedError") {
                toast.error(configJSON.microphoneError)
            } else {
                toast.error(configJSON.microphoneError1)
            }
        }
    }

    getAudioURL = (blob: Blob | null) => {
        if (blob)
            return URL.createObjectURL(blob)
    }
    stopRecording = () => {
        this.mediaRecorder?.stop();
        this.setState({ isRecording: false });
    };


    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

}