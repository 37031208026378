Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getAllAboutsApiEndPoint = "bx_block_landingpage/all_abouts";
exports.getAboutUsApiEndPoint = "bx_block_landingpage/about_us";
exports.getLoggedInUserApiEndPoint = "account_block/accounts/logged_user"; 

exports.btnExampleTitle = "CLICK ME";

exports.reasonsToChooseTitle = "Why Choose Delegate Me?";
exports.easyToUseTitle = "Easy to Use";
exports.easyToUseDescription = "Delegate Mee is perfectly suitable for all the individuals of every age group.";
exports.accessibleTitle = "Accessible";
exports.accessibleDescription = "All your cherished memories can be shared with loved ones through any device.";
exports.personalTitle = "Personal";
exports.personalDescription = "All your personal data saved in one place for your loved once to cherish after your demisse";
exports.secureTitle = "Secure";
exports.secureDescription = "Your memories can only be accessed by you and individuals authorized by you.";

exports.getStartedBtnText = "Get Started";
exports.loginBtnText = "Login";
exports.signupBtnText = "Sign Up";
exports.aboutUsTitle= "About US";
exports.whatWeDo = "What we do?";
exports.futureAndAims = "Future Aims/ Success"
exports.ourStory = "Our Story"
exports.somethingWW="Something went wrong!"
exports.ourStoryDes1 = "After losing a best friend at a young age, our founder was shaken by a lack of control over funeral arrangements. This sparked questions."
exports.ourStoryDes2 = "Would she have wanted that outfit when put to rest? Shouldn’t she have deserved a say in her funeral? Who is taking responsibility for her financials? "
exports.ourStoryDes3 = "Then, what if there was a tool that could empower people to preserve their most important data before passing?"
exports.ourStoryDes4 = "If people can take control of their own legacy, they can ensure their story is told as they wish, even after they're gone."
exports.ourStoryDes5 = "And so, Delegate Mee was born. Empower your legacy with dignity and security."
exports.aboutUsDescription = "Delegate Mee help you save your data and memories for your loved once to look back to"
// Customizable Area End