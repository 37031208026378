import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import FuneralController, {
    configJSON,
    Props,
} from "./FuneralController.web";
import {
    Box,
    Button,
    Grid,
    Snackbar,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import {DialogWrapper, theme } from "./LegalInformation.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import FuneralBasics from "./FuneralBasics.web";
import Alert from "@material-ui/lab/Alert";
import GenericModal from "../../../components/src/GenericModal";
import FuneralAttire from "./FuneralAttire.web";
import FuneralHairBeauty from "./FuneralHairBeauty.web";
import FuneralFurtherItems from "./FuneralFurtherItems.web";
// Customizable Area End

export default class Funeral extends FuneralController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    selectedScreen = (key: string) =>{
        let commonProps = {
            ...this.props, 
            handleError: this.onErrorHit,
            handleSuccess: this.onSuccessClick,
            handleNextBtnClick: this.onNextSectionBtnClick,
          };

        switch(key){
            case "funeral_basics": 
              return <FuneralBasics {...commonProps} />;
            case "attire": 
              return <FuneralAttire {...commonProps} />;
            case "hair_and_beauty": 
              return <FuneralHairBeauty {...commonProps} />;
            case "further_items": 
              return <FuneralFurtherItems {...commonProps} />;
            default:
              return <></>;
          }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            selectedTab,
            tabList,
            successDialog,
            selectedTabIndex
        } = this.state;

        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} progress={90} />
                        <Box className="container">
                            <Box className="AdditionalInformationSidebar">
                                <AdditionalInformationSidebar navigation={this.props.navigation} />
                            </Box>
                            <Box className="notesInfoWrapper">
                                <Box className="pageTitle">
                                <Box display="flex" justifyContent="space-between">
                                    <Typography data-test-id="funeralPageTitle" variant="h3" className="titleText">
                                        {configJSON.funeralPageTitle}
                                    </Typography>
                                    <Typography variant="h3" className="infomessageText">
                                        <InfoOutlinedIcon className="infoIcon" />
                                        <span>{configJSON.messageText}</span>
                                        </Typography>
                                        </Box>
                                    <Typography variant="h3" className="messageText">
                                        {configJSON.funeralPageSubTitle}
                                    </Typography>
                                </Box>
                                
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Tabs
                                            aria-label="secondary tabs example"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            className="tabsWrapper"
                                        >
                                            {tabList.map((item: {key: string, value: string, disabled: boolean}, index:number) => (                                               
                                                    <Tab
                                                        key={item.key}
                                                        data-test-id={item.key}
                                                        className={`${selectedTab === item.key ? "selectedTab" : "tabsButton"}`}
                                                        disabled={item.disabled}
                                                        onClick={()=> {this.onTabClick(item.key, index)}}
                                                        value={item.value}
                                                        label={item.value}
                                                    />
                                            ))}
                                        </Tabs>
                                    </Grid>
                                </Grid>  
                                <Grid container className="funeralContainer">
                                  {
                                    this.selectedScreen(this.state.selectedTab)
                                  }
                                </Grid>
                            </Box>                          
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                {successDialog && <GenericModal
                    open={successDialog}
                    onClose={()=>this.handleNavigation("CustomForm")}
                    data-test-id="information-added-modal"
                >
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="dialogContentWrapper">
                                <Box className="dialogDescriptionWrapper">
                                    <Typography
                                        variant="h1"
                                        align="center"
                                        data-test-id="emailSentModalTitle"
                                    >
                                        {configJSON.infoAddedDialogTitleText}
                                    </Typography>
                                    <Typography align="center" variant="h2">
                                        {`Your ${tabList[selectedTabIndex].value}`} has been added successfully to your data. Please select<span className="nextSpan"> Next</span> to add more information.
                                    </Typography>
                                </Box>
                                <Box className="dialogBtnsWrapper">
                                    <Button
                                        className="outlinedBtn"
                                        data-test-id="skipForNowDialogBtn"
                                        variant="outlined"
                                        onClick={() => this.handleNavigation("CustomForm")}
                                    >
                                        {configJSON.skipForNowBtnText}
                                    </Button>
                                    <Button
                                        className="containedBtn"
                                        variant="contained"
                                        onClick={() => this.onNextSectionBtnClick("AfterWishes")}
                                        data-test-id="nextSectionBtn"
                                    >
                                        {configJSON.nextSectionBtnText}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>}
                {this.state.isError && (
                    <Snackbar
                      open={this.state.isError}
                      onClose={this.handleCloseSnackbar}
                      autoHideDuration={4000}
                      data-test-id="errorSnackbar"
                    >
                        <Alert 
                            severity="error" 
                            data-test-id="alertClose"
                            onClose={this.handleCloseSnackbar}
                        >
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>
                )}
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .notesInfoWrapper": {
        width: 'calc(100% - 256px)',
    },
     "& .container": {
        display: "flex",
        padding: "50px 72px",
        gap: "72px",
    },
    "& .formWrapper": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "40px",
        width: "100%",
        height: "100%",
    },
    "& .form": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "calc(100% - 189px)",
    },
    "& .formSectionBackground": {
        padding: "24px",
        marginBottom: "24px",
        borderRadius: "8px",
        backgroundColor: "rgba(206, 195, 229, 0.1)",
    },
    "& .formSectionTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "16px 0",
        alignItems: "center",
    },
    "& .pageTitle": {
        display: "flex",
        gap: "8px",
        flexDirection: "column",
        margin: "32px 0",
    },
    "& .infomessageText": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        alignItems: "center",
        lineHeight: "18px",
        color: "#9176C4",
        display: "flex",
        gap: "4px",
      },
    "& .messageText": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1E293B",
    },
    "& .infoIcon": {
        fontSize: "20px",
      },
    "& .tabsWrapper": {
        borderBottom: "1px solid rgb(224, 224, 224)",

    },
    "& .funeralContainer":{
        height: "100%"
    },
    "& .tabsText": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#9176C4",
        textTransform: "none",
    },
    "& .selectedTab":{
        fontFamily: "Lato",
        color: "#9176C4",
        fontSize: 16,
        fontWeight: 600,
        borderBottom: "4px solid",
        flexGrow: 1,
        borderRadius:0,
        padding: "12px 20px",
        textTransform: "none",
    },
    "& .tabsButton":{
        fontFamily: "Lato",
        fontSize: 16,
        color: "#64748B",
        borderRadius:0,
        fontWeight: 600,
        flexGrow: 1,
        borderBottom: "1px solid #64748B",
        padding: "12px 20px",
        textTransform: "none",
    },
    "& .disabledTabs":{
        color: "rgba(93, 100, 111, 0.39) !important"
    },
    "& .selectField": {
        width: "100%",
        height: "48px",
        display: "block",
        margin: "5px 0px",
        borderRadius: "8px",
    },
    "& .inputField": {
        height: "48px",
        borderRadius: "8px",
        width: "100%",
    },
    "& .hairBeautyInput": {
        minWidth: "327px !important",
        minHeight: "84px !important",
    },
    "& .btnSize": {
        width: "158px",
        height: "48px",
    },
    "& .btnWrapper": {
        gap: "8px",
        display: "flex",
        justifyContent: "flex-end",
    },
    "& .radioLabel": {
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "22px",
        color: '#1E293B',
        fontWeight: 400,
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#9176C4',
        },
    },
    "& .formRadioLabel": {
        color: "#1E293B !important",
        fontSize: "16px !important",
    },
    "& .radioGroup": {
        display: 'flex',
        gap: "35px",
        flexDirection: 'row',
        marginLeft: "16px",
    },
    "& [class*=MuiTypography-body2]": {
        body2: {
            fontFamily: "Lato",
            fontSize: "16px !important",
            fontWeight: 400,
            lineHeight: "22px",
        },
    },

    [theme.breakpoints.down(740)]: {
        "& .notesInfoWrapper": {
        width: 'calc(100% - 126px)',
        },
        "& .container": {
            padding: "28px 16px",
        },
    },
}));
// Customizable Area End