import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

interface ReportDeathForm {
  name: string
  email: string
  full_phone_number: string
  deceased_name: string
  deceased_email: string
  security_phrase: string
}

// Customizable Area End

interface S {
  // Customizable Area Start
  reports: ReportDeathForm
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ReportDeathController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  callReportDeathApiId: string = ""
  resetFormData: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reports: {
        name: "",
        email: "",
        full_phone_number: "",
        deceased_name: "",
        deceased_email: "",
        security_phrase: ""
      }
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.callReportDeathApiId) {
        if (responseJson.data) {
          let message = responseJson?.meta?.message
          toast.success(message)
          this.resetFormData()
        }
        if (responseJson?.errors?.length > 0) {
          responseJson.errors.forEach((errorObj: { key: string, message: string }) => {
            Object.values(errorObj).forEach(message => {
              toast.error(message)
            });
          });
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
  }

  reportValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name must be at least 2 characters')
      .max(50, 'Name can be at most 50 characters'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    full_phone_number: Yup.string()
      .matches(/^\d{7,15}$/, 'Phone number must be between 7 and 15 digits')
      .required('Phone number is required'),
    deceased_name: Yup.string()
      .required('Deceased name is required')
      .min(2, 'Deceased name must be at least 2 characters')
      .max(50, 'Deceased name can be at most 50 characters'),
    deceased_email: Yup.string()
      .email('Invalid email address')
      .required('Deceased email is required'),
    security_phrase: Yup.string()
      .required('Security phase is required')
  });

  handleReportDeathForm = (values: ReportDeathForm, reset: any) => {
    let { name, email, full_phone_number, deceased_email, deceased_name, security_phrase } = values

    const data = {
      reports: {
        name,
        email,
        full_phone_number,
        deceased_email,
        deceased_name,
        security_phrase
      }
    }
    this.resetFormData = reset
    this.callReportDeathApiId = sendAPIRequest(
      configJSON.getReportEndPoint,
      {
        method: configJSON.addUserApiMethod,
        headers: {
          "Content-Type": configJSON.getUserListApiContentType,
        },
        body: data
      }
    );
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
