import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { IMedia, ISelectOptions } from "./LegalInformationController.web";
import { IAttributes } from "./AdditionalInfoSidebarController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { toast } from "react-toastify";

export interface IPetsInformation {
    have_pets: string;
    have_responsible: string;
    pet_number: string;
    pet_details_attributes: IPetDetailsAttributes[];
    caretaker_attributes: ICareTakerAttributes;
};

export interface IPetDetailsAttributes {
    pet_photo: File | null;
    pet_name: string;
    date_of_birth: string;
    breed: string;
    requirement: string;
    address_line_1: string;
    address_line_2: string;
    landmark: string;
    city: string;
    country: string;
    post_code: string;
    files: (File | IMedia)[];
};

export interface ICareTakerAttributes {
    caretaker_id: string;
    full_name: string;
    full_phone_number: string;
};

export interface IPetTemplateAttributes {
  id: number;
  image_url: string;
};

export interface IPetTemplatesResponse {
  id: string;
  type: string;
  attributes: IPetTemplateAttributes;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pet_information: IPetsInformation;
  petPhotos: (string | ArrayBuffer | null | undefined)[];
  files: (IMedia | File)[][];
  showPetDetailsForm: boolean;
  showCaretakerDetailsForm: boolean;
  openDialog: boolean;
  openDialogForPhoto: "uploadOptions" | "templates" | null;
  delegateInCharge: ISelectOptions[];
  subscriptionData: IAttributes | null;
  selectedFile: File | null,
  petTemplates: IPetTemplatesResponse[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PetsAndAnimalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.fileInput = React.createRef();

    this.state = {
        pet_information: {
            have_pets: "0",
            have_responsible: "0",
            pet_number: "",
            pet_details_attributes: [{
                pet_photo: null,
                pet_name: "",
                date_of_birth: "",
                breed: "",
                requirement: "",
                address_line_1: "",
                address_line_2: "",
                landmark: "",
                city: "",
                country: "",
                post_code: "",
                files: [],
            }],
            caretaker_attributes: {
                caretaker_id: "",
                full_name: "",
                full_phone_number: "",
            },
        },
        petPhotos: [],
        files: [],
        showPetDetailsForm: false,
        showCaretakerDetailsForm: false,
        openDialog: false,
        delegateInCharge: [],
        subscriptionData: null,
        openDialogForPhoto: null,
        selectedFile: null,
        petTemplates: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddPetsInfoApiId: string = "";
  callGetPetPhotoTemplatesApiId: string = "";
  fileInput: React.RefObject<HTMLInputElement>;

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();

    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callAddPetsInfoApiId]: this.handleAddPetsInfoApiResponse,
      [this.callGetPetPhotoTemplatesApiId]: this.handleGetPetPhotoTemplatesApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
     return; 
   }
 
     const response = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });

       delegateInCharge.push({
        value: "0",
        name: "Other"
       });
 
       this.setState({ delegateInCharge });
     }
   };

  handleAddPetsInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    if (responseJSON.data) {
      this.setState({ openDialog: true });
    }
  };

  handlePetsInformationsFormSubmit = async (values: IPetsInformation) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values).forEach(([keyName, value]) => {
      if(keyName !== "pet_details_attributes" && keyName !== "caretaker_attributes"){
        formData.append(`pet[${keyName}]`, value);
      }
    });

    values.pet_details_attributes.forEach((petDetails, index) => {
      if(petDetails){
        Object.entries(petDetails).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'pet_photo' && this.state.petPhotos.length > 0){
              formData.append(`pet[pet_details_attributes][][${keyName}]`, value as Blob);
            }
            else if(keyName === 'files' && petDetails.files.length > 0){
                petDetails.files.forEach((file: string | IMedia | File | ArrayBuffer) => {
                  formData.append(`pet[pet_details_attributes][][${keyName}][]`, file as Blob);
                });
            }
            else if(keyName !== 'files'){
              formData.append(`pet[pet_details_attributes][][${keyName}]`, value as string);
            }
          }
        });
      }
    });

    Object.entries(values.caretaker_attributes).forEach(([keyName, value]) => {
      if (value) {
          formData.append(`pet[caretaker_attributes][${keyName}]`, value);
      }
    });

    this.callAddPetsInfoApiId = sendAPIRequest(
      configJSON.addPetsInfoApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  getPetPhotoTemplates = async () => {
    const token = await getStorageData("token");
    
    this.callGetPetPhotoTemplatesApiId = sendAPIRequest(
      configJSON.getPetPhotoTemplatesApiEndPoint,
      {
        method: configJSON.fetchFormDataMethod,
        headers: {
          token,
        }
      }
    );
  };

  handleGetPetPhotoTemplatesApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?:IPetTemplatesResponse[];
    };

    if (response.data) {
      let petTemplates = [ ...response.data ];

      this.setState({ petTemplates, openDialogForPhoto: "templates" });
    }
  };

  validationSchema = Yup.object().shape({
    // define validations
    pet_details_attributes: Yup.array().when(['have_pets'], {
      is: (have_pets) => have_pets === "1",
      then: Yup.array().of(Yup.object().shape({
        pet_name: Yup.string().nullable().required("Please enter pet name"),
        date_of_birth: Yup.string().nullable().required("Please enter date of birth"),
        address_line_1: Yup.string().nullable().required("Please enter address line 1"),
        address_line_2: Yup.string().nullable().required("Please enter address line 2"),
        city: Yup.string().nullable().required("Please enter town/city"),
        country: Yup.string().nullable().required("Please enter country"),
        post_code: Yup.string().nullable().required("please enter post code"),
      })),
      otherwise: Yup.array().of(Yup.object().nullable()),
    }),
    caretaker_attributes: Yup.object().when(['have_responsible'], {
      is: (have_responsible) => have_responsible === "1",
      then: Yup.object().shape({
        caretaker_id: Yup.string().nullable().required("Please select caretaker"),
        full_name: Yup.string().nullable().when('caretaker_id', {
            is: (caretaker_id) => caretaker_id === "0",
            then: Yup.string().required('Please enter full name'),
            otherwise: Yup.string().nullable(),
          }),
          full_phone_number: Yup.string().nullable().required("Please enter phone number"),
      }),
      otherwise: Yup.object().nullable(),
    }),
  });



  handlePetPhoto = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
    
        // Update the specific pet photo field in the FieldArray
        setFieldValue(`pet_details_attributes.${index}.pet_photo`, file);
    
        const reader = new FileReader();
    
        reader.onload = (loadEvent) => {
          const result = loadEvent.target?.result;
    
          this.setState((prevState) => {
            // Copy the existing petPhotos array to avoid mutating the state directly
            const updatedPhotos = [...(prevState.petPhotos || [])];
    
            // Make sure the array has a spot for the current index
            updatedPhotos[index] = result; 
    
            return { petPhotos: updatedPhotos };
          });
        };
    
        reader.readAsDataURL(file);

        this.setState({ openDialogForPhoto: null });
      }
  };

  handleTemplatePetPhotoclick = async (url: string, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number) => {
      const file = await urlToFile(url, 'petTemplatePhoto.png');
      // Update the specific pet photo field in the FieldArray
      setFieldValue(`pet_details_attributes.${index}.pet_photo`, file);

      const reader = new FileReader();
    
        reader.onload = (loadEvent) => {
          const result = loadEvent.target?.result;
    
          this.setState((prevState) => {
            // Copy the existing petPhotos array to avoid mutating the state directly
            const updatedPhotos = [...(prevState.petPhotos || [])];
    
            // Make sure the array has a spot for the current index
            updatedPhotos[index] = result; 
    
            return { petPhotos: updatedPhotos };
          });
        };
    
        reader.readAsDataURL(file);

        this.setState({ openDialogForPhoto: null });
  };

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number = 0) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: this.updateFilesState(prevState, "files", index, newFiles),
      }), () => {
          // Set the field value after the state has been updated
          const currentFiles = this.state.files[index] || [];
          setFieldValue(`pet_details_attributes.${index}.files`, currentFiles);
      });
    }
  };

  // Helper function to update state
  updateFilesState = (prevState: any, stateKey: string, index: number, newFiles: (File | IMedia)[]): 
  (File | IMedia)[][] => {
   const updatedFiles = [...(prevState[stateKey] || [])];
   if (!updatedFiles[index]) {
     updatedFiles[index] = [];
   }
   updatedFiles[index] = [...updatedFiles[index], ...newFiles];
   return updatedFiles;
 };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpendialogForUploadOptions = () => {
    this.setState({ openDialogForPhoto: "uploadOptions"});
  };

  handleCloseDialogForPhoto = () => {
    this.setState({ openDialogForPhoto: null });
  };

  calenderIcon = () => {
    return (
      <div style={{ cursor: "grab" }}>
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
