import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IFuneralFurtherItems {
    added_your_coffine: string;
    items: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  furtherItemsInfo: IFuneralFurtherItems,
  isLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralFurtherItemsController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        furtherItemsInfo: {
            added_your_coffine: "0",
            items: "",
        },
        isLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callAddFuneralFurtherItemsInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callAddFuneralFurtherItemsInfoApiId]: this.handleAddFuneralFurtherItemsInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

   handleAddFuneralFurtherItemsInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false})
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  handleFuneralFurtherItemsInfoFormSubmit = async (values: IFuneralFurtherItems) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    this.setState({isLoading: true});

    formData.append("further[added_your_coffine]", values.added_your_coffine);
    formData.append("further[items]", values.items);     

    this.callAddFuneralFurtherItemsInfoApiId = sendAPIRequest(
      configJSON.addFuneralFurtherItemsInformationApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };

 validationSchema = Yup.object().shape({
    // define validation schema
  });
  // Customizable Area End
}
