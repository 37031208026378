import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { FormikValues } from "formik";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { ILoggedInUserApiResponse } from "../../../blocks/customform/src/AdditionalInfoSidebarController.web";
import { toast } from "react-toastify";

export interface IDelegateMember {
  id: number;
  photo: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  nationality: string;
  gender: string;
  gender_name: string;
  email: string;
  country_code: string;
  phone_number: string;
  full_phone_number: string;
  date_of_birth: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
  security_phrase: string;
  delegate_key: string;
};

export interface IDelegateMemberAPIResponse {
  id: number;
  photo_url: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  nationality: string;
  gender: string;
  gender_name: string;
  email: string;
  country_code: string;
  phone_number: string;
  full_phone_number: string;
  date_of_birth: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
  security_phrase: string;
  delegate_key: string;
  account_id: number,
  created_at: string,
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: "Profile" | "Delegate Members" | "completed";
  delegateKey: string;
  delegateMember: IDelegateMember;
  delegateMember1: IDelegateMember;
  delegateMember2: IDelegateMember;
  showMemberCard1: boolean;
  showMemberCard2: boolean;
  profileImage: string | ArrayBuffer | Blob | null | undefined;
  removePhoto: boolean;
  switchChecked: boolean;
  openDialog: boolean;
  openAllSetDialog: boolean;
  editMode: boolean;
  anchorEl: Element | null;
  errorMessage: string;
  successMsg: string;
  nationalities: ISelectOptions[];
  phoneNumberLength: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DelegateMembersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddDelegateMemberApiId: string = "";
  callEditDelegateMemberApiId: string = "";
  callConfirmDetailsApiId: string = "";
  callGetNationalitiesApiId: string = "";
  callGetLoggedInUserDetailsApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: "Delegate Members",
      delegateKey: "delegate_1",
      delegateMember: {
          id: 0,
          photo: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          nationality:"",
          gender: "",
          gender_name: "",
          email: "",
          country_code: "+44",
          phone_number: "",
          full_phone_number: "",
          date_of_birth: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
          security_phrase: "",
          delegate_key: "delegate_1",
      },
      delegateMember1: {
        id: 0,
        photo: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        nationality:"",
        gender: "",
        gender_name: "",
        email: "",
        country_code: "+44",
        phone_number: "",
        full_phone_number: "",
        date_of_birth: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        city: "",
        country: "",
        post_code: "",
        security_phrase: "",
        delegate_key: "delegate_1",
    },
      delegateMember2: {
        id: 0,
        photo: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        nationality:"",
        gender: "",
        gender_name: "",
        email: "",
        country_code: "+44",
        phone_number: "",
        full_phone_number: "",
        date_of_birth: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        city: "",
        country: "",
        post_code: "",
        security_phrase: "",
        delegate_key: "delegate_2",
    },
      showMemberCard1: false,
      showMemberCard2: false,
      profileImage: null,
      removePhoto: false,
      switchChecked: false,
      openDialog: false,
      openAllSetDialog: false,
      editMode: false,
      anchorEl: null,
      errorMessage: "",
      successMsg: "",
      nationalities: [],
      phoneNumberLength: 10,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getNationalitiesList();
    this.getDelegateMembersList();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start

  //API integration
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callAddDelegateMemberApiId]: this.handleAddDelegateMemberApiResponse,
      [this.callEditDelegateMemberApiId]: this.handleEditDelegateMemberApiResponse,
      [this.callConfirmDetailsApiId]: this.handleConfirmDetailsApiResponse,
      [this.callGetNationalitiesApiId]: this.handleGetNationalitiesApiResponse,
      [this.callGetLoggedInUserDetailsApiId]: this.handleGetLoggedInUserDetailsResponse
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  delegateMemberValidationSchema = (phoneNumberLength: number) => Yup.object().shape({
    first_name: Yup.string().nullable().required("Please enter your first name"),
    last_name: Yup.string().nullable().required("Please enter your last name"),
    nationality: Yup.string().nullable().required("Please select your nationality"),
    gender: Yup.string().nullable().required("Please select your gender"),
    email: Yup.string().nullable().email("Please enter valid email ID").required("Please enter your email ID"),
    phone_number: Yup.string().nullable()
    .transform((value) => {
      return value.replace(/\D/g, '');  // Remove non-numeric characters
    })
      .matches(
        new RegExp(`^\\d{${phoneNumberLength}}$`),
        `Phone number must be ${phoneNumberLength} digits`
      )
      .required("Please enter a phone number"),
    date_of_birth: Yup.string().nullable().required("Please enter your date of birth"),
    address_line_1: Yup.string().nullable().required("Please enter your address line 1"),
    address_line_2: Yup.string().nullable().required("Please enter your address line 2"),
    city: Yup.string().nullable().required("Please enter your city"),
    country: Yup.string().nullable().required("Please select your country"),
    post_code: Yup.string().nullable().required("Please enter a valid post code"),
    security_phrase: Yup.string().nullable().required("Please enter a security phrase"),
  });

  calenderIcon = () => {
    return (
      <div className="calenderIcon">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#A190C2" d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M11.9955 13.6992H12.0045" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 13.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 16.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

//web events
  handleCloseAllSetDialog = () => {
    this.setState({ openAllSetDialog: !this.state.openAllSetDialog });
  };

  handleProfileImg = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFieldValue("photo", file);

      const reader = new FileReader();

      reader.onload = (event) => {
        this.setState({ 
          profileImage: event.target?.result,
          anchorEl: null ,
          removePhoto: false,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  handleProfilePicEditMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfilePicEditMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemoveProfilePic = (setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    setFieldValue("photo", null);
    this.setState({ profileImage: null, anchorEl: null, removePhoto: true });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  //Delegate Members
  getDelegateMembersList = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.httpGetMethod,
          headers: {
            token,
          },
        }
      );
  }


  handleDelegateFormSubmit = async (
    values: FormikValues,
    ) => {
    this.setState({
      errorMessage: "",
      successMsg: "",
    });
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values).forEach(([keyName, value]) => {
      if(keyName !== 'id' && value !== undefined && keyName !== 'full_phone_number'){
        if(keyName === 'photo'){
            if(this.state.profileImage){
              formData.append(`partner[${keyName}]`, value);
            }
            else if(value === null){
               formData.append(`partner[${keyName}]`, new Blob([], {type: "image/jpg"}), "");
            }
        }
        else if(keyName === 'delegate_key'){
            formData.append(`partner[${keyName}]`, this.state.delegateKey);
        }
        else{
            formData.append(`partner[${keyName}]`, value);
        }
      }
    });

    formData.append(`partner[delegate_key]`, this.state.delegateKey);

    this.setState({ delegateMember: {
        id: 0,
          photo: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          nationality:"",
          gender: "",
          gender_name: "",
          email: "",
          country_code: "+44",
          phone_number: "",
          full_phone_number: "",
          date_of_birth: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
          security_phrase: "",
          delegate_key: this.state.delegateKey,
    }})

    if(this.state.editMode === true){
      this.callEditDelegateMemberApiId = sendAPIRequest(
        `${configJSON.editDelegateMemberApiEndPoint}/${values.id}?remove_photo=${this.state.removePhoto}`,
        {
          method: configJSON.httpPutMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
    }
    else{
      this.callAddDelegateMemberApiId = sendAPIRequest(
        configJSON.addDelegateMemberApiEndPoint,
        {
          method: configJSON.httpPostMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
    }
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
        meta?: { message: string };
        data?: {
          id: string,
          type: string,
          attributes: IDelegateMemberAPIResponse,
        }[];
      };

      if (response.data) {
        if (response.data.length > 1) {
          const addedDelegateMember1 = response.data.find((item: {
            id: string,
            type: string,
            attributes: IDelegateMemberAPIResponse,
          }) => item.attributes.delegate_key === "delegate_1");

            const memberData1 = addedDelegateMember1?.attributes;

            const delegateMember1 = {
              id: memberData1?.id,
              photo: memberData1?.photo_url,
              first_name: memberData1?.first_name,
              middle_name: memberData1?.middle_name || "",
              last_name: memberData1?.last_name,
              nationality: memberData1?.nationality,
              gender: memberData1?.gender,
              gender_name: memberData1?.gender_name,
              email: memberData1?.email,
              country_code: memberData1?.country_code || "+44",
              phone_number: memberData1?.phone_number,
              full_phone_number: memberData1?.full_phone_number || "+44",
              date_of_birth: memberData1?.date_of_birth,
              address_line_1: memberData1?.address_line_1,
              address_line_2: memberData1?.address_line_2,
              landmark: memberData1?.landmark,
              city: memberData1?.city,
              country: memberData1?.country,
              post_code: memberData1?.post_code,
              security_phrase: memberData1?.security_phrase,
              delegate_key: memberData1?.delegate_key,
            } as IDelegateMember;

            this.setState({ delegateMember1, showMemberCard1: true });

          const addedDelegateMember2 = response.data.find((item: {
            id: string,
            type: string,
            attributes: IDelegateMemberAPIResponse,
          }) => item.attributes.delegate_key === "delegate_2");

            const memberData2 = addedDelegateMember2?.attributes;

            const delegateMember2 = {
              id: memberData2?.id,
              photo: memberData2?.photo_url,
              first_name: memberData2?.first_name,
              middle_name: memberData2?.middle_name || "",
              last_name: memberData2?.last_name,
              nationality: memberData2?.nationality,
              gender: memberData2?.gender,
              gender_name: memberData2?.gender_name,
              email: memberData2?.email,
              country_code: memberData1?.country_code || "+44",
              phone_number: memberData1?.phone_number,
              full_phone_number: memberData2?.full_phone_number || "+44",
              date_of_birth: memberData2?.date_of_birth,
              address_line_1: memberData2?.address_line_1,
              address_line_2: memberData2?.address_line_2,
              landmark: memberData2?.landmark,
              city: memberData2?.city,
              country: memberData2?.country,
              post_code: memberData2?.post_code,
              security_phrase: memberData2?.security_phrase,
              delegate_key: memberData2?.delegate_key,
            } as IDelegateMember;

            this.setState({ delegateMember2, showMemberCard2: true });
          this.setState({ activeStep: "completed" });
        }
        else {
          const delgatemember1 = response.data.find((item: {
            id: string,
            type: string,
            attributes: IDelegateMemberAPIResponse,
          }) => item.attributes.delegate_key === "delegate_1");

          const memberData = delgatemember1?.attributes;

          this.setState({
            errorMessage: '',
          });

          const delegateMember = {
            id: memberData?.id,
            photo: memberData?.photo_url,
            first_name: memberData?.first_name,
            middle_name: memberData?.middle_name || "",
            last_name: memberData?.last_name,
            nationality: memberData?.nationality,
            gender: memberData?.gender,
            gender_name: memberData?.gender_name,
            email: memberData?.email,
            country_code: memberData?.country_code || "+44",
            phone_number: memberData?.phone_number,
            full_phone_number: memberData?.full_phone_number || "+44",
            date_of_birth: memberData?.date_of_birth,
            address_line_1: memberData?.address_line_1,
            address_line_2: memberData?.address_line_2,
            landmark: memberData?.landmark,
            city: memberData?.city,
            country: memberData?.country,
            post_code: memberData?.post_code,
            security_phrase: memberData?.security_phrase,
            delegate_key: memberData?.delegate_key,
          } as IDelegateMember;

          this.setState({ delegateMember });
        }
      }
  };

  handleAddDelegateMemberApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      };
    };
    
    if (response.data) {
      const memberData = response.data.attributes;
      this.setState({
        errorMessage: '',
        delegateKey: "delegate_2",
        profileImage: null,
      });

      const delegateMember = {
        id: memberData.id,
        photo: memberData.photo_url,
        first_name: memberData.first_name,
        middle_name: memberData.middle_name,
        last_name: memberData.last_name,
        country_code: memberData.country_code,
        phone_number: memberData.phone_number,
        full_phone_number: memberData.full_phone_number,
        nationality: memberData.nationality,
        gender: memberData.gender,
        email: memberData.email,
        date_of_birth: memberData.date_of_birth,
        address_line_1: memberData.address_line_1,
        address_line_2: memberData.address_line_2,
        city: memberData.city,
        landmark: memberData.landmark,
        country: memberData.country,
        post_code: memberData.post_code,
        security_phrase: memberData.security_phrase,
        delegate_key: memberData.delegate_key,
      } as IDelegateMember;

      if(memberData.delegate_key === "delegate_1"){
        this.setState({ 
            delegateMember1: delegateMember, 
            showMemberCard1: true 
        });
      }
      else{
        this.setState({ 
            delegateMember2: delegateMember, 
            showMemberCard2: true, 
            activeStep: "completed" 
        });
      }
    }
  };

  handleEditDelegateMemberApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      };
    };

    if (response.data) {
      const memberData = response.data.attributes;
      this.setState({
        errorMessage: '',
        profileImage: null,
      });

      const delegateMember = {
        id: memberData.id,
        photo: memberData.photo_url,
        first_name: memberData.first_name,
        middle_name: memberData.middle_name,
        last_name: memberData.last_name,
        nationality: memberData.nationality,
        gender: memberData.gender,
        gender_name: memberData.gender_name,
        email: memberData.email,
        country_code: memberData.country_code,
        phone_number: memberData.phone_number,
        full_phone_number: memberData.full_phone_number,
        date_of_birth: memberData.date_of_birth,
        address_line_1: memberData.address_line_1,
        address_line_2: memberData.address_line_2,
        landmark: memberData.landmark,
        city: memberData.city,
        country: memberData.country,
        post_code: memberData.post_code,
        security_phrase: memberData.security_phrase,
        delegate_key: memberData.delegate_key,
      } as IDelegateMember;

      if(memberData.delegate_key === "delegate_1") {
        this.setState({ 
          delegateMember1: delegateMember, 
          showMemberCard1: true,
          delegateKey: "delegate_2",
         });
      }
      else {
        this.setState({ 
          delegateMember2: delegateMember, 
          showMemberCard2: true,
        });
      }
      this.setState({ editMode: false });
    }

  };

  handleEditDelegateMember = (delegateMember: IDelegateMember) => {
    this.setState({ 
      editMode: true,
      delegateMember: delegateMember,
      delegateKey: delegateMember.delegate_key,
      profileImage: null,
    });

    if(delegateMember.delegate_key === "delegate_1"){
      this.setState({ showMemberCard1: false });
      if(this.state.delegateMember2.id !== 0)
        this.setState({ showMemberCard2: true });
    }
    else if(delegateMember.delegate_key === "delegate_2"){
      this.setState({ showMemberCard2: false });
      if(this.state.delegateMember1.id !== 0)
        this.setState({ showMemberCard1: true });
    }
  };

  handleConfirmDetailsBtnClick = async () => {
    const token = await getStorageData("token");

    this.callConfirmDetailsApiId = sendAPIRequest(
      configJSON.confirmDetailsApiEndPoint,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  };

  handleConfirmDetailsApiResponse = async () => {
    const token = await getStorageData("token");

    this.callGetLoggedInUserDetailsApiId = sendAPIRequest(
      configJSON.getLoggedInUserDetailsApiEndPoint,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  };

  handleGetNationalitiesApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }
  
      const response = responseJSON as {
        nationalities?: string[];
      };
  
      if (response.nationalities) {
        let nationalities: ISelectOptions[] = [];
  
        response.nationalities.forEach((nationality, index) => {
          nationalities.push({
            value: index.toString(),
            name: nationality,
          });
        });
  
        this.setState({ nationalities });
      }
  };

  getNationalitiesList = async () => {
    this.callGetNationalitiesApiId = sendAPIRequest(
        configJSON.getNationalitiesApiEndPoint,
        {
          method: configJSON.httpGetMethod,
        }
      );
  };

  handleGetLoggedInUserDetailsResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILoggedInUserApiResponse;
    };

    if (response.data) {
       const attributes = response.data.attributes;

       if(attributes?.["have_subscription?"]){
        this.handleNavigation("CustomForm");
       }
       else{
        this.setState({ openAllSetDialog: true });
       }
    }
  };

  handleContinueBtnClick = () => {
    this.handleNavigation("Customisableusersubscriptions");
  };

  handlePhoneInputChange = (value: string, country: any, setFieldValue:
    {
      (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        setFieldValue("country_code", country.dialCode);
        setFieldValue("phone_number", value.slice(country.dialCode.length));

        this.setState({ 
          phoneNumberLength: country.format.match(/\./g).length - country.dialCode.length
        });
  };
  // Customizable Area End
}
