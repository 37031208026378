import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
    const { method = "GET", headers, body } = params || {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    
    const callId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    if (body)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body?.append ? body : JSON.stringify(body)
      )
   
    if (headers)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return callId
  };

  export function getLastPartOfURL() {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
};

export function urlToFile(url: string, filename: string) {
  return new Promise<Blob>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const file = new File([blob], filename, { type: 'image/png' });
            resolve(file);
          } else {
            reject(new Error('Blob conversion failed'));
          }
        }, 'image/png');
      } else {
        reject(new Error('Failed to get canvas 2D context'));
      }
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url; // Set the source of the image
  });
};