import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { ISelectOptions } from "./LegalInformationController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest } from "../../../components/src/Utils";

export interface IAfterWishesInformation {
    have_passed: string;
    delegate_id: string;
    my_wishes_attributes: IWish[];
};

export interface IWish {
    wish: string,
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wishes_information: IAfterWishesInformation;
  showAfterWishesForm: boolean;
  openDialog: boolean;
  delegateInCharge: ISelectOptions[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AfterWishesController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        wishes_information: {
            have_passed: "0",
            delegate_id: "",
            my_wishes_attributes: [{
                wish: "",
            }],
        },
        showAfterWishesForm: false,
        openDialog: false,
        delegateInCharge: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddAfterWishesInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callAddAfterWishesInfoApiId]: this.handleAddAfterWishesInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
     return; 
   }
 
     const response = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState({ delegateInCharge });
     }
   };

   handleAddAfterWishesInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    if (responseJSON.data) {
      this.setState({ openDialog: true });
    }
  };

  handleWishesInformationsFormSubmit = async (values: IAfterWishesInformation) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values).forEach(([keyName, value]) => {
        if (value && keyName !== "my_wishes_attributes") {
            formData.append(`wish[${keyName}]`, value);
        }
      });

    values.my_wishes_attributes.forEach((wish, index) => {
        Object.entries(wish).forEach(([keyName, value]) => {
          if (value) {
              formData.append(`wish[my_wishes_attributes][][${keyName}]`, value as string);
            }
        });
    });

    this.callAddAfterWishesInfoApiId = sendAPIRequest(
      configJSON.addAfterWishesInfoApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  validationSchema = Yup.object().shape({
    delegate_id: Yup.string().when(['have_passed'], {
        is: (have_passed) => have_passed === "1",
        then: Yup.string().nullable().required("Please select delegate user"),
        otherwise: Yup.string().nullable(),
      }),
  });

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
