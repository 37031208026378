import React from "react";
// Customizable Area Start
import AboutFavouriteController, {
    configJSON,
    Props
} from "./AboutFavouriteController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    Switch,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import Spinner from "./components/Spinner.web";

// Customizable Area End

export default class AboutFavourite extends AboutFavouriteController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            favourite,
            loading
        } = this.state;
        return (
            <Box className="formWrapper">
                {loading && <Spinner spinnerModal={loading} />}
                <Formik
                    initialValues={favourite}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutFavouriteInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutFavouriteForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.animal}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favAnimal"
                                                    className="inputField"
                                                    name={configJSON.animal}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.animalPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`animal`, e.target.value)
                                                    }}
                                                    value={values.animal}
                                                />
                                                <ErrorMessage name={`animal`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.sport}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favSport"
                                                    className="inputField"
                                                    name={configJSON.sport}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.sportPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`sport`, e.target.value)
                                                    }}
                                                    value={values.sport}
                                                />
                                                <ErrorMessage name={`sport`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.food}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favFood"
                                                    className="inputField"
                                                    name={configJSON.food}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.foodPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`food`, e.target.value)
                                                    }}
                                                    value={values.food}
                                                />
                                                <ErrorMessage name={`food`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.drink}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="recipientOfLetter"
                                                    className="inputField"
                                                    name={configJSON.drink}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.drinkPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`drink`, e.target.value)
                                                    }}
                                                    value={values.drink}
                                                />
                                                <ErrorMessage name={`drink`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.alcoholic_drink}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favAlochol"
                                                    className="inputField"
                                                    name={configJSON.alcoholic_drink}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.alcoholic_drinkPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`alcohol`, e.target.value)
                                                    }}
                                                    value={values.alcohol}
                                                />
                                                <ErrorMessage name={`alcohol`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.holiday_destination}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favHolidayDes"
                                                    className="inputField"
                                                    name={configJSON.holiday_destination}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.holiday_destinationPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`holiday_destination`, e.target.value)
                                                    }}
                                                    value={values.holiday_destination}
                                                />
                                                <ErrorMessage name={`holiday_destination`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.clothing_brand}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favCloth"
                                                    className="inputField"
                                                    name={configJSON.clothing_brand}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.clothing_brandPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`clothing_brand`, e.target.value)
                                                    }}
                                                    value={values.clothing_brand}
                                                />
                                                <ErrorMessage name={`clothing_brand`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.singer}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favSinger"
                                                    className="inputField"
                                                    name={configJSON.singer}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.singerPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`singer`, e.target.value)
                                                    }}
                                                    value={values.singer}
                                                />
                                                <ErrorMessage name={`singer`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.actor}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favActor"
                                                    className="inputField"
                                                    name={configJSON.actor}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.actorPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`actor`, e.target.value)
                                                    }}
                                                    value={values.actor}
                                                />
                                                <ErrorMessage name={`actor`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.movie}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favMovie"
                                                    className="inputField"
                                                    name={configJSON.movie}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.moviePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`movie`, e.target.value)
                                                    }}
                                                    value={values.movie}
                                                />
                                                <ErrorMessage name={`movie`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.clothing_item}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favClothingItem"
                                                    className="inputField"
                                                    name={configJSON.clothing_item}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.clothing_itemPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`clothing_item`, e.target.value)
                                                    }}
                                                    value={values.clothing_item}
                                                />
                                                <ErrorMessage name={`clothing_item`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6} alignContent="center">
                                         <Box className="haveSpecialDayWrapper">
                                            <Typography align="center" className="haveSpecialDayLabel">
                                                {configJSON.upload_image_of_clothing_item}
                                            </Typography>
                                            <Switch
                                                data-test-id="favHaveImage"
                                                color="primary"
                                                checked={values.have_image_clothing_item}
                                                onChange={(event: any) =>
                                                    this.handleSwitchChange(event.target.checked, setFieldValue)
                                                }
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            </Box>
                                    </Grid>
                                    {values.have_image_clothing_item && 
                                    <Grid item xs={12}>
                                        <MediaUpload
                                    data-test-id="mediaUploadForCloth"
                                    files={values?.files}
                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFavFileUpload(event, setFieldValue, values.files)}
                                        />
                                        </Grid>
                                    }
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.age}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favAge"
                                                    className="inputField"
                                                    name={configJSON.age}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.agePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`age`, e.target.value)
                                                    }}
                                                    value={values.age}
                                                />
                                                <ErrorMessage name={`age`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.date}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                            <Field
                        data-test-id="favDate"
                        name="petDOB"
                        className="inputField"
                        size="small"
                        as={CustomDatePicker}
                        placeholder="DD/MM/YYYY"
                        endIcon={
                          this.calenderIcon
                        }
                        onChange={(date: Date) =>
                          setFieldValue(`date`, date.toLocaleDateString("en-GB"))
                        }
                        value={values.date}
                      />
                                                <ErrorMessage name={`date`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel component="label">
                                            {configJSON.about_age}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favAboutAge"
                                                    className="multilineInput"
                                                    name={configJSON.about_age}
                                                    as={CustomInput}
                                                    size="small"
                                                    multiline
                                                    placeholder={configJSON.about_agePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`about_age`, e.target.value)
                                                    }}
                                                    value={values.about_age}
                                                />
                                                <ErrorMessage name={`about_age`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.home}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favHome"
                                                    className="inputField"
                                                    name={configJSON.home}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.homePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`home`, e.target.value)
                                                    }}
                                                    value={values.home}
                                                />
                                                <ErrorMessage name={`home`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.colour}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favColor"
                                                    className="inputField"
                                                    name={configJSON.colour}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.colourPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`colour`, e.target.value)
                                                    }}
                                                    value={values.colour}
                                                />
                                                <ErrorMessage name={`colour`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.holiday}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favHoliday"
                                                    className="inputField"
                                                    name={configJSON.holiday}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.holidayPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`holiday`, e.target.value)
                                                    }}
                                                    value={values.holiday}
                                                />
                                                <ErrorMessage name={`holiday`} />
                                            </Grid>
                                    </Grid>
                                   </Grid>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    data-test-id="skipForNow"
                                    className="btnSize"
                                    variant="outlined"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    className="btnSize"
                                    variant="contained"
                                    data-test-id="saveAndNextBtn"
                                    type="submit"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End