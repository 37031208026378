import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SupportController, {
  Props,
  configJSON,
} from "./SupportController.web";
import { Box, Grid, Typography, Button, Card } from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import { Icon1, Icon2, Icon3, Icon4, map, supportImage, supportImageBack } from "./assets";
import CallMadeIcon from '@material-ui/icons/CallMade';

const theme = createTheme({
  overrides: {
    MuiTypography: {
     h2: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h3: {
        color: "#000000",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h5:{
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h6: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
        color:"#1E293B"
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#1E293B"
      },
      subtitle2: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "36px",
        color: "#475569"
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#64748B"
      }
    },
  },
});
// Customizable Area End

export default class Support extends SupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  selfCare = (description: string, image: string) =>{
    return(
        <Grid item className="alignGridItem" xs={12} sm={6} md={4}>
                      <Card className="card-root" style={{borderRadius: '24px'}}>
                        <img
                          
                          alt="futureAims"
                          src={image}
                          className="selfCareImage"
                        />
                        <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: description }} />
                      </Card>
                    </Grid>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {supportData} = this.state
    const cardDetails = [
        {
          "title": "Love everyone",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "icon": Icon1
        },
        {
          "title": "Live a good life",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "icon": Icon2
        },
        {
          "title": "Make others happy",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "icon": Icon3
        },
        {
          "title": "Help everyone",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "icon": Icon4
        }
      ]
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box className="container">
            <Grid container>
              <Grid className="alignContent" item md={6}>
                <Box
                  className="alignCenter"
                  mb={5}
                  style={{ maxWidth: "575px" }}
                >
                  <Typography
                    variant="h2"
                    className="heading"
                    data-test-id="heading"
                  >
                    {supportData.heading_1}
                  </Typography>
                  <Typography variant="body1">
                  {supportData.heading_2}
                  </Typography>
                </Box>
                <Box className="alignCenter">
                  <Button
                  data-test-id = "aboutUSButton"
                    variant="contained"
                    className="button"
                    onClick={() => this.handleNavigation("AboutUs")}
                  >
                    {configJSON.aboutUS}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6} className="supportImageContaine">
                <Box className="heroSectionImgWrapper">
                  <img
                    alt="image"
                    src={supportImage}
                    className="heroSectionImg"
                    
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="reasonsToChooseWrapper" style={{backgroundColor: "#F3EDFF",}}>
            <Box className="reasonsToChooseTitle">
              <Typography align="center" variant="h3">
                {configJSON.weBelieve}
              </Typography>
              <Typography variant="body1" align="center">
              {configJSON.weBelieveDes}
              </Typography>
            </Box>
            <Box className="reasonsToChooseCardsWrapper">
              <Grid container spacing={4}>
              {cardDetails.map((_item: any)=>{
                return(
              <Grid item className="alignGridItem" xs={12} sm={6} md={3} key={_item.title}>
                  <Card className="card-root">
                    <img
                      alt="easy-to-use"
                      src={_item.icon}
                      className="cardImgIcon"
                    />
                    <Typography variant="subtitle2">
                      {_item.title}
                    </Typography>
                    <Typography variant="body2">
                      {_item.description}
                    </Typography>
                  </Card>
                </Grid>
                )
              })
                }
              </Grid>
            </Box>
          </Box>
          <Box className="reasonsToChooseWrapper">
            <Box className="reasonsToChooseTitle">
              <Typography align="center" variant="h3">
                {configJSON.support}
              </Typography>
              <Typography variant="body1" align="center">
              {configJSON.supportDes}
              </Typography>
            </Box>
            <Grid container spacing={4} >
                <ul>
              {supportData.organisation_details.map((_item: any)=>{
                return(
             <li>
                <Typography variant="h5">
                      {_item.headings}
                    </Typography>
                    <Typography variant="subtitle1" style={{fontSize: "20px"}}>
                      {_item.description}
                    </Typography>
                    <Typography variant="body2" className="siteVisit">
                        <a href={_item.url} target="_blank " style={{color: 'rgb(145, 118, 196)'}}>
                        {configJSON.visitSite}
                        </a>
                    <CallMadeIcon />
                    </Typography>

                </li>
               )
              })
                }
                </ul>
              </Grid>
            </Box>
            <Box className="reasonsToChooseWrapper" alignItems="left" style={{backgroundColor: "rgb(241, 251, 229)",}}>
            <Box className="reasonsToChooseTitle" justifyContent='flex-start' style={{maxWidth: '100%'}}>
              <Typography variant="h3">
                {configJSON.selfCare}
              </Typography>
              <Typography variant="body1">
              {configJSON.selfCareDes}
              </Typography>
            </Box>
            <Grid container spacing={4} >
              {this.selfCare(supportData.care_description_1, supportData.care_image_1.url)}
              {this.selfCare(supportData.care_description_2, supportData.care_image_2.url)}
              {this.selfCare(supportData.care_description_3, supportData.care_image_3.url)}
              </Grid>
            </Box>
            <Box className="reasonsToChooseWrapper">
            <Grid container spacing={2} >
        <Grid item md={5}>
          <img src={supportData?.forword_image?.url} className="forwordImage" />
        </Grid>
            <Grid item className="alignContent" md={7}>
            <Typography variant="body1" className="forwordDes" dangerouslySetInnerHTML={{ __html: supportData?.forword_description }} />
                
        </Grid>
              </Grid>
            </Box>
            <Box className="reasonsToChooseWrapper">
            <Box className="reasonsToChooseTitle">
              <Typography align="center" variant="h3">
                {configJSON.map}
              </Typography>
              <Typography variant="h6" align="center">
              {configJSON.mapDes}
              </Typography>
            </Box>
            <img src={map}  className="forwordImage"/>
            </Box>
          <WebFooter handleNavigation={this.handleNavigation} />
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFFF3",
    padding: "50px 0px 0"
  },
  "& .selfCareImage": {
    width: "100%",
    height: "auto",
    minHeight: "180px",
    maxHeight: "260px",
    borderRadius: "10px",
    marginBottom: "18px"
  },
  "& .button": {
    width: "212px",
    height: "56px",
    backgroundColor: "#A190C2",
    color: "#fff",
    borderRadius: "100px",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 600,
  },
  "& .heroSectionImg": {
    width: "auto",
    height: "auto",
    maxHeight: "calc(100% - 50px)",
    aspectRatio: 613 / 566
  },
  "& .forwordImage": {
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    borderRadius: "15px"
  },
  "& .alignContent": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: '15px 35px 50px 50px'
  },

  "& .reasonsToChooseWrapper": {
    padding: "59px 114px",
    display: "flex",
  flexDirection: "column",
  alignItems: "center"
  },
  "& .siteVisit":{
    display: "flex",
    marginTop: '5px',
    textDecoration: "underline",
    color: "#9176C4"
  },
  "& .reasonsToChooseTitle": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "64px",
    flexDirection: "column",
    maxWidth: "800px",
    padding: '0 15px'
  },
  "& .reasonsToChooseCardsWrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .card-root": {
    width: "100%",
    padding: "24px",
    height: "100%",
    boxSizing: "border-box",
    borderRadius: 8
  },
  "& .cardImgIcon": {
    width: "44px",
    height: "44px",
    marginBottom: '7px'
  },
  "& .alignGridItem": {
    display: "flex",
    justifyContent: "center",
  },
  "& .forwordDes strong":{
    color: "#0F172A"
  },
  [theme.breakpoints.down(760)]: {
    "& .reasonsToChooseWrapper": {
      padding: "59px 40px !important",
    },
  },
  "& .supportImageContaine":{
      display: "flex",
      justifyContent: "flex-end"
  },
  "& .heroSectionImgWrapper": {
     backgroundImage: `url(${supportImageBack})`,
     height: "100%",
     backgroundSize: "cover",
     maxHeight: "680px",
     maxWidth: '750px',
     display:'flex',
     justifyContent: "flex-end"
    },
  [theme.breakpoints.down(500)]: {
    "& .alignCenter": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .heroSectionImgWrapper": {
      display: "none !important",
    },
  },
}));
// Customizable Area End
