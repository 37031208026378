import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bannerImage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  getActiveSubscriptionDetails: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        bannerImage: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  if (apiRequestCallId === this.getActiveSubscriptionDetails) {
      if (responseJSON.data) {
        await setStorageData("active_subscription", JSON.stringify(responseJSON.data.attributes));

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getActiveSubscriptionPlan()
  }

  getActiveSubscriptionPlan = async () => {
    const token = await getStorageData("token");

    if(token){
        this.getActiveSubscriptionDetails = sendAPIRequest(
            configJSON.getSubscriptionPlanApiEndPoint,
            {
              method: configJSON.getApiRequest,
              headers: {
                  token,
                },
            }
        );
    }
  };


  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}