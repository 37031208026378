import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { ISelectOptions } from "./LegalInformationController.web";

export interface IFuneralBasics {
  is_include_final_pdf: boolean;
  delegate_id: string;
  body_handled_after_passing: "Buried" | "Cremated";
  decision: string;
  have_funeral_place: string;
  have_location_for_wake: string;
  have_stone_spot: string;
  have_any_song: string;
  carry_people_name: string[];
  have_add_two_close_friend: string;
  have_specific_request: string;
  special_request: string;
  funeral_detail_attributes: IFuneralDetailAttributes;
  cremation_detail_attributes: ICremationDetailAttributes;
  wake_location_attributes: IWakeLocationAttributes;
  funeral_song_attributes: IFuneralSongAttributes;
  eulogy_apart_deliver_attributes: IEulogyApartDeliverAttributes;
  files: File[],
};

export interface IFuneralDetailAttributes {
  have_location_for_funeral: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
};

export interface ICremationDetailAttributes {
  have_diamon_ect: string;
  decision: string;
};

export interface IWakeLocationAttributes {
  place_name: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
};

export interface IFuneralSongAttributes {
  song_1: string;
  song_2: string;
  song_3: string;
};

export interface IEulogyApartDeliverAttributes {
  delegate1: string;
  delegate2: string;
  name_p1: string;
  relationship_p1: string;
  name_p2: string;
  relationship_p2: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void;
  handleSuccess: () => void;
  handleNextBtnClick: (path: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
funeralBasicInfo: IFuneralBasics,
delegateInCharge: ISelectOptions[],
showFuneralPlace: boolean,
showLocationForWake: boolean,
showStoneSpot: boolean,
showSongs: boolean,
showCloseFriends: boolean,
showSpecificRequest: boolean,
showCremationDetails: boolean,
showDiamonEctField: boolean,
files: File[],
isLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralBasicsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      funeralBasicInfo: {
        is_include_final_pdf: false,
        delegate_id: "",
        body_handled_after_passing: "Buried",
        decision: "",
        have_funeral_place: "0",
        have_location_for_wake: "0",
        have_stone_spot:"0",
        have_any_song: "0",
        carry_people_name: ["", "", ""],
        have_add_two_close_friend: "0",
        have_specific_request: "0",
        special_request: "",
        files: [],
        funeral_detail_attributes: {
          have_location_for_funeral: "0",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
        },
        cremation_detail_attributes: {
          have_diamon_ect: "0",
          decision: "",
        },
        wake_location_attributes: {
          place_name: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
        },
        funeral_song_attributes: {
          song_1: "",
          song_2: "",
          song_3: "",
        },
        eulogy_apart_deliver_attributes: {
          delegate1: "",
          delegate2: "",
          name_p1: "",
          relationship_p1: "",
          name_p2: "",
          relationship_p2: "",
        },
      },
      delegateInCharge: [],
      showFuneralPlace: false,
      showLocationForWake: false,
      showStoneSpot: false,
      showSongs: false,
      showCloseFriends: false,
      showSpecificRequest: false,
      showCremationDetails: false,
      showDiamonEctField: false,
      files: [],
      isLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddFuneralBasicsInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
        [this.callAddFuneralBasicsInfoApiId]: this.handleAddFuneralBasicsInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { 
      errors: possibleErrors 
    } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) 
    {
     this.props.handleError("something went wrong!"); 
     return; 
   }
 
     const response = responseJSON as {
       meta?: { 
        message: string 
      };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push(
          {
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState((prevState) => ({
        delegateInCharge,
        funeralBasicInfo: {
          ...prevState.funeralBasicInfo,
          eulogy_apart_deliver_attributes: {
            ...prevState.funeralBasicInfo.eulogy_apart_deliver_attributes, 
            delegate1: delegateInCharge[0]?.name || "", 
            delegate2: delegateInCharge[1]?.name || "",
          },
        },
      }));
      
     }
   };

  handleAddFuneralBasicsInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false})
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  handleFuneralBasicsInformationsFormSubmit = async (values: IFuneralBasics) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    this.setState({isLoading: true});

    // Append main form values
    Object.entries(values).forEach(([keyName, value]) => {
      if (keyName === 'files') {
        this.appendFiles(values.files, this.state.files, formData);
      } else {
        this.appendOtherValues(keyName, value, formData);
      }
    });

    // Append conditional attributes
    if (values.have_funeral_place === "1") this.appendAttributes(values.funeral_detail_attributes, 'funeral_detail_attributes', formData);
    if (values.body_handled_after_passing === "Cremated") this.appendAttributes(values.cremation_detail_attributes, 'cremation_detail_attributes', formData);
    if (values.have_location_for_wake === "1") this.appendAttributes(values.wake_location_attributes, 'wake_location_attributes', formData);
    if (values.have_any_song === "1") this.appendAttributes(values.funeral_song_attributes, 'funeral_song_attributes', formData);
    if (values.have_add_two_close_friend === "1") this.appendAttributes(values.eulogy_apart_deliver_attributes, 'eulogy_apart_deliver_attributes', formData);


    this.callAddFuneralBasicsInfoApiId = sendAPIRequest(
      configJSON.addFuneralBasicsInformationApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  appendAttributes = (
    attributes: IFuneralDetailAttributes | IWakeLocationAttributes | ICremationDetailAttributes | IFuneralSongAttributes | IEulogyApartDeliverAttributes, 
    attributeName: string, 
    formData: FormData
  ) => {
    Object.entries(attributes).forEach(([keyName, value]) => {
      if (value) {
        formData.append(`funeral[${attributeName}][${keyName}]`, value as string);
      }
    });
  };
  
  appendFiles = (files: File[], stateFiles: File[], formData: FormData) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach((file: File) => {
        formData.append(`funeral[files][]`, file as Blob);
      });
    }
  };
  
  appendOtherValues = (keyName: string, value: any, formData: FormData) => {
    if (keyName === "carry_people_name") {
      formData.append(`funeral[${keyName}][]`, value as string);
    } else if (!["funeral_detail_attributes", "cremation_detail_attributes", "wake_location_attributes", "funeral_song_attributes", "eulogy_apart_deliver_attributes"].includes(keyName)) {
      formData.append(`funeral[${keyName}]`, value as string);
    }
  };

  validationSchema = Yup.object().shape({
    delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
    funeral_detail_attributes:  Yup.object().when(['have_funeral_place'], {
      is: (have_funeral_place) => have_funeral_place === "1",
      then: Yup.object().shape({
        address_line_1: Yup.string().nullable().required("Please enter address line 1"),
        address_line_2: Yup.string().nullable().required("Please enter address line 2"),
        city: Yup.string().nullable().required("Please enter city"),
        country: Yup.string().nullable().required("Please select country"),
        post_code: Yup.string().nullable().required("Please enter post code"),
      }),
      otherwise: Yup.object().nullable(),
    }),
    wake_location_attributes:  Yup.object().when(['have_location_for_wake'], {
      is: (have_location_for_wake) => have_location_for_wake === "1",
      then: Yup.object().shape({
        address_line_1: Yup.string().nullable().required("Please enter address line 1"),
        address_line_2: Yup.string().nullable().required("Please enter address line 2"),
        city: Yup.string().nullable().required("Please enter city"),
        country: Yup.string().nullable().required("Please select country"),
        post_code: Yup.string().nullable().required("Please enter post code"),
      }),
      otherwise: Yup.object().nullable(),
    }),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
  ) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFiles],
      }), () => {
        // Set the field value after the state has been updated
        setFieldValue('files', [...this.state.files]);
      });
    }
  };
  // Customizable Area End
}