import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { IFuneralFurtherItems } from "../../../blocks/customform/src/FuneralFurtherItemsController.web";

export interface IGetFurtherItemsApiResponse {
    id: string;
    type: string;
    attributes: {
        id: number;
        added_your_coffine: string;
        items: string;
        account_id: number;
    };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  furtherItemsInfo: IFuneralFurtherItems,
  isLoading: boolean,
  isEdit: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralFurtherEditController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        furtherItemsInfo: {
            added_your_coffine: "0",
            items: "",
        },
        isLoading: false,
        isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetFuneralFurtherItemsDataApiId: string = "";
  callEditFuneralFurtherItemsDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getFurtherItemsData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetFuneralFurtherItemsDataApiId]: this.handleGetFuneralFurtherItemsDataApiResponse,
        [this.callEditFuneralFurtherItemsDataApiId]: this.handleEditFuneralFurtherItemsDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getFurtherItemsData = async() => {
    const token = await getStorageData("token");

    this.callGetFuneralFurtherItemsDataApiId = sendAPIRequest(
        configJSON.getFuneralFurtherItemsDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetFuneralFurtherItemsDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFurtherItemsApiResponse;
    };

    if (response.data) {
        const attributes = response.data.attributes;

        let furtherItemsInfo = {
            added_your_coffine: attributes.added_your_coffine === "Yes" ? "1" : "0",
            items: attributes.items,
        } as IFuneralFurtherItems;

        this.setState({ furtherItemsInfo });
    }
  };

   handleEditFuneralFurtherItemsDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isEdit: false});
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  handleFuneralFurtherItemsDataFormSubmit = async (values: IFuneralFurtherItems) => {
    if(!this.state.isEdit){
        this.setState({
            isEdit: true,
        });
    }
    else {
        const token = await getStorageData("token");
        const formData = new FormData();

        this.setState({ isLoading: true });

        formData.append("further[added_your_coffine]", values.added_your_coffine);
        
        if(values.added_your_coffine === "1")
            formData.append("further[items]", values.items);

        this.callEditFuneralFurtherItemsDataApiId = sendAPIRequest(
            configJSON.getFuneralFurtherItemsDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };
  // Customizable Area End
}
