import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import InvestmentsAndFundsController, {
    IBankingProvider,
    IBond,
    IStock,
    InvestmentsAndFundsInterface,
    Props,
    configJSON,
} from "./InvestmentsAndFundsController.web";
import { 
  Box,
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DialogWrapper, StyledRadio, theme } from "./LegalInformation.web";
import MediaUpload from "../../../components/src/MediaUpload.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import GenericModal from "../../../components/src/GenericModal";

// Customizable Area End
export default class InvestmentsAndFunds extends InvestmentsAndFundsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBankDetailsForm = (
    values: FormikValues, 
    errors: FormikErrors<InvestmentsAndFundsInterface>, 
    touched: FormikTouched<InvestmentsAndFundsInterface>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <FieldArray name="banking_provider" data-test-id="bankDetailsFieldArray">
            {({ remove, push }) => (
                values.banking_provider.map((bankProvider: IBankingProvider, index: number) => {
                    return (
                        <Grid item xs={12} key={index} className="formSectionBackground">
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                    <Typography data-test-id="bankDetailsFormTitle" variant="h6">
                                        {configJSON.bankDetailsFormTitle}
                                    </Typography>
                                    {index === 0 && (
                                    <Button 
                                    className="addMoreBtn"
                                    data-test-id = "addMoreBankProvidersBtn"
                                    onClick={() => push({
                                        provider_name: "",
                                    })}
                                    >
                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                    {configJSON.addMoreBtnText}
                                    </Button>
                                    )}
                                    {index !== 0 && (
                                    <Button 
                                    data-test-id="removeBankProvidersBtn"
                                    className="addMoreBtn"
                                    onClick={() => remove(index)}>
                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                    {configJSON.removeBtnText}
                                    </Button>)}
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">{configJSON.providerNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                    <Field
                                        data-test-id="providerName"
                                        name="providerName"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Enter your banking provider"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`banking_provider.${index}.provider_name`, e.target.value)
                                        }}
                                        value={bankProvider.provider_name}
                                    />
                                    <ErrorMessage name={`banking_provider.${index}.provider_name`} />
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })
            )}
        </FieldArray>
  )};

  renderStockDetailsForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="stocks" data-test-id="stockFieldArray">
        {({ remove, push }) => (
          values.stocks.map((stock: IStock, index: number) => {
            return(
              <Grid item xs={12} style={{ margin: "24px 0" }} className="formSectionBackground">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Typography data-test-id="stockDetailsFormTitle" variant="h6">{configJSON.stockDetailsFormTitle}</Typography>
                    {index === 0 && (
                    <Button 
                      className="addMoreBtn"
                      data-test-id = "addMoreStocksBtn"
                      onClick={() => push({
                          name: "",
                          quantity: "",
                          total_value: "",
                      })}
                    >
                      <AddCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.addMoreBtnText}
                    </Button>
                    )}
                    {index !== 0 && (
                    <Button 
                      data-test-id="removeStocksBtn"
                      className="addMoreBtn"
                      onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.removeBtnText}
                    </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.stockNameFieldLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="stockName"
                      className="inputField"
                      name="stockName"
                      as={CustomInput}
                      placeholder="Enter bond holder name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`stocks.${index}.name`, e.target.value)
                      }}
                      value={stock.name}
                    />
                    <ErrorMessage name={`stocks.${index}.name`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.stockQuantity}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="stockQuantity"
                      className="inputField"
                      name="stockQuantity"
                      as={CustomInput}
                      placeholder="Quantity"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`stocks.${index}.quantity`, e.target.value)
                      }}
                      value={stock.quantity}
                    />
                    <ErrorMessage name={`stocks.${index}.quantity`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.totalValue}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="totalValue"
                      className="inputField"
                      name="totalValue"
                      as={CustomInput}
                      placeholder="00.00"
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`stocks.${index}.total_value`, e.target.value)
                      }}
                      value={stock.total_value}
                    />
                    <ErrorMessage name={`stocks.${index}.total_value`} />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
  )};

  renderBondDetailsForm = (
    values: FormikValues,  
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      const max_investment_documents = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_investment_documents : 2;
    return(
      <FieldArray name="bonds" data-test-id="bondFieldArray">
        {({ remove, push }) => (
          values.bonds.map((bond: IBond, index: number) => {
            return(
                <Box mb={3}>
              <Grid item xs={12} style={{ margin: "24px 0" }} className="formSectionBackground">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Typography data-test-id="bondDetailsFormTitle" variant="h6">{configJSON.bondDetailsFormTitle}</Typography>
                    {index === 0 && (
                    <Button 
                      className="addMoreBtn"
                      data-test-id = "addMoreBondsBtn"
                      onClick={() => push({
                        holder_name: "",
                        unit: "",
                        no_of_unit: "",
                        bond_value: "",
                        files: [],
                      })}
                    >
                      <AddCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.addMoreBtnText}
                    </Button>
                    )}
                    {index !== 0 && (
                    <Button 
                      data-test-id="removeStocksBtn"
                      className="addMoreBtn"
                      onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.removeBtnText}
                    </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.holderNameFieldLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="holderName"
                      className="inputField"
                      name="holderName"
                      as={CustomInput}
                      placeholder="Enter bond holder name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`bonds.${index}.holder_name`, e.target.value)
                      }}
                      value={bond.holder_name}
                    />
                    <ErrorMessage name={`bonds.${index}.holder_name`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.unitFieldLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="unit"
                      className="inputField"
                      name="unit"
                      as={CustomInput}
                      placeholder="Enter your unit value"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`bonds.${index}.unit`, e.target.value)
                      }}
                      value={bond.unit}
                    />
                    <ErrorMessage name={`bonds.${index}.unit`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.noOfUnitFieldLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="noOfUnit"
                      className="inputField"
                      name="noOfUnit"
                      as={CustomInput}
                      placeholder="Enter number of units"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`bonds.${index}.no_of_unit`, e.target.value)
                      }}
                      value={bond.no_of_unit}
                    />
                    <ErrorMessage name={`bonds.${index}.no_of_unit`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                        {configJSON.bondValueFieldLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="bondValue"
                      className="inputField"
                      name="bondValue"
                      as={CustomInput}
                      placeholder="Enter your bond value"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`bonds.${index}.bond_value`, e.target.value)
                      }}
                      value={bond.bond_value}
                    />
                    <ErrorMessage name={`bonds.${index}.bond_value`} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <MediaUpload
                data-test-id="mediaUpload"
                files={(this.state.files.length > 0  && this.state.files[index]) ? this.state.files[index] : bond.files }
                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, index, setFieldValue)}
                maxFiles={max_investment_documents}
                />
              </Grid>
              </Box>
            );
          })
        )}
      </FieldArray>
  )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
        investmentsAndFunds,
        showBankDetailsForm,
        showStockDetailsForm,
        showBondDetailsForm,
        openDialog,
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} progress={27} />
            <Box className="container">
              <Box className="sidebarWrapper">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
              </Box>
              <Box className="investmentsWrapper">
                <Box className="pageTitle">
                  <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                    {configJSON.investmentsAndFundsPageTitle}
                  </Typography>
                </Box>
                <Box className="formWrapper wrapperFormContainer">
                  <Formik
                    initialValues={investmentsAndFunds}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleInvestmentsAndFundsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="investmentsAndFundsForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form className="wrapperFormContainer">
                        <Box minHeight="calc(100% - 100px)" height="auto" className="formWrapper">
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveBankingProviderQuestion}
                                </FormLabel>
                                <RadioGroup
                                  data-test-id="haveBankingProvider"
                                  aria-label="haveBankingProvider"
                                  name="haveBankingProvider"
                                  value={values.investment.have_banking_provider}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("investment.have_banking_provider", e.target.value);
                                    this.setState({ showBankDetailsForm: !showBankDetailsForm });
                                  }
                                  }
                                  className="radioGroup"
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                    className="radioLabel"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                    className="radioLabel"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showBankDetailsForm && (
                                this.renderBankDetailsForm(values, errors, touched, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.haveStocksQuestion}
                                </FormLabel>
                                <RadioGroup
                                  data-test-id="haveStocks"
                                  aria-label="haveStocks"
                                  name="haveStocks"
                                  value={values.investment.have_stocks}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("investment.have_stocks", e.target.value);
                                    this.setState({ showStockDetailsForm: !showStockDetailsForm });
                                  }
                                  }
                                  className="radioGroup"
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                    className="radioLabel"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                    className="radioLabel"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showStockDetailsForm && (
                                this.renderStockDetailsForm(values, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                  {configJSON.havePremimumBondsQuestion}
                                </FormLabel>
                                <RadioGroup
                                  data-test-id="havePremimumBonds"
                                  aria-label="havePremimumBonds"
                                  name="havePremimumBonds"
                                  value={values.investment.have_premimum_bonds}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("investment.have_premimum_bonds", e.target.value);
                                    this.setState({ showBondDetailsForm: !showBondDetailsForm });
                                  }
                                  }
                                  className="radioGroup"
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                    className="radioLabel"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                    className="radioLabel"
                                  />
                                </RadioGroup>
                              </Grid>
                              {showBondDetailsForm && (
                                this.renderBondDetailsForm(values, setFieldValue)
                              )}
                            </Grid>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                              data-test-id="skipForNow"
                              className="btnSize"
                              variant="outlined"
                              onClick={
                                () => this.handleNavigation("CustomForm")
                                }
                              >
                              {configJSON.skipForNowBtnText}
                            </Button>
                            <Button
                              data-test-id="saveAndNextBtn"
                              className="btnSize"
                              variant="contained"
                              type="submit"
                            >
                              {configJSON.saveAndNext}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
            <WebFooter 
              handleNavigation={this.handleNavigation} 
            />
          </Wrapper>
        </ThemeProvider>
        <GenericModal 
          data-test-id="information-added-modal" 
          open={openDialog} 
          onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <Typography 
                    align="center" 
                    variant="h1" 
                    data-test-id="emailSentModalTitle"
                  >
                    {configJSON.infoAddedDialogTitleText}
                  </Typography>
                  <Typography align="center" variant="h2">
                    Your investments details has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                  </Typography>
                </Box>
                <Box className="dialogBtnsWrapper">
                  <Button
                    data-test-id="skipForNowDialogBtn"
                    className="outlinedBtn"
                    variant="outlined"
                    onClick={
                      () => this.handleNavigation("CustomForm")
                    }
                  >
                    {configJSON.skipForNowBtnText}
                  </Button>
                  <Button
                    data-test-id="nextSectionBtn"
                    className="containedBtn"
                    variant="contained"
                    onClick={
                      () => this.handleNavigation("FamilyDetails")
                    }
                  >
                    {configJSON.nextSectionBtnText}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .errorMsg": {
        color: "#DC2626",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
      },
    "& .container": {
        display: "flex",
        gap: "72px",
        padding: "50px 72px",
    },
    "& .pageTitle": {
        margin: "32px 0",
    },
    "& .selectField": {
        height: "48px",
        width: "300px",
        borderRadius: "8px",
        margin: "5px 0px",
        display: "block",
    },
    "& .formWrapper": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    "& .wrapperFormContainer":{
height: '100%'
    },
    "& .investmentsWrapper": {
      width: "100vw",
    },
    "& .inputField": {
        height: "48px",
        width: "300px",
        borderRadius: "8px",
    },
    "& .radioGroup": {
        marginLeft: "16px",
        gap: "35px",
        display: 'flex',
        flexDirection: 'row',
    },
    "& .radioLabel": {
        color: '#1E293B',
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#9176C4',
        },
    },
    "& .formSectionBackground": {
        borderRadius: "8px",
        backgroundColor: "rgba(206, 195, 229, 0.1)",
        padding: "24px",
    },
    "& .fieldArrayHeaderWrapper": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .addMoreBtn": {
        color: "#9176C4",
        textTransform: "none",
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        '&:hover': {
          backgroundColor: "transparent",
          textDecoration: "underline",
        },
    },
    "& .circleOutlineIcon": {
        marginRight: "8px",
        fontSize: "16px",
    },
    "& .btnSize": {
      height: "48px",
      width: "158px",
    },
    "& .btnWrapper": {
        gap: "8px",
        display: "flex",
        justifyContent: "flex-end",
    },

    [theme.breakpoints.down(740)]: {
        "& .container": {
            padding: "28px 16px",
        },
    },
}));
// Customizable Area End
