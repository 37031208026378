import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";
import { Box, Grid, Typography, Button, Card } from "@material-ui/core";
import {
  accessibleImg,
  easyToUseImg,
  mainSectionImg,
  personalImg,
  secureImg,
} from "./assets";
import WebHeader from "./WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "42px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      h2: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h3: {
        color: "#000000",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h6: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "40px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
  },
});
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box className="container">
            <Grid container>
              <Grid className="alignContent" item md={6}>
                <Box
                  className="alignCenter"
                  mb={5}
                  style={{ maxWidth: "575px" }}
                >
                  <Typography variant="h6" className="subHeading">
                    <span style={{ color: "#ACCC86" }}>CAPTURE</span>
                    <span style={{ color: "#475569" }}>AND</span>
                    <span style={{ color: "#A190C2" }}>SHARE</span>
                  </Typography>
                  <Typography
                    variant="h2"
                    className="heading"
                    data-test-id="heading"
                  >
                    Preserve your Important{" "}
                    <span style={{ color: "#ACCC86" }}>DATA</span> and{" "}
                    <span style={{ color: "#ACCC86" }}>MEMORIES</span>
                  </Typography>
                  <Typography variant="body1">
                    Save your data and memories with{" "}
                    <span className="delegateMeeSpan">DELEGATE MEE</span> to be
                    shared on your dimisse. Then, let your loved ones hear your
                    meaningful stories.
                  </Typography>
                </Box>
                <Box className="alignCenter">
                  <Button
                    variant="contained"
                    className="button"
                    onClick={() => this.handleNavigation("Signuplogin")}
                  >
                    {configJSON.getStartedBtnText}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className="heroSectionImgWrapper">
                  <img
                    alt="image"
                    src={mainSectionImg}
                    className="heroSectionImg"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="reasonsToChooseWrapper">
            <Box className="reasonsToChooseTitle">
              <Typography align="center" variant="h3">
                {configJSON.reasonsToChooseTitle}
              </Typography>
            </Box>
            <Box className="reasonsToChooseCardsWrapper">
              <Grid container spacing={4}>
                <Grid item className="alignGridItem" xs={12} sm={6} md={3}>
                  <Card className="card-root">
                    <img
                      alt="easy-to-use"
                      src={easyToUseImg}
                      className="cardImgIcon"
                    />
                    <Typography align="center" variant="subtitle1">
                      {configJSON.easyToUseTitle}
                    </Typography>
                    <Typography align="center" variant="body2">
                      {configJSON.easyToUseDescription}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item className="alignGridItem" xs={12} sm={6} md={3}>
                  <Card className="card-root">
                    <img
                      alt="accesible"
                      src={accessibleImg}
                      className="cardImgIcon"
                    />
                    <Typography align="center" variant="subtitle1">
                      {configJSON.accessibleTitle}
                    </Typography>
                    <Typography align="center" variant="body2">
                      {configJSON.accessibleDescription}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item className="alignGridItem" xs={12} sm={6} md={3}>
                  <Card className="card-root">
                    <img
                      alt="personal"
                      src={personalImg}
                      className="cardImgIcon"
                    />
                    <Typography align="center" variant="subtitle1">
                      {configJSON.personalTitle}
                    </Typography>
                    <Typography align="center" variant="body2">
                      {configJSON.personalDescription}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item className="alignGridItem" xs={12} sm={6} md={3}>
                  <Card className="card-root">
                    <img alt="secure" src={secureImg} className="cardImgIcon" />
                    <Typography align="center" variant="subtitle1">
                      {configJSON.secureTitle}
                    </Typography>
                    <Typography align="center" variant="body2">
                      {configJSON.secureDescription}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="allAboutWrapper">
            <Box className="allAboutTitle">
              <Typography align="center" variant="h1">
                All about &nbsp;
                <span className="allAboutSpan1">DELEGATE</span>&nbsp;
                <span className="allAboutSpan2">MEE</span>
              </Typography>
            </Box>
            <Box>
              {this.state.aboutData.map((item, index) => {
                return (
                  <Grid
                    container
                    key={item.id}
                    spacing={4}
                    direction={index % 2 === 0 ? "row" : "row-reverse"}
                  >
                    <Grid item className="alignContent" md={6}>
                      <Typography variant="h3" style={{ lineHeight: "42px" }}>
                        {item.heading}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#475569", lineHeight: "36px" }}
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item className="alignContent" md={6}>
                      <img src={item.image} className="heroSectionImg" />
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
          <WebFooter handleNavigation={this.handleNavigation} />
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px 72px",
    backgroundColor: "#FAFFF3",
  },
  "& .subHeading": {
    display: "flex",
    gap: "5px",
  },
  "& .delegateMeeSpan": {
    fontFamily: "Times New Roman",
    fontStyle: "italic",
    fontWeight: 800,
  },
  "& .button": {
    width: "212px",
    height: "56px",
    backgroundColor: "#A190C2",
    color: "#fff",
    borderRadius: "100px",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 600,
  },
  "& .heroSectionImg": {
    width: "100%",
    height: "100%",
    maxHeight: '650px',
    objectFit: 'contain'
  },
  "& .alignContent": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  // Reasons to choose section
  "& .reasonsToChooseWrapper": {
    backgroundColor: "#CEC3E3",
    padding: "59px 114px",
  },
  "& .reasonsToChooseTitle": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "64px",
  },
  "& .reasonsToChooseCardsWrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .card-root": {
    width: "270px",
    height: "280px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    boxSizing: "border-box",
  },
  "& .cardImgIcon": {
    width: "64px",
    height: "64px",
  },
  "& .alignGridItem": {
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down(760)]: {
    "& .reasonsToChooseWrapper": {
      padding: "59px 40px !important",
    },
  },

  // All about section
  "& .allAboutWrapper": {
    padding: "77px 122px",
  },
  "& .allAboutTitle": {
    marginBottom: "54px",
  },
  "& .allAboutSpan1": {
    fontWeight: 800,
    fontStyle: "italic",
    color: "#ACCC86",
  },
  "& .allAboutSpan2": {
    fontWeight: 800,
    fontStyle: "italic",
    color: "#CEC3E3",
  },
  [theme.breakpoints.down(500)]: {
    "& .allAboutWrapper": {
      padding: "50px 60px !important",
    },
    "& .alignCenter": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .heroSectionImgWrapper": {
      display: "none !important",
    },
  },
}));
// Customizable Area End
