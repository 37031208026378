Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
"bx_block_profile/profile/custom_user_profile_fields";
exports.getDelegateMembersApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.addDelegateMemberApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.editDelegateMemberApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.confirmDetailsApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members/confirm";
exports.getNationalitiesApiEndPoint = 
"bx_block_customisableuserprofiles/nationalities";
exports.getLoggedInUserDetailsApiEndPoint = 
"account_block/accounts/logged_user";


exports.changePicture = "Change Picture";
exports.removePicture = "Remove Picture";

// create profile
exports.createProfileStep1="Profile";
exports.createProfileStep2="Delegate Members";
exports.createProfileTitle = "Create Profile";
exports.createProfileSubtitle = "Before starting, let’s complete your profile.";
exports.addressTitle = "This address is where we will send your Delegate Mee user card";
exports.profileCreationReason = "Profile Creation Reason";

exports.firstNameLabel = "First Name";
exports.middleNameLabel = "Middle Name (optional)";
exports.lastNameLabel = "Last Name";
exports.nationality = "Nationality";
exports.genderLabel = "Gender";
exports.genderNameLabel = "Enter gender";
exports.addressLine1 = "Address line 1";
exports.addressLine2 = "Address line 2";
exports.landmarkLabel = "Landmark (if required)";
exports.townCityLabel = "Town/City";
exports.countryLabel = "Country";
exports.postCodeLabel = "Post Code";
exports.emailIdLabel = "Email ID";
exports.phoneNumber = "Phone Number";
exports.dateOfBirthLabel = "DOB";
exports.ageLabel = "Age";

exports.reasonFieldLabel = "Reason";
exports.partnerDetails = "Partnership Details";
exports.partnershipStatusFieldLabel = "Partnership Status";
exports.addyYourPartnerTitle = "Add your partner as Delegate Member 1";
exports.healthAndFitness = "Health and Fitness";
exports.areYouFitAndWell = "Are you currently fit and well?";
exports.issueFieldLabel = "Issue";

exports.skipForNowBtnText = "Skip for now";
exports.createProfileBtnText = "Create Profile";

exports.profileCreated = "Profile Created!";
exports.modalSuccessMsg = "Your profile has been created successfully. Before getting started, we would like you to add delegate users for future.";
exports.addDelegatesBtnText = "Add Delegates";

// delegate members
exports.delegateMemberAddressDescription = 
"This address must be for your Delegated Member. We will post them a personal access card to keep safe. Don't worry, they still will not be able to access your account useless we have proof of death";
exports.securityPhraseLabel = "Security Phrase";
exports.nextBtnText = "Next";
exports.securityPhraseInfo = 
"DON NOT SHARE this with anyone. Your security phrase will be ‘foiled’ behind scratch panel on your delegates security card. This is all part of our security/retrieval process.";
exports.addDelegateMemberTitle = "Delegated Member";
exports.delegateMemberInfo = "Delegate Users are the only people who can access your information in case something happens to you.";
exports.delegateMemberSubtitle = 
"Delegated Members will be sent access cards. We will also make them aware of your profile creation. Adding two Delegated Members is mandatory.";

exports.delegateUser = "Delegate User";
exports.confirmDetailsBtnText = "Confirm Details";

exports.youAreAllSet = "You are all Set!";
exports.youAreAllSetDescription = "Before getting started, please select the desired subscription plan to start using the Delegate Mee app.";
exports.continueBtnText = "Continue";
// Customizable Area End
