import React from "react";
// Customizable Area Start
import FuneralAttireController, {
    configJSON,
    Props
} from "./FuneralAttireController.web";
import {
    Box,
    Button,
    Checkbox,
    FormLabel,
    Grid,
    Typography,
} from "@material-ui/core";
import Spinner from "./components/Spinner.web";
import { Field, Form, Formik, FormikValues } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import MediaUpload from "../../../components/src/MediaUpload.web";

// Customizable Area End

export default class FuneralAttire extends FuneralAttireController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderInChargeDetails = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <Typography data-test-id="attireInChargeTitle" variant="h6">
                            {configJSON.attireInChargeTitle}
                        </Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <FormLabel component="label">
                            {configJSON.nameFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="inChargeFullName"
                            name="inChargeFullName"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Enter the full name"
                            value={values.charger_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("charger_name", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <FormLabel component="label">
                            {configJSON.relationshipFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="inChargeRelationship"
                            className="inputField"
                            name="inChargeRelationship"
                            as={CustomInput}
                            placeholder="Enter relationship"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("charger_relationship", e.target.value)
                            }}
                            value={values.charger_relationship}
                        />
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                </Grid>
            </Grid>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            attireInfo,
            isLoading,
            delegateInCharge,
            attireInCharge,
        } = this.state;

        return (
            <Box className="formWrapper">
                {isLoading && <Spinner spinnerModal={isLoading} />}
                <Formik
                    initialValues={attireInfo}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleFuneralAttireInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="attireForm"
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Box className="attireFormFields">
                                    <Box className="formSectionTitle">
                                        <Box>
                                            <Typography data-test-id="attireTitle" variant="h3" className="titleText">
                                                {configJSON.attireTitle}
                                            </Typography>
                                        </Box>
                                        <Box className="checkboxWrapper">
                                            <Checkbox
                                                data-test-id="is_include_final_pdf"
                                                color="primary"
                                                checked={values.is_include_final_pdf}
                                                onChange={(e) => {
                                                    setFieldValue('is_include_final_pdf', e.target.checked)
                                                }}
                                                style={{ color: "#9176C4" }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <FormLabel component="label" className="pdfLabel">{configJSON.pdfLabel}</FormLabel>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label">
                                                    {configJSON.DelegateInChargeLabel}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Field
                                                    name="delegateInCharge"
                                                    data-test-id="delegateInCharge"
                                                    className="selectField delegateSelectField"
                                                    as={CustomSelect}
                                                    options={delegateInCharge}
                                                    placeholder="Select Delegate"
                                                    setValue={true}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("delegate_id", e.target.value)
                                                    }
                                                    error={touched.delegate_id && errors.delegate_id}
                                                    errorMsg={errors.delegate_id}
                                                    value={values.delegate_id}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                    {configJSON.attireQuestion1}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    {configJSON.attireInCharge}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Field
                                                    data-test-id="attireInCharge"
                                                    name="attireInCharge"
                                                    className="selectField delegateSelectField"
                                                    as={CustomSelect}
                                                    placeholder="Select"
                                                    options={attireInCharge}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("charge_person", e.target.value)
                                                    }
                                                    value={values.charge_person}
                                                    error={touched.charge_person && errors.charge_person}
                                                    errorMsg={errors.charge_person}
                                                />
                                            </Grid>
                                            {values.charge_person === "Other" && (
                                                this.renderInChargeDetails(values, setFieldValue)
                                            )}
                                            <Grid item xs={12}>
                                                <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                    {configJSON.attireQuestion2}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <FormLabel component="label">
                                                    {configJSON.upperClothFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="upperCloth"
                                                    name="upperCloth"
                                                    className="inputField"
                                                    size="small"
                                                    as={CustomInput}
                                                    placeholder="Name of clothing Item"
                                                    value={values.upper_cloth}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("upper_cloth", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <FormLabel component="label">
                                                    {configJSON.lowerClothFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="lowerCloth"
                                                    name="lowerCloth"
                                                    className="inputField"
                                                    size="small"
                                                    as={CustomInput}
                                                    placeholder="Name of clothing Item"
                                                    value={values.lower_cloth}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("lower_cloth", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <FormLabel component="label">
                                                    {configJSON.shoesFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="shoes"
                                                    name="Shoes"
                                                    className="inputField"
                                                    size="small"
                                                    as={CustomInput}
                                                    placeholder="Name of clothing Item"
                                                    value={values.shoes}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("shoes", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <FormLabel component="label">
                                                    {configJSON.socksFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="socks"
                                                    name="socks"
                                                    className="inputField"
                                                    size="small"
                                                    as={CustomInput}
                                                    placeholder="Name of clothing Item"
                                                    value={values.socks}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("socks", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <FormLabel component="label">
                                                    {configJSON.otherFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="other"
                                                    name="other"
                                                    className="inputField"
                                                    size="small"
                                                    as={CustomInput}
                                                    placeholder="Name of clothing Item"
                                                    value={values.other}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("other", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <MediaUpload
                                                    data-test-id="mediaUpload"
                                                    files={this.state.files}
                                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="btnWrapper">
                                    <Button
                                        className="btnSize"
                                        variant="outlined"
                                        data-test-id="skipForNow"
                                        onClick={() => this.props.handleNextBtnClick("CustomForm")
                                        }
                                    >
                                        {configJSON.skipForNowBtnText}
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="btnSize"
                                        data-test-id="saveAndNextBtn"
                                    >
                                        {configJSON.saveAndNext}
                                    </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End