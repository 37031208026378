import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";

export interface ICV {
  have_cv: string
  have_job: string
  files: File[]
  job_title: string
  job_description: string
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string) => void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  aboutCV: ICV
  cvLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutCVController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize: number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      aboutCV: {
        have_cv: '0',
        have_job: '0',
        job_title: "",
        job_description: "",
        files: []
      },
      cvLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.callAboutCVInfoApiId) {
      this.setState({ cvLoading: false })
      if (responseJSON.data) {
        this.props.handleSuccess()
      } else {
        let cvError = responseJSON?.errors[0]
        if (cvError) {
          let key = Object.keys(cvError)[0]
          let message = key + " " + cvError[key]
          this.props.handleError(message.replace("_", " "))
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  callAboutCVInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
  }

  handleAboutCVInformationsFormSubmit = async (values: ICV) => {
    const token = await getStorageData("token");
    const cvFormData = new FormData();
    this.setState({ cvLoading: true })
    Object.entries(values).forEach(([keyName, value]) => {
      if (value) {
        if (keyName === 'files' && values.files.length > 0) {
          values.files.forEach((file: File) => {
            cvFormData.append(`cv[${keyName}][]`, file as Blob);
          });
        }
        if(keyName !== "files"){
          cvFormData.append(`cv[${keyName}]`, value);
        }
      }
    });
    this.callAboutCVInfoApiId = sendAPIRequest(
      configJSON.cvEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: cvFormData,
      }
    );
  };

  validationSchema = Yup.object().shape({
    job_title: Yup.string().when('have_job', {
      is: "1",
      then: Yup.string().required('Job title is required'),
      otherwise: Yup.string(),
    }),
  });


  handleCVFile = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void;
      (arg0: string, arg1: string): void;
    },
    prevFiles: File[]) => {
    if (event.target.files) {
      const cvFiles = Array.from(event.target.files);
      const cvOversizedFiles = cvFiles.filter(file => file.size > this.maxFileSize);
      if (cvOversizedFiles.length > 0) {
        this.props.handleError(configJSON.maxFileSizeError)
        return
      }
      let files = [...prevFiles, ...cvFiles]
      setFieldValue(`files`, files)
    }
  };

  // Customizable Area End
}
