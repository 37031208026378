import React from "react";
// Customizable Area Start
import FuneralHairBeautyController, {
    configJSON,
    Props
} from "./FuneralHairBeautyController.web";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import Spinner from "./components/Spinner.web";
import { Field, Form, Formik } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import MediaUpload from "../../../components/src/MediaUpload.web";
import { StyledRadio } from "./LegalInformation.web";

// Customizable Area End

export default class FuneralHairBeauty extends FuneralHairBeautyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            hairBeautyInfo,
            isLoading,
            delegateInCharge,
        } = this.state;

        return (
            <Box className="formWrapper">
                {isLoading && <Spinner spinnerModal={isLoading} />}
                <Formik
                    initialValues={hairBeautyInfo}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleFuneralHairBeautyInfoFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="hairBeautyForm"
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Box className="hairBeautyFormFields">
                                    <Box className="formSectionTitle">
                                        <Box>
                                            <Typography data-test-id="hairBeautyTitle" variant="h3" className="titleText">
                                                {configJSON.attireTitle}
                                            </Typography>
                                        </Box>
                                        <Box className="checkboxWrapper">
                                            <Checkbox
                                                color="primary"
                                                data-test-id="is_include_final_pdf"
                                                checked={values.is_include_final_pdf}
                                                onChange={(e) => {
                                                    setFieldValue('is_include_final_pdf', e.target.checked)
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                style={{ 
                                                    color: "#9176C4" 
                                                }}
                                            />
                                            <FormLabel component="label" className="pdfLabel">
                                                {configJSON.pdfLabel}
                                            </FormLabel>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label">
                                                    {configJSON.DelegateInChargeLabel}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Field
                                                    data-test-id="delegateInCharge"
                                                    name="delegateInCharge"
                                                    as={CustomSelect}
                                                    className="selectField delegateSelectField"
                                                    setValue={true}
                                                    placeholder="Select Delegate"
                                                    options={delegateInCharge}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("delegate_id", e.target.value)
                                                    }
                                                    value={values.delegate_id}
                                                    error={touched.delegate_id && errors.delegate_id}
                                                    errorMsg={errors.delegate_id}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                    {configJSON.hairBeautyQuestion1}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    {configJSON.hairStyleTypeFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="hairStyleType"
                                                    name="hairStyleType"
                                                    className="hairBeautyInput"
                                                    multiline
                                                    as={CustomInput}
                                                    placeholder="Example: Bob, Straight, Spikey"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("hair_style_type", e.target.value)
                                                    }
                                                    value={values.hair_style_type}
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <MediaUpload
                                                    data-test-id="mediaUploadForHair"
                                                    files={this.state.hairStyleFiles}
                                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "hair_style_files")}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                        {configJSON.hairBeautyQuestion2}
                                                    </Typography>
                                                </FormLabel>
                                                <RadioGroup
                                                    className="radioGroup"
                                                    data-test-id="requireMakeup"
                                                    aria-label="requireMakeup"
                                                    name="requireMakeup"
                                                    value={values.require_makeup}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue("require_makeup", e.target.value);
                                                    }
                                                    }
                                                >
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="1"
                                                        control={<StyledRadio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="0"
                                                        control={<StyledRadio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            { values.require_makeup === "1" && (
                                                <>
                                                    <Grid item lg={4} xs={12} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} style={{ marginTop: "16px" }}>
                                                                <Typography data-test-id="makeupDetailsTitle" variant="h6">
                                                                    {configJSON.makeupDetailsTitle}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={4} xs={12} sm={6}>
                                                                <FormLabel component="label" className="formRadioLabel">
                                                                    {configJSON.makeupTypeFieldLabel}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="makeupType"
                                                                    name="makeupType"
                                                                    className="hairBeautyInput"
                                                                    multiline
                                                                    as={CustomInput}
                                                                    placeholder="Example: Duewy glow, full face"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                        setFieldValue("makeup_type", e.target.value)
                                                                    }
                                                                    value={values.makeup_type}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <MediaUpload
                                                            data-test-id="mediaUploadForMakeup"
                                                            files={this.state.makeupFiles}
                                                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "makeup_files")}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                        {configJSON.hairBeautyQuestion3}
                                                    </Typography>
                                                </FormLabel>
                                                <RadioGroup
                                                    className="radioGroup"
                                                    data-test-id="requireNails"
                                                    aria-label="requireNails"
                                                    name="requireNails"
                                                    value={values.require_nails}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue("require_nails", e.target.value);
                                                    }
                                                    }
                                                >
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="1"
                                                        control={<StyledRadio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="0"
                                                        control={<StyledRadio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            { values.require_nails === "1" && (
                                                <>
                                                    <Grid item lg={4} xs={12} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={4} xs={12} sm={6}>
                                                                <FormLabel component="label" className="formRadioLabel">
                                                                    {configJSON.nailTypeFieldLabel}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="nailsType"
                                                                    name="nailsType"
                                                                    className="hairBeautyInput"
                                                                    multiline
                                                                    as={CustomInput}
                                                                    placeholder="Example: False nails"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                        setFieldValue("nails_type", e.target.value)
                                                                    }
                                                                    value={values.nails_type}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <MediaUpload
                                                            data-test-id="mediaUploadForNails"
                                                            files={this.state.nailFiles}
                                                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "nail_files")}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <Typography variant="h2" style={{ color: "#1E293B" }}>
                                                    {configJSON.hairBeautyQuestion4}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    {configJSON.perfumeNameFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="perfumeName"
                                                    name="perfumeName"
                                                    className="hairBeautyInput"
                                                    multiline
                                                    as={CustomInput}
                                                    placeholder="Example: Victoria's Secret Bombshell"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("perfume_name", e.target.value)
                                                    }
                                                    value={values.perfume_name}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="btnWrapper">
                                    <Button
                                        className="btnSize"
                                        variant="outlined"
                                        onClick={
                                            () => this.props.handleNextBtnClick("CustomForm")
                                        }
                                        data-test-id="skipForNow"
                                    >
                                        {configJSON.skipForNowBtnText}
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btnSize"
                                        variant="contained"
                                        data-test-id="saveAndNextBtn"
                                    >
                                        {configJSON.saveAndNext}
                                    </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End