import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface ITabList {
  key: string, 
  value: string,
  disabled: boolean
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTab: string
  selectedTabIndex: number
  isError: boolean;
  errorMessage: string;
  tabList: ITabList[]
  successDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutMeController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        selectedTab:"",
        isError: false,
        errorMessage: "",
        selectedTabIndex: -1,
        tabList:[
          {
              key: "physical",
              value: "Physical",
              disabled: false
          },
          {
              key: "favourite",
              value: "Favourite",
              disabled: false
          },
          {
              key: "hobbies",
              value: "Hobbies",
              disabled: false
          } ,
          {
              key: "dreams",
              value: "Dreams",
              disabled: false
          },
          {
              key: "proudest_moment",
              value: "Proudest Moment",
              disabled: false
          } ,
          {
              key: "achievements",
              value: "Achievements",
              disabled: false
          },
          {
              key: "cv",
              value: "CV",
              disabled: false
          }],
          successDialog: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getEnableTabs()
  }

  getEnableTabs =async () =>{
    let enableTabs = await getStorageData("aboutMeTabs");
    let aboutMe = JSON.parse(enableTabs)
    if(aboutMe){
      this.setState({tabList: [
        {
            key: "physical",
            value: "Physical",
            disabled: aboutMe.have_physcial_information
        },
        {
            key: "favourite",
            value: "Favourite",
            disabled: aboutMe.have_favourite_information
        },
        {
            key: "hobbies",
            value: "Hobbies",
            disabled: aboutMe.have_hobbies_information
        } ,
        {
            key: "dreams",
            value: "Dreams",
            disabled: aboutMe.have_dream_information
        },
        {
            key: "proudest_moment",
            value: "Proudest Moment",
            disabled: aboutMe.have_proud_moments
        } ,
        {
            key: "achievements",
            value: "Achievements",
            disabled: aboutMe.have_achievement_information
        },
        {
            key: "cv",
            value: "CV",
            disabled: aboutMe.have_cv_information
        }]},()=> this.onNextClick("Funeral"))
    }
  }

  onTabClick = (key: string, index: number) =>{
    this.setState({selectedTab: key, selectedTabIndex: index})
  }

  onErrorHit = (errorMessage: string) =>{
    if(errorMessage){
      this.setState({isError: true, errorMessage})
    }
  }

  onSuccessClick = () =>{
    this.setState({successDialog: true})
  }

  onNextClick = (path: string) =>{
    if(path === "CustomForm"){
      this.handleNavigation(path)
    }
    let {selectedTabIndex, tabList} = this.state
    let nextTabIndex = selectedTabIndex + 1;
    
    while (nextTabIndex < tabList.length && tabList[nextTabIndex].disabled) {
      nextTabIndex++;
    }
    if (nextTabIndex < tabList.length) {
      let updatedTabList = [...this.state.tabList]
      updatedTabList[selectedTabIndex] = {
        ...updatedTabList[selectedTabIndex],
        disabled: true
      };  
      this.setState({successDialog: false, tabList: updatedTabList})
     this.onTabClick(tabList[nextTabIndex].key , nextTabIndex)
    }else{
      this.handleNavigation(path)
    }
  }

  handleCloseSnackbar = ()=>{
    this.setState({errorMessage: "", isError: false})
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  // Customizable Area End
}
