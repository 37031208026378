Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.selfCare ="Self Care for Grief"
exports.somethingWW = "Something went wrong!"
exports.selfCareDes ="Coping with losing someone can be incredibly painful. It can take time to understand your feelings and adjust to what has happened. But there are things you can do to help yourself cope."
exports.aboutUS = "About US"
exports.getSupportAPIEndPoint = "bx_block_contact_us/supports"
exports.visitSite = "Visit Site"
exports.support = "Support Organisations"
exports.mapDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500"
exports.map = "Helping the once who need us!"
exports.supportDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
exports.weBelieve = "We believe, We can help!"
exports.weBelieveDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
exports.getSupport = "Get Support"
exports.whereTo = "Where to find us?"
exports.contactFormDes = "Fill in the form below, and we will get back to you as soon as possible!"
exports.name = "Name";
exports.name_placeholder = "Enter your full name";
exports.email_id = "Email ID";
exports.email_id_placeholder = "example@gmail.com";
exports.phone_number = "Phone Number";
exports.phone_number_placeholder = "+44 0000000000";
exports.query_type = "Query Type";
exports.query_type_placeholder = "Select your query type";
exports.subject = "Subject";
exports.subject_placeholder = "What would you like to talk about?";
exports.additional_detail = "Additional Detail (if any)";
exports.additional_detail_placeholder = "Enter here";
exports.description = "Details";
exports.description_placeholder = "Any details you would like to share?";
exports.sendMessage = "Send Message"
exports.getcontactUsAddEndPoint = "bx_block_contact_us/office_addresses"
exports.contactUSHeading = "Contact Us"
exports.contactUSSubHeading = "Let us know about any issue you are facing or have a query about. We’ll get back to you as soon as possible"
exports.contactUSHeading1 = "How can we help you?"
exports.contactUSSubHeading1 = "Choose a category below and message the support team so we can get back to you as soon as possible"
exports.getcontactUsFormEndPoint = "bx_block_contact_us/contacts"
// Customizable Area End