import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";



export interface IDream{
    job: string;
    car: string;
    home: string;
    ultimate_dream: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
dream: IDream
isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutDreamsController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        dream: {
            job: "",
    car: "",
    home: "",
    ultimate_dream: "", 
          },
          isLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.callAboutDreamsInfoApiId){
        this.setState({isLoading: false})
      if(responseJSON.data){
        this.props.handleSuccess()
      }else{
        let error = responseJSON?.errors[0]
        if(error){
            let key = Object.keys(error)[0]
            let message = key + " " + error[key]
            this.props.handleError(message.replace("_", " "))
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  callAboutDreamsInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
  }

  handleAboutDreamsInformationsFormSubmit = async (values: IDream) => {
    const token = await getStorageData("token");
    const formData = new FormData();
    this.setState({isLoading: true})
    Object.entries(values).forEach(([keyName, value]) => {
        formData.append(`dream[${keyName}]`, value);
      });

    this.callAboutDreamsInfoApiId = sendAPIRequest(
      configJSON.dreamApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

 validationSchema = Yup.object().shape({
    job: Yup.string().required('Job is required'),
    car: Yup.string().required('Car is required'),
    home: Yup.string().required('home is required'),
    ultimate_dream: Yup.string().required('Ultimate dream is required'),
   
  });
  // Customizable Area End
}
