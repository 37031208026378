import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  email: string;
  password: string;
  confirmPassword: string;
  emailError: string;
  passwordError: any;
  confirmPasswordError: any;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  // Customizable Area Start
  isEmailValid: boolean;
  isPasswordValid: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasMinLength: boolean;
  isChecked: boolean;
  termsError: string;
  open: boolean;
  params: any;
  isEmailVerified: boolean;
  verificationToken: string | null;
  linkSent: boolean,
  linkResent: boolean,
  linkVerified: boolean,
  showEmailVerifiedModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  verificationToken: string | null;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailSignupCallId: string = '';
  sendVerifyEmailCallId: string = '';
  verifyEmailCallId: string = '';
  deleteAccountCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      // Customizable Area Start
      isEmailValid: false,
      isPasswordValid: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasMinLength: false,
      isChecked: false,
      termsError: "",
      open: false,
      isEmailVerified: false,
      verificationToken: null,
      params: "",
      linkSent: false,
      linkResent: false,
      linkVerified: false,
      showEmailVerifiedModal: false,


      // Customizable Area End
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      runEngine.debugLog("API Response Received", response);

      if (apiRequestCallId === this.apiEmailSignupCallId) {
        if (response.errors && response.errors.length > 0) {
          const error = response.errors[0];
          if (error.email) {
            this.setState({
              emailError: "Email has already been taken",
              passwordError: "",
              confirmPasswordError: ""
            });
          } else if (error.password) {
            this.setState({
              emailError: "",
              passwordError: error.password,
              confirmPasswordError: ""
            });
          } else if (error.confirmPassword) {
            this.setState({
              emailError: "",
              passwordError: "",
              confirmPasswordError: error.confirmPassword
            });
          }
        } else {
          storage.set("token", response.meta.token);
          this.handleOpen();
        }
      }  else if (apiRequestCallId === this.verifyEmailCallId) {
        if (response.data && response.data.attributes.activated) {
          this.setState({ showEmailVerifiedModal: true });
        }
      }
    }
    // Customizable Area End
  }

  validateEmail(email: string): boolean {
    if (email.trim() === "") {
      return false;
    }else{
      let testEmail=(configJSON.emailPattern).test(email);
     return  testEmail;
    }
  }

  validatePassword(password: string): {
    hasUppercase: any;
    hasLowercase: any;
    hasNumber: boolean;
    hasMinLength: boolean;
  } {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numericRegex =  /\d/;
    const minLength = 8;

    return {
      hasUppercase: uppercaseRegex.test(password),
      hasLowercase: lowercaseRegex.test(password),
      hasNumber: numericRegex.test(password),
      hasMinLength: password.length >= minLength,
    };
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    let emailError = '';

    if (email.trim() === "") {
      emailError = configJSON.passwordnemtyError;
    } else if (!this.validateEmail(email)) {
      emailError = configJSON.passwordninvalidError;
    }

    this.setState({ email: event.target.value, isEmailValid: emailError === '', emailError });
  };

  handlePasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const validationResults = this.validatePassword(password);
    const isPasswordValid = Object.values(validationResults).every(Boolean);
    await new Promise<void>((resolve) => this.setState({ password: event.target.value, isPasswordValid, ...validationResults  }, () => resolve()));
    if(this.state.password.length > 0){
      this.setState({ passwordError: "" });
    }
  };
  handleConfirmPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await new Promise<void>((resolve) => this.setState({ confirmPassword: event.target.value }, () => resolve()));
    if(this.state.confirmPassword !== this.state.password) {
      this.setState({ confirmPasswordError: "Passwords do not match" });
    }
    else if(this.state.confirmPassword === this.state.password) {
      this.setState({ confirmPasswordError: "" });
    }
  };

  handleOpen = () => {
    this.setState({ open: true });

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSendLink = () => {
    this.sendVerifyEmail();
    this.setState({ linkSent: true });

  }

  handleResendLink = () => {
     this.sendVerifyEmail(); 
     if(this.state.linkSent)
      {
        this.setState({ linkResent: true });

      }
    

  }

  setEnablePasswordField = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
  };

  setEnableConfirmPasswordField = () => {
    this.setState({ enableConfirmPasswordField: !this.state.enableConfirmPasswordField });
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked, termsError: "" });
  };



  initializeParams = () => {
    const params = new URLSearchParams(window.location.search);
    const verifymessage = params.get("activated");
    const logintoken = params.get("token");
    const loginemail = params.get("email");
    this.setState({ params: { verifymessage, logintoken, loginemail } });

    if (verifymessage !== null && verifymessage) {
      this.verifyEmail();
    }
    else if(!verifymessage)
      {
          this.handleResendLink();
      }
  };

  async componentDidMount() {
    this.initializeParams();

  }
  handleCloseModal = () => {
    this.setState({ showEmailVerifiedModal: false });
  };

  handleContinueClick = () => {
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableUserProfiles");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
    this.handleCloseModal(); 
  };
 
  validateForm = () => {
    const errors = {
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      termsError: ""
    };
  
    const { email, password, confirmPassword, isChecked } = this.state;
  
    if (!isChecked) {
      errors.termsError = "Please consent to the Privacy Policy and Terms of Use ";
    }
  
    if (email === "") {
      errors.emailError = "Email cannot be blank";
    } else if (!this.validateEmail(email)) {
      errors.emailError = "Invalid email";
    }

    if (password === "" ) {
      errors.passwordError =  "Password cannot be blank";
    } else if (!this.validatePassword(password)) {
      errors.passwordError = "Invalid Password ";
    }
  
    if (confirmPassword === "") {
      errors.confirmPasswordError = "Confirm Password cannot be blank";
    } else if (confirmPassword !== password) {
      errors.confirmPasswordError = "Passwords do not match";
    }
  
    this.setState(errors);
  
    const hasErrors = Object.values(errors).some(error => error !== "");
    return !hasErrors;
  }

  doEmailSignUp() {
    if (!this.validateForm()) {
      return false; 
    }
    runEngine.debugLog("Attempting to sign up with email: ", this.state.email);

    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };
    const body = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
          password_confirmation: this.state.confirmPassword,
          term_and_condition: this.state.isChecked,
        },
      },
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignupCallId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_signuplogin/signup`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  sendVerifyEmail = async () => {
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const token = await storage.get("token");
    this.sendVerifyEmailCallId = setIntroMsg.messageId; 
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_signuplogin/signup/send_mail?token=${token}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  };


  verifyEmail = async () => {
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    const verifyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await storage.get("token");
    this.verifyEmailCallId = verifyMsg.messageId; 

    verifyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `email_confirmations/verify_email?token=${token}`
    );

    verifyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    verifyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(verifyMsg.id, verifyMsg);
  };

  clearFields = async() => {
    
    this.setState({  email: '',password:'',confirmPassword:'' ,hasLowercase:false,hasMinLength:false,hasNumber:false,hasUppercase:false,isChecked:false,linkSent: false});
    
    const deleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAccountCallId = deleteMsg.messageId;

    const token = await storage.get("token");
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
      "token": token, 
    };
  deleteMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "account_block/accounts/remove_account"
  );

  deleteMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  deleteMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "DELETE"
  );

  runEngine.sendMessage(deleteMsg.id, deleteMsg);

    this.handleClose();
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
}
