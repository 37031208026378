import React from "react";
// Customizable Area Start
import FuneralBasicsEditController, {
    configJSON,
    IFuneralBasics,
    Props,
} from "./FuneralBasicsEditController.web";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { 
    Field, 
    Form, 
    Formik, 
    FormikErrors, 
    FormikTouched, 
    FormikValues 
} from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import { countries } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import MediaUpload from "../../../components/src/MediaUpload.web";
import { styledMyLocationTwoToneIcon, StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";

const relationshipDropdown = [
    { value: 1, name: "Brother" },
    { value: 2, name: "Best Friend" },
];
// Customizable Area End

export default class FuneralBasicsEdit extends FuneralBasicsEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderCremationDetails = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
            const {
                isEdit
              } = this.state;
        return (
            <Grid item xs={12}>
                <Box my={2}>
                    <Typography 
                        className="sectionTitle"
                        data-test-id="cremationDetailsTitle"
                        variant={this.state.isEdit ? "h3" : "h2"}
                    >
                        {configJSON.cremationDetailsTitle}
                    </Typography>
                </Box>
                <Box my={2}>
                    <FormLabel component="label" className="formRadioLabel">
                        {configJSON.haveDiamonEctFieldLabel}
                    </FormLabel>
                    <RadioGroup
                        className="radioGroup"
                        data-test-id="haveDiamonEct"
                        aria-label="haveDiamonEct"
                        name="haveDiamonEct"
                        value={values.cremation_detail_attributes.have_diamon_ect}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("cremation_detail_attributes.have_diamon_ect", e.target.value);
                        }
                        }
                    >
                        <FormControlLabel
                            className="radioLabel"
                            value="1"
                            disabled={!isEdit}
                            control={<StyledRadio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            className="radioLabel"
                            value="0"
                            disabled={!isEdit}
                            control={<StyledRadio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
                {values.cremation_detail_attributes.have_diamon_ect === "1" && (
                    <Box my={2}>
                        <FormLabel component="label" className="formRadioLabel">
                            {configJSON.cremationDecisionFieldLabel}
                        </FormLabel>
                        <Field
                            name="cremationDecision"
                            data-test-id="cremationDecision"
                            className="multilineInput"
                            as={CustomInput}
                            multiline
                            disabled={!isEdit}
                            placeholder="Your thoughts behind this "
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(`cremation_detail_attributes.decision`, e.target.value)
                            }}
                            value={values.cremation_detail_attributes.decision}
                        />
                    </Box>
                )}
            </Grid>
        )
    };


    renderFuneralPlaceDetails = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {( 
            field: string, 
            value: any,
            shouldValidate?: boolean | undefined): void; 
            (arg0: string, arg1: string): void;
        }) => {
            const { isEdit
            } = this.state;
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography 
                            data-test-id="funeralDetailsTitle" 
                            variant={this.state.isEdit ? "h3" : "h2"}
                        >
                            {configJSON.funeralDetailsTitle}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="funeralPlaceAddressLine1"
                            data-test-id="funeralPlaceAddressLine1"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            startIcon={styledMyLocationTwoToneIcon}
                            as={CustomInput}
                            placeholder="House numberFlat no. "
                            value={values.funeral_detail_attributes.address_line_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.address_line_1", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.address_line_1 && errors.funeral_detail_attributes?.address_line_1}
                            errorMsg={errors.funeral_detail_attributes?.address_line_1}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="funeralPlaceAddressLine2"
                            data-test-id="funeralPlaceAddressLine2"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Street and Locality"
                            value={values.funeral_detail_attributes.address_line_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.address_line_2", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.address_line_2 && errors.funeral_detail_attributes?.address_line_2}
                            errorMsg={errors.funeral_detail_attributes?.address_line_2}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.landmarkLabel}
                        </FormLabel>
                        <Field
                            name="funeralPlaceLandmark"
                            data-test-id="funeralPlaceLandmark"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Landmark"
                            value={values.funeral_detail_attributes.landmark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.landmark", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="funeralPlaceTownCity"
                            data-test-id="funeralPlaceTownCity"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled={!isEdit}
                            placeholder="City"
                            value={values.funeral_detail_attributes.city}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.city", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.city && errors.funeral_detail_attributes?.city}
                            errorMsg={errors.funeral_detail_attributes?.city}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            className="selectField"
                            data-test-id="funeralPlaceCountry"
                            name="funeralPlaceCountry"
                            as={CustomSelect}
                            disabled={!isEdit}
                            placeholder="Country"
                            options={countries}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("funeral_detail_attributes.country", e.target.value)
                            }}
                            value={values.funeral_detail_attributes.country}
                            errorMsg={errors.funeral_detail_attributes?.country}
                            error={touched.funeral_detail_attributes?.country && errors.funeral_detail_attributes?.country}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="funeralPlacePostCode"
                            data-test-id="funeralPlacePostCode"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Post Code"
                            value={values.funeral_detail_attributes.post_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.post_code", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.post_code && errors.funeral_detail_attributes?.post_code}
                            errorMsg={errors.funeral_detail_attributes?.post_code}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderLocationForWakeForm = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {( field: string, value: any,
            shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
            const { isEdit } = this.state;
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography data-test-id="locationForWakeTitle" variant={this.state.isEdit ? "h3" : "h2"}>{configJSON.locationForWakeTitle}</Typography>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameOfPlaceFieldLabel}
                        </FormLabel>
                        <Field
                            name="wakePlaceName"
                            data-test-id="wakePlaceName"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Enter name of place"
                            value={values.wake_location_attributes.place_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.place_name", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="wakeAddressLine1"
                            data-test-id="wakeAddressLine1"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            startIcon={styledMyLocationTwoToneIcon}
                            as={CustomInput}
                            placeholder="House numberFlat no. "
                            value={values.wake_location_attributes.address_line_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.address_line_1", e.target.value)
                            }
                            error={touched.wake_location_attributes?.address_line_1 && errors.wake_location_attributes?.address_line_1}
                            errorMsg={errors.wake_location_attributes?.address_line_1}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="wakeAddressLine2"
                            data-test-id="wakeAddressLine2"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Street and Locality"
                            value={values.wake_location_attributes.address_line_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.address_line_2", e.target.value)
                            }
                            error={touched.wake_location_attributes?.address_line_2 && errors.wake_location_attributes?.address_line_2}
                            errorMsg={errors.wake_location_attributes?.address_line_2}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.landmarkLabel}
                        </FormLabel>
                        <Field
                            name="wakeLandmark"
                            data-test-id="wakeLandmark"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Landmark"
                            value={values.wake_location_attributes.landmark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.landmark", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="wakeTownCity"
                            data-test-id="wakeTownCity"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled={!isEdit}
                            placeholder="City"
                            value={values.wake_location_attributes.city}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.city", e.target.value)
                            }
                            error={touched.wake_location_attributes?.city && errors.wake_location_attributes?.city}
                            errorMsg={errors.wake_location_attributes?.city}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            className="selectField"
                            data-test-id="wakeCountry"
                            name="wakeCountry"
                            as={CustomSelect}
                            disabled={!isEdit}
                            placeholder="Country"
                            options={countries}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("wake_location_attributes.country", e.target.value)
                            }}
                            value={values.wake_location_attributes.country}
                            errorMsg={errors.wake_location_attributes?.country}
                            error={touched.wake_location_attributes?.country && errors.wake_location_attributes?.country}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            name="wakePostCode"
                            data-test-id="wakePostCode"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Post Code"
                            value={values.wake_location_attributes.post_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.post_code", e.target.value)
                            }
                            error={touched.wake_location_attributes?.post_code && errors.wake_location_attributes?.post_code}
                            errorMsg={errors.wake_location_attributes?.post_code}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderStoneSpotForm = (
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <MediaUpload
                    data-test-id="mediaUpload"
                    title="Proof of Purchase"
                    files={this.state.files}
                    readonly={!this.state.isEdit}
                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
                />
                { !this.state.isEdit && this.state.files.length === 0 && (
                      <Box>
                        <Typography variant="subtitle1" className="dataNotAvailable">
                          {configJSON.NoMediaFound}
                        </Typography>
                      </Box>
                    )}
            </Grid>
        )
    };

    renderSongsFields = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {(field: string,
            value: any,
            shouldValidate?: boolean | undefined): void; 
            (arg0: string, arg1: string): void;
        }) => {
            const { isEdit } = this.state;
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {!isEdit && (
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Songs
                            </Typography>
                        </Grid>
                    )}
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel1}
                        </FormLabel>
                        <Field
                            name="song1"
                            data-test-id="song1"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled={!isEdit}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_1", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_1 && errors.funeral_song_attributes?.song_1}
                            errorMsg={errors.funeral_song_attributes?.song_1}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel2}
                        </FormLabel>
                        <Field
                            name="song2"
                            data-test-id="song2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled={!isEdit}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_2", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_2 && errors.funeral_song_attributes?.song_2}
                            errorMsg={errors.funeral_song_attributes?.song_2}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel3}
                        </FormLabel>
                        <Field
                            name="song3"
                            data-test-id="song3"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_3", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_3 && errors.funeral_song_attributes?.song_3}
                            errorMsg={errors.funeral_song_attributes?.song_3}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderListOfPeopleFields = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
            const { 
                isEdit } = this.state;
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <Typography data-test-id="listOfPeopleTitle" variant={isEdit ? "h3" : "h2"}>{configJSON.listOfPeopleTitle}</Typography>
                    </Grid>
                    {values.carry_people_name.length > 0 ? (values.carry_people_name.map((friendName: string, index: number) => {
                        return (
                            <Grid item lg={3} md={4} sm={6} key={index}>
                                <FormLabel component="label">
                                    {configJSON.nameFieldLabel}
                                </FormLabel>
                                <Field
                                    name={`name${index}`}
                                    data-test-id={`name${index}`}
                                    className="inputField"
                                    size="small"
                                    disabled={!isEdit}
                                    as={CustomInput}
                                    placeholder="Enter the full name"
                                    value={values.carry_people_name.index || friendName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFieldValue(`carry_people_name.${index}`, e.target.value)
                                    }
                                />
                            </Grid>
                        );
                    })) :
                    (
                            <Typography variant="subtitle1" className="dataNotAvailable">
                                {configJSON.dataNotAvailable}
                            </Typography>
                    )
                }
                </Grid>
            </Grid>
        )
    };

    renderCloseFriendsForm = (
        values: FormikValues,
        setFieldValue: {
            (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
            const { 
                isEdit,
            } = this.state;
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.delegateUserFieldLabel1}
                        </FormLabel>
                        <Field
                            name="delegate1"
                            data-test-id="delegate1"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled
                            placeholder="delegate 1"
                            value={values.eulogy_apart_deliver_attributes.delegate1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.delegate1", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.delegateUserFieldLabel2}
                        </FormLabel>
                        <Field
                            name="delegate2"
                            data-test-id="delegate2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled
                            placeholder="delegate 2"
                            value={values.eulogy_apart_deliver_attributes.delegate2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.delegate2", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-test-id="person1Title" variant="h6">{configJSON.person1Title}</Typography>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameFieldLabel}
                        </FormLabel>
                        <Field
                            name="person1Name"
                            data-test-id="person1Name"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled={!isEdit}
                            placeholder="Enter the full name"
                            value={values.eulogy_apart_deliver_attributes.name_p1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.name_p1", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.relationshipFieldLabel}
                        </FormLabel>
                        <Field
                            className="selectField"
                            data-test-id="person1Relationship"
                            name="person1Relationship"
                            as={CustomSelect}
                            disabled={!isEdit}
                            placeholder="Select relationship "
                            options={relationshipDropdown}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("eulogy_apart_deliver_attributes.relationship_p1", e.target.value)
                            }}
                            value={values.eulogy_apart_deliver_attributes.relationship_p1}
                        />
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-test-id="funeralDetailsTitle" variant="h6">{configJSON.person2Title}</Typography>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameFieldLabel}
                        </FormLabel>
                        <Field
                            name="person2Name"
                            data-test-id="person2Name"
                            className="inputField"
                            size="small"
                            disabled={!isEdit}
                            as={CustomInput}
                            placeholder="Enter the full name"
                            value={values.eulogy_apart_deliver_attributes.name_p2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.name_p2", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                        <FormLabel component="label">
                            {configJSON.relationshipFieldLabel}
                        </FormLabel>
                        <Field
                            className="selectField"
                            data-test-id="person2Relationship"
                            name="person2Relationship"
                            as={CustomSelect}
                            disabled={!isEdit}
                            placeholder="Select relationship "
                            options={relationshipDropdown}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("eulogy_apart_deliver_attributes.relationship_p2", e.target.value)
                            }}
                            value={values.eulogy_apart_deliver_attributes.relationship_p2}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderDataNotAvailable = (value: string, title: string) => {
        if (!this.state.isEdit && value === "0") {
            return (
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" className="dataNotAvailable">
                        {configJSON.dataNotAvailable}
                    </Typography>
                </Grid>
            );
        }
        return null;
    };

    renderRadioGroup = (question: string, testId: string, name: string, value: string, setFieldValue: {
        (field: string,
            value: any,
            shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        if (!this.state.isEdit) return null;
    
        return (
            <Grid item xs={12}>
                <FormLabel component="label" className="formRadioLabel">
                    {question}
                </FormLabel>
                <RadioGroup
                    className="radioGroup"
                    data-test-id={testId}
                    aria-label={testId}
                    name={name}
                    value={value}
                    onChange={(e) => setFieldValue(name, e.target.value)}
                >
                    <FormControlLabel
                        className="radioLabel"
                        value="1"
                        control={<StyledRadio />}
                        label="Yes"
                    />
                    <FormControlLabel
                        className="radioLabel"
                        value="0"
                        control={<StyledRadio />}
                        label="No"
                    />
                </RadioGroup>
            </Grid>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            funeralBasicInfo,
            delegateInCharge,
            isEdit,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={funeralBasicInfo}
                    validationSchema={this.validationSchema(isEdit)}
                    onSubmit={(values) => {
                        this.handleFuneralBasicsInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="funeralBasicsForm"
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Box className="funeralBasicsFormFields">
                                    <Box className="formSectionTitle">
                                        <Box>
                                            <Typography data-test-id="funeralBasicsTitle" variant="h3" className="titleText">
                                                {configJSON.funeralBasicsTitle}
                                            </Typography>
                                        </Box>
                                        <Box className="checkboxWrapper">
                                            <Checkbox
                                                color="primary"
                                                data-test-id="is_include_final_pdf"
                                                checked={values.is_include_final_pdf}
                                                disabled={!isEdit}
                                                onChange={(e) => {
                                                    setFieldValue('is_include_final_pdf', e.target.checked)
                                                }}
                                                style={{ color: "#9176C4" }}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox'
                                                }}
                                            />
                                            <FormLabel component="label" className="pdfLabel">
                                                {configJSON.pdfLabel}
                                            </FormLabel>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} md={3} sm={6}>
                                                <FormLabel component="label">
                                                    {configJSON.delegateInChargeText}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Field
                                                    data-test-id="delegateInCharge"
                                                    name="delegateInCharge"
                                                    className="selectField delegateSelectField"
                                                    as={CustomSelect}
                                                    placeholder="Select Delegate"
                                                    setValue={true}
                                                    disabled={!isEdit}
                                                    options={delegateInCharge}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setFieldValue("delegate_id", e.target.value)
                                                    }
                                                    value={values.delegate_id}
                                                    error={touched.delegate_id && errors.delegate_id}
                                                    errorMsg={errors.delegate_id}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    {configJSON.funeralBasicsQuestion1}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <RadioGroup
                                                    data-test-id="bodyHandledAfterPassing"
                                                    aria-label="bodyHandledAfterPassing"
                                                    className="radioGroup"
                                                    name="bodyHandledAfterPassing"
                                                    value={values.body_handled_after_passing}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue("body_handled_after_passing", e.target.value);
                                                    }
                                                    }
                                                >
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="Buried"
                                                        disabled={!isEdit}
                                                        control={<StyledRadio />}
                                                        label="Buried"
                                                    />
                                                    <FormControlLabel
                                                        className="radioLabel"
                                                        value="Cremated"
                                                        disabled={!isEdit}
                                                        control={<StyledRadio />}
                                                        label="Cremated"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="label" className="formRadioLabel">
                                                    {configJSON.decisionFieldLabel}
                                                </FormLabel>
                                                <Field
                                                    data-test-id="decision"
                                                    name="decision"
                                                    className="multilineInput"
                                                    as={CustomInput}
                                                    multiline
                                                    disabled={!isEdit}
                                                    placeholder="Your thoughts behind this"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`decision`, e.target.value)
                                                    }}
                                                    value={values.decision}
                                                />
                                            </Grid>
                                            {values.body_handled_after_passing === "Cremated" && (
                                                this.renderCremationDetails(values, setFieldValue)
                                            )}
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion2, "haveFuneralPlace", "have_funeral_place", values.have_funeral_place, setFieldValue)}
                                            {this.renderDataNotAvailable(values.have_funeral_place, configJSON.funeralDetailsTitle)}
                                            {values.have_funeral_place === "1" && (
                                                this.renderFuneralPlaceDetails(values, errors, touched, setFieldValue)
                                            )}
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion3, "haveLocationForWake", "have_location_for_wake", values.have_location_for_wake, setFieldValue)}
                                            {this.renderDataNotAvailable(values.have_location_for_wake, configJSON.locationForWakeTitle)}
                                            {values.have_location_for_wake === "1" && (
                                                this.renderLocationForWakeForm(values, errors, touched, setFieldValue)
                                            )}
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion4, "haveStoneSpot", "have_stone_spot", values.have_stone_spot, setFieldValue)}
                                            {values.have_stone_spot === "1" && (
                                                this.renderStoneSpotForm(setFieldValue)
                                            )}
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion5, "haveAnySong", "have_any_song", values.have_any_song, setFieldValue)}
                                            {this.renderDataNotAvailable(values.have_any_song, "Songs")}
                                            {values.have_any_song === "1" && (

                                                this.renderSongsFields(values, errors, touched, setFieldValue)
                                            )}
                                            <Grid item xs={12}>
                                                {isEdit && (
                                                    <Box>
                                                        <FormLabel component="label" className="formRadioLabel">
                                                            {configJSON.funeralBasicsQuestion6}
                                                        </FormLabel>
                                                    </Box>
                                                )}
                                                {this.renderListOfPeopleFields(values, setFieldValue)}
                                            </Grid>
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion7, "haveAddTwoCloseFriend", "have_add_two_close_friend", values.have_add_two_close_friend, setFieldValue)}
                                            {this.renderDataNotAvailable(values.have_add_two_close_friend, "Close Friends")}
                                            {values.have_add_two_close_friend === "1" && (
                                                this.renderCloseFriendsForm(values, setFieldValue)
                                            )}
                                            {this.renderRadioGroup(configJSON.funeralBasicsQuestion8, "haveSpecificRequest", "have_specific_request", values.have_specific_request, setFieldValue)}
                                            {this.renderDataNotAvailable(values.have_specific_request, "Special Request")}
                                            {values.have_specific_request === "1" && (
                                                <Grid item xs={12}>
                                                    <FormLabel component="label" className="formRadioLabel">
                                                        {configJSON.specialRequestFieldLabel}
                                                    </FormLabel>
                                                    <Field
                                                        name="special_request "
                                                        data-test-id="specialRequest"
                                                        className="multilineInput"
                                                        as={CustomInput}
                                                        disabled={!isEdit}
                                                        multiline
                                                        placeholder="Please write your special request"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFieldValue(`special_request`, e.target.value)
                                                        }}
                                                        value={values.special_request}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={
                                        () => this.props.handleNextBtnClick("Home")}
                                    data-test-id="backBtn"
                                >
                                    {configJSON.backBtnText}
                                </Button>
                                <Button
                                    type="submit"
                                    className="btnSize"
                                    data-test-id="editBasicsDetailsBtn"
                                    variant="contained"
                                >
                                    {isEdit ? configJSON.saveChangesBtnText : configJSON.editDetailsBtnText}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End