import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AboutUsController, {
  Props,
  configJSON,
} from "./AboutUsController.web";
import { Box, Grid, Typography,Card } from "@material-ui/core";
import {
  fifthIcon,
  firstIcon,
  fourthIcon,
  mapImg,
  OrnamentLine,
  OrnamentLine1,
  secondIcon,
  sixthIcon,
  thirdIcon
} from "./assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import WebHeader from "./WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "rgba(15, 23, 42, 1)",
        fontFamily: "Lato",
        fontSize: "40px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      h2: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "34px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h3: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "36px",
        color:"#1E293B"
      },
      body1: {
        fontFamily: "Lato",
        color: "rgba(30, 41, 59, 1)",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "40px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "34px",
        color: "#0F172A"
      },
      subtitle2: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color:"#1E293B"
      }
    },
  },
});
// Customizable Area End

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  listData = (index: number, heading: string , description: string , image: string) =>{
    return(
        <Grid
        container
        spacing={4}
        key={heading}
        direction={index % 2 === 0 ? "row" : "row-reverse"}
        className="listingDataContainer"
      >
        <Grid item className={`alignContent ${index % 2 !== 0 && "reverseAlignContent"}`} md={7}>
          <Typography variant="h3">
            {heading}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#475569", lineHeight: "36px" }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid item className="alignContent" md={5}>
          <img src={image} className="heroSectionImg" loading="lazy" alt="vision"/>
        </Grid>
      </Grid>
    )
  }

  whatWeDoDetails = (color: string, image: string, description: string) =>{
    return(
        <Grid item xs={12} sm={6} md={4} lg={3} style={{padding: 12}}>
            <Grid container className="whatWeDoContainer" style={{background: color}}>
        <Typography align="center" variant="body2">
        {description}
        </Typography>
        <img src={image} alt="icons" className="whatsWeDoIcon"/>
        </Grid>
        </Grid>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {aboutData, currentIndex , itemsInScreen} = this.state
    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <WebHeader navigation={this.props.navigation} />
                <Box className="reasonsToChooseWrapper">
                    <Box className="reasonsToChooseTitle">
                        <Typography align="center" variant="h1">
                            {aboutData.title}
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{ textAlign: "center", lineHeight: "36px", maxWidth: "650px", padding: "0 10px" }}
                        >
                            {aboutData.heading}
                        </Typography>
                    </Box>
                    <Grid item xs={12}>
                        <img src={aboutData?.banner_image} className="aboutUsBannerImage" alt="aboutUs" loading="lazy" />
                    </Grid>
            <Box className="allAboutWrapper" marginTop="45px">
              <Box className="reasonsToChooseTitle">
                <Typography align="center" variant="h3">
                  {configJSON.ourStory}
                </Typography>
              </Box>
              <Grid container className="ourStoryContainer">
                <Grid item className="circleContainer flexCenter" style={{ backgroundColor: "rgba(239, 234, 251, 1)" }}>

                  <Typography className="circleTypo">{configJSON.ourStoryDes1}</Typography>

                </Grid>
                <Grid item className="lineContainer flexCenter" >
                  <img src={OrnamentLine} className="zigZagLine" />
                </Grid>
                <Grid item className="circleContainer1 flexCenter" style={{ backgroundColor: "rgba(255, 251, 237, 1)" }}>

                  <Typography className="circleTypo">{configJSON.ourStoryDes2}</Typography>

                </Grid>
                <Grid item className="lineContainer flexCenter" >
                  <img src={OrnamentLine1} className="zigZagLine" />
                </Grid>
                <Grid item className="circleContainer flexCenter" style={{ backgroundColor: "rgba(253, 242, 247, 1)" }}>

                  <Typography className="circleTypo">{configJSON.ourStoryDes3}</Typography>

                </Grid>
                <Grid item className="lineContainer flexCenter" >
                  <img src={OrnamentLine} className="zigZagLine" />
                </Grid>
                <Grid item className="circleContainer1 flexCenter" style={{ backgroundColor: "rgba(251, 234, 234, 1)" }}>

                  <Typography className="circleTypo">{configJSON.ourStoryDes4}</Typography>

                </Grid>
                <Grid item className="lineContainer flexCenter">
                  <img src={OrnamentLine1} className="zigZagLine" />
                </Grid>
                <Grid item className="circleContainer flexCenter" style={{ backgroundColor: "rgba(241, 253, 247, 1)" }}>
                  <Typography className="circleTypo">{configJSON.ourStoryDes5}</Typography>

                </Grid>
              </Grid>
            </Box>
                    <Grid item xs={12}>
                        <img src={mapImg} className="aboutUsBannerImage" alt="aboutUs" loading="lazy" />
                    </Grid>
                </Box>
                <Box className="allAboutWrapper">
                    {aboutData.vission_title && this.listData(0, aboutData.vission_title, aboutData.vission_description, aboutData.vission_image)}
                    {aboutData.mission_title && this.listData(1, aboutData.mission_title, aboutData.mission_description, aboutData.mission_image)}
                    <Box>
                        <Box className="reasonsToChooseTitle">
                            <Typography align="center" variant="h3">
                                {configJSON.whatWeDo}
                            </Typography>
                        </Box>
                        <Grid container spacing={4} justifyContent="center">
                            {this.whatWeDoDetails("rgba(232, 239, 249, 0.4)", firstIcon, aboutData?.we_do_description_1)}
                            {this.whatWeDoDetails("rgba(251, 212, 230, 0.4)", secondIcon, aboutData?.we_do_description_2)}
                            {this.whatWeDoDetails("rgba(255, 244, 196, 0.4)", thirdIcon, aboutData?.we_do_description_3)}
                            {this.whatWeDoDetails("rgba(232, 221, 255, 0.4)", fourthIcon, aboutData?.we_do_description_4)}
                            {this.whatWeDoDetails("rgba(209, 250, 229, 0.4)", fifthIcon, aboutData?.we_do_description_5)}
                            {this.whatWeDoDetails("rgba(255, 208, 196, 0.4)", sixthIcon, aboutData?.we_do_description_6)}
                        </Grid>
                    </Box>
                    </Box>
                    <Box className="futureAimsContainer">
            <Grid container style={{marginBottom: '35px'}}>
              <Typography variant="h2">
                {configJSON.futureAndAims}
              </Typography>
              <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="body1" style={{maxWidth: "calc(100% - 72px)"}}>
              {aboutData?.future_aim_heading}
              </Typography>
              {aboutData?.future_aims?.length > itemsInScreen &&<div><ArrowBackIosIcon data-test-id="backArrow" className="futureAimsIcons" onClick={()=>this.handlePrev()}/> <ArrowForwardIosIcon data-test-id="forwardArrow" onClick={()=>this.handleNext()} className="futureAimsIcons"/></div>}
              </Grid>
            </Grid>
            <Box className="reasonsToChooseCardsWrapper">
              <Grid container spacing={4}>
                {aboutData?.future_aims?.slice(currentIndex, currentIndex + itemsInScreen)?.map((_data: {
                  id: number;
                  heading: string;
                  description: string;
                  image_url: string;
                }) => {
                  return (
                    <Grid item key={_data?.id} className="alignGridItem" xs={12} sm={6} md={4}>
                      <Card className="card-root">
                        <img
                          loading="lazy"
                          alt="futureAims"
                          src={_data?.image_url}
                          className="cardImgIcon"
                        />
                        <Typography variant="subtitle1">
                          {_data.heading}
                        </Typography>
                        <Typography variant="subtitle2">
                          {_data.description}
                        </Typography>
                      </Card>
                    </Grid>
                  )
                })}
               
              </Grid>
            </Box>
          </Box>
          <Box className="allAboutWrapper">
                    {aboutData.version_2_heading && this.listData(2, aboutData?.version_2_heading, aboutData?.version_2_description, aboutData?.version_2_image)}
                </Box>
                <WebFooter handleNavigation={this.handleNavigation} />
            </Wrapper>
        </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  '& .listingDataContainer': {
    margin: "15px 0",
  },
  "& .reverseAlignContent": {
    textAlign: "end"
  },
  "& .circleContainer": {
    marginTop: '120px',
    width: '215px',
    height: '215px',
    padding: '10px',
    borderRadius: "50%",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    zIndex: 1

  },
  "& .flexCenter": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  "& .circleContainer1": {
    width: '215px',
    height: '215px',
    padding: '10px',
    borderRadius: "50%",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    zIndex: 1
  },
  "& .lineContainer": {
    width: '10px',
    flexGrow: 1
  },
  "& .circleTypo": {
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: '23px',
    color: "rgba(15, 23, 42, 1)",
    padding: '0 10px'
  },
  "& .whatWeDoContainer": {
    padding: '30px',
    borderRadius: 12,
    position: "relative",
    height: "100%",
    boxShadow: "0px 2px 8px 0px #00000014"

  },
  "& .whatsWeDoIcon": {
    width: "35px",
    height: "35px",
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  "& .futureAimsIcons": {
    color: "rgba(145, 118, 196, 1)"
  },
  "& .heroSectionImg": {
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    borderRadius: "15px"
  },
  "& .alignContent": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%"
  },

  "& .aboutUsBannerImage": {
    width: '100%',
    height: "auto",
    maxHeight: "500px"
  },
  "& .reasonsToChooseWrapper": {
    padding: "59px 0px",
  },
  "& .futureAimsContainer": {
    padding: "30px 122px",
    background: "rgba(232, 221, 255, 0.5)"
  },
  "& .zigZagLine": {
    width: "100%",
    minWidth: '140px',
    overflow: "hidden",
    margin: "0 0 30px",
    maxHeight: "140px"
  },
  "& .reasonsToChooseTitle": {
    display:"flex",
    justifyContent: "center",
    flexDirection: 'column',
    marginBottom: "54px",
    alignItems: "center"
  },
  "& .reasonsToChooseCardsWrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .card-root": {
    width: "100%",
    height: "100%",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: 24
  },
  "& .cardImgIcon": {
    width: "100%",
    height: "auto",
    minHeight: "180px",
    maxHeight: "260px",
    borderRadius: "10px",
    marginBottom: "18px"
  },
  "& .alignGridItem": {
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down(760)]: {
    "& .reasonsToChooseWrapper": {
      padding: "39px 0px !important",
    },
  },
  [theme.breakpoints.down(960)]: {
    "& .reverseAlignContent": {
      textAlign: "start"
    },
  },
  "& .allAboutWrapper": {
    padding: "0 90px 57px",
  },
  [theme.breakpoints.down(1100)]: {
    "& .allAboutWrapper": {
      padding: "0 60px 50px!important",
    },
  },
  [theme.breakpoints.down(1310)]: {
    "& .ourStoryContainer": {
      flexDirection: "column",
      alignItems: "center"
    },
    "& .lineContainer": {
      width: "auto"
    },
    "& .zigZagLine": {
      width: "auto",
      overflow: "hidden",
      maxHeight: "110px",
      transform: "rotate(90deg)",
      margin: "0px"
    },
    "& .circleContainer": {
      marginTop: '0px'
    },
    "& .circleContainer1": {
      marginLeft: '80px'
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .allAboutWrapper": {
      padding: "0 40px 50px!important",
    },
    "& .futureAimsContainer": {
      padding: "30px 40px 50px!important",
    },
    "& .alignCenter": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }
  },
}));
// Customizable Area End
