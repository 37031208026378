import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { IMedia, ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IAttributes } from "../../../blocks/customform/src/AdditionalInfoSidebarController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { toast } from "react-toastify";
export interface IStep {
    label: string;
    path: string;
  };
  export interface PetInformation {
      id: string;
      type: string;
      attributes: {
        id: number;
        have_pets: string;
        pet_number: number;
        pet_details: {
          id: number;
          pet_photo: string | null;
          pet_name: string;
          date_of_birth: string;
          breed: string;
          requirement: string;
          address_line_1: string;
          address_line_2: string;
          landmark: string;
          city: string;
          country: string;
          post_code: string;
          files: {
            file_id: number;
            file_name: string;
            content_type: string;
            file_size: number;
            url: string;
          }[];
        }[];
        have_responsible: string;
        caretaker: {
          caretaker_id: number;
          full_name: string;
          full_phone_number: string;
        };
        account_id: number;
      };
  }
  
export interface IPetsInformation {
    have_pets: string;
    have_responsible: string;
    pet_number: string;
    pet_details_attributes: IPetDetailsAttributes[];
    caretaker_attributes: ICareTakerAttributes;
};

export interface IPetDetailsAttributes {
    pet_photo:any;
    pet_name: string;
    date_of_birth: string;
    breed: string;
    requirement: string;
    address_line_1: string;
    address_line_2: string;
    landmark: string;
    city: string;
    country: string;
    post_code: string;
    files: (File | IMedia)[];
};

export interface ICareTakerAttributes {
    caretaker_id: number;
    full_name: string;
    full_phone_number: string;
};

export interface IPetTemplateAttributes {
  id: number;
  image_url: string;
};

export interface IPetTemplatesResponse {
  id: string;
  type: string;
  attributes: IPetTemplateAttributes;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pet_information: IPetsInformation;
  petPhotos: (string | ArrayBuffer | null | undefined)[];
  files: (IMedia | File)[][];
  showPetDetailsForm: boolean;
  showCaretakerDetailsForm: boolean;
  openDialog: boolean;
  openDialogForPhoto: "uploadOptions" | "templates" | null;
  delegateInCharges: ISelectOptions[];
  subscriptionData: IAttributes | null;
  selectedFile: File | null,
  petTemplates: IPetTemplatesResponse[];
  isEdit: boolean;
  stepsPets: IStep[];
  careTakerIdOne:any,
  fullNames:any,
  fullPhoneNumber:any,
  petPhotosingle:any,


  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PetsAndAnimalsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.fileInput = React.createRef();

    this.state = {
        pet_information: {
            have_pets: "0",
            have_responsible: "0",
            pet_number: "",
            pet_details_attributes: [{
                pet_photo: null,
                pet_name: "",
                date_of_birth: "",
                breed: "",
                requirement: "",
                address_line_1: "",
                address_line_2: "",
                landmark: "",
                city: "",
                country: "",
                post_code: "",
                files: [],
            }],
            caretaker_attributes: {
                caretaker_id: 0,
                full_name: "",
                full_phone_number: "",
            },
        },
        petPhotos: [],
        files: [],
        showPetDetailsForm: false,
        showCaretakerDetailsForm: false,
        openDialog: false,
        delegateInCharges: [{  value: "1",name: "Select Delegate" }],
        subscriptionData: null,
        openDialogForPhoto: null,
        selectedFile: null,
        petTemplates: [],
        careTakerIdOne:"",
        fullNames:"",
        fullPhoneNumber:"",
        petPhotosingle:[],
      
        isEdit: false,
        stepsPets: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Pets And Animals Data', path: 'PetsAndAnimalsEdit' },
          ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddPetsInfoApiId: string = "";
  callGetEditPetsInfoApiId: string ="";
  callGetPetPhotoTemplatesApiId: string = "";
  fileInput: React.RefObject<HTMLInputElement>;

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getPetEditApi();
    let careTakerIds = await getStorageData("careTakerIds");
    let fullName = await getStorageData("full_name");
    let fullPhoneNo = await getStorageData("fullPhoneNo");
    this.setState({
      careTakerIdOne:careTakerIds,
      fullNames:fullName,
      fullPhoneNumber:fullPhoneNo,
     
      })
    
    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }
  
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersPetsApiResponse,
      [this.callAddPetsInfoApiId]: this.handleAddPetsInfoApiResponse,
      [this.callGetEditPetsInfoApiId]: this.handleGetEditPetsInfoApiResponse,
      [this.callGetPetPhotoTemplatesApiId]: this.handleGetPetPhotoTemplatesApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true;
    }
    return false;
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersPetsApiResponse = async(responseJSON: any) => {
    if (this.handleErrorResponse(responseJSON)) { 
     return; 
   }

     const responseJson = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
  
     if (responseJson.data) {
     
      let delegateInCharges = [...this.state.delegateInCharges];
 
      responseJson.data.forEach((member) => {
        delegateInCharges.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });

       delegateInCharges.push({
        value: "0",
        name: "Other"
       });
 
       this.setState({ delegateInCharges });
     }
   };

  handleAddPetsInfoApiResponse = async(responseJSON: any) => {
    let careTakerIds = await getStorageData("careTakerIds");
   let fullName = await getStorageData("full_name");
    let fullPhoneNo = await getStorageData("fullPhoneNo");

    if (this.handleErrorResponse(responseJSON)) 
      return; 
   

    if (responseJSON.data) {
        this.setState((prevState) => ({
            careTakerIdOne:careTakerIds,
            fullNames:fullName,
            fullPhoneNumber:fullPhoneNo,
            openDialog: true,
            isEdit: false,
            stepsPets: prevState.stepsPets.filter(
              (step) => !(step.label === 'Edit Details' && step.path === 'PetsAndAnimalsEdit')
            ),
          }));
    }
  };
  
  handlePetsInformationsFormSubmit = async (values: IPetsInformation) => {
    if(!this.state.isEdit){
      this.toggleEditMode();
    }
    else{ 
      const token = await getStorageData("token");

      const formData = new FormData();
      Object.entries(values).forEach(([keyName, value]) => {
                if(keyName !== "pet_details_attributes" && keyName !== "caretaker_attributes"){
                  formData.append(`pet[${keyName}]`, value);
                }
              });
  
              
      for (const petDetails of values.pet_details_attributes) {
        if(values.have_pets === "1"){
          if (petDetails) {
            if (petDetails.pet_photo) {
                if (typeof petDetails.pet_photo === 'string' && petDetails.pet_photo.startsWith('http')) {
                    const agencyLogoFile = await this.convertUrlToFile(
                        petDetails.pet_photo,
                        "agency_logo.jpg",
                        "image/jpeg"
                    );
                    formData.append("pet[pet_details_attributes][][pet_photo]", agencyLogoFile);
                } else {
                    formData.append("pet[pet_details_attributes][][pet_photo]", petDetails.pet_photo);
                }
            }
         
              formData.append(`pet[pet_details_attributes][][pet_name]`, petDetails.pet_name);
              formData.append(`pet[pet_details_attributes][][date_of_birth]`, petDetails.date_of_birth);
              formData.append(`pet[pet_details_attributes][][breed]`, petDetails.breed);
              formData.append(`pet[pet_details_attributes][][requirement]`, petDetails.requirement);
              formData.append(`pet[pet_details_attributes][][address_line_1]`, petDetails.address_line_1);
              formData.append(`pet[pet_details_attributes][][address_line_2]`, petDetails.address_line_2);
              formData.append(`pet[pet_details_attributes][][landmark]`, petDetails.landmark);
              formData.append(`pet[pet_details_attributes][][city]`, petDetails.city);
              formData.append(`pet[pet_details_attributes][][country]`, petDetails.country);
              formData.append(`pet[pet_details_attributes][][post_code]`, petDetails.post_code);
              
           
              if (petDetails.files && petDetails.files.length > 0) {
                for (const file of petDetails.files) {
                    let fileName: string;
                    let fileBlob: Blob | File | null = null;
                    let fileType: string = "application/pdf";  
            
                  
                    if (file instanceof File) {
                        fileName = file.name;
                        fileType = file.type || "application/pdf";  
                        fileBlob = file;
                    } else {
                       
                        fileName = file.url.split("/").pop() || "default.pdf";
            
                        fileBlob = await this.convertUrlToFile1(
                            file.url,
                            fileName,
                            fileType 
                        );
                    }
            
                    if (fileBlob) {
                        formData.append(`pet[pet_details_attributes][][files][]`, fileBlob);
                    } else {
                        console.error("Failed to append file to formData.");
                    }
                }
            }
            
     
      
      
      
        } 
        }
         
      }
      if(values.have_responsible === "1"){
        if (values.caretaker_attributes) {
          // @ts-ignore
          formData.append("pet[caretaker_attributes][caretaker_id]", values.caretaker_attributes.caretaker_id);
          formData.append("pet[caretaker_attributes][full_name]", values.caretaker_attributes.full_name);
          formData.append("pet[caretaker_attributes][full_phone_number]", values.caretaker_attributes.full_phone_number);
      }
     
      }
  
      // Append caretaker details
      
      // Send API request
      this.callAddPetsInfoApiId = sendAPIRequest(
          configJSON.addPetsInfoApiEndPoint,
          {
              method: configJSON.formAPiMethod,
              headers: {
                  token,
              },
              body: formData,
          }
      );
    }
 
};


  
  appendFormData = (formData: FormData, section: string, data: any) => {
    Object.entries(data).forEach(([keyName, value]) => {
      if (value) {
        formData.append(`${section}[][${keyName}]`, value as string);
      }
    });
  };

  async convertUrlToFile(url:any, filename:any, mimeType:any) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }
  async convertUrlToFile1(url: string, filename: string, mimeType: string) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch file from URL: ${url}`);
        }
        const blob = await response.blob();
        return new File([blob], filename, { type: mimeType });
    } catch (error) {
        console.error("Error converting URL to file:", error);
        return null; 
    }
}


  handleGetEditPetsInfoApiResponse = async(responseJSON: any) => {
   
   
    const response = responseJSON as {
      meta?: { message: string };
      data?: PetInformation;
    };
  
    if (response.data) {
      const attributes = response.data.attributes;
      
      let pet_information = {
        have_pets: this.checkValue(attributes.have_pets),
        pet_number: attributes.pet_number.toString(),
        pet_details_attributes: attributes.pet_details.length > 0 ? attributes.pet_details.map((petDetail:any) => ({
          pet_photo: petDetail.pet_photo,
          pet_name: petDetail.pet_name,
          date_of_birth: petDetail.date_of_birth,
          breed: petDetail.breed,
          requirement: petDetail.requirement,
          address_line_1: petDetail.address_line_1,
          address_line_2: petDetail.address_line_2,
          landmark: petDetail.landmark,
          city: petDetail.city,
          country: petDetail.country,
          post_code: petDetail.post_code,
          files: petDetail.files?.map((mediaFile: any) => ({
            file_id: mediaFile.file_id,
            file_name: mediaFile.file_name,
            content_type: mediaFile.content_type,
            file_size: mediaFile.file_size,
            url: mediaFile.url,
          })) || [],
        })): [{
          pet_photo: null,
          pet_name: "",
          date_of_birth: "",
          breed: "",
          requirement: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
          files: [],
        }],
        
        have_responsible: this.checkValue(attributes.have_responsible),       
        caretaker_attributes: {
          caretaker_id: this.state.careTakerIdOne || "1" ,
          full_name: this.state.fullNames || "",
          full_phone_number: this.state.fullPhoneNumber || "",
        },
      } as IPetsInformation;
      this.setState({ pet_information,
      });
    
    }
  }
  checkValue = (value: string) => {
    return value === "Yes" ? '1' : '0'
}

  toggleEditMode = () => {
    this.setState((prevState) => ({
      isEdit: true,
      stepsPets: [...prevState.stepsPets, { label: 'Edit Details', path: 'PetsAndAnimalsEdit' }],
    }));
  };
  getPetPhotoTemplates = async () => {
    const token = await getStorageData("token");
    
    this.callGetPetPhotoTemplatesApiId = sendAPIRequest(
      configJSON.getPetPhotoTemplatesApiEndPoint,
      {
        method: configJSON.fetchFormDataMethod,
        headers: {
          token,
        }
      }
    );
  };
  getPetEditApi = async () => {
    const token = await getStorageData("token");
    
    this.callGetEditPetsInfoApiId = sendAPIRequest(
      configJSON.addPetsInfoApiEndPoint,
      {
        method: configJSON.fetchFormDataMethod,
        headers: {
          token,
        }
      }
    );
  };

  handleGetPetPhotoTemplatesApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?:IPetTemplatesResponse[];
    };
   
    if (response.data) {
      let petTemplates = [ ...response.data ];

      this.setState({ petTemplates, openDialogForPhoto: "templates" });
    
    }
  };
  validationSchema = (isEdit: boolean) => {
    if(isEdit) {
        return Yup.object().shape({
            pet_details_attributes: Yup.array().when(['have_pets'], {
              is: (have_pets) => have_pets === "1",
              then: Yup.array().of(Yup.object().shape({
                pet_name: Yup.string().nullable().required("Please enter pet name"),
                date_of_birth: Yup.string().nullable().required("Please enter date of birth"),
                address_line_1: Yup.string().nullable().required("Please enter address line 1"),
                address_line_2: Yup.string().nullable().required("Please enter address line 2"),
                city: Yup.string().nullable().required("Please enter town/city"),
                country: Yup.string().nullable().required("Please enter country"),
                post_code: Yup.string().nullable().required("please enter post code"),
              })),
              otherwise: Yup.array().of(Yup.object().nullable()),
            }),
            caretaker_attributes: Yup.object().when(['have_responsible'], {
              is: (have_responsible) => have_responsible  === "1",
              then: Yup.object().shape({
                caretaker_id: Yup.string().nullable().required("Please select caretaker"),
                full_name: Yup.string().nullable().when('caretaker_id', {
                    is: (caretaker_id) => caretaker_id === "0",
                    then: Yup.string().required('Please enter full name'),
                    otherwise: Yup.string().nullable(),
                  }),
                  full_phone_number: Yup.string().nullable().required("Please enter phone number"),
              }),
              otherwise: Yup.object().nullable(),
            }),
          });
    }
  }




  handlePetPhoto = async(
    event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (arg0: string, arg1: string): void;
    }, 
    index: number
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
  
  
      // Update the specific pet photo field in the FieldArray
      setFieldValue(`pet_details_attributes.${index}.pet_photo`, file);
  
      const reader = new FileReader();
  
      reader.onload = async(loadEvent) => {
        const result = loadEvent.target?.result;
  
        this.setState((prevState) => {
          // Copy the existing petPhotos array to avoid mutating the state directly
          const updatedPhotos = [...(prevState.petPhotos || [])];
  
          // Make sure the array has a spot for the current index
          updatedPhotos[index] = result;
  
          // Save updated photos to localStorage using setStorageData
          setStorageData('petPhotos', updatedPhotos);
          return { petPhotos: updatedPhotos };
        });
      };
  
      reader.readAsDataURL(file);
  
      this.setState({ openDialogForPhoto: null });
    }
  };
  

  handleTemplatePetPhotoclick = async (url: string, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number) => {
      const file = await urlToFile(url, 'petTemplatePhoto.png');
      // Update the specific pet photo field in the FieldArray
      setFieldValue(`pet_details_attributes.${index}.pet_photo`, file);

      const reader = new FileReader();
    
        reader.onload = (loadEvent) => {
          const result = loadEvent.target?.result;
    
          this.setState((prevState) => {
            // Copy the existing petPhotos array to avoid mutating the state directly
            const updatedPhotos = [...(prevState.petPhotos || [])];
    
            // Make sure the array has a spot for the current index
            updatedPhotos[index] = result; 
    
            return { petPhotos: updatedPhotos };
          });
        };
    
        reader.readAsDataURL(file);

        this.setState({ openDialogForPhoto: null });
  };

  handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue: (field: string, value: any) => void, 
    index: number = 0
  ) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
  
      this.setState(
        (prevState) => ({
          files: this.updateFilesState(prevState, "files", index, newFiles),
        }),
        () => {
          const currentFiles = this.state.files[index] || [];
          setFieldValue(`pet_details_attributes.${index}.files`, currentFiles);
        }
      );
    }
  };

  updateFilesState(prevState: any, key: string, index: number, newFiles: File[]) {
    const updatedFiles = [...(prevState[key][index] || []), ...newFiles];
    return {
      ...prevState[key],
      [index]: updatedFiles,
    };
  }
  

  handleCloseDialog = () => {
    this.getPetEditApi()
    this.setState({ openDialog: false ,isEdit:false});
  };

  handleOpendialogForUploadOptions = () => {
    
    this.setState({ openDialogForPhoto: "uploadOptions"});
  };

  handleCloseDialogForPhoto = () => {
    this.setState({ openDialogForPhoto: null,isEdit:false });
  };

  calenderIcon = () => {
    return (
      <div style={{ cursor: "grab" }}>
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleEditClick = (event: any) => {
    
    event.preventDefault();
    this.setState((prevState) => ({
      isEdit: true,
      stepsPets: [...prevState.stepsPets, { label: 'Edit Details', path: 'PetsAndAnimalsEdit' }],
    }));
  }
  // Customizable Area End
}
