export const mainSectionImg = require("../assets/hero-section(1).png");
export const easyToUseImg = require("../assets/snap(1).png");
export const accessibleImg = require("../assets/global-network.png");
export const personalImg = require("../assets/folder.png");
export const secureImg = require("../assets/cyber-security.png");
export const mapImg = require("../assets/mapImage.png");
export const firstIcon = require("../assets/firstIcon.png");
export const secondIcon = require("../assets/secondIcon.png");
export const thirdIcon = require("../assets/thirdIcon.png");
export const fourthIcon = require("../assets/fourthIcon.png");
export const fifthIcon = require("../assets/fifthIcon.png");
export const sixthIcon = require("../assets/sixthIcon.png");
export const OrnamentLine = require("../assets/Ornament-22.png");
export const OrnamentLine1 = require("../assets/Ornament-23.png");
