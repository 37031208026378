import React from "react";
// Customizable Area Start
import { 
    styled, 
    ThemeProvider 
} from "@material-ui/core/styles";
import AboutMeEditController, {
    configJSON,
    Props,
} from "./AboutMeEditController.web";
import {
    Box,
    Grid,
    Tab,
    Snackbar,
    Typography,
    Tabs,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import GenericModal from "../../../components/src/GenericModal";
import { theme } from "./LegalDataEdit.web";
import { DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";
import AboutPhysicalEdit from "./AboutPhysicalEdit.web";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import AboutFavouriteEdit from "./AboutFavouriteEdit.web";
import AboutHobbiesEdit from "./AboutHobbiesEdit.web";
import AboutDreamsEdit from "./AboutDreamsEdit.web";
import AboutMomentEdit from "./AboutMomentEdit.web";
import AboutAchievementsEdit from "./AboutAchievementsEdit.web";
import AboutCVEdit from "./AboutCVEdit.web";
// Customizable Area End

export default class AboutMeEdit extends AboutMeEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    selectedScreen = (key: string) =>{
        let commonProps = {
            ...this.props, 
            handleError: this.onErrorHit,
            handleSuccess: this.onSuccessClick,
            handleNext: this.onNextClick
          };

        switch(key){
            case "physical": 
              return <AboutPhysicalEdit {...commonProps}/>;
            case "favourite": 
              return <AboutFavouriteEdit {...commonProps} />;
              case "hobbies": 
              return <AboutHobbiesEdit {...commonProps} />;
              case "dreams": 
              return <AboutDreamsEdit {...commonProps} />;
              case "proudest_moment": 
              return <AboutMomentEdit {...commonProps} />;
              case "achievements": 
              return <AboutAchievementsEdit {...commonProps} />;
              case "cv": 
              return <AboutCVEdit {...commonProps} />;
            default: 
              return <></>;
          }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            selectedTab,
            tabList,
            successDialog,
            steps,
        } = this.state;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Box>
                                <BreadcrumbStepper data-test-id="breadcrumbStepper" steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                            <Box className="notesInfoWrapper">
                                <Box className="pageTitle">
                                <Box display="flex" justifyContent="space-between">
                                    <Typography data-test-id="pageTitle" variant="h1" className="titleText">
                                        {configJSON.aboutMePageTitle}
                                    </Typography>
                                    <Typography variant="h3" className="infomessageText">
                                        <InfoOutlinedIcon className="infoIcon" />
                                        <span>{configJSON.messageText}</span>
                                        </Typography>
                                        </Box>
                                    <Typography variant="h3" className="messageText">
                                        {configJSON.aboutMePageSubtitle}
                                    </Typography>
                                </Box>
                                
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Tabs
                                            aria-label="secondary tabs example"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            {tabList.map((item: {key: string, value: string}, index:number) => (                                               
                                                    <Tab
                                                    key={item.key}
                                                    data-test-id={item.key}
                                                    className={`${selectedTab === item.key ? "selectedTab" : "tabsButton"}`}
                                                    onClick={()=> {this.onTabClick(item.key, index)}}
                                                    value={item.value}
                                                    label={item.value}
                                                    >
                                                        {item.value}
                                                    </Tab>
                                            ))}
                                        </Tabs>
                                    </Grid>
                                </Grid>  
                                <Grid container className="aboutMeContainer">
                                  {
                                    this.selectedScreen(this.state.selectedTab)
                                  }
                                </Grid>
                            </Box>
                           
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                {this.state.isError && (
                    <Snackbar
                      open={this.state.isError}
                      autoHideDuration={4000}
                      onClose={this.handleCloseSnackbar}
                      data-test-id="errorSnackbar"
                    >
                        <Alert severity="error" data-test-id="alertClose"
                            onClose={this.handleCloseSnackbar}
                        >
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                {successDialog && <GenericModal open={successDialog} data-test-id="success-modal" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                        <img alt="success" src={rightTickImg} style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                        <Typography align="center" variant="body2">
                          {configJSON.dialogMessageText}
                        </Typography>
                    </Box>
                  </Box>
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>}
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .notesInfoWrapper": {
        width: '100%',
    },
     "& .container": {
        padding: "50px 72px",
    },
    "& .formWrapper": {
        height: "100%",
        flexDirection: "column",
        display: "flex",
        gap: "40px",
        width: "100%",
        justifyContent: "space-between",
    },
    "& .form": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        minHeight: "calc(100% - 185px)",
    },
    "& .aboutMeContainer":{
        height: "100%"
    },
    "& .pageTitle": {
        display: "flex",
        flexDirection: "column",
        margin: "32px 0",
        gap: "16px",
    },
    "& .inputField": {
        width: "100%",
        height: "48px",
        borderRadius: "8px",
    },
    "& .selectField": {
        borderRadius: "8px",
        height: "48px",
        display: "block",
        margin: "5px 0px",
        width: "100%",
    },
    "& .disabledTabs":{
        color: "rgba(93, 100, 111, 0.39) !important"
    },

    "& .delegateSelectField": {
        width: "100% !important",
    },
    "& .fieldArrayHeaderWrapper": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .formSectionBackground": {
        marginBottom: "72px",
        padding: "24px",
        borderRadius: "8px",
        backgroundColor: "rgba(206, 195, 229, 0.1)",
    },
    "& .btnSize": {
        width: "158px",
        height: "48px",
    },
    "& .btnWrapper": {
        gap: "8px",
        justifyContent: "flex-end",
        display: "flex",
    },
    "& .addLettersBtn": {
        fontSize: "14px",
        fontFamily: "Lato",
        lineHeight: "22px",
        fontWeight: 700,
        display: "flex",
        color: "#9176C4",
        alignItems: "center",
        textTransform: "none",
        '&:hover': {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },
    "& .formRadioLabel": {
        color: "#1E293B",
        fontSize: "16px",
        fontWeight: 500
    },
    "& .infomessageText": {
        alignItems: "center",
        fontFamily: "Lato",
        color: "#9176C4",
        fontWeight: 500,
        lineHeight: "18px",
        fontSize: "14px",
        gap: "4px",
        display: "flex",
      },
    "& .infoIcon": {
        fontSize: "20px",
      },
    "& .messageText": {
        fontSize: "16px",
        lineHeight: "18px",
        fontFamily: "Lato",
        fontWeight: 400,
        color: "#1E293B",
    },
    "& .circleOutlineIcon": {
        marginRight: "8px",
        fontSize: "16px",
    },
    "& .haveSpecialDayWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .radioGroup": {
        display: 'flex',
        marginLeft: "16px",
        flexDirection: 'row',
        gap: "35px",
    },
    "& .radioLabel": {
        fontSize: "16px",
        color: '#1E293B',
        lineHeight: "22px",
        fontWeight: 400,
        fontFamily: "Lato",
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#9176C4',
        },
    },
    "& .haveSpecialDayLabel": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
        fontFamily: "Lato",
        color: "#1E293B",
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .title": {
        fontSize: "18px",
        lineHeight: "26px",
        fontFamily: "Lato",
        color: "#0F172A",
        fontWeight: 700,
    },

    "& .uploadInfo": {
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "8px",
        color: "#94A3B8",
    },
    "& .tabsButton":{
        color: "#64748B",
        fontFamily: "Lato",
        borderRadius:0,
        padding: "12px 20px",
        textAlign: "center",
        fontWeight: 600,
        borderBottom: "1px solid #CBD5E1",
        flexGrow: 1,
        textTransform: "none",
        fontSize: 16,
    },
    "& .selectedTab":{
        color: "#9176C4",
        textAlign: "center",
        padding: "12px 20px",
        fontFamily: "Lato",
        flexGrow: 1,
        borderBottom: "4px solid",
        fontSize: 16,
        borderRadius:0,
        textTransform: "none",
        fontWeight: 600,
    },
    "& .childrenWrapper": {
        display: "flex",
        gap: "16px",
    },

    "& [class*=MuiTypography-body2]": {
        body2: {
            fontWeight: 400,
            fontFamily: "Lato",
            lineHeight: "22px",
            fontSize: "16px !important",
        },
    },

    [theme.breakpoints.down(740)]: {
        "& .container": {
            padding: "28px 16px",
        },
        "& .notesInfoWrapper": {
        width: 'calc(100% - 126px)',
    },
    },
}));
// Customizable Area End