import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ISelectOptions } from "./LegalInformationController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
export interface IAttire {
  is_include_final_pdf: boolean;
  delegate_id: string;
  charge_person: string;
  charger_name: string;
  charger_relationship: string;
  upper_cloth: string;
  lower_cloth: string;
  shoes: string;
  socks: string;
  other: string;
  files: File[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  attireInfo: IAttire,
  isLoading: boolean,
  files: File[],
  delegateInCharge: ISelectOptions[],
  attireInCharge: ISelectOptions[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralAttireController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      attireInfo: {
        is_include_final_pdf: false,
        delegate_id: "",
        charge_person: "",
        charger_name: "",
        charger_relationship: "",
        upper_cloth: "",
        lower_cloth: "",
        shoes: "",
        socks: "",
        other: "",
        files: [],
      },
        isLoading: false,
        files: [],
        delegateInCharge: [],
        attireInCharge: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddFuneralAttireInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
        [this.callAddFuneralAttireInfoApiId]: this.handleAddFuneralAttireInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };



  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: { token },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
     this.props.handleError("something went wrong!"); 
     return; 
   }
 
     const response = responseJSON as {
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
       meta?: { message: string };
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push(
          {
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         }
        );
       });
 
       this.setState({ 
        delegateInCharge,
        attireInCharge: [
          ...delegateInCharge,
          {
            value: "0",
            name: "Other"
          }
        ]
       });
     }
   };

  handleErrorResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const { errors: possibleErrors } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

   handleAddFuneralAttireInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false})
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  handleFuneralAttireInformationsFormSubmit = async (values: IAttire) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    this.setState({isLoading: true});

    // Append main form values
    Object.entries(values).forEach(([keyName, value]) => {
      if (keyName === 'files') {
        this.appendFiles(values.files, this.state.files, formData);
      } else {
        formData.append(`attire[${keyName}]`, value as string);
      }
    });

    this.callAddFuneralAttireInfoApiId = sendAPIRequest(
      configJSON.addFuneralAttireInformationApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  appendFiles = (files: File[], stateFiles: File[], formData: FormData) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach((file: File) => {
        formData.append(`attire[files][]`, file as Blob);
      });
    }
  };

 validationSchema = Yup.object().shape({
    delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
    charge_person: Yup.string().nullable().required("Please select attire in charge"),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
  ) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      this.setState((prevState) => (
      {
        files: [...prevState.files, ...newFiles],
      }), () => {
        setFieldValue('files', [...this.state.files]);
      });
    }
  };
  // Customizable Area End
}
