import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
} from "@material-ui/core";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { 
  Field, 
  FieldArray, 
  Form, 
  Formik, 
  FormikErrors, 
  FormikTouched, 
  FormikValues 
} from "formik";
import { 
  DialogWrapper,
  StyledRadio, 
  theme 
} from "./LegalInformation.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import ErrorMessage from "../../../components/src/ErrorMessage";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import AfterWishesController, {
  configJSON,
  IAfterWishesInformation,
  IWish,
  Props,
} from "./AfterWishesController.web";
// Customizable Area End

export default class AfterWishes extends AfterWishesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAfterWishesForm = (
    values: FormikValues,
    errors: FormikErrors<IAfterWishesInformation>, 
    touched: FormikTouched<IAfterWishesInformation>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <Grid item xs={12}>
            <Grid container spacing={2} className="formSectionBackground">
                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Typography data-test-id="afterWishesFormTitle" variant="h6">{configJSON.afterWishesFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.delegateInChargeFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="delegateId"
                        className="selectField"
                        name="delegateId"
                        as={CustomSelect}
                        setValue={true}
                        placeholder="Select Delegate"
                        options={this.state.delegateInCharge}
                        value={values.delegate_id}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("delegate_id", e.target.value)
                        }}
                        error={touched.delegate_id && errors.delegate_id}
                        errorMsg={errors.delegate_id}
                    />
                </Grid>
                <FieldArray name="my_wishes_attributes" data-test-id="fieldArray">
                    {({ remove, push }) => (
                        values.my_wishes_attributes.map((myWish: IWish, index: number) => {
                            return (
                                <>
                                    <Grid item xs={12}>
                                        <Box className="myWishLabelWrapper">
                                            <FormLabel component="label">{configJSON.myWishFieldLabel}</FormLabel>
                                            {index !== 0 && (
                                                <Button
                                                    data-test-id="removeBtn"
                                                    className="addMoreWishesBtn"
                                                    style={{ padding: "0px" }}
                                                    onClick={() => remove(index)}>
                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                    {configJSON.removeBtnText}
                                                </Button>)}
                                        </Box>
                                        <Field
                                            data-test-id="myWish"
                                            name="myWish"
                                            size="medium"
                                            multiline
                                            style={{ minHeight: "84px !important" }}
                                            as={CustomInput}
                                            placeholder="Eg, Please donate to a dog centre in memory of me"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`my_wishes_attributes.${index}.wish`, e.target.value)
                                            }
                                            value={myWish.wish}
                                        />
                                        <ErrorMessage name={`my_wishes_attributes.${index}.wish`} />
                                        <Box>
                                            {index === 0 && (
                                                <Button
                                                    className="addMoreWishesBtn"
                                                    data-test-id="addMoreWishesBtn"
                                                    onClick={() => push({
                                                        id: 0,
                                                        wish: "",
                                                    })}
                                                >
                                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                    {configJSON.addMoreWishesBtnText}
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </>
                            );
                        })
                    )}
                </FieldArray>
            </Grid>
        </Grid>
  )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
        wishes_information,
        showAfterWishesForm,
        openDialog,
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} progress={100} />
            <Box className="container">
              <Box className="AdditionalInformationSidebar">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
              </Box>
              <Box className="afterWishesWrapper">
                <Box className="wishesPageTitle">
                    <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                        {configJSON.afterWishesPageTitle}
                    </Typography>
                </Box>
                <Box className="wishesFormWrapper wishesWrapperContainer">
                  <Formik
                    initialValues={wishes_information}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleWishesInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="afterWishesForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form className="wishesWrapperContainer">
                        <Box minHeight="calc(100% - 100px)" className="wishesFormWrapper">
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                    {configJSON.afterWishesQuestion}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="havePassed"
                                  aria-label="havePassed"
                                  name="havePassed"
                                  value={values.have_passed}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("have_passed", e.target.value);
                                    this.setState({ showAfterWishesForm: !showAfterWishesForm });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              { showAfterWishesForm && (
                                    this.renderAfterWishesForm(values, errors, touched, setFieldValue)
                              )}
                            </Grid>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                                variant="outlined"
                                className="buttonSize"
                                onClick={() => this.handleNavigation("CustomForm")}
                                data-test-id="skipForNowBtn"
                            >
                              {configJSON.skipForNowBtnText}
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              className="buttonSize"
                              data-test-id="saveAndNextBtn"
                            >
                              {configJSON.saveAndNext}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
        <GenericModal 
            open={openDialog} 
            data-test-id="information-added-dialog" 
            onClose={this.handleCloseDialog}
        >
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <Typography 
                    align="center" 
                    variant="h1" 
                    data-test-id="emailSentModalTitle"
                  >
                    {configJSON.infoAddedDialogTitleText}
                  </Typography>
                  <Typography align="center" variant="h2">
                    Your after wishes information has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                  </Typography>
                </Box>
                <Box className="dialogBtnsWrapper">
                  <Button
                    data-test-id="skipForNowDialogBtn"
                    variant="outlined"
                    className="outlinedBtn"
                    onClick={() => this.handleNavigation("CustomForm")}
                  >
                    {configJSON.skipForNowBtnText}
                  </Button>
                  <Button
                    data-test-id="nextSectionBtn"
                    variant="contained"
                    className="containedBtn"
                    onClick={() => this.handleNavigation("LandingPage")}
                  >
                    {configJSON.nextSectionBtnText}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .afterWishesWrapper": {
    width: "100%",
  },
  "& .container": {
    display: "flex",
    padding: "50px 72px",
    gap: "72px",
  },
  "& .wishesPageTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
  },
  "& .wishesFormWrapper": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "40px",
  },
  "& .wishesWrapperContainer":{
    height: "100%"
  },
  "& .selectField": {
    width: "275px",
    height: "48px",
    display: "block",
    borderRadius: "8px",
    margin: "5px 0px",
  },
  "& .radioLabel": {
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: '#1E293B',
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .radioGroup": {
    marginLeft: "16px",
    display: 'flex',
    gap: "35px",
    flexDirection: 'row',
  },
  "& .formRadioLabel": {
    fontSize: "16px",
  },
  "& .formSectionBackground": {
      padding: "24px",
      backgroundColor: "rgba(206, 195, 229, 0.1)",
      borderRadius: "8px",
      marginBottom: "24px", 
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .buttonSize": {
      width: "158px",
      height: "48px",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .addMoreWishesBtn": {
      textTransform: "none",
      fontSize: "14px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#9176C4",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
    '&:hover': {
        textDecoration: "underline",
        backgroundColor: "transparent",
    },
  },
  "& .myWishLabelWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  "& .errorMsg": {
    display: "flex",
    fontSize: "12px",
    color: "#DC2626",
    textAlign: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End
