import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest } from "../../../components/src/Utils";

export interface IAfterWishesInformation {
    have_passed: string;
    delegate_id: any;
    my_wishes_attributes: IWish[];
};

export interface IWish {
    wish: string,
};
export interface IStep {
  label: string;
  path: string;
};
export interface IWishesInformationAPIResponse {
    id: string;
    type: string;
    attributes: {
      id: number;
      have_passed: string;
      delegate_id: number;
      my_wishes: {
        id: number;
        wish: string;
      }[];
      delegate_charge: {
        name: string;
      };
    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wishes_information: IAfterWishesInformation;
  showAfterWishesForm: boolean;
  openDialog: boolean;
  delegateInChargeWishes: ISelectOptions[];
  stepsWishes: IStep[];
  isEditWishes: boolean,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AfterWishesEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        wishes_information: {
            have_passed: "0",
            delegate_id: "",
            my_wishes_attributes: [{
                wish: "",
            }],
        },
        showAfterWishesForm: false,
        openDialog: false,
        delegateInChargeWishes: [{  value: "1",name: "Select Delegate" }],
        stepsWishes: [
          { label: 'Home', path: 'HomePage' },
          { label: 'My Data', path: 'HomePage' },
          { label: 'After Wishes', path: 'AfterWishesEdit' },
        ],
        isEditWishes: false,
        
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddAfterWishesInfoApiId: string = "";
  callGetWishesDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
     await this.getDelegateMembers();
    this.getWishesData()
  
  

  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersAfterWishesApiResponse,
      [this.callGetWishesDataApiId]: this.handleGetWishesDataApiResponse,
      [this.callAddAfterWishesInfoApiId]: this.handleAddAfterWishesInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersAfterWishesApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
     return; 
   }
 
     const responseData = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
    if (responseData.data) {
      let delegateInChargeWishes = [...this.state.delegateInChargeWishes];
      responseData.data.forEach((member) => {
        delegateInChargeWishes.push({
          value: member.attributes.id.toString(),
          name: member.attributes.first_name + " " + member.attributes.last_name,
        });
      });
      this.setState({ delegateInChargeWishes });
    }
   };

   handleAddAfterWishesInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON))  return
    const response = responseJSON as {
      meta?: { message: string };
      data?: IWishesInformationAPIResponse;
    };
  

    if (response.data) {
      this.setState({ openDialog: true });
    }
  };


  handleWishesInformationsFormSubmit = async (values: IAfterWishesInformation) => {
    if(!this.state.isEditWishes){
       this.toggleEditModeWishes()
    }else{
      const token = await getStorageData("token");
      const formData = new FormData();
  
      Object.entries(values).forEach(([keyName, value]) => {
        console.log("keyName",keyName)
          if (value  && keyName == "have_passed") {
              formData.append(`wish[${keyName}]`, value);
          }
        });
        
        if(values.have_passed === "1"){
          formData.append(`wish[delegate_id]`, values.delegate_id.toString());
          values.my_wishes_attributes.forEach((wish, index) => {
            Object.entries(wish).forEach(([keyName, value]) => {
              if (value) {
                  formData.append(`wish[my_wishes_attributes][][${keyName}]`, value as string);
                }
            });
        });
        }
    
  
      this.callAddAfterWishesInfoApiId = sendAPIRequest(
        configJSON.addAfterWishesInfoApiEndPoint,
        {
          method: configJSON.formAPiMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
    }
 
  };

  getWishesData = async() => {
    const token = await getStorageData("token");

    this.callGetWishesDataApiId = sendAPIRequest(
        configJSON.addAfterWishesInfoApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };
  handleGetWishesDataApiResponse = async(
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?:IWishesInformationAPIResponse ;
    };
    if (response.data) {
      const attributes = response.data.attributes;
     console.log("attributes===>",attributes)
      let wishes_information = {
        have_passed: this.checkWishesValue(attributes.have_passed),
        delegate_id: attributes.delegate_id ,
        my_wishes_attributes: attributes.my_wishes.length > 0 ? attributes.my_wishes.map((myWishes:any) => ({
          wish: myWishes.wish,
        })): [{
          id: 0,
          wish: "",
        }],
      } as IAfterWishesInformation;
      this.setState({ wishes_information });
      console.log("wishes_information",this.state.wishes_information)
    }
  };
  checkWishesValue = (value: string) => {
    return value === "Yes" ? '1' : '0'
  }
   toggleEditModeWishes = () => {
    this.setState((prevState) => ({
      isEditWishes: true,
      stepsWishes: [...prevState.stepsWishes, { label: 'Edit Details', path: 'FriendsDetailsEdit' }],
    }));
  };

  validationSchema = (isEditWishes: boolean) => {
    if(isEditWishes) {
      return Yup.object().shape({
        delegate_id: Yup.string().when(['have_passed'], {
            is: (have_passed) => have_passed === "1",
            then: Yup.string().nullable().required("Please select delegate user"),
            otherwise: Yup.string().nullable(),
          }),
      });
  }else {
        return Yup.object().nullable();
    }
  }

  handleCloseDialogWishes = () => {
    this.setState({ openDialog: false });
  };

  handleNavigationWishes = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleEditWishesClick = (event: any) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isEditWishes: true,
      stepsWishes: [...prevState.stepsWishes, { label: 'Edit Details', path: 'AfterWishesEdit' }],
    }));
  }
  // Customizable Area End
}
