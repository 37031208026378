export const Icon1 = require("../assets/Icon1.png");
export const Icon2 = require("../assets/Icon2.png");
export const Icon3 = require("../assets/Icon3.png");
export const Icon4 = require("../assets/Icon4.png");
export const map = require("../assets/map.png");
export const supportImage = require("../assets/supportImage.png");
export const supportImageBack = require("../assets/supportImageBack.png");
export const support1 = require("../assets/support1.png");
export const support2 = require("../assets/support2.png");
export const support3 = require("../assets/support3.png");
export const support4 = require("../assets/support4.png");
export const support5 = require("../assets/support5.png");
export const contactusbanner = require("../assets/contactusbanner.png");
export const locationBack = require("../assets/locationBack.png");
export const successIcon = require("../assets/successIcon.png");