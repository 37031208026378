import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import OrderSummaryController, {
    configJSON,
    countries,
    Props,
} from "./OrderSummaryController.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Box, Typography, Breadcrumbs, Button, Grid, FormLabel, TextField, Checkbox } from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import { deleteIcon, accept } from './assets';
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import { failedIcon } from "../../../blocks/customisableusersubscriptions/src/assets";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                
            },
            h2: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "38px",
                fontWeight: 700,
                lineHeight: "54px",
            },
            h3: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "28px",
            },
            h6: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "26px",
            },
            subtitle1: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "28px",
                color: "#64748B",
            },
            subtitle2: {
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "22px",
            },
            body1: {     
                fontSize: "24px",
                fontFamily: "Lato",
                fontWeight: 400,
                lineHeight: "40px",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: 400,
            },
        },

    },
});
const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
        },
        "& input": {
            padding: "12px 14px",
            marginTop: "5px",
        },
    },
    width: "100%",
});
// Customizable Area Start
const ClampedTypography = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
}));
// Customizable Area End
export default class OrderSummary extends OrderSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { productData,  billingSameAsShipping, errors } = this.state;
        return (
            <>
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <WebHeader navigation={this.props.navigation} />

                    <Box className="container">

                        {productData && (
                            <Box>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadCrumb">
                                    <Typography 
                                        variant="subtitle2" 
                                        onClick={() => this.navigateToShop()} 
                                        data-test-id="shopBtn"
                                    >
                                        Shop
                                    </Typography>
                                    <Typography 
                                        variant="subtitle2" 
                                        onClick={() => this.handleProductNavigation(productData.category_id)} 
                                        data-test-id="productNavigate"
                                    >
                                        {productData.category_name}
                                    </Typography>
                                    <Typography 
                                        variant="subtitle2" 
                                        onClick={() => this.handleShowProductNavigation(productData.id)} 
                                        data-test-id="singleuser"
                                    >
                                            {productData.product_name}
                                    </Typography>
                                    <Typography 
                                        variant="subtitle2"
                                        style={{ color: "#0F172A" }}
                                    >
                                        Checkout
                                    </Typography>
                                </Breadcrumbs>
                                <Grid container spacing={2} style={{ width: "100%", boxShadow: "none" }}>
                                    <Grid item xs={12} md={6} style={{ padding: "24px"}}>
                                        <Box flex={1} mb={2}>
                                            <Typography variant="h6" data-test-id="productTitle">Product</Typography>
                                        </Box>
                                        <Box flex={1}>
                                            <Box display="flex" alignItems="center">
                                                <img
                                                    src={productData.main_image}
                                                    alt="Moonstone silver ring"
                                                    style={{ width: '150px', height: '125px', marginRight: '16px', borderRadius: '8px' }}
                                                />
                                                <Box>
                                                    <Typography variant="h6" color="textPrimary">{productData.product_name}</Typography>
                                                    <ClampedTypography variant="body2" color="textSecondary">
                                                        {productData.description}
                                                    </ClampedTypography>
                                                    <Button
                                                        variant="text"
                                                        className="textBtn"
                                                        style={{ marginTop: '8px' }}
                                                        onClick={() => this.handleShowProductNavigation(productData.id)}
                                                        data-test-id="singleuser">
                                                        View Product
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="billingCost">
                                        <Box flex={1} display="flex" justifyContent="space-between">
                                            <Box>
                                                <Box mb={2}>
                                                    <Typography variant="h6" style={{ flex: 1 }}>Price</Typography>
                                                </Box>
                                                <Box className="productPriceWrapper">
                                                <Typography variant="body1">
                                                    <span style={{ color: '#0F172A' }}>${productData.price}</span>
                                                    <span style={{ color: '#A0AEC0', textDecoration: 'line-through', marginRight: '8px' }}>${productData.price + 10}</span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        </Box>
                                        <Box flex={1} display="flex" justifyContent="space-between">
                                            <Box>
                                                <Box mb={2}>
                                                    <Typography variant="h6" style={{ flex: 1 }}>Quantity</Typography>
                                                </Box>
                                                <Box className="quantitySection">
                                                        <Button variant="outlined" onClick={this.decrement} className="dec" data-test-id="decrement">-</Button>
                                                        <Box className="counterBox">
                                                            <Typography className="countNumber">{this.state.count}</Typography>
                                                        </Box>
                                                        <Button variant="outlined" onClick={this.increment} className="inc" data-test-id="increment">+</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box flex={1} display="flex" flexDirection="column">
                                            <Box>
                                                <Box mb={2}>
                                                    <Typography variant="h6"  style={{ textAlign: 'left', flex: 1 }}>Total Cost</Typography>
                                                </Box>
                                                <Box className="totalCostWrapper" display="flex" justifyContent="center" alignItems="center">
                                                <Typography variant="body1">${this.state.subTotal}</Typography>
                                                <img src={deleteIcon} className="deleteIconImg" onClick={() => this.handelDelete()} data-test-id="deleteBtn"/>
                                            </Box>
                                        </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                       <Grid container spacing={2} className="mainFormGrid">
                            <Grid item xs={12} md={6} className="formMainBox">

                                <Box className="formHeading">
                                    <Typography variant="h3" data-test-id="shippingAddressTitle">Shipping  Address</Typography>
                                </Box>
                                <Box className="addressForm">
                                    <Box className="textBox">
                                        <FormLabel component="label" className="labelTxt">
                                            Address line 1
                                            <span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Box className="firstLine">

                                            <MyLocationTwoToneIcon style={{ color: "#94A3B8", marginLeft: "16px" }} />
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Enter your address"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.line1}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>this.handleShippingChange(event, 'line1')}
                                            />
                                        </Box>
                                        {errors.shippingAddress.line1 && <Typography className="error">{errors.shippingAddress.line1}</Typography>}
                                    </Box>
                                    <Box className="textBox" >
                                        <FormLabel component="label" className="labelTxt">
                                            Address line 2
                                            <span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Box className="firstLine">
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Enter your address"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.line2}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleShippingChange(event, 'line2')}
                                            />
                                        </Box>
                                        {errors.shippingAddress.line2 && <Typography className="error">{errors.shippingAddress.line2}</Typography>}
                                    </Box>
                                    <Box className="textBox" >
                                        <FormLabel component="label" className="labelTxt">
                                            Landmark (if required)
                                        </FormLabel>
                                        <Box className="firstLine">
                                            <CustomTextField
                                            data-test-id="shippingInput"
                                                placeholder="Landmark"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.shippingAddress.landmark}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleShippingChange(event, 'landmark')}
                                            />
                                        </Box>
                                        <Box className="national">
                                            <Box className="cityBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Town/City <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="city">
                                                    <CustomTextField
                                                    data-test-id="shippingInput"
                                                        placeholder="Enter your Town/City"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.shippingAddress.city}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleShippingChange(event, 'city')}
                                                    />
                                                </Box>
                                                {errors.shippingAddress.city && <Typography className="error">{errors.shippingAddress.city}</Typography>}
                                            </Box>

                                            <Box className="countryBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Country <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="country">
                                                    <CustomSelect
                                                        data-test-id="shippingCountry"
                                                        value={this.state.shippingAddress.country}
                                                        onChange={this.handleCountryChange}
                                                        className="select"
                                                        options={countries} 
                                                        placeholder="Country"
                                                    />
                                                </Box>
                                                {errors.shippingAddress.country && <Typography className="error">{errors.shippingAddress.country}</Typography>}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className="cityBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Post Code <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="city">
                                                    <CustomTextField
                                                    data-test-id="shippingInput"
                                                        placeholder="Enter your Post Code"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.shippingAddress.postCode}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleShippingChange(event, 'postCode')}
                                                    />
                                                </Box>
                                            </Box>
                                            {errors.shippingAddress.postCode && <Typography className="error">{errors.shippingAddress.postCode}</Typography>}

                                        </Box>
                                    </Box>
                                    <Box className="checkBox">
                                        <Checkbox
                                        data-test-id="checkBx"
                                            color="primary"
                                            style={{ color: "#A190C2", borderRadius: "30px" }}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            checked={billingSameAsShipping}
                                            onChange={this.handleCheckboxChange}
                                        />
                                        <Typography
                                            style={{
                                                color: "#0F172A",
                                                fontFamily: "Lato",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Billing address same as Shipping address
                                        </Typography>
                                    </Box>
                                </Box>
                                {!this.state.billingSameAsShipping && (
                                    <Box className="billing">
                                        <Box><Typography style={{
                                            color: "#0F172A",
                                            fontFamily: "Lato",
                                            fontSize: "20px",
                                            fontWeight: 600,
                                        }}>Billing  Address</Typography></Box>
                                        <Box className="addressForm">
                                            <Box className="textBox">
                                                <FormLabel component="label" className="labelTxt">
                                                    Address line 1
                                                    <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="firstLine">

                                                    <MyLocationTwoToneIcon style={{ color: "#94A3B8", marginLeft: "16px" }} />
                                                    <CustomTextField
                                                    data-test-id="billingInput"
                                                        placeholder="Enter your address"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.line1}
                                                        onChange={(e) => this.handleBillingChange('line1', e.target.value)}
                                                    />
                                                </Box>
                                                {errors.billingAddress.line1 && <Typography className="error">{errors.billingAddress.line1}</Typography>}

                                            </Box>
                                            <Box className="textBox" >
                                                <FormLabel component="label" className="labelTxt">
                                                    Address line 2
                                                    <span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Box className="firstLine">
                                                    <CustomTextField
                                                        placeholder="Enter your address"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.line2}
                                                        onChange={(e) => this.handleBillingChange('line2', e.target.value)}
                                                    />

                                                </Box>
                                                {errors.billingAddress.line2 && <Typography className="error">{errors.billingAddress.line2}</Typography>}
                                            </Box>
                                            <Box className="textBox" >
                                                <FormLabel component="label" className="labelTxt">
                                                    Landmark (if required)
                                                </FormLabel>
                                                <Box className="firstLine">
                                                    <CustomTextField
                                                    data-test-id="billingInput"
                                                        placeholder="Landmark"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.billingAddress.landmark}
                                                        onChange={(e) => this.handleBillingChange('landmark', e.target.value)}
                                                    />
                                                </Box>
                                                <Box className="national">
                                                    <Box className="cityBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Town/City <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="city">
                                                            <CustomTextField
                                                            data-test-id="billingInput"
                                                                placeholder="Enter your Town/City"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.billingAddress.city}
                                                                onChange={(e) => this.handleBillingChange('city', e.target.value)}
                                                            />
                                                        </Box>
                                                        {errors.billingAddress.city && <Typography className="error">{errors.billingAddress.city}</Typography>}
                                                    </Box>

                                                    <Box className="countryBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Country <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="country">

                                                            <CustomSelect
                                                                data-test-id="billingCountry"
                                                                className="select"
                                                                value={this.state.billingAddress.country}
                                                                onChange={this.handleBillingCountryChange}
                                                                options={countries}
                                                                placeholder="Country"
                                                            />
                                                        </Box>
                                                        {errors.billingAddress.country && <Typography className="error">{errors.billingAddress.country}</Typography>}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box className="cityBox">
                                                        <FormLabel component="label" className="labelTxt">
                                                            Post Code <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Box className="city">
                                                            <CustomTextField
                                                            data-test-id="billingInput"
                                                                placeholder="Enter your Post Code"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.billingAddress.postCode}
                                                                onChange={(e) => this.handleBillingChange('postCode', e.target.value)}
                                                            />
                                                        </Box>
                                                        {errors.billingAddress.postCode && <Typography className="error">{errors.billingAddress.postCode}</Typography>}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} className="formPaymentBox">
                                <Box className="formPayBox">
                                    <Typography variant="h3">Order Summary</Typography>
                                    <Box className="subTotal">
                                        <Typography className="text">Subtotal</Typography>
                                        <Typography className="text">${this.state.subTotal}</Typography>
                                    </Box>
                                    <Box className="subTotal">
                                        <Typography className="text">Delivery Charges</Typography>
                                        <Typography className="text">${this.state.deliveryCharges}</Typography>
                                    </Box>
                                    <Box className="dotLine"></Box>
                                    <Box className="subTotal">
                                        <Typography className="total">Total Payable Amount</Typography>
                                        <Typography className="total">${this.state.totalPayable}</Typography>
                                    </Box>
                                </Box>
                                <Box className="payBtn">
                                    <Button className="payBtnText" onClick={() => this.payBtn()} data-test-id="payBtn">Confirm and Pay</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <WebFooter navigation={this.handleNavigation} data-test-id="HandelFooter" />
                </Wrapper>
            </ThemeProvider>
                <GenericModal open={this.state.openDialog} dataTest="dialogBox" onClose={this.handleCloseDialog}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="alignCenter" mb={4}>
                                <img alt="success" src={this.state.paymentSuccessed ? accept : failedIcon} className="dialogImgIcon" />
                                <Typography
                                    align="center"
                                    variant="h1"
                                    data-test-id="paymentDoneMsg"
                                >
                                    {this.state.paymentSuccessed ? `${configJSON.paymentSuccessedModalTitle}` : `${configJSON.paymentFailedModalTitle}`}
                                </Typography>
                                <Typography
                                    align="center"
                                    variant="subtitle1"
                                >
                                    {this.state.paymentSuccessed ?
                                        `${configJSON.paymentSuccessedModalMsg}` :
                                        `${configJSON.paymentFailedModalMsg}`}
                                </Typography>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    className="outlinedBtn"
                                    variant="outlined"
                                    onClick={() => this.handleNavigation("NavigationMenu")}
                                    data-test-id="continueShoppingModalBtn"
                                >
                                    {configJSON.continueShoppingBtnText}
                                </Button>
                                <Button
                                    className="containedBtn"
                                    variant="contained"
                                    onClick={() => this.handleNavigation("LandingPage")}
                                    data-test-id="goHomeModalBtn"
                                >
                                    {configJSON.goHomeBtnText}
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        padding: "20px 42px",
    },
    "& .imageSection": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: '8px'
    },
    "& .mainImageCard": {
        width: "90%",
        marginBottom: "5px",
        height: "512px",
        borderRadius: "8px"
    },
    "& .detailsSection": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "17px",
        paddingRight: "25px"
    },
    "& .quantitySection": {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        margin: "33% 0",
    },
    "& .productPriceWrapper": {
        margin: "33% 0",
    },
    "& .quantityInput": {
        width: "50px",
        marginLeft: "10px",
    },
    "& .tab, & .activeTab": {
        padding: "10px 20px",
        cursor: "pointer",
        flexGrow: 1,
        textAlign: "center",
    },
    "& .descriptionText": {
        fontSize: "18px",
        lineHeight: "26px",
        color: "#1E293B",
        fontFamily: "Lato",
        fontWeight: 400,
        Paragraph: "12px",
        paddingRight: "45px",
        height: "300px"
    },
    "& .desc": {
        marginRight: "30px"
    },
    "& .countNumber": {
        fontWeight: 500,
        fontFamily: "Lato",
        fontSize: "14px",
        color: "#000000",
        lineHeight: "18px",
    },
    "& .counterBox": {
        width:"64px",
        height: "32px",
        display: "flex",
        justifyContent:"center",
        alignItems: "center",
        backgroundColor:"#F5F5F5",
    },
    "& .inc, .dec":{
      minWidth: "32px",
      width: "32px",
      height: "32px",
      fontSize: "18px",
      border: "1px solid #f5f5f5",
      backgroundColor: "#f5f5f5",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      cursor: "grab",
    },
    "& .breadCrumb": {
        paddingTop: "15px",
        marginBottom: "32px",
    },
    "& .totalprice": {
        display: "flex",
        flexDirection: "column",
    },
    "& .formMainBox": {
        border: "1px solid #CEC3E54D",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
    },
    "& .add": {
        display: "flex",
        marginTop: "20px",
    },
    "& .formMainBox, & .formPaymentBox": {
        padding: "24px",
        boxSizing: "border-box",
    },
    "& .mainFormGrid": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: "20px"
    },
    "& .formPaymentBox": {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "15px",
    },
    "& .formHeading": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    "& .checkBox": {
        display: "flex",
        flexDirection: "row",
        marginLeft: "-12px",
    },
    "& .subTotal": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    "& .text": {
        color: "#1E293B",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
    },
    "& .dotLine": {
        borderTop: "3px dotted #94A3B8",
        margin: "20px 0",
    },
    "& .total": {
        fontSize: "20px",
        color: "#9176C4",
        fontWeight: 700,
    },
    "& .firstLine": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "48px",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "7px",
    },
    "& .textBox": {
        marginBottom: "15px",
        marginTop: "7px"
    },
    "& .national": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "15px 0px !important",
        gap: "16px",
    },
    "& .city": {
        border: "1px solid #CBD5E1",
        marginRight: "5px",
        borderRadius: "8px",
        height: "48px",
        marginTop: "7px"
    },
    "& .country": {
        borderRadius: "8px",
        height: "48px",
        marginTop: "7px"
    },
    "& .cityBox ,& .countryBox": {
        width: "50%",
        boxSizing: "border-box",
        flex: 1,
    },
    "& .payBtn": {
        backgroundColor: "#A190C2",
        width: "386px",
        alignSelf: "center",
        borderRadius: "100px",
        height: "48px",
        textAlign: "center",
        marginTop: "35px"
    },
    "& .payBtnText": { 
        color: "#FFFFFF", 
        alignSelf: "center", 
        fontWeight: 700, 
        fontFamily: "Lato", 
        fontSize: "18px",
        textTransform: "none",
    },
    "& .formPayBox": {
        border: "1px solid #CEC3E54D",
        marginTop: "-16px",
        padding: "24px",
        borderRadius: "12px",
    },
    "& .labelTxt": {
        fontSize: "14px",
        fontFamily: "Lato",
        color: "#334155",
        fontWeight: 400,
        marginBottom: "10px"
    },
    "& .reTryBtn": {
        border: "1px solid #9176C4",
        padding: "16px",
        color: "#9176C4",
        borderRadius: "50px",
        height: "56px",
        width: "190px",
        fontWeight: 600,
        fontFamily: "Lato"
    },
    "& .content": {
        fontWeight: 400,
        color: "#64748B",
        fontSize: "18px",
    },
    "& .select":{
        backgroundColor: "white",
        border: "none",
        height: "48px",
    },
    "& .error":{
        fontSize:"15px",
        color:"red"
    },
    "& .textBtn": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#9176C4",
        textDecoration: "underline",
        textTransform: "none",
        paddingLeft: "0px",
    },
    "& .billingCost": {
        display: "flex",
        justifyContent: "space-between",
        padding: "24px",
    },
    "& .totalCostWrapper": {
        display: "flex",
        justifyContent: "space-between",
        margin: "20% 0",
    },
    "& .deleteIconImg": {
        width: "24px",
        height: "24px",
    },
    [theme.breakpoints.down(500)]: {
        "& .billingCost": {
            flexDirection: "column",
        },
    },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .dialogWrapper": {
      maxWidth: "418px !important",
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "center",
        marginTop: "32px",
        gap: "8px",
    },
    "& .alignCenter": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
  },
    "& .emailSpan": {
        fontColor: "Black",
        fontWeight: 700,
    },
    "& .outlinedBtn": {
        height: "56px",
        width: "192px",
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        color: "#9176C4",
        fontFamily: "Lato",
        borderRadius: "100px",
        textTransform: "none",
    },
    "& .containedBtn": {
        width: "192px",
        height: "56px",
        padding: "16px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "100px",
        textTransform: "none",
      },
    "& .rightTickImg": {
        width: "72px",
        height: "72px",
    },
    "& .infoPaper": {
        width: "293px",
        height: "196px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        padding: "10px",
    },
    "& .dialogImgIcon": {
        height: "72px",
        width: "72px",
    },
    [theme.breakpoints.down('xs')]: {
        "& .btnWrapper": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }
      }
  }));
// Customizable Area End
