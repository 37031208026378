import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IMedia } from "./LegalInformationController.web";
import { IAttributes } from "./AdditionalInfoSidebarController.web";
import { toast } from "react-toastify";


interface IGetFamilyDetailsAPIResponse {
    id: string;
    type: string;
    attributes: {
        id: number;
        closest_name: string;
        reason_for_special: string;
        about: string;
        have_favourite_memory: string;
        favourite_memories: {
            id: number;
            title: string;
            files: IMedia[];
        }[];
        have_festive_memory: string;
        festive_memories: {
            id: number;
            celebration_type: string;
            files: IMedia[];
        }[];
        description: string;
        account_id: number;
    };
}

export interface IFamilyDetails {
    closest_name: string;
    reason_for_special: string;
    about: string;
    have_favourite_memory: string;
    have_festive_memory: string;
    description: string;
    favourite_memories_attributes: IFavouriteMemoriesAttributes[];
    festive_memories_attributes: IFestiveMemoriesAttributes[];
};

export interface IFavouriteMemoriesAttributes {
    title: string;
    files: (File | IMedia)[];
};

export interface IFestiveMemoriesAttributes {
    celebration_type: string;
    files: (File | IMedia)[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  familyDetails: IFamilyDetails;
  filesForFavouriteMemory: (IMedia | File)[][];
  filesForFestiveMemory: (IMedia | File)[][];
  showFavouriteMemories: boolean;
  showFestiveMemories: boolean;
  openDialog: boolean;
  subscriptionData: IAttributes | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FamilyDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        familyDetails: {
            closest_name: "",
            reason_for_special:"",
            about: "",
            have_favourite_memory: "0",
            have_festive_memory: "0",
            description: "",
            favourite_memories_attributes: [{
                title: "",
                files: [],
            }],
            festive_memories_attributes: [{
                celebration_type: "",
                files: [],
            }],
        },
        filesForFavouriteMemory: [],
        filesForFestiveMemory: [],
        showFavouriteMemories: false,
        showFestiveMemories: false,
        openDialog: false,
        subscriptionData: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetFamilyDetailsApiId: string = "";
  callUpsertFamilyDetailsApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getFamilyDetails();

    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetFamilyDetailsApiId]: this.handleGetFamilyDetailsApiResponse,
      [this.callUpsertFamilyDetailsApiId]: this.handleUpsertFamilyDetailsApiResponse,

    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getFamilyDetails = async() => {
    const token = await getStorageData("token");

    this.callGetFamilyDetailsApiId = sendAPIRequest(
        configJSON.getFamilyDetailsApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleFamilyDetailsFormSubmit = async (values: IFamilyDetails) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values).forEach(([keyName, value]) => {
      if(keyName !== "favourite_memories_attributes" && keyName !== "festive_memories_attributes"){
        formData.append(`family[${keyName}]`, value);
      }
    });

    values.favourite_memories_attributes.forEach((favouriteMemory, index) => {
      Object.entries(favouriteMemory).forEach(([keyName, value]) => {
        if (value) {
          if(keyName === 'files'){
            this.processFilesForFavourite(favouriteMemory, keyName, formData, index);
          }
          else{
            formData.append(`family[favourite_memories_attributes][][${keyName}]`, value as string);
          }
        }
      })
    });

      values.festive_memories_attributes.forEach((festiveMemory, index) => {
        Object.entries(festiveMemory).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'files'){
              this.processFilesForFestive(festiveMemory, keyName, formData, index);
            }
            else{
              formData.append(`family[festive_memories_attributes][][${keyName}]`, value as string);
            }
          }
        });
      });


    this.callUpsertFamilyDetailsApiId = sendAPIRequest(
        configJSON.getFamilyDetailsApiEndPoint,
        {
          method: configJSON.formAPiMethod,
          headers: {
            token,
          },
          body: formData,
        }
    );
  };

  // Helper function to check if a file exists and append it to formData for favourite
  appendFileToFormDataForFavourite = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForFavouriteMemory[index] && this.state.filesForFavouriteMemory[index][fileIndex]) {
      formData.append(`family[favourite_memories_attributes][][${keyName}][]`, file as Blob);
    }
  };

  // Helper function to handle file processing for Favourite
  processFilesForFavourite = (
    favouriteMemory: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (favouriteMemory.files && favouriteMemory.files.length > 0 && this.state.filesForFavouriteMemory.length > 0) {
      favouriteMemory.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForFavourite(file, keyName, formData, index, fileIndex);
      });
    }
  };

  // Helper function to check if a file exists and append it to formData for festive
  appendFileToFormDataForFestive = (
    file: (File | IMedia),
    keyName: string,
    formData: FormData,
    index: number,
    fileIndex: number
  ) => {
    if (this.state.filesForFestiveMemory[index] && this.state.filesForFestiveMemory[index][fileIndex]) {
      formData.append(`family[festive_memories_attributes][][${keyName}][]`, file as Blob);
    }
  };

  // Helper function to handle file processing for Favourite
  processFilesForFestive = (
    festiveMemory: { files: (File | IMedia)[] },
    keyName: string,
    formData: FormData,
    index: number
  ) => {
    if (festiveMemory.files && festiveMemory.files.length > 0 && this.state.filesForFestiveMemory.length > 0) {
      festiveMemory.files.forEach((file, fileIndex) => {
        this.appendFileToFormDataForFestive(file, keyName, formData, index, fileIndex);
      });
    }
  };

  updateStateBasedOnAttributes = (data : IGetFamilyDetailsAPIResponse) => {
    if(data.attributes.have_favourite_memory === "Yes"){
      this.setState({ showFavouriteMemories: true });
    }
    if(data.attributes.have_festive_memory === "Yes"){
      this.setState({ showFestiveMemories: true });
    }
  };

  handleGetFamilyDetailsApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) { return; }
    
    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFamilyDetailsAPIResponse;
      };
  
      if (response.data) {
        this.updateStateBasedOnAttributes(response.data);

        const attributes = response.data.attributes;

        let familyDetails = {
            closest_name: attributes.closest_name,
            reason_for_special: attributes.reason_for_special,
            about: attributes.about,
            have_favourite_memory: attributes.have_favourite_memory === "Yes" ? "1" : "0",
            have_festive_memory: attributes.have_festive_memory === "Yes" ? "1" : "0",
            description: attributes.description,
            favourite_memories_attributes: attributes.favourite_memories.length > 0 ?
            attributes.favourite_memories.map(
                (favouriteMemory) => {
                  return(
                    {
                          title: favouriteMemory.title,
                          files: favouriteMemory.files?.map((mediaFile: IMedia) => {
                            return({
                              file_id: mediaFile.file_id,
                              file_name: mediaFile.file_name,
                              content_type: mediaFile.content_type,
                              file_size: mediaFile.file_size,
                              url: mediaFile.url,
                            })
                          }) || [],
                    }
                  )
                }
              ) : [{
                  title: "",
                  files: [],
              }],
            festive_memories_attributes: attributes.festive_memories.length > 0 ? 
            attributes.festive_memories?.map(
                (festiveMemory) => {
                  return(
                    {
                          celebration_type: festiveMemory.celebration_type,
                          files: festiveMemory.files?.map((mediaFile: IMedia) => {
                            return({
                              file_id: mediaFile.file_id,
                              file_name: mediaFile.file_name,
                              content_type: mediaFile.content_type,
                              file_size: mediaFile.file_size,
                              url: mediaFile.url,
                            })
                          }) || [],
                    }
                  )
                }
              ) : [{
                celebration_type: "",
                files: [],
              }],
        } as IFamilyDetails;
        this.setState({ familyDetails });
      }
  };

  handleUpsertFamilyDetailsApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetFamilyDetailsAPIResponse;
    };

    if (response.data) {
      this.setState({ openDialog: true });
    }
  };


  validationSchema = Yup.object().shape({
    favourite_memories_attributes: Yup.array().when(['have_favourite_memory'], {
      is: (have_favourite_memory) => have_favourite_memory === "1",
      then: Yup.array().of(Yup.object().shape({
        title: Yup.string().nullable().required("Please enter memory title"),
      })),
      otherwise: Yup.array().of(Yup.object().nullable()),
    }),
    festive_memories_attributes: Yup.array().when(['have_festive_memory'], {
      is: (have_festive_memory) => have_festive_memory === "1",
      then: Yup.array().of(Yup.object().shape({
        celebration_type: Yup.string().nullable().required("Please enter celebration type"),
      })),
      otherwise: Yup.array().of(Yup.object().nullable()),
    }),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    attributeName: string) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      if(attributeName === "favourite_memories_attributes") {
        this.setState((prevState) => ({
            filesForFavouriteMemory: this.updateFilesState(prevState, "filesForFavouriteMemory", index, newFiles),
          }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForFavouriteMemory[index] || [];
            setFieldValue(`favourite_memories_attributes.${index}.files`, currentFiles);
        });
      }
      else if(attributeName === "festive_memories_attributes"){
        this.setState((prevState) => ({
          filesForFestiveMemory: this.updateFilesState(prevState, "filesForFestiveMemory", index, newFiles),
        }), () => {
            // Set the field value after the state has been updated
            const currentFiles = this.state.filesForFestiveMemory[index] || [];
            setFieldValue(`festive_memories_attributes.${index}.files`, currentFiles);
        });
      }
    }
  };

  // Helper function to update state
  updateFilesState = (
    prevState: any,
    stateKey: string,
    index: number,
    newFiles: (File | IMedia)[]
  ): (File | IMedia)[][] => {
    const updatedFiles = [...(prevState[stateKey] || [])];
    if (!updatedFiles[index]) {
      updatedFiles[index] = [];
    }
    updatedFiles[index] = [...updatedFiles[index], ...newFiles];
    return updatedFiles;
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}