import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ContactUSFormController, {
    Props,
    configJSON,
} from "./ContactUSFormController.web";
import { Box, Grid, Typography, Button, Card, FormLabel, Dialog } from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import RoomIcon from '@material-ui/icons/Room';
import { support1, support2, support3, support4, support5, contactusbanner, locationBack, successIcon } from "./assets";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CustomSelect from "../../../components/src/CustomSelect";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import GenericModal from "../../../components/src/GenericModal";
import CloseIcon from '@material-ui/icons/Close';
const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "54px",
            },
            h3: {
                color: "#000000",
                fontFamily: "Quattrocento",
                fontSize: "40px",
                fontWeight: 700,
                lineHeight: "54px",
            },
            h4: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "28px",
                color: "#000000"
            },
            h6: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#1E293B"
            },
            subtitle1: {
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#1E293B"
            },
            subtitle2: {
                fontFamily: "Lato",
                fontSize: "22px",
                fontWeight: 600,
                lineHeight: "54px",
            },
            body1: {
                fontFamily: "Lato",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "36px",
                color: "#1E293B"
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#000000"
            }
        },
        MuiFormLabel: {
            root: {
                color: "#334155",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
            }
        },

        MuiButton: {
            contained: {
                width: "248px",
                height: "48px",
                color: "#FFFFFF",
                backgroundColor: "#A190C2",
                textTransform: "none",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: "100px",
            },
        },
        MuiInputBase: {
            root: {
                minHeight: '48px',
            },
        },
    },
});
// Customizable Area End

export default class ContactUSForm extends ContactUSFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    locationDetails = (heading: string, description: string, Icon: React.ElementType) => {
        return (
            <Grid container justifyContent="space-between" style={{ padding: "10px 0" }}>
                <div style={{ marginTop: 3 }}>
                    <Icon />
                </div>
                <Grid className="whereToDes">
                    <Typography variant="h4"> {heading}</Typography>
                    <Typography variant="subtitle1" style={{ color: "#475569" }}> {description}</Typography>
                </Grid>
            </Grid>
        )
    }

    contactUSForm = (popup: boolean) => {
        return (
            <Box className="contactUSFormContainer">
                <Formik
                    initialValues={{...this.state.contactUSForm , query_type: this.state.selectedQuery}}
                    validationSchema={this.ContactUSFormValidationSchema}
                    onSubmit={(values , {resetForm}) => {
                        this.handleContactUSFormSubmit(values, resetForm)
                    }}
                    enableReinitialize={true}
                    data-test-id="contactUSFormikForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form translate="no">
                            <Typography variant="h1">
                                    {popup ?  this.getFormName(values.query_type) : "Contact Form"}
                                </Typography>
                                <Typography variant="h6">
                                    {configJSON.contactFormDes}
                                </Typography>
                            <Grid container spacing={3} className="formContainer">
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.name}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favname"
                                            className="inputField"
                                            name={configJSON.name}
                                            as={CustomInput}
                                            size="small"
                                            placeholder={configJSON.name_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`name`, e.target.value)
                                            }}
                                            value={values.name}
                                        />
                                        <ErrorMessage name={`name`} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.email_id}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favemail"
                                            className="inputField"
                                            name={configJSON.email_id}
                                            as={CustomInput}
                                            size="small"
                                            placeholder={configJSON.email_id_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`email`, e.target.value)
                                            }}
                                            value={values.email}
                                        />
                                        <ErrorMessage name={`email`} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.phone_number}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <PhoneInput
                                            data-test-id="phone_number"
                                            placeholder={configJSON.phone_number_Placeholder}
                                            country={'gb'}
                                            value={values.phone_number}
                                            onChange={(value, country) => {
                                                setFieldValue("phone_number", value)
                                                setFieldValue('country_code', country)
                                            }
                                            }
                                            inputStyle={{
                                                padding: '10px 45px',
                                                width: '100%',
                                                height: '48px',
                                                border: '1px solid #e0e0e0',
                                                borderRadius: '8px',
                                            }}
                                            buttonStyle={{
                                                padding: '2px',
                                                border: '1px solid #e0e0e0',
                                                borderTopLeftRadius: '8px',
                                                borderBottomLeftRadius: '8px',
                                            }}
                                            containerStyle={{
                                                margin: '5px 0',
                                                width: '100%',
                                                height: '48px',
                                            }}
                                            inputProps={{
                                                required: true,
                                                autoFocus: true,
                                                name: 'phone',
                                            }}
                                        />
                                        <ErrorMessage name={`phone_number`} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.query_type}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favquery_type"
                                            className="selectField"
                                            name={configJSON.query_type}
                                            as={CustomSelect}
                                            setValue={true}
                                            options={this.state.queryOptions}
                                            size="small"
                                            placeholder={configJSON.query_type_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`query_type`, e.target.value)
                                            }}
                                            value={values.query_type}
                                        />
                                        <ErrorMessage name={`query_type`} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.subject}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favsubject"
                                            className="inputField"
                                            name={configJSON.subject}
                                            as={CustomInput}
                                            size="small"
                                            placeholder={configJSON.subject_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`subject`, e.target.value)
                                            }}
                                            value={values.subject}
                                        />
                                        <ErrorMessage name={`subject`} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={popup ? 12 : 4} xs={12} sm={popup ? 12 : 6}>
                                    <FormLabel component="label">
                                        {configJSON.additional_detail}
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favadditional_detail"
                                            className="inputField"
                                            name={configJSON.additional_detail}
                                            as={CustomInput}
                                            size="small"
                                            placeholder={configJSON.additional_detail_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`additional_detail`, e.target.value)
                                            }}
                                            value={values.additional_detail}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label">
                                        {configJSON.description}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Grid item xs={12} >
                                        <Field
                                            data-test-id="favdescription"
                                            className="multilineInput"
                                            name={configJSON.description}
                                            as={CustomInput}
                                            size="small"
                                            multiline
                                            placeholder={configJSON.description_placeholder}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`description`, e.target.value)
                                            }}
                                            value={values.description}
                                        />
                                        <ErrorMessage name={`description`} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box justifyContent={popup ? "center" : "end"} className="formButton">
                                <Button
                                    className="btnSize"
                                    variant="contained"
                                    data-test-id="saveAndNextBtn"
                                    type="submit"
                                >
                                    {configJSON.sendMessage}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { ContactUsData, contactForm , successDialog, successMessage } = this.state
        const cardDetails = [
            {
                "title": "Ask a question",
                "description": "Ask your question and our support team will resolve your query as soon as possible",
                "icon": support1,
                "type": "ask_a_question"
            },
            {
                "title": "Press and Media",
                "description": "If you have any question relation to press release and media, you can contact us anytime",
                "icon": support2,
                "type": "press_and_media"
            },
            {
                "title": "Online Purchase",
                "description": "Any query or issue related to your online purchase like order delay, wrong order, re-order issue, etc.",
                "icon": support3,
                "type": "online_purchase"
            },
            {
                "title": "User card enquiry",
                "description": "Any issue in your user card information, delay in card delivery, card lost, new card request, etc",
                "icon": support4,
                "type": "user_card_enquiry"
            },
            {
                "title": "User login/enquires",
                "description": "Facing issue in login/signup or any other issue related to your account, you can contact us anytime.",
                "icon": support5,
                "type": "user_login_enquiry"
            }
        ]
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="contactUSWrapper">
                            <Box className="contactUSTitle">
                                <Typography align="center" variant="h3">
                                    {configJSON.contactUSHeading}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {configJSON.contactUSSubHeading}
                                </Typography>
                            </Box>
                            <Grid item xs={12} style={{ width: "100%" }}>
                                <img src={contactusbanner} className="forwordImage" alt="aboutUs"/>
                            </Grid>
                        </Box>
                        <Box className="contactUSContainerWrapper">
                            <Box className="contactUSTitle">
                                <Typography align="center" variant="h3" style={{ fontSize: '32px' }}>
                                    {configJSON.contactUSHeading1}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {configJSON.contactUSSubHeading1}
                                </Typography>
                            </Box>
                            <Box className="reasonsToChooseCardsWrapper">
                                <Grid container spacing={4} justifyContent="center">
                                    {cardDetails.map((_item: any) => {
                                        return (
                                            <Grid item className="alignGridItem" xs={12} sm={6} md={4} key={_item.type}>
                                                <Card className="card-root">
                                                    <img
                                                        alt="easy-to-use"
                                                        src={_item.icon}
                                                        className="cardImgIcon"
                                                    />
                                                    <Typography variant="subtitle2">
                                                        {_item.title}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {_item.description}
                                                    </Typography>
                                                    <Typography variant="body2" className="siteVisit" data-test-id={_item.type} onClick={() => { this.openContactPopUp(_item.type) }}>
                                                        {configJSON.getSupport}
                                                        <CallMadeIcon />
                                                    </Typography>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        <Box className="contactUSContainerWrapper heroSectionImgWrapper">
                            <Card className="card-root" style={{ borderRadius: '24px', width: '400px' }}>
                                <Typography variant="body1" style={{ color: "#9176C4" }}>
                                    {configJSON.whereTo}
                                </Typography>
                                {this.locationDetails("Our location", ContactUsData.full_address, RoomIcon)}
                                {this.locationDetails("Phone Number", ContactUsData.full_phone_number, PhoneIcon)}
                                {this.locationDetails("Email Address", ContactUsData.email, EmailIcon)}
                            </Card>
                        </Box>
                        <Box className="contactUSContainerWrapper">
                            <Grid container className="contactFormContainer">
                               {this.contactUSForm(false)}
                            </Grid>
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                {contactForm && <Dialog
                    open={contactForm}
                    onClose={() => this.handleCloseContactPopUp()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ borderRadius: 8 }}
                >
                    <ThemeProvider theme={theme}>
                        <Wrapper>
                            <div className="dialogContainer">
                                <Grid container justifyContent="flex-end">
                                    <CloseIcon onClick={() => this.handleCloseContactPopUp()} />
                                </Grid>
                                <Grid container>
                                    {this.contactUSForm(true)}
                                </Grid>
                            </div>
                        </Wrapper>
                    </ThemeProvider>
                </Dialog>}

                {successDialog && <GenericModal
                 open={successDialog}
                 data-test-id="successDialog"
                 onClose={()=>this.closeSuccessDialog()}
                 >
                    <ThemeProvider theme={theme}>
                    <Wrapper>
                        <Grid container justifyContent="center">
                            <img src={successIcon} className="successIcon"/>
                            <Typography className="successMessage">
                                {successMessage}
                            </Typography>
                        </Grid>
                        </Wrapper>
                        </ThemeProvider>
                    </GenericModal>}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FAFFF3",
        padding: "50px 0px 0"
    },
    "& .dialogContainer": {
        padding: "30px 25px"
    },
    "& .inputField": {
        height: "48px",
        width: "100%",
        borderRadius: "8px",
    },
    "& .successMessage":{
        marginTop: '20px',
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#64748B",
        textAlign: "center"

    },
    "& .successIcon":{
        height: '56px',
        width: "56px"
    },
    "& .formButton": {
        marginTop: 20,
        display: 'flex'
    },
    "& .selectField": {
        width: "100%",
        display: "block",
        height: "48px",
        borderRadius: "8px",
        margin: "5px 0px"
    },
    "& .contactUSFormContainer": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "40px",
        minHeight: "20vh",
    },
    "& .formContainer" :{
        marginTop: '25px'
    },
    "& .contactFormContainer": {
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: 12,
        padding: '44px 30px',
        flexDirection: "column"

    },
    "& .forwordImage": {
        width: "100%",
        height: "100%",
        maxHeight: "400px"
    },
    "& .contactUSWrapper": {
        padding: "59px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .whereToDes": {
        width: "calc(100% - 40px)"
    },
    "& .contactUSContainerWrapper": {
        padding: "59px 114px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .siteVisit": {
        marginTop: '10px',
        display: "flex",
        textDecoration: "underline",
        alignItems: "center",
        color: "#9176C4",
    },
    "& .contactUSTitle": {
        display: "flex",
        marginBottom: "64px",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "800px",
        padding: '0 15px'
    },
    "& .reasonsToChooseCardsWrapper": {
        display: "flex",
        justifyContent: "center",
    },
    "& .card-root": {
        width: "100%",
        boxSizing: "border-box",
        padding: "24px",
        height: "100%",
        borderRadius: 8
    },
    "& .cardImgIcon": {
        height: "64px",
        width: "64px",
        marginBottom: '12px'
    },
    "& .alignGridItem": {
        display: "flex",
        justifyContent: "center",
    },
    [theme.breakpoints.down(760)]: {
        "& .contactUSContainerWrapper": {
            padding: "59px 40px !important",
        },
    },
    "& .heroSectionImgWrapper": {
        backgroundImage: `url(${locationBack})`,
        backgroundSize: "cover",
        alignItems: "end !important"
    }
}));
// Customizable Area End
