import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { toast } from "react-toastify";
interface FutureAim {
  id: number;
  heading: string;
  description: string;
  image_url: string;
}

interface AboutUsData {
  id: number;
  title: string;
  heading: string;
  vission_title: string;
  vission_description: string;
  mission_title: string;
  mission_description: string;
  we_do_description_1: string;
  we_do_description_2: string;
  we_do_description_3: string;
  we_do_description_4: string;
  we_do_description_5: string;
  we_do_description_6: string;
  version_2_heading: string;
  version_2_description: string;
  banner_image: string;
  vission_image: string;
  mission_image: string;
  version_2_image: string;
  future_aim_heading: string;
  future_aims: FutureAim[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  aboutData:AboutUsData
  currentIndex: number;
  itemsInScreen: number;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      aboutData:  {
        id: 0,
        title: "",
        heading: "",
        vission_title: "",
        vission_description: "",
        mission_title: "",
        mission_description: "",
        we_do_description_1: "",
        we_do_description_2: "",
        we_do_description_3: "",
        we_do_description_4: "",
        we_do_description_5: "",
        we_do_description_6: "",
        version_2_heading: "",
        version_2_description: "",
        banner_image: "",
        vission_image: "",
        mission_image: "",
        version_2_image: "",
        future_aim_heading: "",
        future_aims: []
      },
    currentIndex: 0,
  itemsInScreen:3
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.aboutUSApiCallID){
    if(responseJSON?.data){
        this.handleAboutUsResponse(responseJSON)
      }
      else{
        toast.error(configJSON.somethingWW)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  aboutUSApiCallID: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getAllAboutsDataList();
  }


  handleNext = () => {
    let {itemsInScreen , currentIndex, aboutData} = this.state
    if (currentIndex + itemsInScreen < aboutData?.future_aims?.length) {
      this.setState({currentIndex: currentIndex +1})
    }
  };

  handlePrev = () => {
    if (this.state.currentIndex > 0) {
      this.setState({currentIndex: this.state.currentIndex - 1})
    }
  };

  getAllAboutsDataList = () => {
    let windowSize = window.innerWidth
    let isBigScreen =  windowSize < 900 ? 2 : 3;
    let itemsInScreen = windowSize < 600 ? 1 :isBigScreen
    this.setState({itemsInScreen})
   this.aboutUSApiCallID = sendAPIRequest(
      configJSON.getAboutUsApiEndPoint,
      {
        method: configJSON.validationApiMethodType,
      }
    );
  };

  handleAboutUsResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      data: {attributes: AboutUsData}[]
    }
    if (response.data && response.data.length > 0) {
      this.setState({ aboutData: response?.data[0]?.attributes });
    }
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
