import React from "react";
// Customizable Area Start
import FuneralFurtherEditController, {
    configJSON,
    Props
} from "./FuneralFurtherEditController.web";
import {
    Box,
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";

// Customizable Area End

export default class FuneralFurtherEdit extends FuneralFurtherEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            furtherItemsInfo,
            isEdit,
        } = this.state;

        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={furtherItemsInfo}
                    onSubmit={(values) => {
                        this.handleFuneralFurtherItemsDataFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="furtherItemsForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form className="form">
                            <Box className="formSectionBackground">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    <Typography variant="h3" className="infomessageText">
                                        <InfoOutlinedIcon className="infoIcon" />
                                        <span data-test-id="futherItemsTitle">{configJSON.futherItemsTitle}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel 
                                            component="label" 
                                            style={{ fontSize: "18px", color: "#1E293B"}} 
                                            className="formRadioLabel"
                                        >
                                            {configJSON.addedYourCoffineQuestion}
                                        </FormLabel>
                                        <RadioGroup
                                            className="radioGroup"
                                            aria-label="addedYourCoffine"
                                            data-test-id="addedYourCoffine"
                                            name="addedYourCoffine"
                                            value={values.added_your_coffine}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("added_your_coffine", e.target.value);
                                            }
                                            }
                                        >
                                            <FormControlLabel
                                                className="radioLabel"
                                                value="1"
                                                disabled={!isEdit}
                                                control={<StyledRadio />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                className="radioLabel"
                                                value="0"
                                                disabled={!isEdit}
                                                label="No"
                                                control={<StyledRadio />}
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    { values.added_your_coffine === "1" && (
                                        <Grid item xs={12} style={{ marginLeft: "12px" }}>
                                        <FormLabel component="label" className="formRadioLabel">
                                            {configJSON.furtherItemsFieldLabel}
                                        </FormLabel>
                                        <Field
                                            name="items"
                                            data-test-id="items"
                                            className="multilineInput"
                                            as={CustomInput}
                                            disabled={!isEdit}
                                            multiline
                                            placeholder="Example: Bag, photograph, ring"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`items`, e.target.value)
                                            }}
                                            value={values.items}
                                        />
                                    </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Box className="btnWrapper">
                                    <Button
                                        variant="outlined"
                                        className="btnSize"
                                        onClick={() => this.props.handleNextBtnClick("HomePage")}
                                        data-test-id="backBtn"
                                    >
                                        {configJSON.backBtnText}
                                    </Button>
                                    <Button
                                        type="submit"
                                        data-test-id="editDetailsBtn"
                                        className="btnSize"
                                        variant="contained"
                                    >
                                        {isEdit ? configJSON.saveChangesBtnText : configJSON.editDetailsBtnText}
                                    </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End