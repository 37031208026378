import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ISelectOptions } from "./LegalInformationController.web";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IFuneralHairBeauty {
  is_include_final_pdf: boolean;
  delegate_id: string;
  hair_style_type: string;
  hair_style_files: File[];
  require_makeup: string;
  makeup_type: string;
  makeup_files: File[];
  require_nails: string;
  nails_type: string;
  nail_files: File[];
  perfume_name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hairBeautyInfo: IFuneralHairBeauty,
  isLoading: boolean,
  hairStyleFiles: File[],
  makeupFiles: File[],
  nailFiles: File[],
  delegateInCharge: ISelectOptions[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralHairBeautyController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        hairBeautyInfo: {
            is_include_final_pdf: false,
            delegate_id: "",
            hair_style_type: "",
            hair_style_files: [],
            require_makeup: "0",
            makeup_type: "",
            makeup_files: [],
            require_nails: "0",
            nails_type: "",
            nail_files: [],
            perfume_name: "",
        },
        isLoading: false,
        hairStyleFiles: [],
        makeupFiles: [],
        nailFiles: [],
        delegateInCharge: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersListApiId: string = "";
  callAddfuneralHairBeautyInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegateMembersListApiId]: this.handleGetDelegateMembersListApiResponse,
        [this.callAddfuneralHairBeautyInfoApiId]: this.handleAddfuneralHairBeautyInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersListApiId = sendAPIRequest(configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersListApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
     this.props.handleError("something went wrong!"); 
     return; 
   }
 
     const response = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState({ delegateInCharge });
     }
   };

   handleAddfuneralHairBeautyInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false})
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  handleFuneralHairBeautyInfoFormSubmit = async (values: IFuneralHairBeauty) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    this.setState({isLoading: true});

    // Append main form values
    Object.entries(values).forEach(([keyName, value]) => {
      if (keyName === 'hair_style_files') {
        this.appendFiles(values.hair_style_files, this.state.hairStyleFiles, formData, keyName);
      }
      if (keyName === 'makeup_files') {
        this.appendFiles(values.makeup_files, this.state.makeupFiles, formData, keyName);
      }
      if (keyName === 'nail_files') {
        this.appendFiles(values.nail_files, this.state.nailFiles, formData, keyName);
      } 
      else {
        formData.append(`beauty[${keyName}]`, value as string);
      }
    });

    this.callAddfuneralHairBeautyInfoApiId = sendAPIRequest(
      configJSON.addfuneralHairBeautyInformationApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  appendFiles = (files: File[], stateFiles: File[], formData: FormData, keyName: string) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach((file: File) => {
        formData.append(`beauty[${keyName}][]`, file as Blob);
      });
    }
  };

 validationSchema = Yup.object().shape({
    delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
  });

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    attributeName: string
  ) => {
    if (event.target.files) {
        const newFiles = Array.from(event.target.files);
        console.log(newFiles);
        if(attributeName === "hair_style_files"){
            this.setState((prevState) => ({
                hairStyleFiles: [...prevState.hairStyleFiles, ...newFiles],
            }), () => {
                // Set the field value after the state has been updated
                setFieldValue('hair_style_files', [...this.state.hairStyleFiles]);
            });
        }
        else if(attributeName === "makeup_files"){
            this.setState((prevState) => ({
                makeupFiles: [...prevState.makeupFiles, ...newFiles],
            }), () => {
                // Set the field value after the state has been updated
                setFieldValue('makeup_files', [...this.state.makeupFiles]);
            });
        }
        else if(attributeName === "nail_files"){
            this.setState((prevState) => ({
                nailFiles: [...prevState.nailFiles, ...newFiles],
            }), () => {
                // Set the field value after the state has been updated
                setFieldValue('nail_files', [...this.state.nailFiles]);
            });
        }
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };
  // Customizable Area End
}
