import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ShowProductController, {
  Props,
} from "./ShowProductController.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext'; 
import { 
  Box, 
  Grid, 
  Typography, 
  Button,  
  CardMedia, 
  CardContent, 
  Card, 
  Breadcrumbs, 
  FormLabel, 
  Snackbar 
} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import { configJSON } from "./NavigationMenuController";
import GenericModal from "../../../components/src/GenericModal";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import Alert from "@material-ui/lab/Alert";
const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
      },
      h2: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "54px",
      },
      h3: {
        fontFamily:"Lato",
        fontSize:"20px",
        fontWeight: 600,
        color:"#000000",
        lineHeight: "28px",
      },
      h4: {
        fontFamily:"Quattrocento",
        fontSize:"20px",
        fontWeight: 700,
        color:"#0F172A",
        lineHeight: "28px",
      },
      h6: {
        color: "#475569",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
      },
      subtitle2: {
        color: "#0F172A",
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "24px",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      body2: {
        color:"#1E293B",
        fontSize:"16px",
        fontFamily:"Lato",
        fontWeight:400,
        lineHeight: "22px",
      },
    },
  },
});
// Customizable Area End

export default class ShowProduct extends ShowProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
     
    const { productData, selectedTab,isFirstPage,isLastPage } = this.state;
    return (
      <>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box className="container">
            {productData && (
              <Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadCrumb">
                  <Typography variant="h6" onClick={() => this.navigateToShop()} data-test-id="shopBtn">
                    {configJSON.shopBtn}
                  </Typography>
                  <Typography variant="h6" onClick={() => this.handleProductNavigation(productData.category_id)} data-test-id="productNavigate">{productData.category_name}</Typography>
                  <Typography variant="h6" style={{ color: "#0F172A", fontWeight: 600, lineHeight: "24px" }}>{productData.product_name}</Typography>
                </Breadcrumbs> 
              <Grid container className="mainGrid" >
                <Grid item xs={12} md={6} className="imageSection">
                  <Box className="mainImageCard">
                    <CardMedia
                      component="img"
                      image={productData.main_image}
                      alt={productData.product_name}
                      style={{borderRadius:"8px",height:"512px"}}
                    />
                  </Box>
                  <Box className="thumbnailImages">
                    {productData.other_images.map((img: any, index: number) => (
                      <Box key={index} className="thumbnailCard">
                        <CardMedia component="img" image={img.url}  style={{borderRadius:"8px"}}/>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="detailsSection">
                  <Box className="productTitleWrapper">
                    <Typography variant="h1">{productData.product_name}</Typography>
                    <Typography variant="h1" style={{fontWeight: 700}}>${productData.price.toFixed(2)}</Typography>
                  </Box>
                    <Box className="quantitySection">
                      <Typography variant="body1" style={{marginRight: "16px"}}>Quantity:</Typography>
                      <button className="dec" onClick={this.decrement} data-test-id="decrement">-</button>
                      <Box className="counterBox">
                        <Typography className="countNumber">{this.state.count}</Typography>
                      </Box>
                      <button className="inc" onClick={this.increment} data-test-id="increment">+</button>
                    </Box>
                  <Box className="desc">
                  <Box className="headingSection">
                  {["Description", "Product Details", "Additional Information"].map((tab) => (
                    <Typography
                      key={tab}
                      variant="subtitle1"
                      className={selectedTab === tab ? "activeTab" : "tab"}
                      onClick={() => this.handleTabChange(tab)}
                      data-test-id="btnChange"
                    >
                      {tab}
                    </Typography>
                  ))}
                </Box>
                <Typography variant="body2" className="descriptionText">
                  {this.getTabContent()}
                </Typography>
                </Box>
                <Box style={{alignSelf:"center"}}>
                  <Button variant="contained" className="buyNowButton" onClick={() =>this.handleProductNavigationcheckout(productData.id)} data-test-id="buyBtn">
                    {configJSON.buyNowBtnText}
                  </Button>
                </Box>
                </Grid>
              </Grid>
              </Box>
            )}
          </Box>
          <Box>
          <Grid container spacing={4} className="gridContainer">
            <Grid item xs={12} className="gridTitle">
              <Typography data-test-id="popularProductsTitle" style={{ color: "#0F172A", fontWeight: 600, fontSize: "20px", fontFamily: "Lato" }}>{configJSON.popularProductsText}</Typography>
              <Box className="paginationSection">
                <Button variant="text" onClick={this.handlePreviousPage} disabled={isFirstPage} className="paginationButton" data-test-id="prevButton">
                  <ChevronLeftIcon />
                </Button>
                <Button onClick={this.handleNextPage} disabled={isLastPage} className="paginationButton" data-test-id="nextButton">
                  <ChevronRightIcon />
                </Button>
              </Box>
            </Grid>
            {this.state.products.map((product) => (          
              <Grid item xs={12} sm={6} md={3} key={product.id} className="alignCenter">
                <Card className="cardWrapper" onClick={()=> this.showProduct(product.id)} data-test-id="singleuser">
                  <CardMedia
                    component="img"
                    alt={product.attributes.product_name}
                    image={product.attributes.main_image}
                    title={product.attributes.product_name}
                    className="cardMedia"
                  />
                  <CardContent style={{padding:"10px 0px"}}>
                    <Typography gutterBottom variant="h3">
                      {product.attributes.product_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        fontSize:"16px",
                        fontWeight:"",
                        WebkitBoxOrient: 'vertical',
                        
                      }}
                    >
                      {product.attributes.description}
                    </Typography>
                   <Box style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}> 
                    <Typography variant="subtitle2">
                      ${product.attributes.price}
                    </Typography>
                    <Button 
                      style={{ marginRight: "0px" }}
                      variant="outlined" 
                      className="cardButton"  
                      onClick={()=> this.showProduct(product.id)} 
                      data-test-id="singleuser"
                    >
                      {configJSON.buyNowBtnText}
                    </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          </Box>
          <WebFooter handleNavigation={this.handleNavigation} data-test-id="HandelFooter"/>
        </Wrapper>
      </ThemeProvider>
      <GenericModal open={this.state.openDialog} data-test-id="loginModal" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                      <Typography align="center" variant="h4" data-test-id="loginModalTitle">Login</Typography>
                      <Typography align="center" variant="h6">
                        Please Login/Signup to buy the product
                      </Typography>
                    </Box>
                    <Box className="dialogFormWrapper">
                      <Formik
                        initialValues={this.state.credentials}
                        validationSchema={this.validationSchema}
                        onSubmit={(values) => {
                          this.handleLogin(values)
                        }}
                        enableReinitialize={true}
                        data-test-id="loginForm"
                      >
                        {({ values, errors, touched, setFieldValue }) => (
                          <Form>
                            <Box className="emailFieldWrapper">
                              <Box mb={2}>
                                <FormLabel component="label">
                                  Email ID<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Field
                                  data-test-id="email"
                                  className="inputField"
                                  name="email"
                                  size="small"
                                  placeholder="example@gmail.com"
                                  as={CustomInput}
                                  value={values.email}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue("email", e.target.value)
                                  }
                                  error={touched.email && errors.email}
                                  errorMsg={errors.email}
                                />
                              </Box>
                              <Box>
                                <FormLabel component="label">
                                  Password<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Field
                                  data-test-id="password"
                                  className="inputField"
                                  name="password"
                                  size="small"
                                  placeholder="Password"
                                  as={CustomInput}
                                  value={values.password}
                                  isVisibilityIcon={true}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue("password", e.target.value)
                                  }
                                  error={touched.password && errors.password}
                                  errorMsg={errors.password}
                                />
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                              <Button 
                                variant="text" 
                                className="forgotPasswordLink"
                                onClick={() => this.handleNavigation("ForgotPassword")}
                              >
                                Forgot Password?
                              </Button>
                            </Box>
                            <Box className="dialogBtnsWrapper">
                              <Button
                                className="containedBtn"
                                variant="outlined"
                                type="submit"
                                data-test-id="loginModalBtn"
                              >
                                Login
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                      <Typography variant="h6" align="center" style={{ color: "#0F172A", marginTop: "16px" }}>
                        Don’t have an account? 
                      <Button
                        variant="text"
                        data-test-id="loginLink"
                        onClick={() => this.handleNavigation("Signup")}
                        className="signupLink"
                      >
                        Signup
                      </Button>
                    </Typography>
                    </Box>
                  </Box>
                  {this.state.errorMessage && (
                    <Snackbar
                      open={this.state.openSnackbar}
                      autoHideDuration={4000}
                      onClose={this.handleCloseSnackbar}
                      data-test-id="errorSnackbar"
                    >
                        <Alert severity="error"
                            onClose={this.handleCloseSnackbar}
                        >
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        padding: "20px 42px",
        backgroundColor: "#FAFFF3",
      },
      "& .mainGrid": {
        display: "flex",
        justifyContent: "center",
        flexDirection:"row",
      },
      "& .imageSection, & .detailsSection": {
        width: "50%", 
        padding: "15px",
        boxSizing: "border-box",
      },
      "& .imageSection": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius:'8px',
      },
      "& .mainImageCard": {
        width:"90%",
        marginBottom: "5px",
        height:"512px",
        borderRadius:"8px",
      },
      "& .thumbnailImages": {
        display: "flex",
        justifyContent: "space-between",
        borderRadius:"15px",
      },
      "& .thumbnailCard": {
        width: "138px",
        height: "150px",
        padding:"10px",
       
      },
      "& .detailsSection": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding:"17px",
        paddingRight:"25px",
      },
      "& .quantitySection": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
        gap: "4px",
      },
      "& .quantityInput": {
        width: "50px",
        marginLeft: "10px",
      },
      "& .buyNowButton": {
        backgroundColor: "#A190C2",
        color: "#FFFFFF",
        padding: "10px 20px",
        borderRadius:"100px",
        height:"44px",
        width:"384px",
        alignSelf:"center",
        justifySelf:"end",
        textTransform:"none",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
      },
      "& .headingSection": {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #e0e0e0",
        marginBottom: "20px",
      },
      "& .tab, & .activeTab": {
        padding: "10px 20px",
        cursor: "pointer",
        flexGrow: 1,
        textAlign: "center",
      },
      "& .activeTab": {
        borderBottom: "2px solid #6B46C1",
        color: "#6B46C1",
      },
      "& .tab": {
        color: "#64748B",
      },
      "& .descriptionText": {
        fontSize: "18px",
        lineHeight: "26px",
        color:"#1E293B",
        fontFamily:"Lato",
        fontWeight:400,
        Paragraph:"12px",
        paddingRight:"45px",
        marginBottom: "34px",
      },
      "& .desc":{
         marginRight:"30px"
      },
      "& .cardButton":{
        height: "48px",
        borderRadius: "100px",
        width: "158px",
        borderColor: "#9176C4",
        color: "#9176C4",
        margin: "10px",
        boxSizing: "border-box",
        textTransform: "none",
      },"& .paginationSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& .paginationButton": {
        minWidth: "30px",
        minHeight: "30px",
        borderRadius: "50%",
        backgroundColor: "#f8faff",
        color: "#0F172A",
        "&:disabled": {
          color: "#9CA3AF",
        },
      },
      "& .paginationText": {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Lato",
      },
      "& .paginationBox":{
         display:"flex",
         justifyContent:"space-between",
         marginTop:"20px",
      },
      "& .counterBox": {
        width:"83px",
        height: "28px",
        backgroundColor:"#F5F5F5",
        display: "flex",
        justifyContent:"center",
        alignItems: "center",
      },
      "& .countNumber": {
        color: "#000000",
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Lato",
      },
      "& .inc, .dec":{
        width: "28px",
        height: "28px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #f5f5f5",
        padding: "10px 15px",
        cursor: "grab",
        fontSize: "18px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .gridContainer": {
        display:"flex",
        justifyContent:"space-around",
        width:"100%",
        paddingLeft: "5%", 
        marginRight: "50px",
      },
      "& .breadCrumb":{
        padding:"36px 0px 20px 55px",
      },
      "& .productTitleWrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      },
      "& .alignCenter": {
        justifyContent: "center",
        display: "flex",
      },
      "& .cardWrapper": {
        boxShadow: "none",
        marginBottom: '30px',
      },
      "& .cardMedia": {
        borderRadius: "10px", 
        aspectRatio: "1.2",
      },
      "& .gridTitle": {
        margin: "10px 0px",
        display: "flex",
        justifyContent: "space-between",
      },
      "& .paginationComponent": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: "30px",
      },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
  "& .alignCenter": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  "& .dialogFormWrapper": {
    width: "100%",
    padding: "0px 32px",
  },
  "& .dialogBtnsWrapper": {
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    marginTop: "42px",
  },
  "& .nextSpan": {
    color: "black",
    fontWeight: 700,
  },
  "& .containedBtn": {
    width: "372px",
    height: "48px",
    backgroundColor: "#9176C4",
    color: "#FFFFFF",
    borderRadius: "100px",
    padding: "16px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
  },
  "& .dialogContentWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .dialogDescriptionWrapper": {
    maxWidth: "339px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "32px",
  },
  "& .inputField": {
    height: "48px",
    borderRadius: "8px",
  },
  "& .inputField > div": {
    padding: "4px 16px 4px 0px",
    borderRadius: "8px",
  },
  "& .signupLink": {
    textDecoration: "underline", 
    textUnderlineOffset: "4px", 
    textTransform: "none",
    color: "#9176C4",
  },
  "& .forgotPasswordLink": {
    color: '#0F172A', 
    textAlign: 'end', 
    textTransform: "none", 
    textDecoration: 'underline', 
    textUnderlinePosition: "under", 
    fontSize: "16px", 
    fontWeight: 500, 
    fontFamily: "Lato", 
    lineHeight: "24px",
  },
  [theme.breakpoints.down('xs')]: {
    "& .dialogBtnsWrapper": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
  }
}));
// Customizable Area End
