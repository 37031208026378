import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { toast } from "react-toastify";
export interface IFinalPdfResponse {
    profile: Profile;
    members: Members;
    legal_info: LegalInfo;
    pet_info: PetInfo;
    meta: Meta;
}

interface Profile {
    data: ProfileData;
}

interface ProfileData {
    id: string;
    type: string;
    attributes: ProfileAttributes;
}

interface ProfileAttributes {
    id: number;
    user_id: string;
    uuid: string;
    first_name: string;
    middle_name: string | null;
    last_name: string;
    full_name: string;
    email: string;
    nationality: string;
    gender: string;
    gender_name: string | null;
    country_code: string;
    phone_number: string;
    full_phone_number: string;
    date_of_birth: string;
    age: number;
    address_line_1: string;
    address_line_2: string;
    landmark: string | null;
    city: string;
    country: string;
    post_code: string;
    full_address: string;
    partnership_status: string;
    add_partner_is_delegate: boolean;
    partner_detail: PartnerDetail;
    fit_and_well: string;
    issue: string | null;
    reason: string;
    account_id: number;
    photo_url: string;
}

interface PartnerDetail {}

interface Members {
    data: MemberData[];
}

interface MemberData {
    id: string;
    type: string;
    attributes: MemberAttributes;
}

interface MemberAttributes {
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    country_code: string;
    phone_number: string;
    full_phone_number: string;
    email: string;
    gender: string;
    date_of_birth: string;
    nationality: string;
    account_id: number;
    photo_url: string;
    address_line_1: string;
    address_line_2: string;
    landmark: string;
    city: string;
    country: string;
    post_code: string;
    activated: boolean;
    delegate_key: string;
    security_phrase: string;
    created_at: string;
}

interface LegalInfo {
    data: LegalInfoData;
}

interface LegalInfoData {
    id: string;
    type: string;
    attributes: LegalInfoAttributes;
}

interface LegalInfoAttributes {
    id: number;
    delegate_id: number;
    delegate_charge: DelegateCharge;
    have_legal_representative: string;
    legal_representative: LegalRepresentative;
    kin_detail: KinDetail;
    have_will: string;
    will_location: WillLocation;
    have_life_insurance: string;
    policy_details: any[];
    account_id: number;
    media: null | string;
}

interface DelegateCharge {
    name: string;
}

interface LegalRepresentative {
    id: null | number;
    full_name: null | string;
    email: null | string;
    full_phone_number: null | string;
}

interface KinDetail {
    id: null | number;
    full_name: null | string;
    relationship: null | string;
    date_of_birth: null | string;
    email: null | string;
    full_phone_number: null | string;
    address_line_1: null | string;
    address_line_2: null | string;
    landmark: null | string;
    city: null | string;
    post_code: null | string;
    country: null | string;
}

interface WillLocation {
    id: null | number;
    full_name: null | string;
    about: null | string;
    address_line_1: null | string;
    address_line_2: null | string;
    landmark: null | string;
    city: null | string;
    post_code: null | string;
    country: null | string;
}

interface PetInfo {
    data: PetInfoData;
}

interface PetInfoData {
    id: string;
    type: string;
    attributes: PetInfoAttributes;
}

interface PetInfoAttributes {
    id: number;
    have_pets: string;
    pet_number: number;
    pet_details: PetDetail[];
    have_responsible: string;
    caretaker: Caretaker;
    account_id: number;
}

interface PetDetail {
    id: number;
    pet_photo: string;
    pet_name: string;
    date_of_birth: string;
    breed: string;
    requirement: string;
    address_line_1: string;
    address_line_2: string;
    landmark: string | null;
    city: string;
    country: string;
    post_code: string;
    files: FileDetail[];
}

interface FileDetail {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
}

interface Caretaker {}

interface Meta {
    final_pdf_url: null | string;
}

interface IPriviewData {
    name: string;
    profile_photo: string;
    personalInfo: IPersonalInfo;
    myDelegates: IMyDelegates[];
    legalInfo: ILegalInfo;
}

interface IPersonalInfo {
    nationality: string;
    userId: string;
    gender: string;
    dob: string;
}

interface IMyDelegates {
    name: string;
    photo_url: string;
}

interface ILegalInfo {
    attorney: string;
    have_will: string;
    have_life_insurance: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  previewData: IPriviewData;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FinalPdfController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        previewData: {
            name: "Samantha Robert William",
            profile_photo: "",
            personalInfo: {
                nationality: "British",
                userId: "SAM_WILL07",
                gender: "Female",
                dob: "Monday, 17th July 1996",
            },
            myDelegates: [{
                name: "Robert Williams",
                photo_url: "",
            },
            {
                name: "Elizabeth Cooper",
                photo_url: "",
            }],
            legalInfo: {
                attorney: "William Spark",
                have_will: "Yes",
                have_life_insurance: "Yes",
            }
        }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetFinalPdfDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getFinalPdfData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetFinalPdfDataApiId]: this.handleGetFinalPdfDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getFinalPdfData = async() => {
    const token = await getStorageData("token");

    this.callGetFinalPdfDataApiId = sendAPIRequest(
        configJSON.getFinalPdfDataApiEndPoint,
        {
          method: configJSON.validationApiMethodType,
          headers: {
            token,
          },
        }
      );
  };

  handleGetFinalPdfDataApiResponse = (
    responseJSON: Record<string, any>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as IFinalPdfResponse;

    if (response.profile.data && response.members.data.length > 0) {
        const profile = response.profile.data.attributes;
        const members = response.members.data;
        const legal = response.legal_info.data?.attributes;

        let previewData = {
          name: `${profile.first_name}  ${profile.last_name}`,
          profile_photo: profile.photo_url,
          personalInfo: {
            nationality: profile.nationality,
            userId: profile.user_id,
            gender: profile.gender,
            dob: this.formatDate(profile.date_of_birth),
          },
          myDelegates: members.map((member) => {
            return ({
              name: member.attributes.first_name + " " + member.attributes.last_name,
              photo_url: member.attributes.photo_url,
            });
          }),
          legalInfo: legal ? {
            attorney: legal.legal_representative.full_name || "None",
            have_will: legal.have_will,
            have_life_insurance: legal?.have_life_insurance,
          } : {
            attorney: "None",
            have_will: "No",
            have_life_insurance: "No",
          } 
        } as IPriviewData;

        this.setState({ previewData });
    }
  };

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };

    // Format the date and add the ordinal suffix
    return date.toLocaleDateString('en-GB', options).replace(/\b(\d+)(?=\s)/, (day) => {
      const dayNum = parseInt(day, 10);
      let suffix = 'th';

      switch (dayNum % 10) {
        case 1:
          if (dayNum !== 11) suffix = 'st';
          break;
        case 2:
          if (dayNum !== 12) suffix = 'nd';
          break;
        case 3:
          if (dayNum !== 13) suffix = 'rd';
          break;
      }

      return `${day}${suffix}`;
    });
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      toast.error(possibleErrors[0].message);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
