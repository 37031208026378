import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
   const { currentPage, totalProducts,categoryName } = this.state;
  const startProduct = (currentPage - 1) * this.state.fixedproductsPerPage + 1;
  const endProduct = Math.min(currentPage * this.state.fixedproductsPerPage, totalProducts);
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box>
            {this.state.bannerImage && (
              <img
                src={this.state.bannerImage}
                alt="Banner"
                className="bannerImg"
              />
            )}
          </Box>
          <Box className="headingShopBox">
            <Typography variant="h2">{configJSON.pageTitleText}</Typography>
          </Box>
          <Box className="alignCenter"> {this.state.categories.map((category) => (
            <Button
              key={category.id}
              onClick={() => this.handleCategoryClick(category.attributes.id.toString(), category.attributes.name, this.state.currentPage)}
              className={this.state.selectedCategory === category.attributes.id.toString() ? "activeCategoryButton" : "categoryButton"}
              variant="outlined"
              data-test-id="category"
            >
              {category.attributes.name}
            </Button>
          )
          )}
          </Box>

          <Box>
            <Grid container spacing={4} className="gridContainer">
              <Grid item xs={12} className="gridTitle">
                <Typography variant="h2">
                  {categoryName}
                </Typography>
                <Typography variant="h5">
                  {startProduct}-{endProduct} of {totalProducts} results
                </Typography>
              </Grid>
              {this.state.products.map((product) => (
                <Grid item xs={12} sm={6} md={3} key={product.id} className="alignCenter">
                  <Card
                    className="cardWrapper"
                    onClick={() => this.handleProductNavigation(product.id)}  >
                    <CardMedia
                      component="img"
                      alt={product.attributes.product_name}
                      image={product.attributes.main_image}
                      title={product.attributes.product_name}
                      className="cardMedia"
                    />
                    <CardContent style={{ padding: "10px 0px" }}>
                      <Typography gutterBottom variant="h3">
                        {product.attributes.product_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="productDescription"
                      >
                        {product.attributes.description}
                      </Typography>
                      <Box className="cardContentBottom"> 
                        <Typography variant="subtitle1">
                          ${product.attributes.price}
                        </Typography>
                        <Button
                          className="activeCategoryButton"
                          variant="outlined"
                          style={{ marginRight: "0px" }}
                          onClick={() => this.handleProductNavigation(product.id)}
                        >
                          {configJSON.buyNowBtnText}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Pagination
              count={Math.ceil(this.state.totalProducts / this.state.fixedproductsPerPage)}
              page={this.state.currentPage}
              onChange={this.handlePageChange}
              className="pagination"
              data-test-id="productNavigate"
            />
          </Box>
          <WebFooter handleNavigation={this.handleNavigation} data-test-id="HandelFooter" />
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      h2: {
        fontFamily: "Quattrocento",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "24px",
      }, 
      h3: {
        color:"#000000",
        fontFamily:"Lato",
        fontSize:"20px",
        fontWeight: 600,
        lineHeight: "28px",
      },
      h5: {
        color:"#64748B",
        fontSize:"20px",
        fontFamily:"Quattrocento",
        fontWeight:700,
        lineHeight: "28px",
      },
      h6: {
        fontWeight: 500,
      },
      body2: {
        color:"#1E293B",
        fontSize:"16px",
        fontFamily:"Lato",
        fontWeight:400,
        lineHeight: "22px",
      },
      subtitle1: {
        color: "#0F172A",
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "24px",
      },
    },
  }
});

const Wrapper = styled("div")(({ theme }) => ({
  "& .userProfileWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  "& .userProfile": {
    width: 100,
  },
  "& .userDesignation": {
    fontSize: 14,
    color: "#ccc",
  },
  "& .logout": {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  "& .drawerItemIcon": {
    width: 20,
    alignSelf: "center",
    marginLeft: 7,
  },
  "& .headingShopText": {
    fontFamily: "Quattrocento",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
  },
  "& .headingShopBox": {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    marginBottom: "15px",
  },
  "& .categoryButton": {
    height: "48px",
    borderRadius: "100px",
    width: "158px",
    borderColor: "#64748B",
    color: "#64748B",
    margin: "10px",
    boxSizing: "border-box",
    textTransform: "none",
  },
  "& .alignCenter": {
    display: "flex",
    justifyContent: "center",
  },
  "& .activeCategoryButton": {
    height: "48px",
    borderRadius: "100px",
    width: "158px",
    borderColor: "#9176C4",
    color: "#9176C4",
    margin: "10px",
    boxSizing: "border-box",
    textTransform: "none",
  },
  "& .cardWrapper": {
    marginBottom: '30px',
    boxShadow: "none",
  },
  "& .cardMedia": {
    borderRadius: "10px", 
    aspectRatio: "1.2",
  },
  "& .cardContentBottom": {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "space-between", 
    alignItems: "center",
  },
  "& .productDescription": {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
  },
  "& .gridContainer": {
    display: "flex", 
    justifyContent: "space-around", 
    width: "100%", 
    padding: "0 70px", 
    margin: "0px",
  },
  "& .gridTitle": {
    display: "flex", 
    justifyContent: "space-between", 
    margin: "10px 0px",
  },
  "& .bannerImg": {
    width: "100%",
    aspectRatio: 4,
  },
  "& .pagination": {
    display: 'flex', 
    justifyContent: 'center', 
    marginTop: '20px', 
    marginBottom: "30px",
  },

  //override the classes of pagination
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#9176C4",
    backgroundColor: "#fff !important",
  },
}));

// Customizable Area End
