import React from "react";
// Customizable Area Start
import {
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    TextField,
    FormControl,
    FormLabel,
    Box,
    Grid,
    Typography,
    Avatar,
    Button,
    IconButton,
    Switch,
    Badge,
    Menu,
    Fade,
    MenuItem,
    Container,
    FormHelperText
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Field, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import { countries } from "../../navigationmenu/src/UserProfileEditController.web";
import WebFooter from "../../../components/src/Footer.web";
import UserProfileEditController, {
    Props,
    IField,
    configJSON,
    IUserProfileDetails
} from "./UserProfileEditController.web";
import PhoneInput from "react-phone-input-2";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
            },
            h5: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
            },
            h6: {
                color: "#9176C4",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "18px",
            },
            subtitle1: {
                color: "#64748B",
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22px",
            },
            body1: {
                color: "#9176C4",
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "26px",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#64748B",
            },
        },
        MuiStepLabel: {
            root: {
                label: {
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#334155",
                },
                "& [class*=.MuiStepLabel-label-]": {
                    color: "#334155",
                },
            }
        },
        MuiFormLabel: {
            root: {
                color: "#334155",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
            }
        },
        MuiOutlinedInput: {
            root: {
                minHeight: "48px",
                borderRadius: "8px",
                "& .Mui-focused": {
                    "& [class*=MuiOutlinedInput-notchedOutline]": {
                        borderColor: "#94A3B8 !important",
                    }
                },
                "& [class*=MuiOutlinedInput-root].Mui-focused": {
                    borderColor: "#94A3B8",
                }
            },
            input: {
                "&::focused": {
                    borderColor: "#94A3B8 !important",
                },
                "&::placeholder": {
                    fontFamily: "Lato !important",
                    opacity: 1,
                    color: "#94A3B8",
                    fontWeight: 400,
                },
            },
        },
        MuiInputBase: {
            root: {
                minHeight: "48px",
                borderRadius: "8px",
                "& .Mui-focused": {
                    "& [class*=MuiOutlinedInput-notchedOutline]": {
                        borderColor: "#94A3B8 !important",
                    }
                },
            },
        },
        MuiRadio: {
            colorSecondary: {
                '&$checked': {
                    color: "#9176C4",
                },
            }
        },
        MuiButton: {
            outlined: {
                width: "248px",
                height: "48px",
                color: "#9176C4",
                textTransform: "none",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: "100px",
            },
            contained: {
                width: "248px",
                height: "48px",
                color: "#FFFFFF",
                backgroundColor: "#A190C2",
                textTransform: "none",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: "100px",
            },
        },
        MuiDialog: {
            paper: {
                maxWidth: "418px !important",
            },
        },
        MuiSwitch: {
            root: {
                padding: 0,
                borderRadius: "40px",
                width: "48px",
                height: "28px",
            },
            switchBase: {
                padding: "2px",
                color: "#fff",
                '&$checked': {
                    transform: 'translateX(20px)',
                    color: "#fff",
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: "#A190C2 !important",
                        borderColor: "#A190C2 !important",
                    },
                },
            },
            thumb: {
                width: "24px",
                height: "24px",
                boxShadow: 'none',
            },
            track: {
                borderRadius: "40px",
                opacity: 1,
                backgroundColor: "gray",
                color: "fff"
            },
            colorPrimary: {
                '&$checked': {
                    color: "#fff",
                    padding: "2px",
                }
            }
        },
        MuiTooltip: {
            popper: {
                backgroundColor: "none",
            }
        },
    },
}
);
// Customizable Area End

export default class UserProfileEdit extends UserProfileEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getFieldType = (field: IField) => {
        if (
            field.field_type === "integer" ||
            field.field_type === "number" ||
            field.field_type === "float"
        )
            return "number";
        return "text";
    };

    renderField = (field: IField, index: number) => {
        if (
            field.field_type === "text" ||
            field.field_type === "string" ||
            field.field_type === "file" ||
            field.field_type === "textarea" ||
            field.field_type === "number" ||
            field.field_type === "integer" ||
            field.field_type === "float"
        )
            return (
                <div key={index + "renderField"}>
                    <TextField
                        data-test-id={field.name}
                        label={field.title}
                        multiline={field.field_type === "textarea"}
                        value={this.state.profile[field.name]}
                        onChange={(event) =>
                            this.changeFormValue(field.name, event.target.value, field.field_type)
                        }
                        disabled={!field.is_enable}
                        required={field.is_required}
                        type={this.getFieldType(field)}
                        onKeyPress={(event) => this.onKeyPress(event, field)}
                        InputLabelProps={{
                            shrink: true
                        }}
                    // defaultValue='Enter Value'
                    />
                </div>
            );

        if (field.field_type === "date" || field.field_type === "datetime")
            return (
                <div key={index + "renderField"}>
                    <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        {field.title + (field.is_required ? "*" : "")}
                    </div>
                    <div style={{ marginBottom: "5px", marginTop: "5px" }}>
                        <DatePicker
                            data-test-id={field.name}
                            selected={
                                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                                    ? new Date(this.state.profile[field.name] as string)
                                    : null
                            }
                            onChange={(date: Date) => this.changeFormValue(field.name, date, field.field_type)}
                            disabled={!field.is_enable}
                            required={field.is_required}
                        />
                    </div>
                </div>
            );

        if (field.field_type === "checkbox")
            return (
                <div key={index + "renderField"}>
                    <Checkbox
                        data-test-id={field.name}
                        disabled={!field.is_enable}
                        checked={this.state.profile[field.name] as boolean}
                        onChange={(event) =>
                            this.changeFormValue(field.name, event.target.checked, field.field_type)
                        }
                        color='primary'
                    />
                    {field.title}
                </div>
            );
        if (field.field_type === "boolean")
            return (
                <div key={index + "renderField"}>
                    <FormControl>
                        <FormLabel>{field.title}</FormLabel>
                        <RadioGroup
                            row
                            data-test-id={field.name}
                            onChange={(event) => {
                                this.changeFormValue(field.name, event.target.value, field.field_type);
                            }}
                            value={
                                this.state.profile[field.name] === undefined
                                    ? undefined
                                    : String(this.state.profile[field.name])
                            }
                        >
                            <FormControlLabel value={"true"} control={<Radio />} label='True' />
                            <FormControlLabel value={"false"} control={<Radio />} label='False' />
                        </RadioGroup>
                    </FormControl>
                </div>
            );
    };
    renderPhoneInput = (
        values: FormikValues,
        errors: FormikErrors<IUserProfileDetails>,
        touched: FormikTouched<IUserProfileDetails>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <>
                <FormLabel component="label">{configJSON.phoneNumber}<span style={{ color: "red" }}>*</span></FormLabel>
                <Box>
                    <PhoneInput
                        data-test-id="phoneNumber"
                        country={'gb'}
                        value={values.countryCode + values.phoneNumber}
                        onChange={
                            (value: string, country: any) => this.handlePhoneInputChange(value, country, setFieldValue)
                        }
                        inputStyle={{
                            width: '300px',
                            height: '48px',
                            borderRadius: '8px',
                            border: (touched.phoneNumber && errors.phoneNumber) ? '1px solid red' : '1px solid #e0e0e0',
                            padding: '10px 45px',
                        }}
                        containerStyle={{
                            width: '300px',
                            height: '48px',
                            margin: '5px 0',
                        }}
                        buttonStyle={{
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            border: (touched.phoneNumber && errors.phoneNumber) ? '1px solid red' : '1px solid #e0e0e0',
                            borderRightColor: '#e0e0e0',
                            padding: '2px',
                        }}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: false,
                        }}
                    />
                    {touched.phoneNumber && errors.phoneNumber &&
                        <FormHelperText error className="errorMsg">
                            <InfoOutlinedIcon className="errorIcon" />
                            <span>{errors.phoneNumber}</span>
                        </FormHelperText>
                    }
                </Box>
            </>
        );
    }
    renderUserDetailsForm = (
        values: FormikValues,
        errors: FormikErrors<IUserProfileDetails>,
        touched: FormikTouched<IUserProfileDetails>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.firstNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="firstName"
                        name="firstName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your first name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("firstName", e.target.value)
                        }
                        value={values.firstName}
                        error={touched.firstName && errors.firstName}
                        errorMsg={errors.firstName}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.middleNameLabel}</FormLabel>
                    <Field
                        data-test-id="middleName"
                        name="middleName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your middle name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("middleName", e.target.value)
                        }
                        value={values.middleName}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.lastNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="lastName"
                        name="lastName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your last name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("lastName", e.target.value)
                        }
                        value={values.lastName}
                        error={touched.lastName && errors.lastName}
                        errorMsg={errors.lastName}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.nationaliti}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="nationality"
                        name="nationality"
                        className="selectField"
                        as={CustomSelect}
                        placeholder="Select your Nationality"
                        options={this.state.nationalities}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("nationality", e.target.value)
                        }
                        value={values.nationality}
                        error={touched.nationality && errors.nationality}
                        errorMsg={errors.nationality}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.genderLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="gender"
                        name="gender"
                        className="selectField"
                        as={CustomSelect}
                        placeholder="Select gender"
                        options={[
                            { value: 1, name: "Male" },
                            { value: 2, name: "Female" },
                            { value: 3, name: "Other" },
                        ]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("gender", e.target.value)
                        }
                        value={values.gender}
                        error={touched.gender && errors.gender}
                        errorMsg={errors.gender}
                    />
                </Grid>
                {values.gender === "Other" && (
                    <Grid item lg={4}>
                        <FormLabel component="label">{configJSON.genderNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                        <Field
                            data-test-id="genderName"
                            name="gender name"
                            className="inputField"
                            as={CustomInput}
                            placeholder="Enter your gender"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("genderName", e.target.value)
                            }
                            value={values.genderName}
                            error={touched.genderName && errors.genderName}
                            errorMsg={errors.genderName}
                        />
                    </Grid>
                )}
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.emailIdLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="email"
                        name="email"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="example@gmailcom"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("email", e.target.value)
                        }
                        value={values.email}
                        error={touched.email && errors.email}
                        errorMsg={errors.email}
                    />
                </Grid>
                <Grid item lg={4}>
                    {this.renderPhoneInput(values, errors, touched, setFieldValue)}
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="dob"
                        name="dob"
                        className="inputField"
                        size="small"
                        as={CustomDatePicker}
                        placeholder="DD/MM/YYYY"
                        ageRestriction={18}
                        endIcon={
                            this.calenderIcon
                        }
                        onChange={(date: Date) => {
                            this.handleDateChange(setFieldValue, date)
                        }
                        }
                        value={values.dob}
                        error={touched.dob && errors.dob}
                        errorMsg={errors.dob}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.ageLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="age"
                        name="age"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter age"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("age", e.target.value)
                        }
                        value={values.age}
                        error={touched.age && errors.age}
                        errorMsg={errors.age}
                    />
                </Grid>
            </Grid>
        )
    }
    renderAddressForm = (
        values: FormikValues,
        errors: FormikErrors<IUserProfileDetails>,
        touched: FormikTouched<IUserProfileDetails>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {

        const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
            fill: "#A190C2",
        });

        return (
            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Address line 1<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="addressLine1"
                        name="addressLine1"
                        className="inputField"
                        size="small"
                        startIcon={styledMyLocationTwoToneIcon}
                        as={CustomInput}
                        placeholder="House/Flat no."
                        value={values.addressLine1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("addressLine1", e.target.value)
                        }
                        errorMsg={errors.addressLine1}
                        error={touched.addressLine1 && errors.addressLine1}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Address line 2<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        name="addressLine2"
                        data-test-id="addressLine2"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Street and Locality"
                        value={values.addressLine2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("addressLine2", e.target.value)
                        }
                        error={touched.addressLine2 && errors.addressLine2}
                        errorMsg={errors.addressLine2}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Landmark (if required)
                    </FormLabel>
                    <Field
                        data-test-id="landmark"
                        name="landmark"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Landmark"
                        value={values.landmark}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("landmark", e.target.value)
                        }
                        errorMsg={errors.landmark}
                        error={touched.landmark && errors.landmark}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Town/City<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="city"
                        name="city"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="City"
                        value={values.city}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("city", e.target.value)
                        }
                        errorMsg={errors.city}
                        error={touched.city && errors.city}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Country<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="country"
                        name="country"
                        className="selectField"
                        as={CustomSelect}
                        placeholder="Country"
                        options={countries}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("country", e.target.value)
                        }}
                        value={values.country}
                        errorMsg={errors.country}
                        error={touched.country && errors.country}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">
                        Post Code<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="postcode"
                        name="postcode"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Post Code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("postcode", e.target.value)
                        }
                        value={values.postcode}
                        errorMsg={errors.postcode}
                        error={touched.postcode && errors.postcode}
                    />
                </Grid>
            </Grid>
        )
    }
    renderDelegateForm = (
        values: FormikValues,
        errors: FormikErrors<IUserProfileDetails>,
        touched: FormikTouched<IUserProfileDetails>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {

        const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
            fill: "#A190C2",
        });
        return (
            <Grid container spacing={2}>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.firstNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerFirstName"
                        name="partnerFirstName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your partner's first name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerFirstName", e.target.value)
                        }
                        value={values.partnerFirstName}
                        error={touched.partnerFirstName && errors.partnerFirstName}
                        errorMsg={errors.partnerFirstName}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.middleNameLabel}</FormLabel>
                    <Field
                        data-test-id="partnerMiddleName"
                        name="partnerMiddleName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your partner's middle name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerMiddleName", e.target.value)
                        }
                        value={values.partnerMiddleName}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.lastNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerLastName"
                        name="partnerLastName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter your partner's last name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerLastName", e.target.value)
                        }
                        value={values.partnerLastName}
                        error={touched.partnerLastName && errors.partnerLastName}
                        errorMsg={errors.partnerLastName}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.nationality}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerNationality"
                        name="partnerNationality"
                        className="selectField"
                        as={CustomSelect}
                        placeholder="Select nationality"
                        options={this.state.nationalities}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerNationality", e.target.value)
                        }
                        value={values.partnerNationality }
                        errorMsg={errors.partnerNationality}
                        error={touched.partnerNationality && errors.partnerNationality}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="label">{configJSON.genderLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="parnerGender"
                        name="partnerGender"
                        className="selectField"
                        placeholder="Select gender"
                        as={CustomSelect}
                        options={[
                            { value: 1, name: "Male" },
                            { value: 2, name: "Female" },
                            { value: 3, name: "Other" },
                        ]}
                        value={values.partnerGender}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerGender", e.target.value)
                        }
                        errorMsg={errors.partnerGender}
                        error={touched.partnerGender && errors.partnerGender}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.addressLine1}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerAddressLine1"
                        name="partnerAddressLine1"
                        className="inputField"
                        size="small"
                        startIcon={styledMyLocationTwoToneIcon}
                        as={CustomInput}
                        placeholder="House/Flat no."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerAddressLine1", e.target.value)
                        }
                        value={values.partnerAddressLine1}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.addressLine2}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerAddressLine2"
                        name="partnerAddressLine2"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Street and Locality"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerAddressLine2", e.target.value)
                        }
                        value={values.partnerAddressLine2}
                        error={touched.partnerAddressLine2 && errors.partnerAddressLine2}
                        errorMsg={errors.partnerAddressLine2}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.landmarkLabel}</FormLabel>
                    <Field
                        data-test-id="partnerLandmark"
                        name="partnerLandmark"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Landmark"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerLandmark", e.target.value)
                        }
                        value={values.partnerLandmark}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.townCityLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerCity"
                        name="partnerCity"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Town/City"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerCity", e.target.value)
                        }
                        value={values.partnerCity}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="partnerCountry"
                        className="selectField"
                        name="partnerCountry"
                        as={CustomSelect}
                        placeholder="Country"
                        options={countries}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("partnerCountry", e.target.value)
                        }}
                        value={values.partnerCountry}
                        error={touched.partnerCountry && errors.partnerCountry}
                        errorMsg={errors.partnerCountry}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">{configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="partnerPostcode"
                        name="partnerPostcode"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Post Code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("partnerPostcode", e.target.value)
                        }
                        value={values.partnerPostcode}
                        error={touched.partnerPostcode && errors.partnerPostcode}
                        errorMsg={errors.partnerPostcode}
                    />
                </Grid>
            </Grid>
        );
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            stepsMyProfile,
        } = this.state;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="containerBoxs">
                        <Box>
                            <BreadcrumbStepper data-test-id="breadcrumbStepper" steps={stepsMyProfile} onStepClick={(path) => this.handleNavigation(path)} />
                        </Box>
                        </Box>
                        <Box className="containerProfile">

                            <Container>
                                <Box mt={3} mr={7}>
                                    <Formik
                                        initialValues={this.state.userDetails}
                                        validationSchema={this.validationSchema(this.state.phoneNumberLength)}
                                        onSubmit={(values) => {
                                            this.handleProfileFormSubmit(values)
                                        }}
                                        enableReinitialize={true}
                                        data-test-id="createProfileForm"
                                    >
                                        {({ values, errors, touched, setFieldValue }) => (
                                            <Form>
                                                <Box display="flex"
                                                    justifyContent="center"
                                                    alignItems="center">
                                                    <input
                                                        id="profilePicInput"
                                                        data-test-id="profilePic"
                                                        name="profile_pic"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={(event) => this.handleProfileImg(event, setFieldValue)}
                                                    />
                                                    <label htmlFor="profilePicInput">
                                                        <IconButton component="span" style={{ paddingLeft: '0' }}>
                                                            {this.state.profileImage || values.profileImage ? (
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    className="profilePicBadge"
                                                                    badgeContent={
                                                                        <>
                                                                            <div onClick={(e: React.MouseEvent<HTMLInputElement>) => this.handleProfilePicEditMenu(e)}>
                                                                                <IconButton style={{ padding: "0" }}>
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <rect width="24" height="24" rx="12" fill="#9176C4" />
                                                                                        <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                                                                                        <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                                                                                        <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </div>
                                                                            <Menu
                                                                                id="fade-menu"
                                                                                anchorEl={this.state.anchorEl}
                                                                                keepMounted
                                                                                getContentAnchorEl={null}
                                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                                                open={Boolean(this.state.anchorEl)}
                                                                                onClose={this.handleProfilePicEditMenuClose}
                                                                                TransitionComponent={Fade}
                                                                            >
                                                                                <MenuItem>
                                                                                    <input
                                                                                        id="changeProfilePicInput"
                                                                                        data-test-id="change-image"
                                                                                        name="profile_pic_change"
                                                                                        type="file"
                                                                                        accept="image/*"
                                                                                        onChange={(event) =>
                                                                                            this.handleProfileImg(event, setFieldValue)
                                                                                        }
                                                                                        style={{ display: 'none' }}
                                                                                    />
                                                                                    <label htmlFor="changeProfilePicInput" style={{ display: "flex" }}>
                                                                                        <Box>
                                                                                            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none">
                                                                                                <path stroke="#292D32" d="M9.94501 2.70041L3.78751 9.21791C3.55501 9.46541 3.33001 9.95291 3.28501 10.2904L3.00751 12.7204C2.91001 13.5979 3.54001 14.1979 4.41001 14.0479L6.82501 13.6354C7.16251 13.5754 7.63501 13.3279 7.86751 13.0729L14.025 6.55541C15.09 5.43041 15.57 4.14791 13.9125 2.58041C12.2625 1.02791 11.01 1.57541 9.94501 2.70041Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                <path d="M8.91748 3.78711C9.23998 5.85711 10.92 7.43961 13.005 7.64961" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                <path d="M2.25 16.5H15.75" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            </svg>
                                                                                        </Box>
                                                                                        &nbsp;{configJSON.changePicture}
                                                                                    </label>
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => this.handleRemoveProfilePic(setFieldValue)}>
                                                                                    <Box>
                                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M15.75 4.48438C13.2525 4.23687 10.74 4.10938 8.235 4.10938C6.75 4.10938 5.265 4.18438 3.78 4.33438L2.25 4.48438" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M14.1375 6.85547L13.65 14.408C13.5675 15.5855 13.5 16.5005 11.4075 16.5005H6.59255C4.50005 16.5005 4.43255 15.5855 4.35005 14.408L3.86255 6.85547" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M7.74756 12.375H10.2451" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M7.125 9.375H10.875" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </svg>
                                                                                    </Box>
                                                                                    &nbsp;{configJSON.removePicture}</MenuItem>
                                                                            </Menu>
                                                                        </>
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        className="profilePicAvatar"
                                                                        style={{ margin: '5px 0 10px 0', width: '62px', height: '62px' }}
                                                                        alt={values.firstName || ''}
                                                                        src={(this.state.profileImage || values.profileImage) as string}
                                                                    />
                                                                </Badge>
                                                            ) : (
                                                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" fill="#F8FFF0" />
                                                                    <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" stroke="#ACCC86" />
                                                                    <path
                                                                        d="M33.12 24L34.95 26H39V38H23V26H27.05L28.88 24H33.12ZM34 22H28L26.17 24H23C21.9 24 21 24.9 21 26V38C21 39.1 21.9 40 23 40H39C40.1 40 41 39.1 41 38V26C41 24.9 40.1 24 39 24H35.83L34 22ZM31 29C32.65 29 34 30.35 34 32C34 33.65 32.65 35 31 35C29.35 35 28 33.65 28 32C28 30.35 29.35 29 31 29ZM31 27C28.24 27 26 29.24 26 32C26 34.76 28.24 37 31 37C33.76 37 36 34.76 36 32C36 29.24 33.76 27 31 27Z"
                                                                        fill="#ACCC86"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </IconButton>
                                                    </label>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    {this.renderUserDetailsForm(values, errors, touched, setFieldValue)}
                                                    <Grid item style={{ padding: '16px 0px 16px' }} xs={12}>
                                                        <Typography variant="h6" className="addressTitle">
                                                            <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.addressTitle}
                                                        </Typography>
                                                    </Grid>
                                                    {this.renderAddressForm(values, errors, touched, setFieldValue)}
                                                    <Grid item style={{ padding: '16px 0px 12px' }} xs={12}>
                                                        <Typography variant="body1">
                                                            {configJSON.profileCreationReason}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className="paddingLeft" xs={12}>
                                                        <FormLabel component="label">{configJSON.reasonFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                        <Field
                                                            data-test-id="reason"
                                                            name="reason"
                                                            size="medium"
                                                            multiline
                                                            style={{ minHeight: "84px" }}
                                                            as={CustomInput}
                                                            placeholder="Enter your reason"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                setFieldValue("reason", e.target.value)
                                                            }
                                                            value={values.reason}
                                                            error={touched.reason && errors.reason}
                                                            errorMsg={errors.reason}
                                                        />
                                                    </Grid>
                                                    <Grid item className="paddingLeft" xs={12}>
                                                        <Typography variant="body1">
                                                            {configJSON.partnerDetails}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className="paddingLeft" lg={6}>
                                                        <FormLabel component="label">
                                                            {configJSON.partnershipStatusFieldLabel}<span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                        <Field
                                                            data-test-id="partnershipStatus"
                                                            name="partnershipStatus"
                                                            className="selectField"
                                                            as={CustomSelect}
                                                            placeholder="Select partnership status"
                                                            options={[
                                                                { value: 1, name: "Single" },
                                                                { value: 2, name: "Engaged" },
                                                                { value: 3, name: "Married" },
                                                                { value: 4, name: "Widowed" },
                                                                { value: 5, name: "Civil Partnership" },
                                                                { value: 6, name: "Casual" },
                                                            ]}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                setFieldValue("partnershipStatus", e.target.value)
                                                            }
                                                            value={values.partnershipStatus}
                                                            error={touched.partnershipStatus && errors.partnershipStatus}
                                                            errorMsg={errors.partnershipStatus}
                                                        />
                                                    </Grid>
                                                    {values.partnershipStatus === "Married" && (
                                                        <Grid item className="paddingLeft" xs={12}>
                                                            <Box className="delegateMemberWrapper">
                                                                <Typography align="center" className="delegateMemberLabel">
                                                                    <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.addyYourPartnerTitle}
                                                                </Typography>
                                                                <Switch
                                                                    data-test-id="partnerSwitch"
                                                                    color="primary"
                                                                    checked={this.state.switchChecked}
                                                                    onChange={() =>
                                                                        this.handleSwitchChange(setFieldValue)
                                                                    }
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {values.partnershipStatus === "Married" &&
                                                        this.state.switchChecked &&
                                                        (this.renderDelegateForm(values, errors, touched, setFieldValue))}
                                                    <Grid item className="paddingLeft" xs={12}>
                                                        <Typography variant="body1">
                                                            {configJSON.healthAndFitness}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="healthAndFitness paddingLeft">
                                                        <FormLabel component="label" className="formRadioLabel">
                                                            {configJSON.areYouFitAndWell}
                                                        </FormLabel>
                                                        <RadioGroup
                                                            data-test-id="healthAndFitness"
                                                            aria-label="healthAndFitness"
                                                            name="healthAndFitness"
                                                            value={values.isHealthy}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                setFieldValue("isHealthy", e.target.value)
                                                            }
                                                            className="radioGroup"
                                                        >
                                                            <FormControlLabel
                                                                value="Yes"
                                                                control={<Radio />}
                                                                label="Yes"
                                                                className="radioLabel"
                                                            />
                                                            <FormControlLabel
                                                                value="No"
                                                                control={<Radio />}
                                                                label="No"
                                                                className="radioLabel"
                                                            />
                                                        </RadioGroup>
                                                    </Grid>
                                                    {values.isHealthy === "No" && (
                                                        <Grid item className="paddingLeft" xs={12}>
                                                            <FormLabel component="label">{configJSON.issueFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                            <Field
                                                                data-test-id="issue"
                                                                name="issue"
                                                                size="medium"
                                                                style={{ minHeight: "84px" }}
                                                                multiline
                                                                as={CustomInput}
                                                                placeholder="Enter your issue"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    setFieldValue("issue", e.target.value)
                                                                }
                                                                value={values.issue}
                                                                error={touched.issue && errors.issue}
                                                                errorMsg={errors.issue}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item className="paddingLeft" xs={12}>
                                                        <Box className="btnWrapper">
                                                            <Button
                                                                data-test-id="skipForNow"
                                                                variant="outlined"
                                                                className="btnSize"
                                                             onClick={() => this.handleBack()}
                                                            >
                                                                {configJSON.cancelBtnText}
                                                            </Button>
                                                            <Button
                                                                data-test-id="createProfileBtn"
                                                                variant="contained"
                                                                className="btnSize"
                                                                type="submit"
                                                            >
                                                                {configJSON.saveChangesProfileBtnText}
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                <GenericModal open={this.state.openDialog} data-test-id="data-edited" onClose={this.handleCloseDialog}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="dialogContentWrapper">
                                <Box className="dialogDescriptionWrapper">
                                    <img alt="success"
                                        src={rightTickImg}
                                        style={{
                                            width: "56px",
                                            height: "56px",
                                            margin: "0px auto"
                                        }} />
                                    <Typography variant="body2" align="center">
                                        {configJSON.dialogMessageText}
                                    </Typography>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .rightPanelWrapper": {
        maxHeight: "100vh",
        overflowY: "auto",
        boxSizing: "border-box",
    },
    "& .container": {
        minHeight: '100vh',
    },
    "& .containerProfile": {
        display: "flex",
      
    },
    "& .containerBoxs": {
        padding: "50px 72px",
    },
    "& .stepper": {
        padding: "0",
        fontFamily: "Lato !important",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
    },
    "& .stepperWrapper": {
        width: "415px",
    },
    "& .stepLabel": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
    },
    "& .createProfileFormTitleWrapper": {
        width: "327px",
        height: "59",
        margin: "16px 0px",
    },
    "& .profilePicAvatar": {
        width: "62px",
        height: "62px",
    },
    "& .ErrorOutlineIcon": {
        color: "#9176C4",
        "&:hover": {
            fill: "#9176C4",
        }
    },
    "& .profilePic": {
        marginBottom: "16px",
    },
    "& .calenderIcon": {
        cursor: "grab",
    },
    "& .inputField": {
        height: "48px",
        width: "300px",
        borderRadius: "8px",
    },
    "& .selectField": {
        borderRadius: "8px",
        margin: "5px 0px",
        display: "block",
        height: "48px",
        width: "300px",
    },
    "& .addressTitle": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        maxWidth: "570px",
    },
    "& .healthAndFitness": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    "& .formRadioLabel": {
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
    },
    "& .radioGroup": {
        display: 'flex',
        flexDirection: 'row',
    },
    "& .radioLabel": {
        color: '#000',
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#9176C4',
        },
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
    },
    "& .delegateMemberWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .delegateMemberLabel": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#1E293B",
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .btnSize": {
        width: "240px",
        height: "48px",
    },
    "& .paddingLeft": {
        paddingLeft: "0px",
    },
    //PhoneInput CSS override
    "& .react-tel-input .flag-dropdown.open .selected-flag": {
        backgroundColor: "unset",
    },
    "& .react-tel-input .selected-flag:hover": {
        backgroundColor: "unset",
    },
    "& .errorIcon": {
        width: "16px",
        height: "16px",
        marginRight: "2px",
        marginBottom: "3px",
    },
    "& .errorMsg": {
        color: "#DC2626",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
    },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .dialogWrapper": {
        maxWidth: "418px !important",
    },
    "& .btnWrapper": {
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        marginTop: "32px",
    },
    "& .alignCenter": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
    },
    "& .emailSpan": {
        fontWeight: 700,
        fontColor: "Black",
    },
    "& .outlinedBtn": {
        heigh: "56px",
        width: "192px",
        color: "#9176C4",
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Lato",
        borderRadius: "100px",
        textTransform: "none",
    },
    "& .containedBtn": {
        width: "192px",
        heigh: "56px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Lato",
        borderRadius: "100px",
        textTransform: "none",
    },
    "& .dialogContentWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .dialogDescriptionWrapper": {
    maxWidth: "339px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "32px",
  },
    "& .rightTickImg": {
        height: "72px",
        width: "72px",
    },
    "& .infoPaper": {
        padding: "10px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "293px",
        height: "196px",
    },
    [theme.breakpoints.down('xs')]: {
        "& .btnWrapper": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        }
    }
}));
// Customizable Area End
