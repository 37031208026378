import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IStep } from "./LegalDataEditController.web";

// Customizable Area Start
interface ITabList {
  key: string, 
  value: string,
  disabled: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTab: string;
  selectedTabIndex: number;
  errorMessage: string;
  isError: boolean;
  tabList: ITabList[];
  openDialog: boolean;
  steps: IStep[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralEditController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        selectedTab:"funeral_basics",
        errorMessage: "",
        isError: false,
        selectedTabIndex: 0,
        tabList:[
          {
              key: "funeral_basics",
              value: "Funeral basics",
              disabled: false,
          },
          {
              key: "attire",
              value: "Attire",
              disabled: false,
          },
          {
              key: "hair_and_beauty",
              value: "Hair and Beauty",
              disabled: false,
          } ,
          {
              key: "further_items",
              value: "Further Items",
              disabled: false,
          },
        ],
        steps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Funeral Data', path: 'FuneralData' },
          ],
        openDialog: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  onTabClick = (key: string, index: number) =>{
    this.setState({
      selectedTab: key, 
      selectedTabIndex: index
    });
  }

  onErrorHit = (errorMessage: string) =>{
    if(errorMessage){
      this.setState({
        isError: true, 
        errorMessage
      });
    }
  };

  onSuccessClick = () =>{
    this.setState({openDialog: true})
  }

  onNextSectionBtnClick = (path: string) =>{
    let {selectedTabIndex, tabList} = this.state
    let nextTabIndex = selectedTabIndex + 1;
    
    while (nextTabIndex < tabList.length && tabList[nextTabIndex].disabled) {
      nextTabIndex++;
    }
    if (nextTabIndex < tabList.length) {
      let updatedTabList = [...this.state.tabList]
      updatedTabList[selectedTabIndex] = {
        ...updatedTabList[selectedTabIndex],
        disabled: true
      };  
      this.setState({openDialog: false, tabList: updatedTabList})
     this.onTabClick(tabList[nextTabIndex].key , nextTabIndex)
    }else{
      this.handleNavigation(path)
    }
  }

  getInitialActiveTab = () => {
    const firstEnabledTabKey = this.state.tabList.find(tab => !tab.disabled)?.key || "funeral_basics";
    this.setState({ selectedTab: firstEnabledTabKey });
  };

  handleCloseSnackbar = ()=>{
    this.setState({
      errorMessage: "", 
      isError: false
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  // Customizable Area End
}