import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IStep } from "../../multipageforms/src/LegalDataEditController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";

// Customizable Area Start
interface ITabList {
  key: string, 
  value: string,
  disabled: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTab: string;
  selectedTabIndex: number;
  errorMessage: string;
  isError: boolean;
  tabList: ITabList[];
  openDialog: boolean;
  steps: IStep[];
  selectedButton:boolean;
  selectedEditButton:boolean;
  showUpdateedID:string;
  ShowsProfileApiData:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {

 callShowsProfileApiId:string ="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        selectedTab:"personal information",
        errorMessage: "",
        isError: false,
        selectedTabIndex: 0,
        tabList:[
          {
              key: "personal information",
              value: "Personal Information",
              disabled: false,
          },
          {
              key: "partnership details",
              value: "Partnership Details",
              disabled: false,
          },
          {
              key: "health and fitness",
              value: "Health and Fitness",
              disabled: false,
          } ,
          {
              key: "reason for profile creation",
              value: "Reason for Profile Creation",
              disabled: false,
          },
        ],
        steps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'Account', path: 'HomePage' },
            { label: 'My Profile', path: 'MyProfile' },
          ],
        openDialog: false,
        selectedButton:false,
        selectedEditButton:true,
        showUpdateedID:"",
        ShowsProfileApiData:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getShowsProfileApi()
    let showUpdateedID = await getStorageData("showUpdateedID");
    this.setState({
        showUpdateedID:showUpdateedID
    },()=>{

    })
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.callShowsProfileApiId) {
       this.setState({
        ShowsProfileApiData:responseJSON.data.attributes
       })
      }
    
    // Customizable Area End
  }

  onTabClick = (key: string, index: number) =>{
    console.log(key, index,"check140")
    this.setState({
      selectedTab: key, 
      selectedTabIndex: index
    });
  }

  getInitialActiveTab = () => {
    const firstEnabledTabKey = this.state.tabList.find(tab => !tab.disabled)?.key || "personal information";
    this.setState({ selectedTab: firstEnabledTabKey });
  };

  handleCloseSnackbar = ()=>{
    this.setState({
      errorMessage: "", 
      isError: false
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };
  changePasswordBtnText =()=>{
    this.setState({ selectedButton: true,selectedEditButton:false });
    this.navigateToChangePasswordProfile()
  }
  editProfileBtn =()=>{
    this.setState({ selectedEditButton:true,selectedButton: false });
    this.navigateToEditProfile()
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  navigateToEditProfile =  ()=>{
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileEdit");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
  }
  navigateToChangePasswordProfile = ()=>{
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "ChangePassword");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
  }
  getShowsProfileApi =async()=>{
    
    const token = await getStorageData("token");
    this.callShowsProfileApiId = sendAPIRequest(
     `/bx_block_customisableuserprofiles/user_profiles/${this.state.showUpdateedID}`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  }
  // Customizable Area End
}