import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import VoiceNotesController, {
    configJSON,
    IVoiceNotes,
    Props,
} from "./VoiceNotesController.web";
import { FileDrop } from "react-file-drop";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    Switch,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar
} from "@material-ui/core";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik } from "formik";
import { DialogWrapper, theme } from "./LegalInformation.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import GenericModal from "../../../components/src/GenericModal";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import { audioIcon, uploadMediaIcon } from "./assets";
import { IMedia } from "./LegalInformationController.web";
// Customizable Area End

export default class VoiceNotes extends VoiceNotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    isIMedia(file: File | IMedia): file is IMedia {
        return (file as IMedia).content_type !== undefined;
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            letters,
            openDialog,
        } = this.state;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} progress={72} />
                        <Box className="container">
                            <Box className="AdditionalInformationSidebar">
                                <AdditionalInformationSidebar navigation={this.props.navigation} />
                            </Box>
                            <Box className="voiceNotesInfoWrapper">
                                <Box className="pageTitle">
                                    <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                                        {configJSON.voiceNotesPageTitle}
                                    </Typography>
                                    <Typography variant="h3" className="messageText">
                                        {configJSON.voiceNotesPageSubtitle}
                                    </Typography>
                                </Box>
                                <Box className="voiceNotesFormWrapper">
                                    <Formik
                                        initialValues={letters}
                                        validationSchema={this.validationSchema}
                                        onSubmit={(values) => {
                                            this.handleVoiceNotesInformationsFormSubmit(values)
                                        }}
                                        enableReinitialize={true}
                                        data-test-id="voiceNotesForm"
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form className="voiceFormDataContainer">
                                                <FieldArray name="voiceNotes" data-test-id="fieldArray">
                                                    {({ remove, push }) => (
                                                        values.voiceNotes.map((voiceNotes: IVoiceNotes, index: number) => {
                                                            return (
                                                                <Grid container spacing={2} className="formSectionBackground">
                                                                    <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                                                        <Typography data-test-id="fieldArrayTitle" variant="h6">Delegate In-charge of delivering letter</Typography>
                                                                        {this.getNotesList(index, values.voiceNotes.length) && (
                                                                            <Button
                                                                                className="addLettersBtn"
                                                                                data-test-id="addLettersBtn"
                                                                                onClick={() => push({
                                                                                    delegate_id: values.voiceNotes[0].delegate_id,
                                                                                    recipient: "",
                                                                                    letter: "",
                                                                                    have_special_day: false,
                                                                                    special_day: "",
                                                                                    files: [],
                                                                                })}
                                                                            >
                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                {configJSON.addLettersBtnText}
                                                                            </Button>
                                                                        )}
                                                                        {index !== 0 && (
                                                                            <Button
                                                                                data-test-id="removeBtn"
                                                                                className="addLettersBtn"
                                                                                onClick={() => remove(index)}>
                                                                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                {configJSON.removeBtnText}
                                                                            </Button>)}
                                                                    </Grid>
                                                                    <Grid item  xs={12} sm={6} md={4}>
                                                                        <FormLabel component="label">
                                                                            {configJSON.delegateFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                        </FormLabel>
                                                                        <Field
                                                                            data-test-id="delegateId"
                                                                            className="selectField"
                                                                            name="delegateId"                                                                            
                                                                            as={CustomSelect}
                                                                            setValue={true}
                                                                            disabled= {index !== 0}
                                                                            placeholder="Select delegate user"
                                                                            options={this.state.delegateInCharge}
                                                                            value={values.voiceNotes[0].delegate_id}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue(`voiceNotes.${index}.delegate_id`, e.target.value)
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`voiceNotes.${index}.delegate_id`} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={4}>
                                                                        <FormLabel component="label">{configJSON.recipientFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                                        <Field
                                                                            data-test-id="recipientOfLetter"
                                                                            className="inputField"
                                                                            name="recipientOfLetter"
                                                                            as={CustomInput}
                                                                            size="small"
                                                                            placeholder="Enter a full name of the recipient"
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue(`voiceNotes.${index}.recipient`, e.target.value)
                                                                            }}
                                                                            value={voiceNotes.recipient}
                                                                        />
                                                                        <ErrorMessage name={`voiceNotes.${index}.recipient`} />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box className="haveSpecialDayWrapper">
                                                                            <Typography align="center" className="haveSpecialDayLabel">
                                                                                {configJSON.haveSpecialVoiceDaySwitchLabel}
                                                                            </Typography>
                                                                            <Switch
                                                                                data-test-id="haveSpecialDaySwitch"
                                                                                color="primary"
                                                                                checked={voiceNotes.have_special_day}
                                                                                onChange={() =>
                                                                                    this.handleSwitchChange(index, setFieldValue)
                                                                                }
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                    {voiceNotes.have_special_day && (
                                                                        <>
                                                                            <Grid item xs={12} sm={6} md={4}>
                                                                                <FormLabel component="label">
                                                                                    {configJSON.specialDayFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                </FormLabel>
                                                                                <Field
                                                                                    data-test-id="specialDay"
                                                                                    name="specialDay"
                                                                                    className="inputField"
                                                                                    size="small"
                                                                                    as={CustomInput}
                                                                                    placeholder="Special Day/ Subject"
                                                                                    value={voiceNotes.special_day}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                        setFieldValue(`voiceNotes.${index}.special_day`, e.target.value)
                                                                                    }
                                                                                />
                                                                                <ErrorMessage name={`voiceNotes.${index}.special_day`} />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} md={4}>
                                                                                <FormLabel component="label">
                                                                                    Select Prompts
                                                                                </FormLabel>
                                                                                <Field
                                                                                    data-test-id="prompt"
                                                                                    className="selectField"
                                                                                    name="prompt"
                                                                                    as={CustomSelect}
                                                                                    setValue={true}
                                                                                    placeholder="Select delegate user"
                                                                                    options={this.state.promptsList}
                                                                                    value={voiceNotes.prompt}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFieldValue(`voiceNotes.${index}.prompt`, e.target.value)
                                                                                    }}
                                                                                />

                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                    <Grid item xs={12}>
                                                                    <FileDrop
                                                                    data-test-id="fileDrop"
                onDrop={(files: any, event: any) => {
                    this.handleFileDrops(files, event, index, setFieldValue)
                }} >
                                                                        <Typography className="title">Upload Media</Typography>
                                                                        <Typography className="uploadInfo">
                                                                            Only Audio with max size of 15MB are allowed
                                                                        </Typography>
                                                                        <label className="uploadBox">
                                                                            <Button variant="contained" component="span" className="chooseFileBtn" data-test-id="uploadButton" onClick={() => this.openUploadDialog(index, setFieldValue)}>
                                                                                Choose File
                                                                            </Button>
                                                                            <img alt="icon" className="uploadMediaIcon" src={uploadMediaIcon} />
                                                                            <Typography variant="body2">Or drag & drop here</Typography>
                                                                        </label>
                                                                        </FileDrop>
                                                                        <div>
                                                                            <List className="fileList" key={voiceNotes.recipient}>
                                                                                {this.state.files[index]?.map((file, _index) => (
                                                                                    <>
                                                                                        <Box className="fileItemWrapper" key={_index}>
                                                                                            <ListItem key={_index} className="fileItem">
                                                                                                <ListItemAvatar><Avatar src={audioIcon} className="fileIcon" /></ListItemAvatar>
                                                                                                <ListItemText
                                                                                                    primary={this.isIMedia(file) ? file.file_name : file.name}
                                                                                                    secondary={
                                                                                                        this.isIMedia(file)
                                                                                                            ? `${(file.file_size / 1024).toFixed(2)} KB`
                                                                                                            : `${(file.size / 1024).toFixed(2)} KB`
                                                                                                    }
                                                                                                />
                                                                                            </ListItem>
                                                                                        </Box>
                                                                                    </>
                                                                                ))}
                                                                            </List>
                                                                        </div>

                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })
                                                    )}
                                                </FieldArray>
                                                <Box className="btnWrapper">
                                                    <Button
                                                        className="btnSize"
                                                        variant="outlined"
                                                        onClick={() => this.handleNavigation("CustomForm")}
                                                        data-test-id="skipForNow"
                                                    >
                                                        {configJSON.skipForNowBtnText}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        className="btnSize"
                                                        type="submit"
                                                        data-test-id="saveAndNextBtn"
                                                    >
                                                        {configJSON.saveAndNext}
                                                    </Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                <GenericModal
                    open={openDialog}
                    onClose={this.handleCloseDialog}
                    data-test-id="information-added-modal"
                >
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="dialogContentWrapper">
                                <Box className="dialogDescriptionWrapper">
                                    <Typography
                                        variant="h1"
                                        align="center"
                                        data-test-id="emailSentModalTitle"
                                    >
                                        {configJSON.infoAddedDialogTitleText}
                                    </Typography>
                                    <Typography align="center" variant="h2">
                                        Your voice notes has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                                    </Typography>
                                </Box>
                                <Box className="dialogBtnsWrapper">
                                    <Button
                                        className="outlinedBtn"
                                        variant="outlined"
                                        data-test-id="skipForNowDialogBtn"
                                        onClick={
                                            () => this.handleNavigation("CustomForm")
                                        }
                                    >
                                        {configJSON.skipForNowBtnText}
                                    </Button>
                                    <Button
                                        className="containedBtn"
                                        variant="contained"
                                        data-test-id="nextSectionBtn"
                                        onClick={
                                            () => this.handleNavigation("AboutMe")
                                        }
                                    >
                                        {configJSON.nextSectionBtnText}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
                <GenericModal
                    open={this.state.uploadDialog}
                    onClose={this.handleCloseDialog}
                    data-test-id="information-added-modal"
                >
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="dialogContentWrapper">
                                <Box className="dialogDescriptionWrapper">
                                    <Typography
                                        variant="h1"
                                        align="center"
                                        data-test-id="emailSentModalTitle"
                                    >
                                        Upload Audio
                                    </Typography>
                                </Box>
                                <Box display="flex" mb="30px" flexDirection="column" alignItems="center">
                                    {this.state.isRecording ? <SettingsVoiceIcon fontSize="large" /> : this.getAudioURL(this.state.audioBlob) && <> <audio src={this.getAudioURL(this.state.audioBlob)} controls />
                                        <Box mt="20px">
                                        <Button
                                            className="outlinedBtn"
                                            variant="outlined"
                                            data-test-id="retryRecording"
                                            id="contained-button-file"
                                            style={{marginRight: '6px'}}
                                            onClick={() => this.retryRecording()}
                                        >
                                            Retry
                                        </Button>
                                            <Button
                                                className="containedBtn"
                                                variant="contained"
                                                data-test-id="saveRecording"
                                                onClick={
                                                    () => this.saveRecording()
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </>}
                                </Box>
                                <Box className="dialogBtnsWrapper">
                                    <label className="uploadBox">
                                        <input
                                        data-test-id="uploadMediaLocal"
                                            type="file"
                                            accept="audio/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, this.state.selectedFieldValue, this.state.selectedIndex)}
                                        />
                                        <Button
                                            className="outlinedBtn"
                                            variant="outlined"
                                            data-test-id="uploadAudio"
                                            id="contained-button-file"
                                            component="span"
                                        >
                                            Upload Audio
                                        </Button>
                                    </label>
                                    <Button
                                        className="containedBtn"
                                        variant="contained"
                                        data-test-id="recordAudio"
                                        onClick={()=> this.handleRecordingButton()}
                                    >
                                        {this.handleRecordingButtonText()}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .voiceNotesInfoWrapper": {
        width: "100%",
    },
    "& .container": {
        display: "flex",
        padding: "50px 72px",
        gap: "72px",
    },
    "& .pageTitle": {
        margin: "32px 0",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    "& .voiceNotesFormWrapper": {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "40px",
    },
    "& .inputField": {
        width: "100%",
        height: "48px",
        borderRadius: "8px",
    },
    "& .delegateSelectField": {
        width: "100% !important",
    },
    "& .selectField": {
        width: "100%",
        height: "48px",
        borderRadius: "8px",
        margin: "5px 0px",
        display: "block",
    },
    "& .formSectionBackground": {
        borderRadius: "8px",
        padding: "24px",
        marginBottom: "24px",
        backgroundColor: "rgba(206, 195, 229, 0.1)",
    },
    "& .fieldArrayHeaderWrapper": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .btnSize": {
        width: "158px",
        height: "48px",
    },
    "& .btnWrapper": {
        display: "flex",
        gap: "8px",
        justifyContent: "flex-end",
    },
    "& .addLettersBtn": {
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#9176C4",
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        '&:hover': {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },
    "& .messageText": {
        fontFamily: "Lato",
        fontSize: "16px",
        color: "#1E293B",
        fontWeight: 400,
        lineHeight: "18px",
    },
    "& .circleOutlineIcon": {
        fontSize: "16px",
        marginRight: "8px",
    },
    "& .haveSpecialDayWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .haveSpecialDayLabel": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#1E293B",
        display: "flex",
        gap: "8px",
        alignItems: "center",
    },

    marginTop: "16px",

    "& .uploadBox": {
        border: "2px dashed #d1c4e9",
        borderRadius: "8px",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer",
        backgroundColor: "#fafafa",
        marginBottom: "16px",
        gap: "16px",
    },

    "& .fileList": {
        listStyle: "none",
        padding: 0,
    },

    "& .fileItem": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: "8px",
        flex: 1,
    },

    "& .fileIcon": {
        width: "40px",
        height: "40px",
        marginRight: "16px",
    },

    "& .title": {
        fontFamily: "Lato",
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 700,
        color: "#0F172A",
    },

    "& .uploadInfo": {
        fontFamily: "Lato",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 500,
        color: "#94A3B8",
        marginBottom: "8px",
    },

    "& .chooseFileBtn": {
        width: "158px",
        height: "44px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "4px",
    },

    "& .uploadMediaIcon": {
        width: "44px",
        height: "44px",
    },

    "& .fileItemWrapper": {
        display: "flex",
        gap: "24px",
    },

    "& .voiceFormDataContainer":{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100% - 130px)",
        justifyContent: "space-between"
      },

    "& .childrenWrapper": {
        display: "flex",
        gap: "16px",
    },

    "& [class*=MuiTypography-body2]": {
        body2: {
            fontFamily: "Lato",
            fontSize: "16px !important",
            lineHeight: "22px",
            fontWeight: 400,
        },
    },

    [theme.breakpoints.down(740)]: {
        "& .container": {
            padding: "28px 16px",
        },
    },
}));
// Customizable Area End