import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL, sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

interface IAboutMe {
    have_physcial_information: boolean;
    have_favourite_information: boolean;
    have_hobbies_information: boolean;
    have_dream_information: boolean;
    have_proud_moments: boolean;
    have_achievement_information: boolean;
    have_cv_information: boolean;
};

interface IFuneral {
    have_funeral_basic_information: boolean;
    have_attire: boolean;
    have_hair_and_beauty: boolean;
    have_further_items: boolean;
};

interface IUserDetails {
    id: number;
    user_id: string;
    email: string;
    full_name: string;
    photo_url: string;
    activated: boolean;
    term_and_condition: boolean;
    created_at: string;
    have_created_profile: boolean;
    have_delegate_members: boolean;
    count_delegate: number;
    have_subscription: boolean;
    have_legal_information: boolean;
    have_medical_information: boolean;
    have_investment_information: boolean;
    have_pet_information: boolean;
    have_family_detail: boolean;
    have_friend_detail: boolean;
    have_personal_letters: boolean;
    have_voice_notes: boolean;
    about_me: IAboutMe;
    funeral: IFuneral;
    have_after_wish: boolean;
};

interface ILoggedInUserApiResponse {
    id: string;
    type: string;
    attributes: IUserDetails;
}

export interface INavMenu {
  id: string,
  name: string,
  path: string,
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    progress?: number;
    // Customizable Area End
}

interface S {
  // Customizable Area Start
    drawerOpen: boolean;
    activeMenu: string;
    isAuthenticated: boolean;
    isMobile: boolean;
    profileData: IUserDetails | null;
    anchorEl: HTMLElement | null;
    navMenu: INavMenu[];
    openDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callGetLoggedInUserApiId: string = "";
  callDeleteAccountApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

      this.state = {
          drawerOpen: false,
          activeMenu: "1",
          isAuthenticated: false,
          isMobile: false,
          profileData: null,
          anchorEl: null,
          openDialog: false,
          navMenu: [
            {
              id: "1",
              name: "Home",
              path: "LandingPage",
            },
            {
              id: "2",
              name: "About Us",
              path: "AboutUs",
            },
            {
              id: "3",
              name: "Shop",
              path: "NavigationMenu",
            },
            {
              id: "4",
              name: "Support",
              path: "Support",
            },
            {
              id: "5",
              name: "Final Pdf",
              path: "SaveAsPdf",
            },
            {
              id: "6",
              name: "Subscriptions",
              path: "Customisableusersubscriptions",
            },
          ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLoggedInUserDetails();
    const currentPath = getLastPartOfURL();
    this.setState({ activeMenu: currentPath});
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetLoggedInUserApiId]: this.handleGetLoggedInUserResponse,
      [this.callDeleteAccountApiId]: this.handleDeleteAccountResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getLoggedInUserDetails = async () => {
    const token = await getStorageData("token");

    if(token){
        this.callGetLoggedInUserApiId = sendAPIRequest(
            configJSON.getLoggedInUserApiEndPoint,
            {
              method: configJSON.validationApiMethodType,
              headers: {
                  token,
                },
            }
        );
    }
  };

  handleGetLoggedInUserResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILoggedInUserApiResponse;
    };

    if (response.data) {
      this.setState({ 
        profileData: response.data.attributes,
        isAuthenticated: true,
        navMenu: [
          {
            id: "1",
            name: "Home",
            path: "HomePage",
          },
          {
            id: "2",
            name: "About Us",
            path: "AboutUs",
          },
          {
            id: "3",
            name: "Shop",
            path: "NavigationMenu",
          },
          {
            id: "4",
            name: "Support",
            path: "Support",
          },
          {
            id: "5",
            name: "Final Pdf",
            path: "FinalPdf",
          },
          {
            id: "6",
            name: "Subscriptions",
            path: "Customisableusersubscriptions",
          },
        ],
     });
    }
  };

  handleMenuClick = (id: string) => {
    this.setState({ activeMenu: id });
  };

  activeTab = (path: string) =>{
    let navigationUrl = window.location.href.toLowerCase()
    let navPath = (path === "HomePage") ? "home" : path.toLowerCase()
    return navigationUrl.includes(navPath)
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleDropdownMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (menuItem: string) => {
    if(menuItem === "Logout"){
      this.logout();
    }
    else if(menuItem === "DeleteAccount"){
      this.setState({ openDialog: true });
    }
    else{
      this.setState({ anchorEl: null });
      this.handleNavigation(menuItem);
    }
  };

  logout = async () => {
    await removeStorageData("token");
    this.setState({ isAuthenticated: false, anchorEl: null });
    this.handleNavigation("LandingPage");
  };

  handleDeleteBtnClick = async () => {
    this.setState({ openDialog: false });
    const token = await getStorageData("token");

    if(token){
        this.callDeleteAccountApiId = sendAPIRequest(
            "account_block/accounts/remove_account",
            {
              method: "DELETE",
              headers: {
                  token,
                },
            }
        );
    }
  };

  handleDeleteAccountResponse = async(
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      message: string;
    };

    if (response.message) {
      await removeStorageData("token");
      this.setState({ isAuthenticated: false, anchorEl: null });
      this.handleNavigation("Signup");
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
        const errors = possibleErrors;
      if (Array.isArray(errors) && errors[0]) {
        const errorMsg = Object.values(errors[0])[0] as string;
        toast.error(errorMsg);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }
}

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    console.log("Route", route);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
