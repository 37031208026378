import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { IMedia, ISelectOptions } from "./LegalInformationController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { toast } from "react-toastify";

export interface ILetters {
  voiceNotes: IVoiceNotes[];
};

export interface IVoiceNotes {
    delegate_id: string;
    recipient: string;
    have_special_day: boolean;
    special_day: string;
    prompt: string
    files: (File | IMedia)[];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  letters: ILetters;
  files: (IMedia | File)[][];
  openDialog: boolean;
  delegateInCharge: ISelectOptions[];
  showSpecialDayField: boolean;
  promptsList:{id: number, value: string , name: string}[]
  uploadDialog: boolean
  selectedIndex: number | undefined
  selectedFieldValue: any
  isRecording: boolean;
  audioBlob: Blob | null;
  maxVoiceNotes: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VoiceNotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  mediaRecorder: any = null;
  audioChunks: any = [];
  maxFileSize : number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      letters: {
        voiceNotes: [{
          delegate_id: "",
          recipient: "",
          have_special_day: false,
          prompt: "",
          special_day: "",
          files: [],
      }],
      },
        files: [],
        openDialog: false,
        delegateInCharge: [],
        showSpecialDayField: false,
        promptsList:[{id: 1, value: "Good Morning", name: "Good Morning"}, 
            {id: 2, value: "Happy Birthday", name: "Happy Birthday"},
            {id: 3, value: "Twinkle Twinkle little star", name: "Twinkle Twinkle little star"},
            {id: 4, value: "I love you", name: "I love you"},
            {id: 5, value: "Good Night", name: "Good Night"}
        ],
        uploadDialog: false,
        selectedIndex: undefined,
        selectedFieldValue:{},
        isRecording: false,
        audioBlob: null,
        maxVoiceNotes: 2
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddVoiceNotesInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getMaxVoiceNotes()
  }

  getMaxVoiceNotes = async () =>{
    let subscriptionData = await getStorageData("active_subscription");
    let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_voice_notes
    if(parsedSubscriptionData){
      this.setState({maxVoiceNotes: parseInt(parsedSubscriptionData)})
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callAddVoiceNotesInfoApiId]: this.handleAddVoiceNotesInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleFileDrops =async  (files : File[], event : React.DragEvent<HTMLDivElement> , index: number,  setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },) =>{
    event.preventDefault()
    if(files){
      const fileDrop = Array.from(files);
      if(await this.checkFileSize(fileDrop)){
        this.setState((prevState) => ({
          files: this.updateFilesState(prevState, "files", index, fileDrop),
          uploadDialog: false
        }), () => {
            const currentFiles = this.state.files[index] || [];
            setFieldValue(`voiceNotes.${index}.files`, currentFiles);
        });
      }
    }
  }

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
     return; 
   }
 
     const response = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState({ delegateInCharge });
     }
   };

  handleAddVoiceNotesInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    if (responseJSON.data) {
      this.setState({ openDialog: true });
    }
  };

  handleVoiceNotesInformationsFormSubmit = async (values: ILetters) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    values.voiceNotes.forEach((voice_notes, index) => {
      if(voice_notes){
        Object.entries(voice_notes).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'delegate_id'){
              formData.append(`voice_note[${keyName}]`, value);
            }
            else if(keyName === 'files' && voice_notes.files.length > 0){
              voice_notes.files.forEach((file: string | IMedia | File | ArrayBuffer) => {
                  formData.append(`notes[][${keyName}][]`, file as Blob);
                });
            }
            else if(keyName !== 'files'){
              formData.append(`notes[][${keyName}]`, value as string);
            }
          }
        });
      }
    });

    this.callAddVoiceNotesInfoApiId = sendAPIRequest(
      configJSON.voiceNoteEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  validationSchema = Yup.object().shape({
      voiceNotes: Yup.array().of(Yup.object().shape({
        delegate_id: Yup.string().nullable().required("Please select delegate user"),
        recipient: Yup.string().nullable().required("Please enter recipient"),
        special_day: Yup.string().when(['have_special_day'], {
          is: (have_special_day) => have_special_day,
          then: Yup.string().nullable().required("Please enter special day"),
          otherwise: Yup.string().nullable(),
        }),
      })),
  });

  handleSwitchChange = async (
    index: number,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      await new Promise<void>((resolve) => this.setState({ showSpecialDayField: !this.state.showSpecialDayField }, () => resolve()));
    setFieldValue(`voiceNotes.${index}.have_special_day`, this.state.showSpecialDayField);
  }

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number = 0) => {
      this.setState({isRecording: false})
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      if(await this.checkFileSize(newFiles)){
      this.setState((prevState) => ({
        files: this.updateFilesState(prevState, "files", index, newFiles),
        uploadDialog: false
      }), () => {
          const currentFiles = this.state.files[index] || [];
          setFieldValue(`voiceNotes.${index}.files`, currentFiles);
      });
    }
    }
  };

  checkFileSize = async(file: File[]) => {
    let isInvalid = file.some(file => !file.type.startsWith('audio/'))
    if (isInvalid) {
      toast.error(configJSON.invalidFileError)
      return
    }
    const oversizedFiles = file.filter(file => file.size > this.maxFileSize);

    if (oversizedFiles.length > 0) {
      toast.error(configJSON.maxFileSizeError)
      return 
    }
    let isValidFile: boolean = true
    const checks = file.map(_file => {
      return new Promise((resolve) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(_file);
  
        audio.onloadedmetadata = () => {
          if (audio.duration > 10) {
            isValidFile = false;
            toast.error("Audio file exceeds 10 seconds.");
            resolve(false)
          }
          resolve(true);
        };
  
        audio.onerror = () => {
          resolve(false);
        };
      });
    });
  
    // Wait for all promises to complete
    await Promise.all(checks);
    return isValidFile
  }

handleRecordingButton = () =>{
  this.state.isRecording ? this.stopRecording() : this.startRecording() 
}

getNotesList = (index: number , length: number) => {
  return index === 0 && length < this.state.maxVoiceNotes
}
handleRecordingButtonText = () =>{
  return this.state.isRecording ? "Stop Recording" : "Start Recording"
}
  saveRecording = async () =>{
    let index = this.state.selectedIndex || 0
    if(this.state.audioBlob){
      const audioFile = new File([this.state.audioBlob], 'recording.wav', {
        type: 'audio/wav',
      });
      if(await this.checkFileSize([audioFile])){
        this.setState(
          (prevState) => ({
            files: this.updateFilesState(prevState, 'files', index, [audioFile]),
          }),
          () => {
           const currentFiles = this.state.files[index] || [];
            this.state.selectedFieldValue(`voiceNotes.${index}.files`, currentFiles);
            this.handleCloseDialog()
          }
        );      }

    }
  }

  // Helper function to update state
  updateFilesState = (prevState: any, stateKey: string, index: number, newFiles: (File | IMedia)[]): 
  (File | IMedia)[][] => {
   const updatedFiles = [...(prevState[stateKey] || [])];
   if (!updatedFiles[index]) {
     updatedFiles[index] = [];
   }
   updatedFiles[index] = [...updatedFiles[index], ...newFiles];
   return updatedFiles;
 };

  handleCloseDialog = () => {
    this.setState({ openDialog: false, uploadDialog: false, selectedIndex: undefined , audioBlob: null, isRecording: false });
  };

  openUploadDialog = (index: number | null, setFieldValue : any) =>{
    if(index !== null){
      this.setState({selectedIndex: index ,uploadDialog: true, selectedFieldValue: setFieldValue})
    }
  }
  startRecording = async () =>{
    try {    
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.mediaRecorder = new (window as any).MediaRecorder(stream);

    this.audioChunks = [];
    this.mediaRecorder.ondataavailable = (event: any) => {
      this.audioChunks.push(event.data);
    };

    this.mediaRecorder.onstop = () => {
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
      this.setState({ audioBlob});
    };

    this.mediaRecorder.start();
    this.setState({ isRecording: true });
  }catch(error){
    this.setState({ isRecording: false });
    if (error instanceof DOMException && error.name === "NotAllowedError") {
      toast.error(configJSON.microphoneError)
    } else {
      toast.error(configJSON.microphoneError1)
    }
  }
  }

  getAudioURL = (blob : Blob | null) =>{
    if(blob)
    return URL.createObjectURL(blob)
  }
  stopRecording = () => {
  this.mediaRecorder?.stop();
    this.setState({ isRecording: false });
  };
  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  retryRecording = () =>{
    this.mediaRecorder = null;
  this.audioChunks= [];
    this.setState({ audioBlob : null})
  }
  // Customizable Area End
}
