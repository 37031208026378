import React from "react";

// Customizable Area Start
import {
  Box,  
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { pdfPreviewImg } from "./assets";
import { checkIcon } from "../../../blocks/customisableusersubscriptions/src/assets";

const ref = React.createRef();

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Quattrocento",
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "63px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#9176C4",
      },
      body1: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#1E293B",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
      },
    },
  },
});

const listItems = [
  "Accessible for delegates to download upon your death",
  "Shared to your loved once",
  "Things about you for all to remember",
  "Your fav. people and pet shown in your memory book",
  "All your favourite things from food to places inluded",
];
// Customizable Area End

import SaveAsPdfController, { Props, configJSON } from "./SaveAsPdfController";

export default class SaveAsPdf extends SaveAsPdfController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box className="container">
            <Grid container>
              <Grid className="alignContent" item md={6}>
                <Box
                  className="alignCenter"
                  mb={5}
                  style={{ maxWidth: "575px" }}
                >
                  <Typography variant="subtitle1" className="subHeading">
                    {configJSON.finalPDFText}
                  </Typography>
                  <Typography
                    variant="h1"
                    className="heading"
                    data-test-id="heading"
                  >
                    {configJSON.finalPDFHeading}
                  </Typography>
                  <Typography variant="body1">
                    {configJSON.finalPDFDescription}
                  </Typography>
                  <Box my={2}>
                    { listItems.map((item) => {
                      return(
                        <Box className="flexWrapper">
                          <Box>
                            <img alt="checkIcon" src={checkIcon} />
                          </Box>
                          <Box>
                            <Typography variant="body2">{item}</Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box className="alignCenter">
                  <Button
                    variant="contained"
                    className="button"
                    data-test-id="createMemoriesBtn"
                    onClick={() => this.handleNavigation("Signup")}
                  >
                    {configJSON.createMemoriesBtnText}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className="heroSectionImgWrapper">
                  <img
                    alt="image"
                    src={pdfPreviewImg}
                    className="heroSectionImg"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <WebFooter handleNavigation={this.handleNavigation} />
        </Wrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px 72px",
  },
  "& .subHeading": {
    display: "flex",
    gap: "5px",
  },
  "& .delegateMeeSpan": {
    fontFamily: "Times New Roman",
    fontStyle: "italic",
    fontWeight: 800,
  },
  "& .flexWrapper": {
    display: "flex",
    padding: "6px",
    gap: "8px",
  },
  "& .button": {
    width: "268px",
    height: "48px",
    textTransform: "none",
    color: "#fff",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 700,
    backgroundColor: "#A190C2",
    borderRadius: "100px",
  },
  "& .heroSectionImg": {
    width: "100%",
    height: "100%",
    objectFit: 'contain',
    maxHeight: '650px',
  },
  "& .alignContent": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  [theme.breakpoints.down(500)]: {
    "& .heroSectionImgWrapper": {
      display: "none !important",
    },
    "& .alignCenter": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
// Customizable Area End
