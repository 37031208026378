import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { IMedia } from "./InvestmentsAndFundsEditController.web";

export interface IHaveAchievement {
    title: string;
    loction: string;
    description: string;
    files: (File | IMedia)[];
};

export interface IAchievement {
    have_achievement: string;
    achievement_details_attributes: IHaveAchievement[];
};

export interface IAchievementDataResponse {
    id: string;
    type: "achievement_informations";
    attributes: {
        id: number;
        have_achievement: string;
        account_id: number;
        achievement_details: {
            data: IAchievementDetail[];
        };
    };
}

interface IAchievementDetail {
    id: string;
    type: "achievement_details";
    attributes: {
        id: number;
        title: string;
        description: string;
        loction: string;
        files: IMedia[];
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    achievement: IAchievement,
    maxAchievement: number,
    isloading: boolean,
    isEdit: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutAchievementsEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    maxFileSize: number = 15 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            achievement: {
                have_achievement: '0',
                achievement_details_attributes: [{
                    title: "",
                    loction: "",
                    description: "",
                    files: []
                }]
            },
            maxAchievement: 0,
            isloading: false,
            isEdit: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.callGetAboutAchievementsDataApiId) {
            if (responseJSON.data) {
                this.handleGetAchievementsApiResponse(responseJSON.data as IAchievementDataResponse);
            } else {
                let messError = responseJSON?.errors[0]
                if (messError) {
                    let key = Object.keys(messError)[0]
                    let message = key + " " + messError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        else if (apiRequestCallId === this.callEditAboutAchievementsDataApiId) {
            this.setState({ isEdit: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            } else {
                let achievementError = responseJSON?.errors[0]
                if (achievementError) {
                    let key = Object.keys(achievementError)[0]
                    let message = key + " " + achievementError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callGetAboutAchievementsDataApiId: string = "";
    callEditAboutAchievementsDataApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
        this.getmaxAchievement();
        this.getAchievementsData();
    }

    getmaxAchievement = async () => {
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_achievements
        if (parsedSubscriptionData) {
            this.setState({ maxAchievement: parseInt(parsedSubscriptionData) })
        }
    };

    getAchievementsData = async () => {
        const token = await getStorageData("token");

        this.callGetAboutAchievementsDataApiId = sendAPIRequest(
            configJSON.getAboutMeAchievementsDataApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    handleGetAchievementsApiResponse = (response: IAchievementDataResponse) => {
        const attributes = response.attributes;

        let achievement = {
            have_achievement: attributes.have_achievement === "Yes" ? "1" : "0",
            achievement_details_attributes: attributes.achievement_details.data.length > 0 ? attributes.achievement_details.data.map(
                (achievement) => {
                    return (
                        {
                            title: achievement.attributes.title,
                            loction: achievement.attributes.loction	,
                            description: achievement.attributes.description,
                            files: achievement.attributes.files?.map((mediaFile) => {
                                return ({
                                    file_id: mediaFile.file_id,
                                    file_name: mediaFile.file_name,
                                    content_type: mediaFile.content_type,
                                    file_size: mediaFile.file_size,
                                    url: mediaFile.url,
                                })
                            }) || [],
                        }
                    )
                }
            ) : [{
                title: "",
                loction: "",
                description: "",
                files: []
            }]
        } as IAchievement;

        this.setState({ achievement });
    };

    checkForAddAchievementsBtn = (index: number, length: number) => {
        return this.state.isEdit && index === 0 && length < this.state.maxAchievement
    };

    checkForRemoveBtn = (index: number, length: number) => {
        return this.state.isEdit && index !== 0
    };

    toggleEditMode = () => {
        this.setState({ isEdit: true });
    };

    handleAboutAchievementsDataFormSubmit = async (values: IAchievement) => {
        if (!this.state.isEdit) {
            this.toggleEditMode();
            return;
        }

        const token = await getStorageData("token");
        const formData = new FormData();
        this.setState({ isloading: true })
        Object.entries(values).forEach(([keyName, value]) => {
            if (keyName !== "achievement_details_attributes") {
                formData.append(`achievement[${keyName}]`, value);
            }
        });

        // Add achievement details attributes
        if (values.have_achievement === "1") {
            for (const achievement of values.achievement_details_attributes) {
                await this.appendAchievementDetails(formData, achievement);
            }
        }

        this.callEditAboutAchievementsDataApiId = sendAPIRequest(
            configJSON.getAboutMeAchievementsDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    };

    private async appendAchievementDetails(formData: FormData, achievement: IHaveAchievement) {
        for (const [keyName, value] of Object.entries(achievement)) {
            if (!value) continue;

            if (keyName === 'files' && Array.isArray(achievement.files) && achievement.files.length > 0) {
                await this.appendFiles(formData, achievement.files);
            } else {
                formData.append(`achievement[achievement_details_attributes][][${keyName}]`, value as string);
            }
        }
    }

    private async appendFiles(formData: FormData, files: (File | IMedia)[]) {
        for (const file of files) {
            const blobFile = await this.convertToBlob(file);
            formData.append(`achievement[achievement_details_attributes][][files][]`, blobFile);
        }
    }

    validationSchema = () => {
        if (this.state.isEdit) {
            return (
                Yup.object().shape({
                    achievement_details_attributes: Yup.array().when(['have_achievement'], {
                        is: (have_hobbies) => have_hobbies === "1",
                        then: Yup.array().of(Yup.object().shape({
                            title: Yup.string().nullable().required("Please enter title"),
                            loction: Yup.string().nullable().required("Please enter loction")
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    })
                })
            )
        }
        else {
            return Yup.object().shape({});
        }
    }

    // Convert file (either an existing IMedia or new File) to Blob
    convertToBlob = async (file: IMedia | File): Promise<Blob> => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };


    handleAchievementFile = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: (File | IMedia)[]) => {
        if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
            prevFiles = [];
        }
        if (event.target.files) {
            const achievementFile = Array.from(event.target.files);
            const achOversizedFiles = achievementFile.filter(file => file.size > this.maxFileSize);
            if (achOversizedFiles.length > 0) {
                this.props.handleError(configJSON.maxFileSizeError)
                return
            }
            let files = [...prevFiles, ...achievementFile]
            setFieldValue(`achievement_details_attributes.${index}.files`, files)
        }
    };

    // Customizable Area End
}
