import React from "react";
// Customizable Area Start
import FuneralBasicsController, {
    configJSON,
    IFuneralBasics,
    Props,
} from "./FuneralBasicsController.web";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    styled,
    ThemeProvider,
    Typography,
} from "@material-ui/core";
import { styledMyLocationTwoToneIcon, StyledRadio, theme } from "./LegalInformation.web";
import { Field, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import { countries } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import MediaUpload from "../../../components/src/MediaUpload.web";
import Spinner from "./components/Spinner.web";

const relationshipDropdown = [
    { value: 1, name: "Brother" },
    { value: 2, name: "Best Friend" },
];
// Customizable Area End

export default class FuneralBasics extends FuneralBasicsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderCremationDetails = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Box my={2}>
                    <Typography 
                        className="sectionTitle"
                        data-test-id="cremationDetailsTitle"
                    >
                        {configJSON.cremationDetailsTitle}
                    </Typography>
                </Box>
                <Box my={2}>
                    <FormLabel component="label" className="formRadioLabel">
                        {configJSON.haveDiamonEctFieldLabel}
                    </FormLabel>
                    <RadioGroup
                        className="radioGroup"
                        data-test-id="haveDiamonEct"
                        aria-label="haveDiamonEct"
                        name="haveDiamonEct"
                        value={values.cremation_detail_attributes.have_diamon_ect}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("cremation_detail_attributes.have_diamon_ect", e.target.value);
                            this.setState({ showDiamonEctField: !this.state.showDiamonEctField });
                        }
                        }
                    >
                        <FormControlLabel
                            className="radioLabel"
                            value="1"
                            control={<StyledRadio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            className="radioLabel"
                            value="0"
                            control={<StyledRadio />}
                            label="No"
                        />
                    </RadioGroup>
                </Box>
                {this.state.showDiamonEctField && (
                    <Box my={2}>
                        <FormLabel component="label" className="formRadioLabel">
                            {configJSON.cremationDecisionFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="cremationDecision"
                            name="cremationDecision"
                            className="multilineInput"
                            as={CustomInput}
                            multiline
                            placeholder="Your thoughts behind this "
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(`cremation_detail_attributes.decision`, e.target.value)
                            }}
                            value={values.cremation_detail_attributes.decision}
                        />
                    </Box>
                )}
            </Grid>
        )
    };


    renderFuneralPlaceDetails = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {( 
            field: string, 
            value: any,
            shouldValidate?: boolean | undefined): void; 
            (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography data-test-id="funeralDetailsTitle" variant="h6">{configJSON.funeralDetailsTitle}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlaceAddressLine1"
                            name="funeralPlaceAddressLine1"
                            className="inputField"
                            size="small"
                            startIcon={styledMyLocationTwoToneIcon}
                            as={CustomInput}
                            placeholder="House numberFlat no. "
                            value={values.funeral_detail_attributes.address_line_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.address_line_1", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.address_line_1 && errors.funeral_detail_attributes?.address_line_1}
                            errorMsg={errors.funeral_detail_attributes?.address_line_1}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlaceAddressLine2"
                            name="funeralPlaceAddressLine2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Street and Locality"
                            value={values.funeral_detail_attributes.address_line_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.address_line_2", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.address_line_2 && errors.funeral_detail_attributes?.address_line_2}
                            errorMsg={errors.funeral_detail_attributes?.address_line_2}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.landmarkLabel}
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlaceLandmark"
                            name="funeralPlaceLandmark"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Landmark"
                            value={values.funeral_detail_attributes.landmark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.landmark", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlaceTownCity"
                            name="funeralPlaceTownCity"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="City"
                            value={values.funeral_detail_attributes.city}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.city", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.city && errors.funeral_detail_attributes?.city}
                            errorMsg={errors.funeral_detail_attributes?.city}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlaceCountry"
                            className="selectField"
                            name="funeralPlaceCountry"
                            as={CustomSelect}
                            placeholder="Country"
                            options={countries}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("funeral_detail_attributes.country", e.target.value)
                            }}
                            value={values.funeral_detail_attributes.country}
                            errorMsg={errors.funeral_detail_attributes?.country}
                            error={touched.funeral_detail_attributes?.country && errors.funeral_detail_attributes?.country}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="funeralPlacePostCode"
                            name="funeralPlacePostCode"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Post Code"
                            value={values.funeral_detail_attributes.post_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_detail_attributes.post_code", e.target.value)
                            }
                            error={touched.funeral_detail_attributes?.post_code && errors.funeral_detail_attributes?.post_code}
                            errorMsg={errors.funeral_detail_attributes?.post_code}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderLocationForWakeForm = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {( field: string, value: any,
            shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography data-test-id="locationForWakeTitle" variant="h6">{configJSON.locationForWakeTitle}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameOfPlaceFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="wakePlaceName"
                            name="wakePlaceName"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Enter name of place"
                            value={values.wake_location_attributes.place_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.place_name", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="wakeAddressLine1"
                            name="wakeAddressLine1"
                            className="inputField"
                            size="small"
                            startIcon={styledMyLocationTwoToneIcon}
                            as={CustomInput}
                            placeholder="House numberFlat no. "
                            value={values.wake_location_attributes.address_line_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.address_line_1", e.target.value)
                            }
                            error={touched.wake_location_attributes?.address_line_1 && errors.wake_location_attributes?.address_line_1}
                            errorMsg={errors.wake_location_attributes?.address_line_1}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="wakeAddressLine2"
                            name="wakeAddressLine2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Street and Locality"
                            value={values.wake_location_attributes.address_line_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.address_line_2", e.target.value)
                            }
                            error={touched.wake_location_attributes?.address_line_2 && errors.wake_location_attributes?.address_line_2}
                            errorMsg={errors.wake_location_attributes?.address_line_2}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.landmarkLabel}
                        </FormLabel>
                        <Field
                            data-test-id="wakeLandmark"
                            name="wakeLandmark"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Landmark"
                            value={values.wake_location_attributes.landmark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.landmark", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="wakeTownCity"
                            name="wakeTownCity"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="City"
                            value={values.wake_location_attributes.city}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.city", e.target.value)
                            }
                            error={touched.wake_location_attributes?.city && errors.wake_location_attributes?.city}
                            errorMsg={errors.wake_location_attributes?.city}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="wakeCountry"
                            className="selectField"
                            name="wakeCountry"
                            as={CustomSelect}
                            placeholder="Country"
                            options={countries}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("wake_location_attributes.country", e.target.value)
                            }}
                            value={values.wake_location_attributes.country}
                            errorMsg={errors.wake_location_attributes?.country}
                            error={touched.wake_location_attributes?.country && errors.wake_location_attributes?.country}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Field
                            data-test-id="wakePostCode"
                            name="wakePostCode"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Post Code"
                            value={values.wake_location_attributes.post_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("wake_location_attributes.post_code", e.target.value)
                            }
                            error={touched.wake_location_attributes?.post_code && errors.wake_location_attributes?.post_code}
                            errorMsg={errors.wake_location_attributes?.post_code}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderStoneSpotForm = (
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item lg={12}>
                <MediaUpload
                    data-test-id="mediaUpload"
                    title="Proof of Purchase"
                    files={this.state.files}
                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
                />
            </Grid>
        )
    };

    renderSongsFields = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {(field: string,
            value: any,
            shouldValidate?: boolean | undefined): void; 
            (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel1}
                        </FormLabel>
                        <Field
                            data-test-id="song1"
                            name="song1"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_1", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_1 && errors.funeral_song_attributes?.song_1}
                            errorMsg={errors.funeral_song_attributes?.song_1}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel2}
                        </FormLabel>
                        <Field
                            data-test-id="song2"
                            name="song2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_2", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_2 && errors.funeral_song_attributes?.song_2}
                            errorMsg={errors.funeral_song_attributes?.song_2}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.songFieldLabel3}
                        </FormLabel>
                        <Field
                            data-test-id="song3"
                            name="song3"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Name of the song"
                            value={values.funeral_song_attributes.song_3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("funeral_song_attributes.song_3", e.target.value)
                            }
                            error={touched.funeral_song_attributes?.song_3 && errors.funeral_song_attributes?.song_3}
                            errorMsg={errors.funeral_song_attributes?.song_3}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    renderListOfPeopleFields = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <Typography data-test-id="listOfPeopleTitle" variant="h6">{configJSON.listOfPeopleTitle}</Typography>
                    </Grid>
                    {values.carry_people_name.map((friendName: string, index: number) => {
                        return (
                            <Grid item lg={4} xs={12} sm={6} key={index}>
                                <FormLabel component="label">
                                    {configJSON.nameFieldLabel}
                                </FormLabel>
                                <Field
                                    data-test-id={`name${index}`}
                                    name={`name${index}`}
                                    className="inputField"
                                    size="small"
                                    as={CustomInput}
                                    placeholder="Enter the full name"
                                    value={values.carry_people_name.index}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFieldValue(`carry_people_name.${index}`, e.target.value)
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        )
    };

    renderCloseFriendsForm = (
        values: FormikValues,
        errors: FormikErrors<IFuneralBasics>,
        touched: FormikTouched<IFuneralBasics>,
        setFieldValue: {
            (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.delegateUserFieldLabel1}
                        </FormLabel>
                        <Field
                            data-test-id="delegate1"
                            name="delegate1"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled
                            placeholder="delegate 1"
                            value={values.eulogy_apart_deliver_attributes.delegate1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.delegate1", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.delegateUserFieldLabel2}
                        </FormLabel>
                        <Field
                            data-test-id="delegate2"
                            name="delegate2"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            disabled
                            placeholder="delegate 2"
                            value={values.eulogy_apart_deliver_attributes.delegate2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.delegate2", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-test-id="person1Title" variant="h6">{configJSON.person1Title}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="person1Name"
                            name="person1Name"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Enter the full name"
                            value={values.eulogy_apart_deliver_attributes.name_p1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.name_p1", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.relationshipFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="person1Relationship"
                            className="selectField"
                            name="person1Relationship"
                            as={CustomSelect}
                            placeholder="Select relationship "
                            options={relationshipDropdown}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("eulogy_apart_deliver_attributes.relationship_p1", e.target.value)
                            }}
                            value={values.eulogy_apart_deliver_attributes.relationship_p1}
                        />
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-test-id="funeralDetailsTitle" variant="h6">{configJSON.person2Title}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.nameFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="person2Name"
                            name="person2Name"
                            className="inputField"
                            size="small"
                            as={CustomInput}
                            placeholder="Enter the full name"
                            value={values.eulogy_apart_deliver_attributes.name_p2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("eulogy_apart_deliver_attributes.name_p2", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6}>
                        <FormLabel component="label">
                            {configJSON.relationshipFieldLabel}
                        </FormLabel>
                        <Field
                            data-test-id="person2Relationship"
                            className="selectField"
                            name="person2Relationship"
                            as={CustomSelect}
                            placeholder="Select relationship "
                            options={relationshipDropdown}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("eulogy_apart_deliver_attributes.relationship_p2", e.target.value)
                            }}
                            value={values.eulogy_apart_deliver_attributes.relationship_p2}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            funeralBasicInfo,
            delegateInCharge,
            showFuneralPlace,
            showLocationForWake,
            showStoneSpot,
            showSongs,
            showCloseFriends,
            showSpecificRequest,
            showCremationDetails,
            isLoading,
        } = this.state;
        return (
                            <Box className="formWrapper">
                                {isLoading && <Spinner spinnerModal={isLoading}/>}
                                <Formik
                                    initialValues={funeralBasicInfo}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => {
                                        this.handleFuneralBasicsInformationsFormSubmit(values)
                                    }}
                                    enableReinitialize={true}
                                    data-test-id="funeralBasicsForm"
                                >
                                    {({ values, errors, touched, setFieldValue }) => (
                                        <Form>
                                            <Box className="formSectionBackground">
                                                <Box className="funeralBasicsFormFields">
                                                <Box className="formSectionTitle">
                                                    <Box>
                                                        <Typography data-test-id="funeralBasicsTitle" variant="h3" className="titleText">
                                                            {configJSON.funeralBasicsTitle}
                                                        </Typography>
                                                    </Box>
                                                    <Box className="checkboxWrapper">
                                                        <Checkbox
                                                            data-test-id="is_include_final_pdf"
                                                            color="primary"
                                                            checked={values.is_include_final_pdf}
                                                            onChange={(e) => {
                                                                setFieldValue('is_include_final_pdf', e.target.checked)
                                                            }}
                                                            style={{ color: "#9176C4" }}
                                                            inputProps={{ 
                                                                'aria-label': 'primary checkbox' 
                                                            }}
                                                        />
                                                        <FormLabel component="label" className="pdfLabel">
                                                            {configJSON.pdfLabel}
                                                        </FormLabel>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={4} xs={12} sm={6}>
                                                            <FormLabel component="label">
                                                                {configJSON.DelegateInChargeLabel}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Field
                                                                name="delegateInCharge"
                                                                data-test-id="delegateInCharge"
                                                                className="selectField delegateSelectField"
                                                                as={CustomSelect}
                                                                placeholder="Select Delegate"
                                                                setValue={true}
                                                                options={delegateInCharge}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    setFieldValue("delegate_id", e.target.value)
                                                                }
                                                                value={values.delegate_id}
                                                                error={touched.delegate_id && errors.delegate_id}
                                                                errorMsg={errors.delegate_id}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion1}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="bodyHandledAfterPassing"
                                                                aria-label="bodyHandledAfterPassing"
                                                                name="bodyHandledAfterPassing"
                                                                value={values.body_handled_after_passing}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("body_handled_after_passing", e.target.value);
                                                                    this.setState({ showCremationDetails: !showCremationDetails });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="Buried"
                                                                    control={<StyledRadio />}
                                                                    label="Buried"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="Cremated"
                                                                    control={<StyledRadio />}
                                                                    label="Cremated"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.decisionFieldLabel}
                                                            </FormLabel>
                                                            <Field
                                                                data-test-id="decision"
                                                                name="decision"
                                                                className="multilineInput"
                                                                as={CustomInput}
                                                                multiline
                                                                placeholder="Your thoughts behind this"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue(`decision`, e.target.value)
                                                                }}
                                                                value={values.decision}
                                                            />
                                                        </Grid>
                                                        {showCremationDetails && (
                                                            this.renderCremationDetails(values, setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion2}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveFuneralPlace"
                                                                aria-label="haveFuneralPlace"
                                                                name="haveFuneralPlace"
                                                                value={values.have_funeral_place}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_funeral_place", e.target.value);
                                                                    this.setState({ showFuneralPlace: !showFuneralPlace });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showFuneralPlace && (
                                                            this.renderFuneralPlaceDetails(values, errors, touched, setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion3}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveLocationForWake"
                                                                aria-label="haveLocationForWake"
                                                                name="haveLocationForWake"
                                                                value={values.have_location_for_wake}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_location_for_wake", e.target.value);
                                                                    this.setState({ showLocationForWake: !showLocationForWake });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showLocationForWake && (
                                                            this.renderLocationForWakeForm(values, errors, touched, setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion4}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveStoneSpot"
                                                                aria-label="haveStoneSpot"
                                                                name="customized-radios"
                                                                value={values.have_stone_spot}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_stone_spot", e.target.value);
                                                                    this.setState({ showStoneSpot: !showStoneSpot });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showStoneSpot && (
                                                            this.renderStoneSpotForm(setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion5}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveAnySong"
                                                                aria-label="haveAnySong"
                                                                name="customized-radios"
                                                                value={values.have_any_song}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_any_song", e.target.value);
                                                                    this.setState({ showSongs: !showSongs });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showSongs && (
                                                            this.renderSongsFields(values, errors, touched, setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion6}
                                                            </FormLabel>
                                                            {this.renderListOfPeopleFields(values, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion7}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveAddTwoCloseFriend"
                                                                aria-label="haveAddTwoCloseFriend"
                                                                name="customized-radios"
                                                                value={values.have_add_two_close_friend}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_add_two_close_friend", e.target.value);
                                                                    this.setState({ showCloseFriends: !showCloseFriends });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showCloseFriends && (
                                                            this.renderCloseFriendsForm(values, errors, touched, setFieldValue)
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label" className="formRadioLabel">
                                                                {configJSON.funeralBasicsQuestion8}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                className="radioGroup"
                                                                data-test-id="haveSpecificRequest"
                                                                aria-label="haveSpecificRequest"
                                                                name="customized-radios"
                                                                value={values.have_specific_request}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setFieldValue("have_specific_request", e.target.value);
                                                                    this.setState({ showSpecificRequest: !showSpecificRequest });
                                                                }
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="1"
                                                                    control={<StyledRadio />}
                                                                    label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                    className="radioLabel"
                                                                    value="0"
                                                                    control={<StyledRadio />}
                                                                    label="No"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                        {showSpecificRequest && (
                                                            <Grid item xs={12}>
                                                                <FormLabel component="label" className="formRadioLabel">
                                                                    {configJSON.specialRequestFieldLabel}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="specialRequest"
                                                                    name="special_request "
                                                                    className="multilineInput"
                                                                    as={CustomInput}
                                                                    multiline
                                                                    placeholder="Please write your special request"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`special_request`, e.target.value)
                                                                    }}
                                                                    value={values.special_request}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Box>
                                                </Box>
                                            </Box>
                                            <Box className="btnWrapper">
                                                <Button
                                                    className="btnSize"
                                                    variant="outlined"
                                                    onClick={() => this.props.handleNextBtnClick("CustomForm")}
                                                    data-test-id="skipForNow"
                                                >
                                                    {configJSON.skipForNowBtnText}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    data-test-id="saveAndNextBtn"
                                                    className="btnSize"
                                                    variant="contained"
                                                >
                                                    {configJSON.saveAndNext}
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End