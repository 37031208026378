import React from "react";
// Customizable Area Start
import AboutHobbiesController, {
    configJSON,
    Props,
    IHaveHobbies
} from "./AboutHobbiesController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    styled,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {StyledRadio} from "./LegalInformation.web";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
import Spinner from "./components/Spinner.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';

const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
    fill: "#A190C2",
});

// Customizable Area End

export default class AboutHobbies extends AboutHobbiesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            hobbies,
            isloading
        } = this.state;
        return (
            <Box className="formWrapper">
                {isloading && <Spinner spinnerModal={isloading}/>}
                <Formik
                    initialValues={hobbies}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutHobbiesInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutHobbiesForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                    <FormLabel component="label" className="formRadioLabel">
                                {configJSON.hobbiesQuestion}
                              </FormLabel>
                              <RadioGroup
                                data-test-id="haveLegalRepresentative"
                                aria-label="have_legal_representative"
                                name={configJSON.name}
                                value={values.have_hobbies}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue("have_hobbies", e.target.value);
                                }
                                }
                                className="radioGroup"
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<StyledRadio />}
                                  label="Yes"
                                  className="radioLabel"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<StyledRadio />}
                                  label="No"
                                  className="radioLabel"
                                />
                              </RadioGroup>
                                    </Grid>                                    
                                </Grid>
                                {
                                    values.have_hobbies === "1" && <FieldArray name="hobby_details_attributes" data-test-id="fieldArray">
                                        {({ remove, push }) => (
                                            values.hobby_details_attributes.map((_values: IHaveHobbies, index: number) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                                            <Typography data-test-id="fieldArrayTitle" variant="h6">{configJSON.details}</Typography>
                                                            {this.getHobbiesList(index,values.hobby_details_attributes.length ) ?
                                                                <Button
                                                                    className="addLettersBtn"
                                                                    data-test-id="addTattooBtn"
                                                                    onClick={() => push({
                                                                        name: "",
                                                                        location: "",
                                                                        duration: "",
                                                                        files: []
                                                                    })}
                                                                >
                                                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.addMoreHobbies}
                                                                </Button>
                                                                :
                                                                <Button
                                                                    data-test-id="removeBtn"
                                                                    className="addLettersBtn"
                                                                    onClick={() => remove(index)}>
                                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.removeBtnText}
                                                                </Button>}
                                                        </Grid>
                                                        <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.name}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="hoddyName"
                                                    className="inputField"
                                                    name={configJSON.name}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.namePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`hobby_details_attributes.${index}.name`, e.target.value)
                                                    }}
                                                    value={_values.name}
                                                />
                                                <ErrorMessage name={`hobby_details_attributes.${index}.name`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.location}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="hobbyLocation"
                                                    className="inputField"
                                                    name={configJSON.location}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.locationPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`hobby_details_attributes.${index}.location`, e.target.value)
                                                    }}
                                                    startIcon = {styledMyLocationTwoToneIcon}
                                                    value={_values.location}
                                                />
                                                <ErrorMessage name={`hobby_details_attributes.${index}.location`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormLabel component="label" className="formRadioLabel">
                                {configJSON.durationHeading}
                              </FormLabel>
                              </Grid>
                              <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.duration}
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="hobbyDuration"
                                                    className="inputField"
                                                    name={configJSON.duration}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.durationPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`hobby_details_attributes.${index}.duration`, e.target.value)
                                                    }}
                                                    value={_values.duration}
                                                />
                                                <ErrorMessage name={`hobby_details_attributes.${index}.duration`} />
                                            </Grid>
                              </Grid>
                                                        <Grid item xs={12}>
                                                            <MediaUpload
                                                                data-test-id="mediaUploadForHobbies"
                                                                files={_values.files}
                                                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleHoddieFile(event, setFieldValue, index, _values.files)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        )}
                                    </FieldArray>
                                }
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="skipForNow"
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    data-test-id="saveAndNextBtn"
                                    type="submit"
                                    className="btnSize"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End