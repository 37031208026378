import React from "react";

// Customizable Area Start
import {
    Avatar,
    Box,
    Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import FinalPdfController, { configJSON, Props } from "./FinalPdfController.web";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "36px",
            },
            h2: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "28px",
            },
            h3: {
                color: "#64748B",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "28px",
            },
            subtitle1: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
                color: "#9176C4",
            },
            body1: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                color: "#1E293B",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "22px",
            },
        },
    },
});
// Customizable Area End


export default class FinalPdf extends FinalPdfController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { previewData } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <WebHeader navigation={this.props.navigation} />
                    <Box className="container">
                        <Box className="pageTitleWrapper">
                            <Box>
                                <Typography variant="h2" data-test-id="title">{configJSON.finalPDFText}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {configJSON.previewPdfDescription}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="pdfPreviewWrapper">
                        <Box className="header">
                            <Typography variant="h1">{`${previewData.name}'s`}</Typography>
                            <Typography variant="h1">{configJSON.littleBookOfMemories}</Typography>
                            <Box className="profileImgWrapper">
                                <Avatar
                                    alt="Profile"
                                    src={previewData.profile_photo}
                                    className="profileImage"
                                />
                            </Box>
                            <Typography variant="h1" style={{ color: "#79B432"}}>
                                {configJSON.aboutMeText}
                            </Typography>
                        </Box>

                        <Box className="personalInfo">
                                <Box>
                                    <Typography variant="h2" align="center">
                                        {configJSON.personalInformationTitle}
                                    </Typography>
                                </Box>
                                <Box className="personalInformation">
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.nationalityLabel}</span> {previewData.personalInfo.nationality}</Typography>
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.userIdLabel}</span> {previewData.personalInfo.userId}</Typography>
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.genderLabel}</span> {previewData.personalInfo.gender}</Typography>
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.dobLabel}</span> {previewData.personalInfo.dob}</Typography>
                                </Box>
                        </Box>
                            <Box my={2} style={{ maxWidth: "60%"}}>
                                <Typography variant="h2" align="center" gutterBottom>
                                    {configJSON.reasonForProfileCreationTitle}
                                </Typography>
                                <Typography variant="h3" align="center" gutterBottom>
                                    {configJSON.reasonForProfileCreation}
                                </Typography>
                            </Box>

                        <Typography variant="h1" style={{ color: "#79B432"}} align="center" gutterBottom>
                            {configJSON.myDelegatesTitle}
                        </Typography>
                        <Box className="delegates">
                            {previewData.myDelegates.map((delegate) => {
                                return(
                                    <Box className="delegateCard">
                                        <Avatar
                                            variant="square"
                                            src={delegate.photo_url}
                                            alt="Delegate 1"
                                            className="delegatesImage"
                                        />
                                        <Typography variant="body1">{delegate.name}</Typography>
                                    </Box>
                                );
                            })}
                        </Box>

                        <Box className="legalInfo">
                            <Typography variant="h1" style={{ color: "#79B432"}} align="center" gutterBottom>
                                {configJSON.legalInformationTitle}
                            </Typography>
                                <Box className="personalInformation">
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.attorneyLabel}</span>&nbsp;&nbsp;&nbsp;{previewData.legalInfo.attorney}</Typography>
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.haveWillLabel}</span>&nbsp;&nbsp;&nbsp;{previewData.legalInfo.have_will}</Typography>
                                    <Typography variant="body1" className="dataWithLabel"><span className="lableText">{configJSON.lifeInsuranceLabel}</span>&nbsp;&nbsp;&nbsp;{previewData.legalInfo.have_life_insurance}</Typography>
                                </Box>
                        </Box>
                        </Box>
                    </Box>
                    <WebFooter data-test-id="webFooter" handleNavigation={this.handleNavigation} />
                </Wrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        display: "flex",
        flexDirection: "column",
        padding: "50px 72px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
    },
    "& .pageTitleWrapper": {
        maxWidth: "900px",
        width: "100%",
    },
    "& .pdfPreviewWrapper": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        width: "100%",
        margin: "32px 0px",
        padding: "64px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    "& .header": {
        textAlign: "center",
        marginBottom: "24px",
    },
    "& .profileImgWrapper": {
        display: "flex",
        justifyContent: "center",
    },
    "& .profileImage": {
        borderRadius: "50%",
        width: "200px",
        height: "200px",
        margin: "24px",
        border: "8px solid #E0F2CA",
    },
    "& .delegatesImage": {
        width: "240px",
        height: "200px",
        marginBottom: "16px",
        border: "8px solid #E0F2CA",
    },
    "& .personalInfo": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        margin: "24px 0",
    },
    "& .personalInformation": {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        margin: "16px 0",
    },
    "& .lableText": {
        color: "gray",
    },
    "& .delegates": {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    "& .delegateCard": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
    },
    "& .legalInfo": {
        marginTop: "24px",
        width: "100%",
    },
    [theme.breakpoints.down(830)]: {
        "& .container": {
            padding: "20px",
        },
        "& .profileImage": {
            width: "100px",
            height: "100px",
        },
        "& .personalInfo": {
            flexDirection: "column",
            alignItems: "center",
        },
        "& .personalInformation": {
            flexDirection: "column",
            alignItems: "center",
        },
        "& .delegates": {
            flexDirection: "column",
            alignItems: "center",
        },
        "& .delegateCard": {
            width: "100%",
        },
        "& .dataWithLabel": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    },
}));
// Customizable Area End
