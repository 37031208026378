import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  FormHelperText 
} from "@material-ui/core";
import { 
  Field, 
  FieldArray, 
  Form, 
  Formik, 
  FormikErrors, 
  FormikTouched, 
  FormikValues 
} from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import PhoneInput from "react-phone-input-2";

import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import GenericModal from "../../../components/src/GenericModal";
import MediaUpload from "../../../components/src/MediaUpload.web";
import { countries } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import LegalInformationController, {
  ILegalInformations,
  IPolicyDetails,
  Props,
  configJSON,
} from "./LegalInformationController.web";

const paymentFrequency = [
  { value: 1, name: "Monthly" },
  { value: 2, name: "Quaterly" },
  { value: 3, name: "Half- yearly" },
  { value: 4, name: "Yearly" },
];


export const StyledRadio = styled((props) => <Radio disableRipple color="default" {...props} />)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& [class*="MuiSvgIcon-root"]': {
    width: 20,
    height: 20,
    borderRadius: '16px',
    backgroundColor: '#f5f8fa',
    border: "1px solid #1E293B",
    boxSizing: "border-box",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    fill: "#FFF",
  },
  '&[class*="Mui-checked"] [class*="MuiSvgIcon-root"]': {
    backgroundColor: '#A190C2',
    boxShadow: 'none',
    border: "none",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      content: '""',
      fill: "#FFF",
    },
  },
}));

export const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Quattrocento",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      h2: {
        color: "#64748B",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
      },
      h3: {
        fontFamily: "Quattrocento",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      h6: {
        fontFamily: "Quattrocento",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "54px",
      },
      subtitle2: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
      },
      body1: {
        color: "#1E293B",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
    MuiFormLabel: {
        root: {
          color: "#334155", 
          fontFamily: "Lato", 
          fontSize: "14px", 
          fontWeight: 400,
          lineHeight: "22px",
        }
    },

    MuiButton: {
      outlined: {
        width: "248px",
        height: "48px",
        color: "#9176C4",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
      contained: {
        width: "248px",
        height: "48px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
    },
    MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: "#9176C4",
        },
        }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: "#A190C2",
        },
      }
    },
    MuiSwitch: {
      root: {
        padding: 0,
        borderRadius: "40px",
        width: "48px",
        height: "28px",
      },
      switchBase: {
        padding: "2px",
        color: "#fff",
        '&$checked': {
          transform: 'translateX(20px)',
          color: "#fff",
          '& + $track': {
            opacity: 1,
            backgroundColor: "#A190C2 !important",
            borderColor: "#A190C2 !important",
          },
    },
      },
      thumb: {
        boxShadow: 'none',
        width: "24px",
        height: "24px",
      },
      track: {
        color: "fff",
        borderRadius: "40px",
        backgroundColor: "gray",
        opacity: 1,
      },
      colorPrimary:{
        '&$checked':{
          padding: "2px",
          color: "#fff",
        }
      }
    },
    MuiInputBase: {
      root: {
        minHeight: '48px',
      },
    },
  },
});
// Customizable Area End

export default class LegalInformation extends LegalInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLegalRepresentativeForm = (
    values: FormikValues, 
    errors: FormikErrors<ILegalInformations>, 
    touched: FormikTouched<ILegalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <Grid item className="formSectionBackground">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography data-test-id="legalRepresentativeFormTitle" variant="h6">{configJSON.legalRepresentativeFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.fullNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="full_name"
                        name="full_name"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.fullNamePlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("legal_representative.full_name", e.target.value)
                        }
                        value={values.legal_representative.full_name}
                        error={touched.legal_representative?.full_name && errors.legal_representative?.full_name}
                        errorMsg={errors.legal_representative?.full_name}
                    />
                </Grid>
                <Grid item lg={4}>
                <FormLabel component="label">{configJSON.emailIDLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="email_id"
                        name="email_id"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.emailIDPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("legal_representative.email", e.target.value)
                        }
                        value={values.legal_representative.email}
                        error={touched.legal_representative?.email && errors.legal_representative?.email}
                        errorMsg={errors.legal_representative?.email}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.phoneNumberLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Box>
                        <PhoneInput
                        data-test-id="phone_number"
                        placeholder={configJSON.phoneNumberPlaceholder}
                        country={'gb'}
                        value={values.legal_representative.full_phone_number}
                        onChange={(value) =>
                            setFieldValue("legal_representative.full_phone_number", value)
                        }
                        inputStyle={{
                            padding: '10px 45px',
                            width: '300px',
                            height: '48px',
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                        }}
                        buttonStyle={{
                          padding: '2px',
                          border: '1px solid #e0e0e0',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                        }}
                        containerStyle={{
                          margin: '5px 0',
                          width: '300px',
                          height: '48px',
                        }}
                        inputProps={{
                          required: true,
                          autoFocus: true,
                          name: 'phone',
                        }}
                        />
                        {touched.legal_representative?.full_phone_number && errors.legal_representative?.full_phone_number &&
                        <FormHelperText error>
                            {errors.legal_representative?.full_phone_number}
                        </FormHelperText>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Grid>
  )};


  renderKinDetailsForm = (
    values: FormikValues, 
    errors: FormikErrors<ILegalInformations>, 
    touched: FormikTouched<ILegalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <Grid item style={{ margin: "24px 0" }} className="formSectionBackground">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography data-test-id="kinDetailsFormTitle" variant="h6">{configJSON.kinDetailsFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.fullNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="kinFullName"
                        name="kin_full_name"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.kinFullNamePlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.full_name", e.target.value)
                        }
                        value={values.kin_detail.full_name}
                        error={touched.kin_detail?.full_name && errors.kin_detail?.full_name}
                        errorMsg={errors.kin_detail?.full_name}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.relationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="kinRelation"
                        name="relation"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.kinRelationFieldPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.relation", e.target.value)
                        }
                        value={values.kin_detail.relation}
                        error={touched.kin_detail?.relation && errors.kin_detail?.relation}
                        errorMsg={errors.kin_detail?.relation}
                    />
                </Grid>
                <Grid item lg={4}>
                <FormLabel component="label">{configJSON.emailIDLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="kinEmailId"
                        name="kin_email_id"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.kinEmailIDPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.email", e.target.value)
                        }
                        value={values.kin_detail.email}
                        error={touched.kin_detail?.email && errors.kin_detail?.email}
                        errorMsg={errors.kin_detail?.email}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.phoneNumberLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Box>
                        <PhoneInput
                        data-test-id="kinPhoneNumber"
                        country={'gb'}
                        value={values.kin_detail.full_phone_number}
                        onChange={(value) =>
                            setFieldValue("kin_detail.full_phone_number", value)
                        }
                        inputStyle={{
                            width: '300px',
                            height: '48px',
                            borderRadius: '8px',
                            border: '1px solid #e0e0e0',
                            padding: '10px 45px',
                        }}
                        containerStyle={{
                            width: '300px',
                            height: '48px',
                            margin: '5px 0',
                        }}
                        buttonStyle={{
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            border: '1px solid #e0e0e0',
                            padding: '2px',
                        }}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                        }}
                        />
                        {touched.kin_detail?.full_phone_number && errors.kin_detail?.full_phone_number &&
                        <FormHelperText error>
                            {errors.kin_detail?.full_phone_number}
                        </FormHelperText>
                        }
                    </Box>
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">{configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                  <Field
                    data-test-id="kinDOB"
                    name="Kin-dob"
                    className="inputField"
                    size="small"
                    as={CustomDatePicker}
                    placeholder={configJSON.kinDateOfBirthPlaceholder}
                    ageRestriction={18}
                    endIcon={
                      this.calenderIcon
                    }
                    onChange={(date: Date) => 
                       setFieldValue("kin_detail.date_of_birth", date.toLocaleDateString("en-GB"))
                    }
                    value={values.kin_detail.date_of_birth}
                    error={touched.kin_detail?.date_of_birth && errors.kin_detail?.date_of_birth}
                    errorMsg={errors.kin_detail?.date_of_birth}
                  />
                </Grid>
                <Grid item lg={4}>
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="kinAddressLine1"
                    name="kinAddressLine1"
                    className="inputField"
                    size="small"
                    startIcon={styledMyLocationTwoToneIcon}
                    as={CustomInput}
                    placeholder={configJSON.kinAddressLine1Placeholder}
                    value={values.kin_detail.address_line_1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("kin_detail.address_line_1", e.target.value)
                    }
                    error={touched.kin_detail?.address_line_1 && errors.kin_detail?.address_line_1}
                    errorMsg={errors.kin_detail?.address_line_1}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="kinAddressLine2"
                    name="kinAddressLine2"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.kinAddressLine2Placeholder}
                    value={values.kin_detail.address_line_2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("kin_detail.address_line_2", e.target.value)
                    }
                    error={touched.kin_detail?.address_line_2 && errors.kin_detail?.address_line_2}
                    errorMsg={errors.kin_detail?.address_line_2}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.landmarkLabel}
                  </FormLabel>
                  <Field
                    data-test-id="kinLandmark"
                    name="kinLandmark"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.kinLandmarkPlaceholder}
                    value={values.kin_detail.landmark}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("kin_detail.landmark", e.target.value)
                    }
                    error={touched.kin_detail?.landmark && errors.kin_detail?.landmark}
                    errorMsg={errors.kin_detail?.landmark}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="kinTownCity"
                    name="kinTownCity"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.kinTownCityPlaceholder}
                    value={values.kin_detail.city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("kin_detail.city", e.target.value)
                    }
                    error={touched.kin_detail?.city && errors.kin_detail?.city}
                    errorMsg={errors.kin_detail?.city}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="kinCountry"
                    className="selectField"
                    name="kinCountry"
                    as={CustomSelect}
                    placeholder={configJSON.kinCountryPlaceholder}
                    options={countries}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("kin_detail.country", e.target.value)
                    }}
                    value={values.kin_detail.country}
                    errorMsg={errors.kin_detail?.country}
                    error={touched.kin_detail?.country && errors.kin_detail?.country}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="kinPostCode"
                    name="kinPostCode"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.kinPostCodePlaceholder}
                    value={values.kin_detail.post_code}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("kin_detail.post_code", e.target.value)
                    }
                    error={touched.kin_detail?.post_code && errors.kin_detail?.post_code}
                    errorMsg={errors.kin_detail?.post_code}
                  />
                </Grid>
            </Grid>
        </Grid>
  )};

  renderLocationOfWillDetailsForm = (
    values: FormikValues, 
    errors: FormikErrors<ILegalInformations>, 
    touched: FormikTouched<ILegalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <Grid item style={{ margin: "24px 0" }} className="formSectionBackground">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography data-test-id="locationOfWillFormTitle" variant="h6">{configJSON.locationOfWillFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.nameOfPersonHoldingWillLabel}</FormLabel>
                    <Field
                        data-test-id="willName"
                        name="will_name"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.nameOfPersonHoldingWillPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.full_name", e.target.value)
                        }
                        value={values.will.full_name}
                    />
                </Grid>
                <Grid item lg={8}>
                    <FormLabel component="label">{configJSON.additionalInformationLabel}</FormLabel>
                    <Field
                        data-test-id="additionalInfo"
                        name="additionalInfo"
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.additionalInformationPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.about", e.target.value)
                        }
                        value={values.will.about}
                    />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="willAddressLine1"
                    name="willAddressLine1"
                    className="inputField"
                    size="small"
                    startIcon={styledMyLocationTwoToneIcon}
                    as={CustomInput}
                    placeholder={configJSON.willAddressLine1Placeholder}
                    value={values.will.address_line_1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("will.address_line_1", e.target.value)
                    }
                    error={touched.will?.address_line_1 && errors.will?.address_line_1}
                    errorMsg={errors.will?.address_line_1}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="willAddressLine2"
                    name="willAddressLine2"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.willAddressLine2Placeholder}
                    value={values.will.address_line_2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("will.address_line_2", e.target.value)
                    }
                    error={touched.will?.address_line_2 && errors.will?.address_line_2}
                    errorMsg={errors.will?.address_line_2}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.landmarkLabel}
                  </FormLabel>
                  <Field
                    data-test-id="willLandmark"
                    name="willLandmark"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.willLandmarkPlaceholder}
                    value={values.will.landmark}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("will.landmark", e.target.value)
                    }
                    error={touched.will?.landmark && errors.will?.landmark}
                    errorMsg={errors.will?.landmark}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="willTownCity"
                    name="willTownCity"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.willTownCityPlaceholder}
                    value={values.will.city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("will.city", e.target.value)
                    }
                    error={touched.will?.city && errors.will?.city}
                    errorMsg={errors.will?.city}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="willCountry"
                    className="selectField"
                    name="willCountry"
                    as={CustomSelect}
                    placeholder={configJSON.willCountryPlaceholder}
                    options={countries}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("will.country", e.target.value)
                    }}
                    value={values.will.country}
                    error={touched.will?.country && errors.will?.country}
                    errorMsg={errors.will?.country}
                  />
                </Grid>
                <Grid item lg={4}>
                  <FormLabel component="label">
                    {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Field
                    data-test-id="willPostCode"
                    name="willPostCode"
                    className="inputField"
                    size="small"
                    as={CustomInput}
                    placeholder={configJSON.willPostCodePlaceholder}
                    value={values.will.post_code}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("will.post_code", e.target.value)
                    }
                    error={touched.will?.post_code && errors.will?.post_code}
                    errorMsg={errors.will?.post_code}
                  />
                </Grid>
                <Grid item lg={12}>
                  <MediaUpload 
                   data-test-id="mediaUpload"
                  files={this.state.files}
                  onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
                  />
                </Grid>
            </Grid>
        </Grid>
  )};

  renderPolicyDetailsForm = (
    values: FormikValues, 
    errors: FormikErrors<ILegalInformations>, 
    touched: FormikTouched<ILegalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="policy" data-test-id="fieldArray">
        {({ remove, push }) => (
          values.policy.map((policy: IPolicyDetails, index: number) => {
            return(
              <Grid item style={{ margin: "24px 0" }} className="formSectionBackground">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="policyDetailsHeaderWrapper">
                    <Typography data-test-id="policyDetailsFormTitle" variant="h6">{configJSON.policyDetailsFormTitle}</Typography>
                    {index === 0 && (
                    <Button 
                      className="addMorePolicyBtn"
                      data-test-id = "addMorePolicyBtn"
                      onClick={() => push({
                        policy_number: "",
                        provider: "",
                        agent_name: "",
                        plan_name: "",
                        policy_term: "",
                        payment_frequency: "",
                        start_date: "",
                        maturity_date: "",
                        sum_assured: "",
                      })}
                    >
                      <AddCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.addMorePolicyBtnText}
                    </Button>
                    )}
                    {index !== 0 && (
                    <Button 
                      data-test-id="removePolicyBtn"
                      className="addMorePolicyBtn"
                      onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.removePolicyBtnText}
                    </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.policyNumberLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="policyNumber"
                      name="policyNumber"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.policyNumberPlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.policy_number`, e.target.value)
                      }
                      value={policy.policy_number}
                      error={touched.policy?.[index]?.policy_number &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.policy_number
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.policy_number}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.policyProviderLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="policyProvider"
                      name="policyProvider"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.policyProviderPlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.provider`, e.target.value)
                      }
                      value={policy.provider}
                      error={touched.policy?.[index]?.provider &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.provider
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.provider}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.agentNameLabel}</FormLabel>
                    <Field
                      data-test-id="agentName"
                      name="agentName"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.agentNamePlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.agent_name`, e.target.value)
                      }
                      value={policy.agent_name}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.planNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="planName"
                      name="planName"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.planNamePlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.plan_name`, e.target.value)
                      }
                      value={policy.plan_name}
                      error={touched.policy?.[index]?.plan_name &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.plan_name
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.plan_name}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.policyTermLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="policyTerm"
                      name="policyTerm"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.policyTermPlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.policy_term`, e.target.value)
                      }
                      value={policy.policy_term}
                      error={touched.policy?.[index]?.policy_term &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.policy_term
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.policy_term}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                      {configJSON.paymentFrequencyLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="paymentFrequency"
                      className="selectField"
                      name="paymentFrequency"
                      as={CustomSelect}
                      placeholder={configJSON.paymentFrequencyPlaceholder}
                      options={paymentFrequency}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`policy.${index}.payment_frequency`, e.target.value)
                      }}
                      value={policy.payment_frequency}
                      error={touched.policy?.[index]?.payment_frequency &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.payment_frequency
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.payment_frequency}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.startDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="policyStartDate"
                      name="policyStartDate"
                      className="inputField"
                      size="small"
                      as={CustomDatePicker}
                      placeholder={configJSON.startDatePlaceholder}
                      endIcon={
                        this.calenderIcon
                      }
                      onChange={(date: Date) => {
                        setFieldValue(`policy.${index}.start_date`, date.toLocaleDateString("en-GB"))
                      }
                      }
                      value={policy.start_date}
                      error={touched.policy?.[index]?.start_date &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.start_date
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.start_date}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.maturityDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="policyMaturityDate"
                      name="policyMaturityDate"
                      className="inputField"
                      size="small"
                      as={CustomDatePicker}
                      placeholder={configJSON.maturityDatePlaceholder}
                      minDate= {Date.now()}
                      endIcon={
                        this.calenderIcon
                      }
                      onChange={(date: Date) => {
                        setFieldValue(`policy.${index}.maturity_date`, date.toLocaleDateString("en-GB"))
                      }
                      }
                      value={policy.maturity_date}
                      error={touched.policy?.[index]?.maturity_date &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.maturity_date
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.maturity_date}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">
                      {configJSON.sumAssuredLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="sumAssured"
                      name="sumAssured"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      type="number"
                      placeholder={configJSON.sumAssuredPlaceholder}
                      value={policy.sum_assured}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`policy.${index}.sum_assured`, e.target.value)
                      }
                      errorMsg={(errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.sum_assured}
                      error={touched.policy?.[index]?.sum_assured &&
                        (errors.policy?.[index] as FormikErrors<IPolicyDetails>)?.sum_assured}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
  )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      legalInformations,
      have_legal_representative,
      have_will,
      have_life_insurance,
      openDialog,
      delegateInCharge,
    } = this.state;
    return (
      <>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} progress={9}/>
          <Box className="container">
            <Box className="sidebarWrapper">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
            </Box>
            <Box className="legalInfoWrapper">
                <Box className="pageTitle">
                    <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                        {configJSON.legalInformationPageTitle}
                    </Typography>
                </Box>
                <Box height="100%" className="formWrapper">
                <Formik
                    initialValues={legalInformations}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleLegalInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="legalInformationForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                    <Form style={{height: '100%'}}>
                      <Box minHeight="calc(100% - 100px)" className="formWrapper">
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item>
                              <FormLabel component="label">
                                {configJSON.DelegateInChargeLabel}<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Field
                                data-test-id="delegateInCharge"
                                name="delegateInCharge"
                                className="selectField delegateInChargeField"
                                as={CustomSelect}
                                placeholder="Select Delegate"
                                options={delegateInCharge}
                                setValue={true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                   setFieldValue("legal_information.delegate_id", e.target.value)
                                }
                                value={values.legal_information.delegate_id}
                                error={
                                  touched.legal_information?.delegate_id && 
                                  errors.legal_information?.delegate_id
                                }
                                errorMsg={errors.legal_information?.delegate_id}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel component="label" className="formRadioLabel">
                                {configJSON.haveLegalRepresentativeLabel}
                              </FormLabel>
                              <RadioGroup
                                data-test-id="haveLegalRepresentative"
                                aria-label="have_legal_representative"
                                name="have_legal_representative"
                                value={values.legal_information.have_legal_representative}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue("legal_information.have_legal_representative", e.target.value);
                                  this.setState({ have_legal_representative: !have_legal_representative });
                                }
                                }
                                className="radioGroup"
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<StyledRadio />}
                                  label="Yes"
                                  className="radioLabel"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<StyledRadio />}
                                  label="No"
                                  className="radioLabel"
                                />
                              </RadioGroup>
                            </Grid>
                            {have_legal_representative && (
                              this.renderLegalRepresentativeForm(values, errors, touched, setFieldValue)
                            )}
                            {have_legal_representative && (
                              this.renderKinDetailsForm(values, errors, touched, setFieldValue)
                            )}
                            <Grid item xs={12}>
                              <FormLabel component="label" className="formRadioLabel">
                                {configJSON.haveWillLabel}
                              </FormLabel>
                              <RadioGroup
                                data-test-id="have_will"
                                aria-label="have_will"
                                name="have_will"
                                value={values.legal_information.have_will}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue("legal_information.have_will", e.target.value);
                                  this.setState({ have_will: !have_will });
                                }
                                }
                                className="radioGroup"
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<StyledRadio />}
                                  label="Yes"
                                  className="radioLabel"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<StyledRadio />}
                                  label="No"
                                  className="radioLabel"
                                />
                              </RadioGroup>
                              {!have_will && (
                                <Typography className="DIYText">
                                  <ErrorIcon style={{ fill: "#F59E0B" }} />
                                  {configJSON.DIYText}{" "}<span className="spanText">{configJSON.delegatemeeSiteLinkText}</span>
                                </Typography>
                              )}
                            </Grid>
                            {have_will && (
                              this.renderLocationOfWillDetailsForm(values, errors, touched, setFieldValue)
                            )}
                            <Grid item xs={12}>
                              <FormLabel component="label" className="formRadioLabel">
                                {configJSON.haveLifeInsuranceLabel}
                              </FormLabel>
                              <RadioGroup
                                data-test-id="have_life_insurance"
                                aria-label="have_life_insurance"
                                name="have_life_insurance"
                                value={values.legal_information.have_life_insurance}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue("legal_information.have_life_insurance", e.target.value);
                                  this.setState({ have_life_insurance: !have_life_insurance });
                                }
                                }
                                className="radioGroup"
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<StyledRadio />}
                                  label="Yes"
                                  className="radioLabel"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<StyledRadio />}
                                  label="No"
                                  className="radioLabel"
                                />
                              </RadioGroup>
                            </Grid>
                            {have_life_insurance && (
                                    this.renderPolicyDetailsForm(values, errors, touched, setFieldValue)
                            )}
                          </Grid>
                        </Box>
                        <Box className="btnWrapper">
                          <Button
                            data-test-id="skipForNow"
                            variant="outlined"
                            className="btnSize"
                            onClick={() => this.handleOpenSkipDialog()}>
                            {configJSON.skipForNowBtnText}
                          </Button>
                          <Button
                            data-test-id="saveAndNextBtn"
                            variant="contained"
                            className="btnSize"
                            type="submit"
                          >
                            {configJSON.saveAndNext}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                    )}
                    </Formik>
                </Box>
            </Box>
          </Box>
          <WebFooter handleNavigation={this.handleNavigation} />
        </Wrapper>
      </ThemeProvider>
      <GenericModal open={openDialog === "infoAdded"} data-test-id="information-added-modal" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                      <Typography align="center" variant="h1" data-test-id="emailSentModalTitle">{configJSON.infoAddedDialogTitleText}</Typography>
                      <Typography align="center" variant="h2">
                        Your legal information has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                      </Typography>
                    </Box>
                    <Box className="dialogBtnsWrapper">
                      <Button
                        className="outlinedBtn"
                        variant="outlined"
                        onClick={() => this.handleNavigation("CustomForm")}
                        data-test-id="skipForNowDialogBtn"
                      >
                        {configJSON.skipForNowBtnText}
                      </Button>
                      <Button
                        className="containedBtn"
                        variant="contained"
                        onClick={() => this.handleNavigation("MedicalInformation")}
                        data-test-id="nextSectionBtn"
                      >
                        {configJSON.nextSectionBtnText}
                      </Button>
                    </Box>
                  </Box>
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>
      <GenericModal open={openDialog === "infoNotAdded"} data-test-id="information-not-added-modal" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                      <Typography align="center" variant="h1" data-test-id="emailSentModalTitle">{configJSON.infoNotAddedTitleText}</Typography>
                      <Typography align="center" variant="h2">
                        {configJSON.infoNotAddedDescriptionText}
                      </Typography>
                    </Box>
                    <Box className="dialogBtnsWrapper">
                      <Button
                        className="outlinedBtn"
                        variant="outlined"
                        onClick={() => this.handleNavigation("CustomForm")}
                        data-test-id="skipForNowModalBtn"
                      >
                        {configJSON.skipForNowBtnText}
                      </Button>
                      <Button
                        className="containedBtn"
                        variant="contained"
                        onClick={() => this.handleCloseDialog()}
                        data-test-id="backBtn"
                      >
                        {configJSON.backBtnText}
                      </Button>
                    </Box>
                  </Box>
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    display: "flex",
    gap: "72px",
    padding: "50px 72px",
  },
  "& .pageTitle": {
    margin: "32px 0",
  },
  "& .formWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .inputField": {
    height: "48px",
    width: "300px",
    borderRadius: "8px",
  },
  "& .selectField": {
    borderRadius: "8px",
    margin: "5px 0px",
    display: "block",
    height: "48px",
    width: "300px",
  },
  "& .delegateInChargeField": {
    width: "275px",
  },
  "& .radioGroup": {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: "16px",
    gap: "35px",
  },
  "& .radioLabel": {
    color: '#1E293B',
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .DIYText": {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    backgroundColor: "#FEF3C7",
    padding: "8px 9px 8px 12px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    boxSizing: "border-box",
  },
  "& .formSectionBackground": {
    backgroundColor: "rgba(206, 195, 229, 0.1)",
    borderRadius: "8px",
    padding: "24px",
  },
  "& .calenderIconStyle": {
    cursor: "grab",
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .btnSize": {
    width: "158px",
    height: "48px",
  },
  "& .spanText": {
    fontWeight: 700,
    textDecoration: "underline",
  },
  "& .policyDetailsHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .addMorePolicyBtn": {
    color: "#9176C4",
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    '&:hover': {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  "& .formRadioLabel": {
    fontSize: "16px !important",
    color: "#1E293B !important",
  },
  
  //PhoneInput CSS override
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    backgroundColor: "unset",
  },
  "& .react-tel-input .selected-flag:hover": {
    backgroundColor: "unset",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));

export const DialogWrapper = styled('div')(({ theme }) => ({
  "& .alignCenter": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  "& .dialogBtnsWrapper": {
    display: "flex",
    justifyContent: "center",
    gap: "8px",
  },
  "& .nextSpan": {
    color: "black",
    fontWeight: 700,
  },
  "& .outlinedBtn": {
    width: "161px",
    height: "44px",
    color: "#9176C4",
    borderRadius: "100px",
    textTransform: "none",
    padding: "16px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
  },
  "& .containedBtn": {
    width: "161px",
    height: "44px",
    backgroundColor: "#9176C4",
    color: "#FFFFFF",
    borderRadius: "100px",
    textTransform: "none",
    padding: "16px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
  },
  "& .dialogContentWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .dialogDescriptionWrapper": {
    maxWidth: "339px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "16px",
    marginBottom: "32px",
  },
  "& .petPhotoOptionsDialogWrapper": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "8px",
    justifyContent: "center",
  },
  "& .templatesWrapper": {
    display: "flex",
    gap: "24px",
    flex: 1,
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "scroll",
  },
  [theme.breakpoints.down('xs')]: {
    "& .dialogBtnsWrapper": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
  },
}));

export const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
  fill: "#A190C2",
});
// Customizable Area End
